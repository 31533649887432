import data from "@/assets/road.json";
import { IRoadType } from '../type'

export interface IRoadStubType {
    fetch(): Promise<IRoadType[]>
}

export default class RoadStubStore implements IRoadStubType {
    fetch (): Promise<IRoadType[]> {
        return new Promise((resolve) => resolve(data as IRoadType[]))
    }
}