
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UIPersona',
  props: {
    id: { type: Number, required: true },
    age: { type: String, required: false, default: '' },
    avatar: { type: String, required: false, default: '' },
    gender: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: '#C8C8C8' },
    slogan: { type: String, required: false, default: '' }
  }
})
