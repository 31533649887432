import {Config} from '@/config'
import {CheckboxFilterType, DayAbbr, DayCode, WeekdayFlag} from '@/enum/barchart'
import {RootState} from '@/store/types'
import {GetterTree} from 'vuex'
import {ChartState} from './type'
import _ from 'lodash'
import {IChartItemType, IChartType} from '@/data/chart/type'
import {leadingZero} from '@/utils/time'

const WEEKEND_ABBR = [DayAbbr.Saturday, DayAbbr.Sunday]
const WEEKDAY_ABBR = [DayAbbr.Monday, DayAbbr.Tuesday, DayAbbr.Wednesday, DayAbbr.Thursday, DayAbbr.Friday]

export const getters: GetterTree<ChartState, RootState> = {
  getWeekdayFlag (state: ChartState): boolean {
    return state.weekFilter.includes(1)
  },
  
  getChartLegends (state: ChartState): string[] {
    return state.dayConstraint.map(code => {
      return Config.Instance.translateDayByCode(code)
    })
  },

  getWeekLegends (state: ChartState): string[] {
    return state.weekConstraint.map(code => {
      return Config.Instance.translateWeekByCode(code)
    })
  },

  getCountHomeCity (state: ChartState): number[] {
    const filtered = _.filter(state.chart, (value: IChartItemType, key: string) => {
      const code = Config.Instance.translateDayToCode(key as DayAbbr)
      return state.dayConstraint.includes(code)
    })
    return _.map(filtered, (value: IChartItemType) => {
      const homecity = (value.holiday ? value.holiday.home_city : 0) + (value.not_holiday ? value.not_holiday.home_city : 0)
      const not_home_city = (value.holiday ? value.holiday.not_home_city : 0) + (value.not_holiday ? value.not_holiday.not_home_city : 0)
      const max = homecity + not_home_city
      return Math.floor((100 * homecity) / max)
    })
  },

  getCountNotHomeCity (state: ChartState): number[] {
    const filtered = _.filter(state.chart, (value: IChartItemType, key: string) => {
      const code = Config.Instance.translateDayToCode(key as DayAbbr)
      return state.dayConstraint.includes(code)
    })
    return _.map(filtered, (value: IChartItemType) => {
      const homecity = (value.holiday ? value.holiday.home_city : 0) + (value.not_holiday ? value.not_holiday.home_city : 0)
      const not_home_city = (value.holiday ? value.holiday.not_home_city : 0) + (value.not_holiday ? value.not_holiday.not_home_city : 0)
      const max = homecity + not_home_city
      return Math.ceil((100 * not_home_city) / max)
    })
  },

  getCountHomeCityWeek (state: ChartState): number[] {
    const homeCityWeekday = getWeekdayHomeCity(state.chart)
    const homeCityWeekend = getWeekendHomeCity(state.chart)
    const notHomeCityWeekday = getWeekdayNotHomeCity(state.chart)
    const notHomeCityWeekend = getWeekendNotHomeCity(state.chart)
    const weekdayMax = homeCityWeekday + notHomeCityWeekday
    const weekendMax = homeCityWeekend + notHomeCityWeekend
    
    if (state.weekConstraint.length === 1 && state.weekConstraint[0] === WeekdayFlag.Weekday) {
      return [Math.ceil((100 * homeCityWeekday) / weekdayMax)]
    }

    if (state.weekConstraint.length === 1 && state.weekConstraint[0] === WeekdayFlag.Weekend) {
      return [Math.ceil((100 * homeCityWeekend) / weekendMax)]
    }

    return [Math.ceil((100 * homeCityWeekday) / weekdayMax), Math.ceil((100 * homeCityWeekend) / weekendMax)]
  },

  getCountNotHomeCityWeek (state: ChartState): number[] {
    const notHomeCityWeekday = getWeekdayNotHomeCity(state.chart)
    const notHomeCityWeekend = getWeekendNotHomeCity(state.chart)
    const homeCityWeekday = getWeekdayHomeCity(state.chart)
    const homeCityWeekend = getWeekendHomeCity(state.chart)
    
    const weekdayMax = homeCityWeekday + notHomeCityWeekday
    const weekendMax = homeCityWeekend + notHomeCityWeekend
    if (state.weekConstraint.length === 1 && state.weekConstraint[0] === WeekdayFlag.Weekday) {
      return [Math.floor((100 * notHomeCityWeekday) / weekdayMax)]
    }

    if (state.weekConstraint.length === 1 && state.weekConstraint[0] === WeekdayFlag.Weekend) {
      return [Math.floor((100 * notHomeCityWeekend) / weekendMax)]
    }

    return [Math.floor((100 * notHomeCityWeekday) / weekdayMax), Math.floor((100 * notHomeCityWeekend) / weekendMax)]
  },

  getFirstDayLabel (state: ChartState): string {
    if (state.activeFilterType === CheckboxFilterType.WeekDiffer) {
      return Config.Instance.translateWeekByCode(state.weekConstraint[0])
    }
    return Config.Instance.translateDayByCode(state.dayConstraint[0])
  },

  filterDay (state: ChartState): boolean {
    return state.selectedFilterType === CheckboxFilterType.DayDiffer
  },

  filterWeek (state: ChartState): boolean {
    return state.selectedFilterType === CheckboxFilterType.WeekDiffer
  },
  
  filteringDay (state: ChartState): boolean {
    return state.activeFilterType === CheckboxFilterType.DayDiffer
  },

  filteringWeek (state: ChartState): boolean {
    return state.activeFilterType === CheckboxFilterType.WeekDiffer
  },

  getWeekLabel (state: ChartState): string {
    const str = _.map(state.weekConstraint, (week: WeekdayFlag) => {
      return Config.Instance.translateWeekByCode(week) + '/'
    })
    return _.reduce(str, (agg, val) => agg + val, '').replace(/\/$/, '')
  },

  getDayLabel (state: ChartState): string {
    const str = _.map(state.dayConstraint, (day: DayCode) => {
      return Config.Instance.translateDayByCode(day) + '/'
    })
    return _.reduce(str, (agg, val) => agg + val, '').replace(/\/$/, '')
  },

  getVolumnHomeCityWeek (state: ChartState): number[] {
    const homeCityWeekday = getWeekdayHomeCity(state.chart)
    const homeCityWeekend = getWeekendHomeCity(state.chart)
    const averageHomeCityWeekday = homeCityWeekday / 5
    const averageHomeCityWeekend = homeCityWeekend / 2
    return  [100, Math.ceil((100 * averageHomeCityWeekend) / averageHomeCityWeekday)]
  },

  getVolumnNotHomeCityWeek: function (state: ChartState): number[] {
    const notHomeCityWeekday = getWeekdayNotHomeCity(state.chart)
    const notHomeCityWeekend = getWeekendNotHomeCity(state.chart)
    const averageNotHomeCityWeekday = notHomeCityWeekday / 5
    const averageNotHomeCityWeekend = notHomeCityWeekend / 2
    return [100, Math.ceil((100 * averageNotHomeCityWeekend) / averageNotHomeCityWeekday)]
  },

  getVolumeHomeCity (state: ChartState): number[] {
    if (state.dayConstraint.length === 0) return []
    const uniform = {
      sun: state.chart.sun,
      mon: state.chart.mon,
      tue: state.chart.tue,
      wed: state.chart.wed,
      thu: state.chart.thu,
      fri: state.chart.fri,
      sat: state.chart.sat
    }
    const filtered = _.filter(uniform, (value: IChartItemType, key: string) => {
      const code = Config.Instance.translateDayToCode(key as DayAbbr)
      return state.dayConstraint.includes(code)
    })
    const firstDay = Config.Instance.translateUniversalDayByCode(state.dayConstraint[0])
    const firstDayData: IChartItemType = state.chart[firstDay]
    const max = (firstDayData.holiday ? firstDayData.holiday.home_city : 0) + (firstDayData.not_holiday ? firstDayData.not_holiday.home_city : 0)

    return _.map(filtered, (value: IChartItemType) => {
      const agg = (value.holiday ? value.holiday.home_city : 0) + (value.not_holiday ? value.not_holiday.home_city : 0)
      return Math.ceil((100 * agg) / max)
    })
  },

  getVolumnNotHomeCity (state: ChartState): number[] {
    if (state.dayConstraint.length === 0) return []
    const uniform = {
      sun: state.chart.sun,
      mon: state.chart.mon,
      tue: state.chart.tue,
      wed: state.chart.wed,
      thu: state.chart.thu,
      fri: state.chart.fri,
      sat: state.chart.sat
    }
    const filtered = _.filter(uniform, (value: IChartItemType, key: string) => {
      const code = Config.Instance.translateDayToCode(key as DayAbbr)
      return state.dayConstraint.includes(code)
    })
    const firstDay = Config.Instance.translateUniversalDayByCode(state.dayConstraint[0])
    const firstDayData: IChartItemType = state.chart[firstDay]
    const max = (firstDayData.holiday ? firstDayData.holiday.not_home_city : 0) + (firstDayData.not_holiday ? firstDayData.not_holiday.not_home_city : 0)

    return _.map(filtered, (value: IChartItemType) => {
      const agg = (value.holiday ? value.holiday.not_home_city : 0) + (value.not_holiday ? value.not_holiday.not_home_city : 0)
      return Math.ceil((100 * agg) / max)
    })
  },

  getTimeRangeLabel (state: ChartState): string {
    return `${leadingZero(state.activeTime[0])} - ${leadingZero(state.activeTime[1])}`
  },

  getDoInitFlg (state: ChartState): boolean {
    return state.doInitFlg
  }
}

function getWeekdayHomeCity (chart: IChartType): number {
  const uniform = {
    sun: chart.sun,
    mon: chart.mon,
    tue: chart.tue,
    wed: chart.wed,
    thu: chart.thu,
    fri: chart.fri,
    sat: chart.sat
  }
  const filtered = _.filter(uniform, (value: IChartItemType, key: string) => {
    return WEEKDAY_ABBR.includes(key as DayAbbr)
  })
  return _.reduce(filtered, (agg: number, val: IChartItemType) => {
    return agg + ((val.holiday.home_city ? val.holiday.home_city : 0) + (val.not_holiday.home_city ? val.not_holiday.home_city : 0))
  }, 0)
}

function getWeekdayNotHomeCity (chart: IChartType): number {
  const uniform = {
    sun: chart.sun,
    mon: chart.mon,
    tue: chart.tue,
    wed: chart.wed,
    thu: chart.thu,
    fri: chart.fri,
    sat: chart.sat
  }
  const filtered = _.filter(uniform, (value: IChartItemType, key: string) => {
    return WEEKDAY_ABBR.includes(key as DayAbbr)
  })
  return _.reduce(filtered, (agg: number, val: IChartItemType) => {
    return agg + ((val.holiday.not_home_city ? val.holiday.not_home_city : 0) + (val.not_holiday.not_home_city ? val.not_holiday.not_home_city : 0))
  }, 0)
}

function getWeekendHomeCity (chart: IChartType): number {
  // aggregate home_city for weekend
  const uniform = {
    sun: chart.sun,
    mon: chart.mon,
    tue: chart.tue,
    wed: chart.wed,
    thu: chart.thu,
    fri: chart.fri,
    sat: chart.sat
  }
  const weekendFiltered = _.filter(uniform, (value: IChartItemType, key: string) => {
    return WEEKEND_ABBR.includes(key as DayAbbr)
  })
  return _.reduce(weekendFiltered, (agg: number, val: IChartItemType) => {
    return agg + ((val.holiday ? val.holiday.home_city : 0) + (val.not_holiday ? val.not_holiday.home_city : 0))
  }, 0)
}

function getWeekendNotHomeCity (chart: IChartType): number {
  // aggregate home_city for weekend
  const uniform = {
    sun: chart.sun,
    mon: chart.mon,
    tue: chart.tue,
    wed: chart.wed,
    thu: chart.thu,
    fri: chart.fri,
    sat: chart.sat
  }
  const weekendFiltered = _.filter(uniform, (value: IChartItemType, key: string) => {
    return WEEKEND_ABBR.includes(key as DayAbbr)
  })
  return _.reduce(weekendFiltered, (agg: number, val: IChartItemType) => {
    return agg + ((val.holiday ? val.holiday.not_home_city : 0) + (val.not_holiday ? val.not_holiday.not_home_city : 0))
  }, 0)
}
