import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icon-chevron-down.svg'


const _hoisted_1 = { class: "jr-text-s jr-font-medium" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "jr-pl-10 jr-py-2.5 jr-border-gray-light jr-border-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIRadioButton = _resolveComponent("UIRadioButton")!

  return (_openBlock(), _createElementBlock("figure", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args))),
      class: _normalizeClass(["jr-flex jr-py-3 jr-pl-0.5 jr-border-gray-light jr-cursor-pointer", { 'jr-border-b-1': _ctx.border || !_ctx.line.isCollapsed }])
    }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "icon-chevron-down",
        class: _normalizeClass(["jr-mx-4 jr-transform jr-transition-all", { 'jr-rotate-180': !_ctx.line.isCollapsed }])
      }, null, 2),
      _createElementVNode("p", _hoisted_1, _toDisplayString(`${_ctx.line.company_name}${_ctx.line.line_name}`), 1)
    ], 2),
    (!_ctx.line.isCollapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.line.stations, (station, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_UIRadioButton, {
                  label: station,
                  value: station,
                  "model-value": _ctx.selectedStation,
                  onChange: _ctx.handleStationChange
                }, null, 8, ["label", "value", "model-value", "onChange"])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}