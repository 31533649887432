import {HttpRequest} from '@/core/httpRequest'
import {IPersonaType} from '@/data/master/area/persona/type'
import {HTTPMethod} from '@/core/httpClient'
import {getAccessToken} from '@/utils/storage'
import {APIType} from '@/core/apiType'

export class AreaPersonaMasterRequest implements HttpRequest<IPersonaType[]> {
  public response!: IPersonaType[]
  public path = '/area/persona/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaPersonaMaster
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}
