import {IconLayer} from '@deck.gl/layers'

function uniformIcon(icon: string, type: number): string {
  return icon ? icon.replace('.svg', `-${type}.svg`) : icon
}

export const iconFactory = function (data: number[][], facilities: Record<string, string>): IconLayer {
  return new IconLayer({
    data: data,
    getIcon: d => {
      const icon = uniformIcon(facilities[d[2]], d[3]) || require('@/assets/area/facilities/icon-facility-default.svg')
      return {
        url: icon,
        width: 17,
        height: 24,
        x: 0,
        y: 0
      }
    },
    sizeScale: 30,
    getPosition: d => d,
    // getColor: d => {
    //   const alpha = getIconAlpha(d[3])
    //   return [22, 103, 210, alpha]
    // }
  })
}

// under 0 => set some opacity value
export function getIconAlpha (percentage: number): number {
  if (percentage >= 0 && percentage < 25) {
    return 0.25 * 255
  } else if (percentage >= 25 && percentage < 50) {
    return 0.5 * 255
  } else if (percentage >= 50 && percentage < 75) {
    return 0.75 * 255
  } else {
    return 255
  }
}
