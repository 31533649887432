
import {computed, defineComponent} from 'vue'
import {RailwayActionType} from '@/store/modules/railway/actions'
import {useStore} from 'vuex'
import FilterNavigation from '@/components/subviews/FilterNavigation.vue'
import RailwayReportSidebar from '@/components/subviews/railway/RailwayReportSidebar.vue'

export default defineComponent({
  name: 'RailwayReport',
  components: {
    RailwayReportSidebar,
    FilterNavigation
  },
  setup () {
    let manager
    const store = useStore()
    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const handleMinimum = () => store.dispatch(`railway/${RailwayActionType.toggleSidebar}`)
    return {
      sbMinimum,
      manager,
      handleMinimum
    }
  }
})
