
import {defineComponent, reactive, toRefs} from 'vue'

export default defineComponent({
  name: 'FilterWrapper',
  emits:['minimum'],
  setup (_, { emit }) {
    const state = reactive({
      minimum: false,
    })

    const onClickClose = (event) => {
      event.preventDefault();
      state.minimum = !state.minimum;
      emit('minimum', state.minimum);
    }

    return{
      ...toRefs(state),
      onClickClose
    }
  }
})
