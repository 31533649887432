import {GetterTree} from 'vuex'
import {AreaPolygonMasterState} from '@/store/modules/area/master/polygon/type'
import {RootState} from '@/store/types'

export const getters: GetterTree<AreaPolygonMasterState, RootState> = {
  flattenPolygon (state: AreaPolygonMasterState) {
    return state.polygons.map(p => {
      return p.polygon.map(poly => [poly, p.id])
    }).flat()
  }
}
