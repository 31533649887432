
import {defineComponent, reactive, toRefs} from 'vue'
import {Form} from 'vee-validate'
import {AlertDialog} from '@/service/dialog'
import {MISMATCH_CONFIRM_PASSWORD, PASSWORD_RESET_SUCCESS, SESSION_NAMESPACE} from '@/constant'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {ActionType} from '@/store'
import UIInputField from '@/components/UI/UIInputField.vue'
import UIButton from '@/components/UI/UIButton.vue'
import AuthRepository from '@/data/auth/repository'
import AuthAPIStore from '@/data/auth/store'

interface State {
  password: string,
  passwordConfirm: string
}

export default defineComponent({
  name: 'PasswordReset',
  components: {
    Form,
    UIInputField,
    UIButton
  },
  setup () {
    const api = new AuthAPIStore()
    const repository = new AuthRepository(api)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const state = reactive<State>({
      password: '',
      passwordConfirm: ''
    })
    const submit = () => false
    const token = route.params.token as string
    const userID = route.params.user_id as string

    // token check
    const tokenCheck = () => {
      store.dispatch(ActionType.setAppLoading, true)
      repository.passwordResetTokenCheck(userID, token)
        .catch(err => {
          AlertDialog(err.message)
          if (err.status === 404) {
            router.push('/login')
          }
        })
        .finally(() => store.dispatch(ActionType.setAppLoading, false))
    }
    tokenCheck()

    const handleSubmit = () => {
      // validate if password and confirm password does not match
      if (state.password !== state.passwordConfirm) {
        AlertDialog(MISMATCH_CONFIRM_PASSWORD)
        return
      }

      store.dispatch(ActionType.setAppLoading, true)
      repository.passwordReset(userID, token, state.password, state.passwordConfirm)
        .then(() => {
          localStorage.removeItem(SESSION_NAMESPACE)
          AlertDialog(PASSWORD_RESET_SUCCESS)
          router.push('/login')
        })
        .catch(err => AlertDialog(err.message))
        .finally(() => store.dispatch(ActionType.setAppLoading, false))
    }

    return {
      ...toRefs(state),
      handleSubmit,
      submit
    }
  }
})
