
import {computed, defineComponent, PropType, reactive, toRefs} from 'vue'
import {Chart, registerables} from 'chart.js'
import {BarChart, useBarChart} from 'vue-chart-3'
import {v4 as uuidv4} from 'uuid'
import ChartDataLabels from 'chartjs-plugin-datalabels'

interface State {
  play: boolean
  model: number
  fpsId: number
}

Chart.register(...registerables, ChartDataLabels)
export default defineComponent({
  name: 'UIBarchartTimeController',
  components: {BarChart},
  emits: ['change'],
  props: {
    data: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => ([])
    },
    fps: {
      type: Number,
      required: false,
      default: 2
    },
    captureClip: {
      type: Boolean,
      required: false
    }
  },
  setup (props, {emit}) {
    const rangeId = `input_range_${uuidv4()}`
    const state = reactive<State>({
      play: false,
      model: 0,
      fpsId: -1
    })
    const options = computed(() => {
      return {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.7,
        events: [], // remove this setting to show event related items such as tooltips
        plugins: {
          datalabels: { display: false },
          legend: { display: false }
        },
        scales: {
          x: {
            ticks: {
              font: { size: 11 },
              color: '#000'
            },
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false
            }
          },
          y: {
            font: { size: 11 },
            min: 0,
            max: 100,
            ticks: {
              stepSize: 100,
              font: { size: 11 },
              color: '#000'
            },
            grid: {
              drawBorder: false,
              drawTicks: false
            }
          }
        }
      }
    })

    const chartData = computed(() => {
      const labels = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
      const colors = labels.map((l, i) => {
        if (i === state.model) {
          return '#E66231'
        }
        return '#1967D2'
      })
      return {
        labels: labels,
        datasets: [
          {
            data: props.data ? props.data : [],
            backgroundColor: colors
          }
        ]
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })

    const handlePlay = () => {
      state.play = !state.play
      if (state.fpsId >= 0) {
        clearTimeout(state.fpsId)
      }
      if (state.play) {
        if (state.model >= 23) {
          state.model = 0
        }
        window.requestAnimationFrame(loopAnimation)
      }
    }

    const loopAnimation = () => {
      if (state.play) {
        const fps = props.fps ? props.fps : 2
        state.fpsId = setTimeout(() => {
          const id = window.requestAnimationFrame(loopAnimation)
          state.model = Number(state.model) + 1
          if (state.model >= 23) {
            window.cancelAnimationFrame(id)
            state.play = false
          }
          handleChange()
        }, 1000 / fps)
      }
    }

    const handleChange = () => {
      const value = Number(state.model)
      state.model = value
      emit('change', value)
    }
    return {
      ...toRefs(state),
      rangeId,
      barChartProps,
      handlePlay,
      handleChange
    }
  }
})
