import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "jr-flex jr-text-xs jr-px-2.5 jr-py-1 jr-mr-1.5 jr-bg-gray-light jr-rounded" }
const _hoisted_2 = {
  key: 0,
  class: "jr-mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title) + ":", 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
  ]))
}