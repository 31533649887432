import {GetterTree} from 'vuex'
import {PrefectureMasterState} from '@/store/modules/area/master/prefecture/type'
import {RootState} from '@/store/types'
import {ICityType, IPrefectureType} from '@/data/master/area/prefecture/type'

export const getters: GetterTree<PrefectureMasterState, RootState> = {
  selectedCities (state: PrefectureMasterState): ICityType[] {
    return state.prefectures.map(pref => pref.cities).flat()
      .filter(city => city.isSelected)
  },
  allCities (state: PrefectureMasterState): ICityType[] {
    return state.prefectures.map(pref => pref.cities).flat()
  },
  hasCitySelectedPref (state: PrefectureMasterState): IPrefectureType[] {
    return state.prefectures.filter(pref => {
      return pref.cities.some(city => city.isSelected)
    })
  },
  selectedCityLabels (state: PrefectureMasterState): string {
    return state.prefectures.map(pref => {
      return pref.cities
        .filter(city => city.isSelected)
        .map(city => `${pref.name}${city.name}`)
    }).flat().join(',')
  }
}
