import {IRailwayMasterAPIStoreType} from '@/data/master/railway/store'
import {IRailwayMasterStubType} from '@/data/master/railway/store/stub'
import {IRailLineType} from '@/data/master/railway/type'
import {RailLineRequest} from "@/data/master/railway/store/request";

export interface IRailwayMasterRepositoryType {
  api: IRailwayMasterAPIStoreType
  stub: IRailwayMasterStubType
  fetchRailLine(params: Record<string, any>): Promise<IRailLineType[]>
}

export default class RailwayMasterRepository implements IRailwayMasterRepositoryType {
  stub: IRailwayMasterStubType
  api: IRailwayMasterAPIStoreType
  
  // DI
  constructor (stub: IRailwayMasterStubType, api: IRailwayMasterAPIStoreType) {
    this.stub = stub
    this.api = api
  }
  
  async fetchRailLine(params: Record<string, any>): Promise<IRailLineType[]> {
    const request = new RailLineRequest(params)
    return this.api.fetchRailLine(request)
  }
}
