<template>
  <div>
    <div class="jr-flex jr-justify-between jr-pt-6">
      <p class="jr-text-s-2 jr-text-gray-dark">時間帯</p>
      <button class="jr-text-xxs-1 jr-text-blue-bold" @click="clear">クリア</button>
    </div>
    <div class="jr-w-full jr-px-2">
      <vue-slider
          ref="slider"
          v-model="periods"
          :min="min"
          :max="max"
          :min-range="minrange"
          :height="height"
          tooltip='none'
          :clickable="false"
          :process="handleProcessStyle"
          @change="(e)=>drag(e)"
      ></vue-slider>
    </div>
    <div v-show="isHideUnspecifiedValue">
      <div v-show="handleIsTimeSpecified" class="jr-text-center jr-text-s-2 jr-text-blue-bold jr-font-bold">指定なし</div>
      <div v-show="!handleIsTimeSpecified" class="jr-text-center jr-text-s-2 jr-text-blue-bold jr-font-bold">{{formatHourToLeadingZero(time[0])}}時 - {{formatHourToLeadingZero(time[1])}}時</div>
    </div>
    <div v-show="!isHideUnspecifiedValue">
      <div class="jr-text-center jr-text-s-2 jr-text-blue-bold jr-font-bold">{{formatHourToLeadingZero(time[0])}}時 - {{formatHourToLeadingZero(time[1])}}時</div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watchEffect} from "vue";
import {formatHourToLeadingZero} from '@/utils/time'
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css';

export default defineComponent({
  props: {
    time: { type: Array, required: false, default: () => [] },
    min: { type: Number, required: false, default: () => 0 },
    max: { type: Number, required: false, default: () => 24 },
    isHideUnspecifiedValue: { type: Boolean, required: false, default: false },
  },
  components: {
    VueSlider
  },
  emits: ['update:time'],
  setup(props, {emit}) {
    const periods = ref([]);
    const minrange = 0;
    const height = 10;

    watchEffect(()=>{
      periods.value = props.time
    })

    const handleIsTimeSpecified = computed(() => props.time[0] === props.min && props.time[1] === props.max)
    const handleProcessStyle = computed(() => {
      return dotsPos => {
        const background = props.isHideUnspecifiedValue ?
                            dotsPos[0] === 0 && dotsPos[1] === 100 ? 'transparent' : '#334A7C'
                            : '#334A7C'
        return [[dotsPos[0], dotsPos[1], { backgroundColor: background }]]
      }
    })
    const drag = (value) => {
      const timeValue = [value[0], value[1]]
      emit('update:time', timeValue)
    }
    const clear = () => {
      emit('update:time', [props.min, props.max])
    }
    return {
      minrange,
      height,
      periods,
      drag,
      clear,
      formatHourToLeadingZero,
      handleIsTimeSpecified,
      handleProcessStyle
    }
  }
})
</script>

<style>
.vue-slider-dot-handle {
  background: #334A7C;
  border: 2px solid white;
  box-shadow: none;
  width: 16px !important;
  height: 16px !important;
}
.vue-slider-dot {
  width: 16px !important;
  height: 16px !important;
}
.vue-slider-rail {
  background: #DBDBDB;
  border-radius: 0;
  height: 2px !important;
  width: 100%;
}
.vue-slider-process {
  height: 4px !important;
  top: -1px !important;
}
.vue-slider {
  padding-top: 20px !important;
  width: 100% !important;
  padding-bottom: 3px !important;
}
</style>
