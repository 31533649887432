import {HttpRequest} from '@/core/httpRequest'
import {IRailwayAnimationType, IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import {HttpClient} from '@/core/httpClient'

export interface IRailwayAPIStoreType {
  fetchRailwayStationBarchart(request: HttpRequest<IRailwayStationBarchartType>): Promise<IRailwayStationBarchartType>
  fetchHeatmap(request: HttpRequest<number[][]>): Promise<number[][]>
  fetchComparison(request: HttpRequest<IRailwayComparisonType>): Promise<IRailwayComparisonType>
  fetchAnimation(request: HttpRequest<IRailwayAnimationType>): Promise<IRailwayAnimationType>
}

export default class RailwayAPIStore implements IRailwayAPIStoreType {
  async fetchRailwayStationBarchart(request: HttpRequest<IRailwayStationBarchartType>): Promise<IRailwayStationBarchartType> {
    const client = new HttpClient<IRailwayStationBarchartType>()
    const response = await client.request<IRailwayStationBarchartType>(request)
    return response.data
  }
  
  async fetchHeatmap(request: HttpRequest<number[][]>): Promise<number[][]> {
    const client = new HttpClient<number[][]>()
    const response = await client.request<number[][]>(request)
    return response.data
  }
  
  async fetchComparison(request: HttpRequest<IRailwayComparisonType>): Promise<IRailwayComparisonType> {
    const client = new HttpClient<IRailwayComparisonType>()
    const response = await client.request<IRailwayComparisonType>(request)
    return response.data
  }
  
  async fetchAnimation(request: HttpRequest<IRailwayAnimationType>): Promise<IRailwayAnimationType> {
    const client = new HttpClient<IRailwayAnimationType>()
    const response = await client.request<IRailwayAnimationType>(request)
    return response.data
  }
}
