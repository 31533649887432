import {IAreaPolygonMasterAPIStoreType} from '@/data/master/area/polygon/store'
import {IAreaPolygonMasterType} from '@/data/master/area/polygon/type'
import {AreaPolygonMasterRequest} from '@/data/master/area/polygon/store/request'

export interface IAreaPolygonMasterRepositoryType {
  api: IAreaPolygonMasterAPIStoreType
  fetch(params: Record<string, any>): Promise<IAreaPolygonMasterType[]>
}

export default class AreaPolygonRepository implements IAreaPolygonMasterRepositoryType {
  api: IAreaPolygonMasterAPIStoreType
  
  // DI
  constructor (api: IAreaPolygonMasterAPIStoreType) {
    this.api = api
  }
  
  async fetch(params: Record<string, any>): Promise<IAreaPolygonMasterType[]> {
    const request = new AreaPolygonMasterRequest(params)
    return await this.api.fetch(request)
  }
}
