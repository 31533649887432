
import {computed, defineComponent, reactive, toRefs, watch} from 'vue'
import {useStore} from 'vuex'
import {ActionType} from '@/store'
import {getUsername} from '@/utils/storage'
import {AreaActionType} from '@/store/modules/area/actions'
import {setAccessToken, getAccessToken} from '@/utils/storage'
import {useRoute, useRouter} from 'vue-router'
import {IAreaConfig} from '@/data/area/type'
import AppLoader from '@/components/AppLoader.vue'
import GlobalNavigation from '@/components/subviews/GlobalNavigation.vue'
import TableMakeLoader from '@/components/TableMakeLoader.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'

interface State {
  isConfigLoading: boolean
}

export default defineComponent({
  components: {TableMakeLoader, AppLoader, GlobalNavigation},
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const state = reactive<State>({
      isConfigLoading: false
    })
    const isAppLoading = computed(() => store.state.isAppLoading)
    const isMakingTable = computed(() => store.state.isMakingTable)
    store.dispatch(ActionType.setUsername, getUsername())

    // data instances
    const areaStub = new AreaStubStore()
    const areaApi = new AreaAPIStore()
    const areaRepository = new AreaRepository(areaStub, areaApi)
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const token = getAccessToken()

    // load area app config
   if (token) {
     state.isConfigLoading = true
     areaRepository.fetchConfig({}).then(configs => {
       manageConfig(configs)
     }).catch(err => {
       if (err.status === 401) {
         return authRepository.refreshToken()
         .then(response => setAccessToken(response.access))
         .then(() => areaRepository.fetchConfig({}))
         .then(configs => manageConfig(configs))
       }
     })
     .finally(() => state.isConfigLoading = false)
   }

   const manageConfig = (configs: IAreaConfig[]) => {
     const t = configs.find(conf => conf.key === 'polygon_max_zoom')
     if (t) {
       store.dispatch(`area/${AreaActionType.setPolygonMaxZoom}`, t.value)
     }

     // display menus
     const menus = configs.find(conf => conf.key === 'display_menus')
     if (menus) {
       store.dispatch(ActionType.setDisplayMenus, menus.value)
     }

     // default center
     const lat = configs.find(conf => conf.key === 'default_latitude')
     const lng = configs.find(conf => conf.key === 'default_longitude')
     if (lat && lng) {
       const latLng = {
         lat: lat.value,
         lng: lng.value
       }
       localStorage.setItem('latlng', JSON.stringify(latLng))
       store.dispatch(ActionType.setDefaultCenter, {
         lat: lat.value,
         lng: lng.value
       })
     }

     // default zoom level
     const zoom = configs.find(conf => conf.key === 'default_zoom_level')
     if (zoom) {
       store.dispatch(ActionType.setDefaultZoom, zoom.value)
     }

     // percentage threshold
     const percentageThreshold = configs.find(conf => conf.key === 'percentage_threshold')
     if (percentageThreshold) {
       store.dispatch(ActionType.setPercentageThreshold, percentageThreshold.value)
     }

     // heatmap max zoom level
     const heatmapMaxZoom = configs.find(conf => conf.key === 'heatmap_max_zoom')
     if (heatmapMaxZoom) {
       store.dispatch(ActionType.setHeatmapMaxZoom, heatmapMaxZoom.value)
     }

     //show area visitor
      const showAreaVisitor = configs.find(
        (conf) => conf.key === "show_area_visitor"
      )
      if (showAreaVisitor) {
        store.dispatch(ActionType.setShowAreaVisitor, showAreaVisitor.value);
      }
   }

    watch(() => store.state.displayMenus, (menus: string[]) => {
      const hasRoad = menus.includes('road')
      const hasRailway = menus.includes('railway')
      const hasArea = menus.includes('area')

      if (!hasRoad && route.meta.isRoad) {
        router.push('/')
      }
      if (!hasRailway && route.meta.isRailway) {
        router.push('/')
      }
      if (!hasArea && route.meta.isArea) {
        router.push('/')
      }
    })

    return {
     ...toRefs(state),
     isAppLoading,
     isMakingTable
   }
  }
})
