
import {defineComponent, toRefs, reactive, watchEffect} from "vue";
import { Config } from "@/config";
import UICheckbox from "@/components/UI/UICheckbox.vue";

interface IFilterOptionsType {
  code: number
  status: boolean
}
export default defineComponent({
  name: "UICheckboxFilter",
  components: {
    UICheckbox
  },
  props: {
    modelValues: {
      type: Array,
      required: false,
      default: () => []
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    type: {
      type: String,
      required: false,
      default: () => ('day'),
      validator: (value: string) => ['day', 'week'].includes(value)
    }
  },
  setup(props, { emit }) {
    const getLabel = (code: number) => {
      if(props.type === 'week') {
        return Config.Instance.getWeekByCode(code).label
      }
      if(props.type === 'day') {
        return Config.Instance.getDayByCode(code).label
      }
      return ""
    }

    const state = reactive<{filterOptions: IFilterOptionsType[]}>({
      filterOptions: []
    })

    watchEffect(() => {
      state.filterOptions = props.options ? props.options.map(d => {
        return {
          code: d,
          status: !!props.modelValues.includes(d)
        } as IFilterOptionsType
      }) : []
    })

    const deserializeOptions = () => {
      return state.filterOptions.length > 0 ? state.filterOptions.filter(o => o.status).map(o => o.code) : []
    }

    const handleFilterChange = () => {
      emit('update:modelValues', deserializeOptions())
    }

    return {
      ...toRefs(state),
      getLabel,
      handleFilterChange,
    }
  }
})
