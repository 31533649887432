import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon-train-circle.svg'


const _withScopeId = n => (_pushScopeId("data-v-df4be6ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container jr-h-full jr-absolute jr-right-0 jr-top-18 jr-z-10 jr-bg-white jr-flex jr-flex-col jr-justify-center jr-items-center" }
const _hoisted_2 = {
  src: _imports_0,
  class: "jr-opacity-30",
  alt: "train"
}
const _hoisted_3 = { class: "jr-text-primary jr-text-sm jr-opacity-30 jr-mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
      [_vShow, _ctx.isShowBadge]
    ]),
    _withDirectives(_createElementVNode("h1", _hoisted_3, "路線利用者／非利用者を可視化します", 512), [
      [_vShow, _ctx.isShowBadge]
    ])
  ]))
}