import { IQueryAPIStoreType } from '../store'
import { QuerySearchRequest } from '../store/request'
import { IQueryStubType } from '../store/stub'
import { IQueryRequestType, IQueryType } from '../type'

export interface IQueryRepositoryType {
  api: IQueryAPIStoreType
  stub: IQueryStubType
  fetch(params: Record<string, any>): Promise<Array<Array<number>>>
}

export default class QueryRepository implements IQueryRepositoryType {
  stub: IQueryStubType
  api: IQueryAPIStoreType

  /// DI
  constructor (stub: IQueryStubType, api: IQueryAPIStoreType) {
    this.stub = stub
    this.api = api
  }

  /**
   * Fetch
   * @returns collection of order entity
   */
  async fetch(params: IQueryRequestType): Promise<Array<Array<number>>> {
    const req = new QuerySearchRequest(params)
    //const response = await this.stub.fetch()
    const response = await this.api.fetch(req)
    return response
  }
}