import {HttpRequest} from '@/core/httpRequest'
import {IRailLineType} from '@/data/master/railway/type'
import {HTTPMethod} from '@/core/httpClient'
import {getAccessToken} from '@/utils/storage'
import {APIType} from '@/core/apiType'

export class RailLineRequest implements HttpRequest<IRailLineType[]> {
  public response!: IRailLineType[]
  public path = '/railway/lines/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.RailwayLine
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}
