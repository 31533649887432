import { IChartType } from '@/data/chart/type'
import { CheckboxFilterType, DayCode, WeekdayFlag } from '@/enum/barchart'
import { MutationTree } from 'vuex'
import { ChartState } from './type'

export enum ChartMutationType {
  SET_CHART = 'SetChart',
  SET_FILTER_TYPE = 'SetFilterType',
  SET_ACTIVE_FILTER_TYPE = 'SetActiveFilterType',
  SET_DAY_CONSTRAINT = 'SetDayConstraint',
  SET_WEEK_CONSTRAINT = 'SetWeekConstraint',
  SET_ACTIVE_TIME = 'SetActiveTime',
  SET_DO_INIT_FLG = 'SetDoInitFlg',
  RESET_DAY = 'ResetDay',
  RESET_WEEK = 'ResetWeek',
  RESET_TIME = 'ResetTime',
  CLEAR_TIME = 'ClearTime',
  CLEAR_WEEK = 'ClearWeek',
  CLEAR_DAY = 'ClearDay'
}

export const mutations: MutationTree<ChartState> = {
  [ChartMutationType.SET_CHART] (state: ChartState, payload: IChartType): void {
    state.chart = payload
  },
  [ChartMutationType.SET_FILTER_TYPE] (state: ChartState, payload: CheckboxFilterType): void {
    state.selectedFilterType = payload
  },
  [ChartMutationType.SET_ACTIVE_FILTER_TYPE] (state: ChartState, payload: CheckboxFilterType): void {
    state.activeFilterType = payload
  },
  [ChartMutationType.SET_DAY_CONSTRAINT] (state: ChartState, payload: DayCode[]): void {
    state.dayConstraint = payload
  },
  [ChartMutationType.SET_WEEK_CONSTRAINT] (state: ChartState, payload: WeekdayFlag[]): void {
    state.weekConstraint = payload
  },
  [ChartMutationType.SET_ACTIVE_TIME] (state: ChartState, payload: number[]): void {
    state.activeTime = payload
  },
  [ChartMutationType.SET_DO_INIT_FLG] (state: ChartState, payload: boolean): void {
    state.doInitFlg = payload
  },
  [ChartMutationType.RESET_DAY] (state: ChartState): void {
    state.dayFilter = [
      DayCode.Sunday,
      DayCode.Monday,
      DayCode.Tuesday,
      DayCode.Wednesday,
      DayCode.Thursday,
      DayCode.Friday,
      DayCode.Saturday
    ]
  },
  [ChartMutationType.RESET_WEEK] (state: ChartState): void {
    state.weekFilter = [WeekdayFlag.Weekday, WeekdayFlag.Weekend]
  },
  [ChartMutationType.RESET_TIME] (state: ChartState): void {
    state.time = [0, 24]
  },
  [ChartMutationType.CLEAR_WEEK] (state: ChartState): void {
    state.weekFilter = []
  },
  [ChartMutationType.CLEAR_TIME] (state: ChartState): void {
    state.time = [0, 0]
  },
  [ChartMutationType.CLEAR_DAY] (state: ChartState): void {
    state.dayFilter = []
  }
}
