import { IChartAPIStoreType } from "../store"
import { ChartRequest } from "../store/request"
import { IChartStubType } from "../store/stub"
import { IChartType } from "../type"

export interface IChatRepositoryType {
	api: IChartAPIStoreType
	stub: IChartStubType
	fetch(params: Record<string, any>): Promise<IChartType>
}

export default class ChatRepository implements IChatRepositoryType {
	stub: IChartStubType
	api: IChartAPIStoreType

	/// DI
	constructor (stub: IChartStubType, api: IChartAPIStoreType) {
		this.stub = stub
		this.api = api
	}

	/**
	 * Fetch
	 * @returns collection of order entity
	 */
	async fetch(params: Record<string, any>): Promise<IChartType> {
		const request = new ChartRequest(params)
		return await this.api.fetch(request)
		// return await this.stub.fetch()
	}
}
