<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <div class="jr-font-base jr-text-sm">
        <p class="jr-whitespace-nowrap">性別・年代別比：選択したエリア来訪者の性別・年代別比を表示しています。</p>
        <p class="wordbreak-keepall">ペルソナ：選択したエリア来訪者のペルソナの構成比をランキング（5位以内）で表示しています。</p>
        <div class="jr-pt-4">
          <p>集計は「日のべ人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合は2とカウント。</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
