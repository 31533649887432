import {MutationTree} from 'vuex'
import {AreaRankingState} from '@/store/modules/area/search/ranking/type'
import {IRankingType} from '@/data/area/type'

export enum AreaRankingMutationType {
  SET_LOADING = 'SetLoading',
  SET_MIN = 'SetMin',
  SET_MAX = 'SetMax',
  SET_RESIDENT = 'SetResident',
  SET_WORK_LOCATION = 'SetWorkLocation'
}

export const mutations: MutationTree<AreaRankingState> = {
  [AreaRankingMutationType.SET_LOADING] (state: AreaRankingState, payload: boolean): void {
    state.isLoading = payload
  },
  [AreaRankingMutationType.SET_MIN] (state: AreaRankingState, payload: number): void {
    state.min = payload
  },
  [AreaRankingMutationType.SET_MAX] (state: AreaRankingState, payload: number): void {
    state.max = payload
  },
  [AreaRankingMutationType.SET_RESIDENT] (state: AreaRankingState, payload: IRankingType[]): void {
    state.resident = payload
  },
  [AreaRankingMutationType.SET_WORK_LOCATION] (state: AreaRankingState, payload: IRankingType[]): void {
    state.workLocation = payload
  }
}
