import {ActionTree} from 'vuex'
import {RailLineState} from '@/store/modules/railway/master/line/type'
import {RootState} from '@/store/types'
import {IRailLineType} from '@/data/master/railway/type'
import {RailLineMutationType} from '@/store/modules/railway/master/line/mutations'

export enum RailLineActionType {
  setLines = 'setLines',
  setSelectedLine = 'setSelectedLine',
  setSelectedStation = 'setSelectedStation',
  toggleLineCollapse = 'toggleLineCollapse',
  setSelectedLineStation = 'setSelectedLineStation',
  setChosenLine = 'setChosenLine',
  setChosenStation = 'setChosenStation'
}

export const actions: ActionTree<RailLineState, RootState> = {
  [RailLineActionType.setLines] ({ commit }, payload: IRailLineType[]): void {
    commit(RailLineMutationType.SET_LINES, payload)
  },
  [RailLineActionType.setSelectedLine] ({ commit }, payload: string): void {
    commit(RailLineMutationType.SET_SELECTED_LINE, payload)
  },
  [RailLineActionType.setSelectedStation] ({ commit }, payload: string): void {
    commit(RailLineMutationType.SET_SELECTED_STATION, payload)
  },
  [RailLineActionType.setChosenLine] ({ commit }, payload: string): void {
    commit(RailLineMutationType.SET_CHOSEN_LINE, payload)
  },
  [RailLineActionType.setChosenStation] ({ commit }, payload: string): void {
    commit(RailLineMutationType.SET_CHOSEN_STATION, payload)
  },
  [RailLineActionType.toggleLineCollapse] ({ commit }, payload: IRailLineType): void {
    commit(RailLineMutationType.TOGGLE_LINE_COLLAPSE, payload)
  },
  [RailLineActionType.setSelectedLineStation] ({ commit }, payload: IRailLineType): void {
    commit(RailLineMutationType.SET_SELECTED_LINE_STATION, payload)
  }
}
