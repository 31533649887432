
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {RailLineActionType} from '@/store/modules/railway/master/line/actions'
import {setSelectedRailwayStation, setRailwayPolygons, setSelectedRailwayLine} from '@/utils/storage'
import RailwayAccordion from '@/components/UI/railway/RailwayAccordion.vue'

interface State {
  minimum: boolean
}

export default defineComponent({
  name: 'StationUserSelector',
  emits: ['clear', 'minimum'],
  components: {RailwayAccordion},
  setup(_, {emit}) {
    const store = useStore()
    const router = useRouter()
    const state = reactive<State>({
      minimum: false
    })
    const lines = computed<string>(() => store.state.railLine.lines)
    const selectedStation = computed(() => store.state.railLine.selectedStation)
    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
      emit('minimum', state.minimum)
    }

    const clearAll = (event: Event) => {
      event.preventDefault()
      emit('clear')
    }

    const handleAccordionChange = (value: string) => {
      store.dispatch(`railLine/${RailLineActionType.setSelectedStation}`, value)
      setSelectedRailwayStation(value)
    }
    const handleSearch = () => {
      setRailwayPolygons({})
      setSelectedRailwayLine(store.state.railLine.selectedLine)
      setSelectedRailwayStation(store.state.railLine.selectedStation)
      store.dispatch(`railLine/${RailLineActionType.setChosenLine}`, store.state.railLine.selectedLine)
      store.dispatch(`railLine/${RailLineActionType.setChosenStation}`, store.state.railLine.selectedStation)
      router.push('/railway_polygon')
    }
    return {
      ...toRefs(state),
      lines,
      selectedStation,
      handleAccordionChange,
      clickClose,
      clearAll,
      handleSearch
    }
  },
})
