import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { ChartState } from './type'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { CheckboxFilterType, DayCode, WeekdayFlag } from '@/enum/barchart'

export const state: ChartState = {
  chart: {
    sun: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    mon: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    tue: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    wed: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    thu: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    fri: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    },
    sat: {
      holiday: { home_city: 0, not_home_city: 0 },
      not_holiday: { home_city: 0, not_home_city: 0 }
    }
  },
  dayFilter: [
    DayCode.Sunday,
    DayCode.Monday,
    DayCode.Tuesday,
    DayCode.Wednesday,
    DayCode.Thursday,
    DayCode.Friday,
    DayCode.Saturday
  ],
  weekFilter: [WeekdayFlag.Weekday, WeekdayFlag.Weekend],
  selectedFilterType: CheckboxFilterType.DayDiffer,
  activeFilterType: CheckboxFilterType.DayDiffer,
  dayConstraint: [
    DayCode.Sunday,
    DayCode.Monday,
    DayCode.Tuesday,
    DayCode.Wednesday,
    DayCode.Thursday,
    DayCode.Friday,
    DayCode.Saturday
  ],
  weekConstraint: [WeekdayFlag.Weekday, WeekdayFlag.Weekend],
  time: [0, 24],
  activeTime: [0, 24],
  doInitFlg: false,
}

const namespaced = true
export const chart: Module<ChartState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
