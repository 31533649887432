
import { defineComponent, PropType } from 'vue'
import { ButtonType } from '@/enum/button'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: '' },
    loading: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    type: { type: String as PropType<ButtonType>, required: false, default: ButtonType.primary },
    primitiveType: { type: String, required: false, default: () => 'button' }
  },
  setup () {
    return {
      ButtonType
    }
  }
})
