import {HttpRequest} from '@/core/httpRequest'
import {IRailLineType} from '@/data/master/railway/type'
import {HttpClient} from '@/core/httpClient'
import RailLineJsonTranslator from "@/data/master/railway/translator";

export interface IRailwayMasterAPIStoreType {
  fetchRailLine(request: HttpRequest<IRailLineType[]>): Promise<IRailLineType[]>
}

export default class RailwayMasterAPIStore implements IRailwayMasterAPIStoreType {
  private translator: RailLineJsonTranslator
  constructor() {
    this.translator = new RailLineJsonTranslator()
  }
  
  async fetchRailLine(request: HttpRequest<IRailLineType[]>): Promise<IRailLineType[]> {
    const client = new HttpClient<IRailLineType>()
    const response = await client.request<IRailLineType[]>(request)
    return response.data.map(d => this.translator.translate(d))
  }
}
