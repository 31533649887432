import { DayCode } from '@/enum/barchart'
import { MutationTree } from 'vuex'
import { HourSeriesState } from './type'

export enum HourSeriesMutationType {
  SET_DAY_CONSTRAINT = 'SetDayConstraint',
  SET_DAY_FILTER = 'SetDayFilter',
  SET_DO_INIT_FLG = 'SetDoInitFlg',
  SET_TIME_LABEL = 'SetTimeLabel',
  SET_ACTIVE_TIME = 'SetActiveTime',
}

export const mutations: MutationTree<HourSeriesState> = {
  [HourSeriesMutationType.SET_DAY_FILTER] (state: HourSeriesState, payload: DayCode[]): void {
    state.dayFilter = payload
  },
  [HourSeriesMutationType.SET_DAY_CONSTRAINT] (state: HourSeriesState, payload: DayCode[]): void {
    state.dayConstraint = payload
  },
  [HourSeriesMutationType.SET_DO_INIT_FLG] (state: HourSeriesState, payload: boolean): void {
    state.doInitFlg = payload
  },
  [HourSeriesMutationType.SET_TIME_LABEL] (state: HourSeriesState, payload: string): void {
    state.timeLabel = payload
  },
  [HourSeriesMutationType.SET_ACTIVE_TIME] (state: HourSeriesState, payload: string): void {
    state.activeTime = payload
  }
}
