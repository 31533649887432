
import {computed, defineComponent} from 'vue'
import { CheckboxFilterType } from '@/enum/barchart'
import {useStore} from "vuex";

export default defineComponent({
  name: 'ContentWrapper',
  props: {
    filters: { type: Array, required: false, default: () => null },
    startDate: { type: String, required: false, default: '' },
    endDate: { type: String, required: false, default: '' },
    dayLabel: { type: String, required: false, default: '' },
    weekLabel: { type: String, required: false, default: '' },
    timeLabel: { type: String, required: false, default: '' },
    showDownload: { type: Boolean, required: false, default: true },
    isMaxWidth: { type: Boolean, required: false, default: true }
  },
  setup () {
    const store = useStore()
    const isCapturing = computed(() => store.state.isCapturing)
    return {
      CheckboxFilterType,
      isCapturing
    }
  }
})
