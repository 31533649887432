import { DayCode } from '@/enum/barchart'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { HourSeriesState } from './type'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state: HourSeriesState = {
  dayFilter: [
    DayCode.Monday,
    DayCode.Tuesday,
    DayCode.Wednesday,
    DayCode.Thursday,
    DayCode.Friday,
    DayCode.Saturday,
    DayCode.Sunday,
    DayCode.EveryDay
  ],
  dayConstraint: [],
  time: [0, 24],
  activeTime: '',
  doInitFlg: false,
  timeLabel: '',
  timeConstraint: []
}

const namespaced = true
export const hourSeries: Module<HourSeriesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
