import {MutationTree} from 'vuex'
import {AreaFacilityPinGroup, AreaFacilityPinState} from '@/store/modules/area/search/facility/type'

export enum AreaFacilityPinMutationType {
  SET_LOADING = 'SetLoading',
  SET_PINS = 'SetPins',
  SET_FACILITY_PIN_GROUPS = 'SetFacilityPinGroup',
  SELECT_ALL_FACILITY_PIN_GROUP = 'SelectAllFacilityPinGroup',
  UNSELECT_ALL_FACILITY_PIN_GROUP = 'UnselectAllFacilityPinGroup'
}

export const mutations: MutationTree<AreaFacilityPinState> = {
  [AreaFacilityPinMutationType.SET_PINS] (state: AreaFacilityPinState, payload: Record<string, any>): void {
    state.pins = payload
  },
  [AreaFacilityPinMutationType.SET_FACILITY_PIN_GROUPS] (state: AreaFacilityPinState, payload: AreaFacilityPinGroup[]): void {
    state.pinGroups = payload
  },
  [AreaFacilityPinMutationType.SET_LOADING] (state: AreaFacilityPinState, payload: boolean): void {
    state.isLoading = payload
  },
  [AreaFacilityPinMutationType.SELECT_ALL_FACILITY_PIN_GROUP] (state: AreaFacilityPinState): void {
    state.pinGroups.forEach(p => p.selected = true)
  },
  [AreaFacilityPinMutationType.UNSELECT_ALL_FACILITY_PIN_GROUP] (state: AreaFacilityPinState): void {
    state.pinGroups.forEach(p => p.selected = false)
  }
}
