
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AreaFilterBadge',
  props: {
    title: { type: String, required: false, default: '' },
    content: { type: String, required: false, default: '' }
  }
})
