
import {defineComponent} from 'vue'
import {Config} from '@/config'

export default defineComponent({
  name: 'PolygonColors',
  setup () {
    const first = Config.Instance.polygonColor.first.hex
    const second = Config.Instance.polygonColor.second.hex
    const third = Config.Instance.polygonColor.third.hex
    const fourth = Config.Instance.polygonColor.fourth.hex
    const fifth = Config.Instance.polygonColor.fifth.hex
    const sixth = Config.Instance.polygonColor.sixth.hex
    return {
      first,
      second,
      third,
      fourth,
      fifth,
      sixth
    }
  }
})
