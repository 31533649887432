
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: "UIEditableSelection",
  props: {
    options: { type: Array, required: false, default: () => [] },
    modelValue: { type: [Boolean, String, Number], required: false, default: false },
  },
  emits: ['update:modelValue', 'selectionChange'],
  setup(props, { emit }) {
    const state = reactive({
      selected: props.modelValue
    })
    const handleChange = () => {
      emit('update:modelValue', state.selected)
      emit('selectionChange')
    }
    return {
      ...toRefs(state),
      handleChange,
    }
  }
})
