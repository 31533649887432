
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {Config} from '@/config'
import {useStore} from 'vuex'
import {Emitter, EventType} from 'mitt'
import {getSelectedRailwayLine, setAccessToken} from '@/utils/storage'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {railwayHeatmapFactory} from '@/layers/heatmap'
import {DOWNLOAD_RAILWAY_HEATMAP, HINT_RAILWAY_HEATMAP, globalEmitter} from '@/service/GlobalEmmiter'
import {ActionType} from '@/store'
import {ConvertToImage} from '@/service/file'
import {
  CONTENT_DOWNLOAD_ELEMENT_ID,
  NO_CONDITION_SPECIFIED,
  RAILWAY_NO_TRANSPORTATION,
  RAILWAY_NO_RESIDENT,
  AREA_WARNING_TITLE,
  AREA_WARNING_MESSAGE,
  MAX_THRESHOLD_TITLE,
  MAX_THRESHOLD_MESSAGE
} from '@/constant'
import {useRoute} from 'vue-router'
import {getSelectedRailwayStation} from '@/utils/storage'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import RailwaySearchFilter from '@/components/subviews/railway/RailwaySearchFilter.vue'
import AppLoader from '@/components/AppLoader.vue'
import RailwayStubStore from '@/data/railway/store/stub'
import RailwayAPIStore from '@/data/railway/store'
import RailwayRepository from '@/data/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import {HintDialog} from '@/service/dialog'
import RailwayHeatmap from '@/components/hints/RailwayHeatmap.vue'

interface State {
  map: any
  captureClip: boolean
  isLoading: boolean
  filterMinimum: boolean
}

export default defineComponent({
  name: 'Heatmap',
  components: {
    AppLoader,
    RailwaySearchFilter,
    UIColorToggler,
    UIGoogleMap
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const colors = Config.Instance.heatmapColors.hex
    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const state = reactive<State>({
      map: null,
      captureClip: false,
      isLoading: false,
      filterMinimum: false
    })
    const line = getSelectedRailwayLine()
    const station = getSelectedRailwayStation()
    const lineSplit = line.split('-')

    // data layer instances
    const stub = new RailwayStubStore()
    const api = new RailwayAPIStore()
    const repository = new RailwayRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })

    const maxZoom = computed(() => {
      const max = store.state.heatmapMaxZoom || 16
      if (state.map) {
        if (state.map.getZoom() > max) {
          state.map.setZoom(max)
        }
      }
      return max
    })

    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(state.map)

      // center the map
      const service = new window.google.maps.places.PlacesService(state.map)
      const request = {
        query: `${station}駅`,
        fields: ['name', 'geometry']
      }
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const geometry = results[0].geometry
          if (geometry !== undefined) {
            if (geometry.location?.lat() !== undefined && geometry.location?.lng() !== undefined) {
              const pos = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
              }
              state.map.setCenter(pos)
              const zoom = localStorage.getItem('zoom')
              if (zoom) {
                state.map.setZoom(Number(zoom))
              }
            }
          }
        }
      })
    }

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const makeAPICall = (params: Record<string, any>) => {
      state.isLoading = true
      deckOverlay.setProps({ layers: [] })
      repository.fetchHeatmap(params).then(res => {
        if (res.length > 0) {
          deckOverlay.setProps({ layers: railwayHeatmapFactory(res) })
        }
        emitter?.emit('notifyRailwayFilter')
      })
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetchHeatmap(params))
          .then(res => {
            if (res.length > 0) {
              deckOverlay.setProps({ layers: railwayHeatmapFactory(res) })
            }
            emitter?.emit('notifyRailwayFilter')
          })
          .catch(err => {
            if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
        } else if (err.status === 404) { // amount of search result is less, and the API return this error
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => state.isLoading = false)
    }
    const handleSearch = (params: Record<string, any>) => {
      params['station'] = station
      delete params.time_range
      delete params.week

      if (lineSplit.length > 0) {
        params['company'] = lineSplit[0]
      }
      if (lineSplit.length > 1) {
        params['line'] = lineSplit[1]
      }

      const trans = params.type && params.type.length === 0
      const resident = params.resident && params.resident.length === 0
      if (trans && resident) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }
      if (trans) {
        AlertDialog(RAILWAY_NO_TRANSPORTATION)
        return
      }
      if (resident) {
        AlertDialog(RAILWAY_NO_RESIDENT)
        return
      }
      makeAPICall(params)
    }

    // Initial load
    const type = store.state.railway.transportations
      .filter(t => t.isSelected)
      .map(t => t.value)
    const resident = store.state.railway.residents
      .filter(r => r.isSelected)
      .map(r => r.value)
    const params = {
      station: station
    }
    if (lineSplit.length > 0) {
      params['company'] = lineSplit[0]
    }
    if (lineSplit.length > 1) {
      params['line'] = lineSplit[1]
    }
    if (type.length < store.state.railway.transportations.length) {
      params['type'] = type
    }
    if (resident.length < store.state.railway.residents.length) {
      params['resident'] = resident
    }
    makeAPICall(params)

    globalEmitter.on(DOWNLOAD_RAILWAY_HEATMAP, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_RAILWAY_HEATMAP, () => {
      HintDialog(RailwayHeatmap)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_RAILWAY_HEATMAP)
      globalEmitter.off(HINT_RAILWAY_HEATMAP)
    })

    return {
      ...toRefs(state),
      colors,
      sbMinimum,
      handleSearch,
      handleMapLoad,
      handleFilterResize,
      maxZoom
    }
  }
})
