import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon-tick.svg'
import _imports_1 from '@/assets/icon-minus.svg'


const _withScopeId = n => (_pushScopeId("data-v-620ebdbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-relative" }
const _hoisted_2 = ["id", "name", "value", "checked", "data-icon"]
const _hoisted_3 = { class: "jr-rounded-sm jr-border-gray jr-border-2 jr-w-4.5 jr-h-4.5 jr-flex jr-flex-shrink-0 jr-justify-center jr-items-center jr-focus-within:border-blue" }
const _hoisted_4 = {
  src: _imports_0,
  alt: "icon-tick",
  class: "jr-fill-current jr-pointer-events-none"
}
const _hoisted_5 = {
  src: _imports_1,
  alt: "icon-minus",
  class: "jr-fill-current jr-pointer-events-none"
}
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["jr-flex", {
    'jr-items-center': _ctx.align === 'center',
    'jr-items-start': _ctx.align === 'top',
    'jr-items-end': _ctx.align === 'bottom'
  }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        id: _ctx.id,
        name: _ctx.name,
        value: _ctx.modelValue,
        checked: _ctx.modelValue,
        "data-icon": _ctx.icon,
        class: "jr-opacity-0 jr-absolute jr-left-0 jr-top-0 jr-h-4 jr-w-4 jr-z-10 jr-cursor-pointer",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 40, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
          [_vShow, _ctx.icon === 'tick']
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
          [_vShow, _ctx.icon === 'minus']
        ])
      ])
    ]),
    _withDirectives(_createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass(["jr-cursor-pointer jr-ml-1.75 jr-align-middle jr-text-s", _ctx.labelClasses])
    }, _toDisplayString(_ctx.label), 11, _hoisted_6), [
      [_vShow, _ctx.label]
    ])
  ], 2))
}