import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef90fd30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-flex jr-fixed jr-w-full jr-h-full" }
const _hoisted_2 = { class: "jr-absolute jr-left-0 jr-bottom-3.9 jr-w-full jr-flex jr-justify-center" }
const _hoisted_3 = { class: "transition jr-w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIAreaToggle = _resolveComponent("UIAreaToggle")!
  const _component_UITimeSeriesController = _resolveComponent("UITimeSeriesController")!
  const _component_UIColorToggler = _resolveComponent("UIColorToggler")!
  const _component_UIGoogleMap = _resolveComponent("UIGoogleMap")!
  const _component_AreaSearchFilter = _resolveComponent("AreaSearchFilter")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIGoogleMap, {
      onLoad: _ctx.handleMapLoad,
      right: _ctx.captureClip ? 16 : _ctx.filterMinimum ? 74 : 300,
      "max-zoom": _ctx.maxZoom,
      class: "content jr-absolute jr-w-full"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UIAreaToggle, {
          class: "transition-slow jr-absolute jr-top-2",
          "data-html2canvas-ignore": "true",
          onToggle: _ctx.handleToggleArea,
          style: _normalizeStyle({ right: _ctx.captureClip ? '16px' : _ctx.filterMinimum ? '74px' : '300px' })
        }, null, 8, ["onToggle", "style"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isPrepareHeatmap)
              ? (_openBlock(), _createBlock(_component_UITimeSeriesController, {
                  key: 0,
                  from: 0,
                  to: 24,
                  step: 24*_ctx.tableUnitVal,
                  onChange: _ctx.onTimeSeriesChange
                }, null, 8, ["step", "onChange"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_UIColorToggler, {
          class: "transition-indicator jr-absolute jr-bottom-18",
          "capture-clip": _ctx.captureClip,
          style: _normalizeStyle({ left: _ctx.captureClip ? '10px' : _ctx.sbMinimum ? '74px' : '250px' }),
          colors: _ctx.colors
        }, null, 8, ["capture-clip", "style", "colors"])
      ]),
      _: 1
    }, 8, ["onLoad", "right", "max-zoom"]),
    _createVNode(_component_AreaSearchFilter, {
      onSearch: _ctx.handleSearch,
      onResize: _ctx.handleFilterResize
    }, null, 8, ["onSearch", "onResize"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}