import {HeatmapLayer} from '@deck.gl/aggregation-layers'
import {Config} from '@/config'

export const heatmapAnimationFactory = function (data: (number | string)[][], colorDomain: number[]): HeatmapLayer {
  return new HeatmapLayer({
    data: data,
    intensity: Config.Instance.heatmapDetailProps.intensity,
    opacity: Config.Instance.heatmapDetailProps.opacity,
    threshold: Config.Instance.heatmapDetailProps.threshold,
    radiusPixels: Config.Instance.heatmapDetailProps.radiusPixels,
    colorDomain: colorDomain,
    getPosition: (d) =>[d[0], d[1]],
    getWeight: (d) => d[3],
    colorRange: Config.Instance.heatmapColors.rgb,
    aggregation: 'SUM'
  })
}

export const areaHeatmapAnimationFactory = function (data: (number | string)[][], colorDomain: number[]): HeatmapLayer {
  return new HeatmapLayer({
    data: data,
    intensity: Config.Instance.heatmapDetailProps.intensity,
    opacity: Config.Instance.heatmapDetailProps.opacity,
    threshold: Config.Instance.heatmapDetailProps.threshold,
    radiusPixels: Config.Instance.heatmapDetailProps.radiusPixels,
    colorDomain: colorDomain,
    getPosition: (d) => [d[1], d[0]],
    getWeight: (d) => d[3],
    colorRange: Config.Instance.heatmapColors.rgb,
    aggregation: 'SUM'
  })
}

export const railwayHeatmapFactory = function (data: number[][]): HeatmapLayer {
  const min = data[0][2]
  const max = data.slice(-1)[0][2]
  return new HeatmapLayer({
    data: data,
    intensity: Config.Instance.heatmapDetailProps.intensity,
    opacity: Config.Instance.heatmapDetailProps.opacity,
    threshold: Config.Instance.heatmapDetailProps.threshold,
    radiusPixels: Config.Instance.heatmapDetailProps.radiusPixels,
    colorDomain: [min, max],
    colorRange: Config.Instance.heatmapColors.rgb,
    aggregation: 'SUM',
    getPosition: d => [d[0], d[1]],
    getWeight: d => d[2]
  })
}
