
import {useStore} from 'vuex'
import {computed, defineComponent, reactive, ref, toRefs} from 'vue'
import {ActionType} from '@/store'
import {GeolocationPosition} from '@/store/types'
import {AlertDialog} from '@/service/dialog'
import {LOCATION_NOT_FOUND, LOCATION_TURN_OFF} from '@/constant'
import {GoogleMap} from 'vue3-google-map'

interface State {
  map: any,
  initMap: boolean,
  isMaxZoom: boolean,
  isCenterLoaded: boolean,
  center: { lat: number; lng: number }
}

export default defineComponent({
  name: 'UIGoogleMap',
  components: {
    GoogleMap
  },
  props: {
    right: { type: Number, required: false, default: 16 },
    maxZoom: { type: Number, required: false, default: 18 }
  },
  emits: ['load'],
  setup (_, { emit }) {
    const apiKey = process.env.VUE_APP_MAP_API_KEY
    const store = useStore()
    const mapStyle = [{ stylers: [{ saturation: -100 }] }]
    const gMap = ref()
    const state = reactive<State>({
      map: null,
      initMap: false,
      isMaxZoom: false,
      isCenterLoaded: false,
      center: store.state.defaultCenter ? store.state.defaultCenter : { lat: 36.7018311, lng: 137.2104371 },
    })

    // handlers
    const handleZoomIn = () => state.map.setZoom(state.map.getZoom() + 1)
    const handleZoomOut = () => state.map.setZoom(state.map.getZoom() - 1)
    const defaultZoom = computed(() => {
      const lZoom = Number(localStorage.getItem('zoom'))
      return !isNaN(lZoom) ? lZoom : store.state.defaultZoom
    })
    const handleCurrentLocation = () => {
      store.dispatch(ActionType.setAppLoading, true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          state.map.setCenter(pos)
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setMapPos, pos)
        }, (e) => {
          if (e.code === 1 || e.code === 2) {
            AlertDialog(LOCATION_TURN_OFF)
          } else {
            AlertDialog(LOCATION_NOT_FOUND)
          }
          store.dispatch(ActionType.setAppLoading, false)
        })
      }
    }

    // events
    const onChangeMapPosition = () => {
      store.dispatch(
          ActionType.setMapPos,
          {
            lat: state.map.getCenter().lat(),
            lng: state.map.getCenter().lng()
          }
      )
      const latLng = {
        lat: state.map.getCenter().lat(),
        lng: state.map.getCenter().lng()
      }
      localStorage.setItem('latlng', JSON.stringify(latLng))
    }
    const onLoad = () => {
      if (state.initMap) {
        return
      }
      state.initMap = true
      emit('load', gMap.value.map)
      state.map = gMap.value.map
      state.isMaxZoom = state.map.maxZoom === state.map.getZoom()

      localStorage.setItem('zoom', defaultZoom.value)
      state.map.addListener('zoom_changed', () => {
        localStorage.setItem('zoom', state.map.zoom)
      })
      if (!state.isCenterLoaded && navigator.geolocation) {
        state.map.addListener('bounds_changed', () => {
          // store.dispatch(ActionType.setAppLoading, false)
          state.isCenterLoaded = true
          window.google.maps.event.clearListeners(state.map, 'bounds_changed')
        })
      }
    }

    return {
      ...toRefs(state),
      apiKey,
      gMap,
      mapStyle,
      defaultZoom,
      handleZoomIn,
      handleZoomOut,
      handleCurrentLocation,
      onChangeMapPosition,
      onLoad
    }
  }
})
