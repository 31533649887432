import {HttpRequest} from "@/core/httpRequest";
import {HTTPMethod} from "@/core/httpClient";
import {getAccessToken} from "@/utils/storage";
import {APIType} from "@/core/apiType";
import {IAnimationDaysType} from "@/data/animationday/type";

export class AnimationDayRequest implements HttpRequest<IAnimationDaysType> {
    public response!: IAnimationDaysType
    public path = '/road/day/'
    public method = HTTPMethod.POST
    public bearer = getAccessToken()
    public params: Record<string, any>
    public apiType = APIType.AnimationDay

    constructor (params: Record<string, any>) {
        this.params = params
    }
}