import { HttpClient } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import { IChartType } from '../type'

export interface IChartAPIStoreType {
  fetch(request: HttpRequest<IChartType>): Promise<IChartType>
}

export default class ChartAPIStore implements IChartAPIStoreType {
  async fetch(request: HttpRequest<IChartType>): Promise<IChartType> {
    const client = new HttpClient<IChartType>()
    const response = await client.request<IChartType>(request)
    return response.data
  }
}