
import {computed, defineComponent, PropType, reactive, toRefs, watch} from 'vue'
import {useStore} from 'vuex'
import {IPrefectureType} from '@/data/master/area/prefecture/type'
import {PrefectureMasterActionType} from '@/store/modules/area/master/prefecture/actions'
import {ICityType} from '@/data/master/area/prefecture/type'
import {AreaPolygonMasterActionType} from '@/store/modules/area/master/polygon/actions'
import UICheckbox from '@/components/UI/UICheckbox.vue'

interface State {
  bulkSelected: boolean
}

export default defineComponent({
  name: 'AreaAccordion',
  components: { UICheckbox },
  props: {
    pref: { type: Object as PropType<IPrefectureType>, required: true },
    border: { type: Boolean, default: true }
  },
  setup (props) {
    // vuex
    const store = useStore()

    // internal state
    const hasSelected = props.pref.cities.map(city => city.isSelected).some(selected => selected)
    const isAllSelected = computed(() => props.pref.cities.every(city => city.isSelected))
    const state = reactive<State>({
      bulkSelected: hasSelected
    })

    // watch
    watch(() => store.getters['prefectureMaster/selectedCities'], (cities: ICityType[]) => {
      const polygons = store.state.areaPolygonMaster.polygons
      const names = cities.map(city => city.name)
      const selected = polygons.filter(p => names.includes(p.name)).map(p => p.polygon).flat(2)
      state.bulkSelected = props.pref.cities.some(city => city.isSelected)
      store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setSelectedPolygon}`, selected)
    })

    const handleToggle = () => {
      store.dispatch(`prefectureMaster/${PrefectureMasterActionType.setCollapsed}`, {
        name: props.pref.name,
        isCollapsed: !props.pref.isCollapsed
      })
    }

    const handleBulkToggle = (value: boolean) => {
      if (value) {
        store.dispatch(`prefectureMaster/${PrefectureMasterActionType.selectAllCities}`, props.pref)
      } else {
        store.dispatch(`prefectureMaster/${PrefectureMasterActionType.unselectAllCities}`, props.pref)
      }
    }
    return {
      ...toRefs(state),
      isAllSelected,
      handleToggle,
      handleBulkToggle
    }
  }
})
