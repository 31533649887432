import {IRailwayAPIStoreType} from '@/data/railway/store'
import {IRailwayStubType} from '@/data/railway/store/stub'
import {IRailwayAnimationType, IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import {
  RailwayAnimationRequest,
  RailwayComparisonRequest,
  RailwayHeatmapRequest,
  RailwayStationBarchartRequest
} from '@/data/railway/store/request'

export interface IRailwayRepositoryType {
  api: IRailwayAPIStoreType
  stub: IRailwayStubType
  fetchRailwayStationBarchart(params: Record<string, any>): Promise<IRailwayStationBarchartType>
  fetchHeatmap(params: Record<string, any>): Promise<number[][]>
  fetchComparison(params: Record<string, any>): Promise<IRailwayComparisonType>
  fetchAnimation(params: Record<string, any>): Promise<IRailwayAnimationType>
}

export default class RailwayRepository implements IRailwayRepositoryType {
  stub: IRailwayStubType
  api: IRailwayAPIStoreType
  
  // DI
  constructor (stub: IRailwayStubType, api: IRailwayAPIStoreType) {
    this.stub = stub
    this.api = api
  }
  
  async fetchRailwayStationBarchart(params: Record<string, any>): Promise<IRailwayStationBarchartType> {
    const request = new RailwayStationBarchartRequest(params)
    return await this.api.fetchRailwayStationBarchart(request)
    // return await this.stub.fetchRailwayStationBarchart()
  }
  
  async fetchHeatmap(params: Record<string, any>): Promise<number[][]> {
    const request = new RailwayHeatmapRequest(params)
    return await this.api.fetchHeatmap(request)
    // return await this.stub.fetchHeatmap()
  }
  
  async fetchComparison(params: Record<string, any>): Promise<IRailwayComparisonType> {
    const request = new RailwayComparisonRequest(params)
    return await this.api.fetchComparison(request)
    // return await this.stub.fetchComparison()
  }
  
  async fetchAnimation(params: Record<string, any>): Promise<IRailwayAnimationType> {
    const request = new RailwayAnimationRequest(params)
    return await this.api.fetchAnimation(request)
    // return await this.stub.fetchAnimation()
  }
}
