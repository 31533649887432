
import {defineComponent, PropType, reactive, toRefs} from 'vue'
import VueSlider from 'vue-slider-component'
import { leadingZero } from '@/utils/time'

interface State {
  value: number[]
}

export default defineComponent({
  name: 'UIHourRangePicker',
  components: {
    VueSlider
  },
  props: {
    modelValue: {
      type: Array as PropType<Array<number>>,
      required: false,
      default: () => ([]),
      validator: (value: number[]) => value.length === 2
    }
  },
  emits: ['change', 'update:modelValue'],
  setup (props, { emit }) {
    const state = reactive<State>({
      value: props.modelValue ? props.modelValue : [0, 24]
    })
    const stepStyle = {
      width: '14px !important',
      height: '14px !important'
    }
    const processStyle = {
      backgroundColor: '#334A7C'
    }

    const formatTime = (time: number): string => {
      return `${leadingZero(time)}:00`
    }

    const handleChange = (value) => {
      emit('change', value)
      if (props.modelValue) {
        emit('update:modelValue', value)
      }
    }

    return {
      ...toRefs(state),
      formatTime,
      handleChange,
      stepStyle,
      processStyle
    }
  }
})
