import { HttpClient } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import { ILoginResponseType, IRefreshTokenType } from '../@types'

export interface IAuthAPIStoreType {
  login(request: HttpRequest<ILoginResponseType>): Promise<ILoginResponseType>
  logout(request: HttpRequest<boolean>): Promise<boolean>
  refreshToken(request: HttpRequest<IRefreshTokenType>): Promise<IRefreshTokenType>
  requestPasswordReset(request: HttpRequest<boolean>): Promise<boolean>
  passwordResetTokenCheck(request: HttpRequest<boolean>): Promise<boolean>
  passwordReset(request: HttpRequest<boolean>): Promise<boolean>
}

export default class AuthAPIStore implements IAuthAPIStoreType {
  async login(request: HttpRequest<ILoginResponseType>): Promise<ILoginResponseType> {
    const client = new HttpClient<ILoginResponseType>()
    const response = await client.request<ILoginResponseType>(request)
    return response.data
  }

  async logout(request: HttpRequest<boolean>): Promise<boolean> {
    const client = new HttpClient<boolean>()
    const response = await client.request<boolean>(request)
    return response.data
  }

  async refreshToken(request: HttpRequest<IRefreshTokenType>): Promise<IRefreshTokenType> {
    const client = new HttpClient<IRefreshTokenType>()
    const response = await client.request<IRefreshTokenType>(request)
    return response.data
  }
  
  async requestPasswordReset(request: HttpRequest<boolean>): Promise<boolean> {
    const client = new HttpClient<boolean>()
    const response = await client.request<boolean>(request)
    return response.data
  }
  
  async passwordResetTokenCheck(request: HttpRequest<boolean>): Promise<boolean> {
    const client = new HttpClient<boolean>()
    const response = await client.request<boolean>(request)
    return response.data
  }
  
  async passwordReset(request: HttpRequest<boolean>): Promise<boolean> {
    const client = new HttpClient<boolean>()
    const response = await client.request<boolean>(request)
    return response.data
  }
}
