import {ActionTree} from 'vuex'
import {AreaHeatmapState} from '@/store/modules/area/search/heatmap/type'
import {RootState} from '@/store/types'
import {AreaHeatmapMutationType} from '@/store/modules/area/search/heatmap/mutations'

export enum AreaHeatmapActionType {
  setPlotData = 'setPlotData'
}

export const actions: ActionTree<AreaHeatmapState, RootState> = {
  [AreaHeatmapActionType.setPlotData] ({ commit }, payload: (number | string)[][]): void {
    commit(AreaHeatmapMutationType.SET_PLOT_DATA, payload)
  }
}
