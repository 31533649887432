import {MutationTree} from 'vuex'
import {RailwayState} from '@/store/modules/railway/type'
import {ICoordinateType} from '@/types'
import {IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import {CheckboxModel} from '@/components/subviews/area/AreaSearchFilter.vue'
import {RailwayStationTab} from "@/enum/station";

export enum RailwayMutationType {
  TOGGLE_SIDEBAR = 'ToggleSidebar',
  SET_FIRST_POLYGON = 'SetFirstPolygon',
  SET_SECOND_POLYGON = 'SetSecondPolygon',
  SET_THIRD_POLYGON = 'SetThirdPolygon',
  SET_FOURTH_POLYGON = 'SetFourthPolygon',
  SET_FIFTH_POLYGON = 'SetFifthPolygon',
  SET_SIXTH_POLYGON = 'SetSixthPolygon',
  SET_ACTIVE_POLYGON = 'SetActivePolygon',
  SET_RAILWAY_STATION = 'SetRailwayStation',
  SET_SELECTED_TIME_RANGE = 'SetSelectedTimeRange',
  SET_WEEK = 'SetWeek',
  SET_SELECTED_TRANSPORTATION = 'SetSelectedTransportation',
  SET_SELECTED_RESIDENT = 'SetSelectedResident',
  SET_COMPARISON = 'SetComparison',
  SET_CREATE = 'SetCreate',
  SET_LOADING_COMPARISON = 'SetLoadingComparison',
  SET_RAILWAY_STATION_TAB = 'SetRailwayStationTab'
}

export const mutations: MutationTree<RailwayState> = {
  [RailwayMutationType.TOGGLE_SIDEBAR] (state: RailwayState): void {
    state.sbMinimum = !state.sbMinimum
  },
  [RailwayMutationType.SET_FIRST_POLYGON] (state: RailwayState, payload: boolean): void {
    state.firstPolygon = payload
  },
  [RailwayMutationType.SET_SECOND_POLYGON] (state: RailwayState, payload: boolean): void {
    state.secondPolygon = payload
  },
  [RailwayMutationType.SET_THIRD_POLYGON] (state: RailwayState, payload: boolean): void {
    state.thirdPolygon = payload
  },
  [RailwayMutationType.SET_FOURTH_POLYGON] (state: RailwayState, payload: boolean): void {
    state.fourthPolygon = payload
  },
  [RailwayMutationType.SET_FIFTH_POLYGON] (state: RailwayState, payload: boolean): void {
    state.fifthPolygon = payload
  },
  [RailwayMutationType.SET_SIXTH_POLYGON] (state: RailwayState, payload: boolean): void {
    state.sixthPolygon = payload
  },
  [RailwayMutationType.SET_ACTIVE_POLYGON] (state: RailwayState, payload: ICoordinateType[][]): void {
    state.activePolygons = payload
  },
  [RailwayMutationType.SET_RAILWAY_STATION] (state: RailwayState, payload: IRailwayStationBarchartType): void {
    state.railwayStation = payload
  },
  [RailwayMutationType.SET_SELECTED_TIME_RANGE] (state: RailwayState, payload: number[]): void {
    state.timeRange = payload
  },
  [RailwayMutationType.SET_WEEK] (state: RailwayState, payload: CheckboxModel[]): void {
    state.week = payload
  },
  [RailwayMutationType.SET_SELECTED_TRANSPORTATION] (state: RailwayState, payload: CheckboxModel[]): void {
    state.transportations = payload
  },
  [RailwayMutationType.SET_SELECTED_RESIDENT] (state: RailwayState, payload: CheckboxModel[]): void {
    state.residents = payload
  },
  [RailwayMutationType.SET_COMPARISON] (state: RailwayState, payload: IRailwayComparisonType): void {
    state.comparison = payload
  },
  [RailwayMutationType.SET_CREATE] (state: RailwayState, payload: boolean): void {
    state.isCreate = payload
  },
  [RailwayMutationType.SET_LOADING_COMPARISON] (state: RailwayState, payload: boolean): void {
    state.isLoadingComparison = payload
  },
  [RailwayMutationType.SET_RAILWAY_STATION_TAB] (state: RailwayState, payload: RailwayStationTab): void {
    state.stationTab = payload
  }
}
