import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "jr-rounded-lg jr-z-10" }
const _hoisted_2 = { class: "jr-flex jr-w-full jr-justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UIButton, {
        type: _ctx.ButtonType.primary,
        label: "選択した範囲で表示",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select-range'))),
        class: "jr-py-2 jr-w-40 jr-rounded-md jr-text-center jr-text-sm"
      }, null, 8, ["type"])
    ])
  ]))
}