import {GetterTree} from 'vuex'
import {AreaState} from '@/store/modules/area/type'
import {RootState} from '@/store/types'
import {IDistrictType, IFacilityType} from '@/data/area/type'
import {leadingZero} from '@/utils/time'
import {Config} from '@/config'
import _ from 'lodash'
import {TRANSPORTATIONS, ATTRIBUTES, FREQUENCIES, WEEK} from '@/components/subviews/area/AreaSearchFilter.vue'
import {AreaTimeSpentState} from '@/store/modules/area/search/timeSpent/type'
import {percentRound} from '@/utils/number'

export const getters: GetterTree<AreaState, RootState> = {
  selectedDistricts (state: AreaState): IDistrictType[] {
    return state.areas.map(area => area.districts).flat()
      .filter(district => district.isSelected)
  },
  allDistricts (state: AreaState): IDistrictType[] {
    return state.areas.map(area => area.districts).flat()
  },
  getSelectedFacilities (state: AreaState): IFacilityType[] {
    return state.facilities.filter(f => f.isSelected)
  },
  timeLabels (state: AreaTimeSpentState): string[] {
    return state.times.map(time => time.label)
  },
  timeVolumes (state: AreaTimeSpentState): number[] {
    const volumes = state.times.map(time => time.volume)
    return percentRound(volumes)
  },
  facilityLabels (state: AreaState): string[] {
    return _.take(Object.keys(state.pins), Config.Instance.MAX_DASHBOARD_FACILITY).map((k, i) => {
      return `${i + 1}. ${k}`
    })
  },
  facilityDataset (state: AreaState): number[] {
    let set = []
    let counter = 0
    for (const pin in state.pins) {
      set = set.concat(state.pins[pin][0])
      if (counter >= Config.Instance.MAX_DASHBOARD_FACILITY - 1) {
        break
      }
      counter++
    }
    return set
  },
  getAPIParams (state: AreaState): Record<string, any> {
    const params: Record<string, any> = {
      dashboard: false,
      except_target_area: state.except ? 1 : 0,
      time_range: {
        from: state.timeRange[0],
        to: state.timeRange[1]
      }
    }
    
    // week
    if (state.week.some(w => w.isSelected)) {
      const week = state.week
        .filter(w => w.isSelected)
        .map(w => w.value)
      if (!_.isEqual(week, WEEK.map(w => w.value))) {
        params.week = week
      }
    }
    
    // type
    if (state.transportations.some(t => t.isSelected)) {
      const type = state.transportations
        .filter(t => t.isSelected)
        .map(t => t.value)
      if (!_.isEqual(type, TRANSPORTATIONS.map(t => t.value))) {
        params.type = type
      }
    }
    // frequencies
    if (state.frequencies.some(f => f.isSelected)) {
      const frequencies = state.frequencies
        .filter(f => f.isSelected)
        .map(f => f.value)
      if (!_.isEqual(frequencies, FREQUENCIES.map(f => f.value))) {
        params.frequencies = frequencies
      }
    }
    // visitor_attribute
    if (state.attributes.some(a => a.isSelected)) {
      const attributes = state.attributes
        .filter(a => a.isSelected)
        .map(a => a.value)
      if (!_.isEqual(attributes, ATTRIBUTES.map(a => a.value))) {
        params.visitor_attribute = attributes
      }
    }
    return params
  },
  getHeatmapFilterBadges (state: AreaState): { title: string; content: string }[] {
    const res: { title: string; content: string }[] = []
    if (state.week.length > 0) {
      const content = state.week
        .map(w => w.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.week.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      res.push({
        title: '曜日別',
        content: content
      })
    }
    
    if (state.frequencies.length > 0) {
      const content = state.frequencies
        .map(f => f.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.frequencies.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      res.push({
        title: '来訪頻度別',
        content: content
      })
    }
    return res
  },
  getFilterBadges (state: AreaState): { title: string; content: string }[] {
    const res: { title: string; content: string }[] = []
    // timeRange
    if (state.timeRange[0] !== state.timeRange[1]) {
      res.push({
        title: '時間帯',
        content: `${leadingZero(state.timeRange[0])}:00 - ${leadingZero(state.timeRange[1])}:00`
      })
    }
    
    // weekdays
    if (state.week.length > 0) {
      const content = state.week
      .map(w => w.name)
      .reduce((acc, val, index) => {
        let t = acc + val
        if (index < state.week.length - 1) {
          t = t + '/'
        }
        return t
      }, '')
      res.push({
        title: '曜日別',
        content: content
      })
    }
    
    // transportation type
    if (state.transportations.length > 0) {
      const content = state.transportations
        .map(t => t.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.transportations.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      res.push({
        title: '交通手段別',
        content: content
      })
    }
    
    // visit frequencies
    if (state.frequencies.length > 0) {
      const content = state.frequencies
        .map(f => f.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.frequencies.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      res.push({
        title: '来訪頻度別',
        content: content
      })
    }
    
    // area visitor attribute
    if (state.attributes.length) {
      const content = state.attributes
        .map(a => a.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.attributes.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      res.push({
        title: 'エリア来訪者の属性',
        content: content
      })
    }
    
    return res
  },
  ages (state: AreaState): {men: number[]; women: number[]; max: number} {
    const menTotal = state.age.men.reduce((acc, val) => acc + val, 0)
    const womenTotal = state.age.women.reduce((acc, val) => acc + val, 0)
    const subTotal = menTotal + womenTotal
    const men = state.age.men.map(m => (m * 100) / subTotal)
    const women = state.age.women.map(w => (w * 100) / subTotal)
    const max = Math.ceil(_.max(men.concat(women)))
    const maxOffset = (10 - (max % 10))
    return {
      max: max + maxOffset,
      men: men,
      women: women
    }
  }
}
