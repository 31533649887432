import {HttpRequest} from '@/core/httpRequest'
import {IAreaPolygonMasterType} from '@/data/master/area/polygon/type'
import {HttpClient} from '@/core/httpClient'

export interface IAreaPolygonMasterAPIStoreType {
  fetch(request: HttpRequest<IAreaPolygonMasterType[]>): Promise<IAreaPolygonMasterType[]>
}

export default class AreaPolygonAPIStore implements IAreaPolygonMasterAPIStoreType {
  async fetch(request: HttpRequest<IAreaPolygonMasterType[]>): Promise<IAreaPolygonMasterType[]> {
    const client = new HttpClient<IAreaPolygonMasterType[]>()
    const response = await client.request<IAreaPolygonMasterType[]>(request)
    return response.data
  }
}
