import {IPersonaMasterAPIStoreType} from '@/data/master/area/persona/store'
import {IPersonaMasterStubType} from '@/data/master/area/persona/store/stub'
import {IPersonaType} from '@/data/master/area/persona/type'
import {AreaPersonaMasterRequest} from '@/data/master/area/persona/store/request'

export interface IPersonaMasterRepositoryType {
  api: IPersonaMasterAPIStoreType
  stub: IPersonaMasterStubType
  fetch(params: Record<string, any>): Promise<IPersonaType[]>
}

export default class PersonaRepository implements IPersonaMasterRepositoryType {
  stub: IPersonaMasterStubType
  api: IPersonaMasterAPIStoreType
  
  // DI
  constructor(stub: IPersonaMasterStubType, api: IPersonaMasterAPIStoreType) {
    this.stub = stub
    this.api = api
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetch(params: Record<string, any>): Promise<IPersonaType[]> {
    // return await this.stub.fetch()
    const request = new AreaPersonaMasterRequest(params)
    return await this.api.fetch(request)
  }
}
