import {ColorRGBA} from '@/types'

export const lightenColor = function (color: string, percent: number): string {
  const num = parseInt(color.replace('#', ''), 16)
  const amt = Math.round(2.55 * percent)
  const R = (num >> 16) + amt
  const B = (num >> 8 & 0x00FF) + amt
  const G = (num & 0x0000FF) + amt
  return '#' + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255))
  .toString(16)
  .slice(1)
}

export const lightenColorGL = function (color: ColorRGBA, slope = 10): ColorRGBA {
  return {
    Red: color.Red,
    Green: color.Green,
    Blue: color.Blue,
    Alpha: color.Alpha - slope
  }
}

export const hexToRGB = function (hex: string, alpha = 1): ColorRGBA {
  const red = parseInt(hex.slice(1, 3), 16)
  const green = parseInt(hex.slice(3, 5), 16)
  const blue = parseInt(hex.slice(5, 7), 16)
  return { Red: red, Green: green, Blue: blue, Alpha: alpha }
}

export const getRandomColor = function (): string {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
