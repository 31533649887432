
import {computed, defineComponent, PropType} from 'vue'
import {Chart, registerables} from 'chart.js'
import {BarChart, useBarChart} from 'vue-chart-3'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(...registerables, ChartDataLabels)
export default defineComponent({
  name: 'UIComparisonChart',
  components: {BarChart},
  props: {
    showLabel: { type: Boolean, default: true },
    labels: { type: Array as PropType<string[]>, required: false, default: () => ([]) },
    data: { type: Array as PropType<number[]>, required: false, default: () => ([]) }
  },
  setup (props) {
    const height = computed(() => {
      return props.data ? 64 * props.data.length : 0
    })
    const options = computed(() => {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        categoryPercentage: 1.0,
        barPercentage: 0.55,
        events: [], // remove this setting to show event related items such as tooltips
        plugins: {
          datalabels: { display: false },
          legend: { display: false }
        },
        scales: {
          y: {
            display: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              display: true,
              font: { size: 14 },
              color: '#000',
              padding: 30,
              callback: (index) => {
                return props.showLabel ? chartData.value.labels[index] : ''
              }
            }
          },
          x: {
            position: 'top',
            min: 0,
            max: 100,
            grid: {
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              font: { size: 13 },
              color: '#000',
              stepSize: 25,
              callback: function (value) {
                const n = Number(value)
                if (n % 100 === 0 && n !== 0) {
                  return `${value} (%)`
                }

                if (n % 50 === 0 && n !== 0) {
                  return value
                }
                return n === 0 ? n : ''
              }
            }
          }
        }
      }
    })

    const chartData = computed(() => {
      return {
        labels: props.labels,
        datasets: [
          {
            data: props.data,
            backgroundColor: ['#1967D2']
          }
        ]
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps, height }
  }
})
