<template>
  <div class="jr-mx-15 jr-text-left">
    <p class="jr-text-sm jr-font-medium">各駅利用者の駅から居住地/勤務地までの距離別に、人数の構成比を表示しています。</p>

    <div class="jr-pt-6.252 jr-text-s">
      <p>集計は「日のべ人数」です。</p>
      <p>
        <span class="jr-mr-2.5">※</span>
        <span>同一人物が2日間駅を利用した場合は2とカウント。</span>
      </p>
      <p>駅を頻繁に来訪する利用者がいた場合、その利用者の居住地/勤務地までの距離の構成比が大きくなります。</p>
    </div>
  </div>
</template>
