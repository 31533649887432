import { HttpRequest } from '@/core/httpRequest'
import {
  IAreaConfig,
  IAreaType,
  IFacilityType,
  IRankingType,
  ITimeSpentType,
  IVisitorAgeType,
  IVisitorPersonaType
} from '@/data/area/type'
import { HttpClient } from '@/core/httpClient'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'

export interface IAreaAPIStoreType {
  fetchConfig(request: HttpRequest<IAreaConfig[]>): Promise<IAreaConfig[]>
  fetch(request: HttpRequest<IAreaType[]>): Promise<IAreaType[]>
  fetchTransportation(request: HttpRequest<Array<number[]>>): Promise<Array<number[]>>
  fetchFacility(request: HttpRequest<IFacilityType[]>): Promise<IFacilityType[]>
  fetchFacilityPin(request: HttpRequest<Array<(number | string)[]>>): Promise<Array<(number | string)[]>>
  fetchTransportationChart(request: HttpRequest<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}>)
  fetchHeatmap(request: HttpRequest<(number | string)[][]>): Promise<(number | string)[][]>
  fetchTimeSpent(request: HttpRequest<{average: number; data: ITimeSpentType[]}>): Promise<{average: number; data: ITimeSpentType[]}>
  fetchVisitor(request: HttpRequest<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}>): Promise<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}>
  fetchRanking(request: HttpRequest<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }>): Promise<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }>
}

export default class AreaAPIStore implements IAreaAPIStoreType {
  async fetch(request: HttpRequest<IAreaType[]>): Promise<IAreaType[]> {
    const client = new HttpClient<IAreaType>()
    const response = await client.request<IAreaType[]>(request)
    return response.data
  }
  
  async fetchConfig(request: HttpRequest<IAreaConfig[]>): Promise<IAreaConfig[]> {
    const client = new HttpClient<IAreaConfig[]>()
    const response = await client.request<IAreaConfig[]>(request)
    return response.data
  }
  
  async fetchTransportation(request: HttpRequest<Array<number[]>>): Promise<Array<number[]>> {
    const client = new HttpClient<Array<number[]>>()
    const response = await client.request<Array<number[]>>(request)
    return response.data
  }
  
  async fetchFacility(request: HttpRequest<IFacilityType[]>): Promise<IFacilityType[]> {
    const client = new HttpClient<IFacilityType[]>()
    const response = await client.request<IFacilityType[]>(request)
    return response.data
  }
  
  async fetchTransportationChart(request: HttpRequest<{ in_area: ITransportationChart[]; visit_area: ITransportationChart[] }>): Promise<{ in_area: ITransportationChart[]; visit_area: ITransportationChart[] }> {
    const client = new HttpClient<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}>()
    const response = await client.request<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}>(request)
    return response.data
  }
  
  async fetchHeatmap(request: HttpRequest<(number | string)[][]>): Promise<(number | string)[][]> {
    const client = new HttpClient<(number | string)[][]>()
    const response = await client.request<(number | string)[][]>(request)
    return response.data
  }
  
  async fetchTimeSpent(request: HttpRequest<{average: number; data: ITimeSpentType[]}>): Promise<{average: number; data: ITimeSpentType[]}> {
    const client = new HttpClient<{average: number; data: ITimeSpentType[]}>()
    const response = await client.request<{average: number; data: ITimeSpentType[]}>(request)
    return response.data
  }
  
  async fetchVisitor(request: HttpRequest<{ persona: IVisitorPersonaType[]; ages: IVisitorAgeType }>): Promise<{ persona: IVisitorPersonaType[]; ages: IVisitorAgeType }> {
    const client = new HttpClient<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}>()
    const response = await client.request<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}>(request)
    return response.data
  }
  
  async fetchRanking(request: HttpRequest<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }>): Promise<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }> {
    const client = new HttpClient<{
      min: number;
      max: number;
      resident: IRankingType[];
      work_location: IRankingType[]
    }>()
    const response = await client.request<{
      min: number;
      max: number;
      resident: IRankingType[];
      work_location: IRankingType[]
    }>(request)
    return response.data
  }
  
  async fetchFacilityPin(request: HttpRequest<Array<(number | string)[]>>): Promise<Array<(number | string)[]>> {
    const client = new HttpClient<Array<(number | string)[]>>()
    const response = await client.request<Array<(number | string)[]>>(request)
    return response.data
  }
}
