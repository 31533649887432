import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISearchField = _resolveComponent("UISearchField")!
  const _component_CustomControl = _resolveComponent("CustomControl")!
  const _component_AreaSidebar = _resolveComponent("AreaSidebar")!
  const _component_AreaDistrictSelector = _resolveComponent("AreaDistrictSelector")!
  const _component_AreaPolygonSelector = _resolveComponent("AreaPolygonSelector")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomControl, { position: "TOP_RIGHT" }, {
      default: _withCtx(() => [
        _createVNode(_component_UISearchField, {
          id: _ctx.inputSearchId,
          class: "jr-w-controller",
          placeholder: "地図を検索",
          onSearch: _ctx.onKeywordSearch
        }, null, 8, ["id", "onSearch"])
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_AreaSidebar, {
      onDistrict: _ctx.onDistrictSearch,
      onArea: _ctx.onAreaSearch
    }, null, 8, ["onDistrict", "onArea"]), [
      [_vShow, _ctx.mode === _ctx.Mode.None]
    ]),
    _withDirectives(_createVNode(_component_AreaDistrictSelector, { onClear: _ctx.onClearDistrict }, null, 8, ["onClear"]), [
      [_vShow, _ctx.mode === _ctx.Mode.District]
    ]),
    _withDirectives(_createVNode(_component_AreaPolygonSelector, {
      onEsc: _ctx.onEscArea,
      onClear: _ctx.onClearArea
    }, null, 8, ["onEsc", "onClear"]), [
      [_vShow, _ctx.mode === _ctx.Mode.Area]
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}