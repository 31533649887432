import { resolveComponent as _resolveComponent, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return _withDirectives((_openBlock(), _createBlock(_component_BarChart, _mergeProps(_ctx.barChartProps, {
    style: { height: `${_ctx.height}px`, width: `${_ctx.width}px` }
  }), null, 16, ["style"])), [
    [_vShow, _ctx.isShow]
  ])
}