import {MutationTree} from 'vuex'
import {AreaState} from '@/store/modules/area/type'
import {IFacilityType, IRankingType, ITimeSpentType, IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'
import {CheckboxModel} from '@/components/subviews/area/AreaSearchFilter.vue'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'
import {Config} from '@/config'
import _ from 'lodash'

export enum AreaMutationType {
  TOGGLE_SIDEBAR = 'ToggleSidebar',
  SET_FACILITIES = 'SetFacilities',
  SELECT_ALL_FACILITIES = 'SelectAllFacilities',
  UNSELECT_ALL_FACILITIES = 'UnselectAllFacilities',
  SET_TIME_RANGE = 'SetTimeRange',
  SET_WEEK = 'SetWeek',
  SET_TRANSPORTATIONS = 'SetTransportations',
  SET_FREQUENCIES = 'SetFrequencies',
  SET_ATTRIBUTES = 'SetAttributes',
  SET_SELECT_PAGE = 'SetSelectPage',
  SET_IS_DETAIL_LOADED = 'setIsDetailLoaded',
  SET_POLYGON_MAX_ZOOM = 'setPolygonMaxZoom',
  
  // for dashboard display
  SET_DB_RANKING = 'SetDBRanking',
  SET_DB_TIME_SPENT = 'SetDBTimeSpent',
  SET_EXCEPT = 'SetExcept',
  SET_AVERAGE = 'SetAverage',
  SET_DB_TRANSPORTATION = 'SetDBTransportation',
  SET_DB_VISITOR = 'SetDBVisitor',
  SET_DB_FACILITY = 'SetDBFacility',
  SET_LESS_RANKING = 'SetLessRanking',
  SET_LESS_TRANSPORTATION = 'SetLessTransportation',
  SET_LESS_FACILITY = 'SetLessFacility',
  SET_LESS_TIME_SPENT = 'SetLessTimeSpent',
  SET_LESS_VISITOR = 'SetLessVisitor'
}

export const mutations: MutationTree<AreaState> = {
  [AreaMutationType.TOGGLE_SIDEBAR] (state: AreaState): void {
    state.sbMinimum = !state.sbMinimum
  },
  [AreaMutationType.SET_FACILITIES] (state: AreaState, payload: IFacilityType[]): void {
    state.facilities = payload
  },
  [AreaMutationType.SELECT_ALL_FACILITIES] (state: AreaState): void {
    state.facilities.forEach(f => f.isSelected = true)
  },
  [AreaMutationType.UNSELECT_ALL_FACILITIES] (state: AreaState): void {
    state.facilities.forEach(f => f.isSelected = false)
  },
  [AreaMutationType.SET_EXCEPT] (state: AreaState, payload: boolean): void {
    state.except = payload
  },
  [AreaMutationType.SET_TIME_RANGE] (state: AreaState, payload: number[]): void {
    state.timeRange = payload
  },
  [AreaMutationType.SET_WEEK] (state: AreaState, payload: CheckboxModel[]): void {
    state.week = payload
  },
  [AreaMutationType.SET_TRANSPORTATIONS] (state: AreaState, payload: CheckboxModel[]): void {
    state.transportations = payload
  },
  [AreaMutationType.SET_FREQUENCIES] (state: AreaState, payload: CheckboxModel[]): void {
    state.frequencies = payload
  },
  [AreaMutationType.SET_ATTRIBUTES] (state: AreaState, payload: CheckboxModel[]): void {
    state.attributes = payload
    if (!state.hasAttrChange) {
      state.hasAttrChange
    }
  },
  [AreaMutationType.SET_SELECT_PAGE] (state: AreaState, payload: string): void {
    state.selectPage = payload
  },
  [AreaMutationType.SET_DB_RANKING] (state: AreaState, payload: {resident: IRankingType[]; workLocation: IRankingType[]}): void {
    state.resident = _.take(_.orderBy(payload.resident, ['rank']), Config.Instance.MAX_DASHBOARD_RANKING)
    state.workLocation = _.take(_.orderBy(payload.workLocation, ['rank']), Config.Instance.MAX_DASHBOARD_RANKING)
    state.isLessRanking = false
  },
  [AreaMutationType.SET_DB_TIME_SPENT] (state: AreaState, payload: ITimeSpentType[]): void {
    state.times = payload
    state.isLessTimeSpent = false
  },
  [AreaMutationType.SET_AVERAGE] (state: AreaState, payload: number): void {
    state.average = payload
  },
  [AreaMutationType.SET_DB_TRANSPORTATION] (state: AreaState, payload: {inArea: ITransportationChart[], visitArea: ITransportationChart[]}): void {
    state.inArea = payload.inArea
    state.visitArea = payload.visitArea
    state.isLessTransportation = false
  },
  [AreaMutationType.SET_DB_VISITOR] (state: AreaState, payload: {persona: IVisitorPersonaType[]; age: IVisitorAgeType}): void {
    state.persona = payload.persona
    state.age = payload.age
    state.isLessVisitor = false
  },
  [AreaMutationType.SET_DB_FACILITY] (state: AreaState, payload: Record<string, any>): void {
    state.pins = payload
    state.isLessFacility = false
  },
  [AreaMutationType.SET_LESS_RANKING] (state: AreaState, payload: boolean): void {
    state.isLessRanking = payload
  },
  [AreaMutationType.SET_LESS_TRANSPORTATION] (state: AreaState, payload: boolean): void {
    state.isLessTransportation = payload
  },
  [AreaMutationType.SET_LESS_FACILITY] (state: AreaState, payload: boolean): void {
    state.isLessFacility = payload
  },
  [AreaMutationType.SET_LESS_TIME_SPENT] (state: AreaState, payload: boolean): void {
    state.isLessTimeSpent = payload
  },
  [AreaMutationType.SET_LESS_VISITOR] (state: AreaState, payload: boolean): void {
    state.isLessVisitor = payload
  },
  [AreaMutationType.SET_IS_DETAIL_LOADED] (state: AreaState, payload: boolean): void {
    state.isDetailLoaded = payload
  },
  [AreaMutationType.SET_POLYGON_MAX_ZOOM] (state: AreaState, payload: number): void {
    state.polygonMaxZoom = payload
  }
}

