import { APIType } from '@/core/apiType'
import { HTTPMethod } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import { getAccessToken, getRefreshToken } from '@/utils/storage'
import { ILoginResponseType, IRefreshTokenType } from '../@types'

export class LoginRequest implements HttpRequest<ILoginResponseType> {
  public response!: ILoginResponseType
  public path = '/api/token/'
  public method = HTTPMethod.POST
  public params: Record<string, any>
  public apiType = APIType.Login

  constructor (tenant: string, username: string, password: string) {
    this.params = {
      tenant_code: tenant,
      username: username,
      password: password
    }
  }
}

export class LogoutRequest implements HttpRequest<boolean> {
  public response!: boolean
  public path = '/api/logout/'
  public method = HTTPMethod.POST
  public apiType = APIType.Logout
  public bearer = getAccessToken()
}

export class RefreshTokenRequest implements HttpRequest<IRefreshTokenType> {
  public response!: ILoginResponseType
  public path = '/api/token/refresh/'
  public method = HTTPMethod.POST
  public params = { refresh: getRefreshToken() }
  public apiType = APIType.RefreshToken
}

export class SendPasswordResetRequest implements HttpRequest<boolean> {
  public response!: boolean
  public path = '/api/password_reset/reset/'
  public method = HTTPMethod.POST
  public apiType = APIType.SendPasswordReset
  public params = {}
  
  constructor (email: string) {
    this.params = {
      email: email
    }
  }
}

export class PasswordResetTokenCheckRequest implements HttpRequest<boolean> {
  public response!: boolean
  public path = '/api/password_reset/verify_token/'
  public method = HTTPMethod.POST
  public apiType = APIType.PasswordResetTokenCheck
  public params = {}
  
  constructor (user_id: string, token: string) {
    this.params = {
      token: token,
      user_id: user_id
    }
  }
}

export class PasswordResetRequest implements HttpRequest<boolean> {
  public response!: boolean
  public path = '/api/password_reset/change/'
  public method = HTTPMethod.POST
  public apiType = APIType.PasswordReset
  public params = {}
  
  constructor (user_id: string, token: string, password: string, confirm_password: string) {
    this.params = {
      user_id: user_id,
      token: token,
      password: password,
      confirm_password: confirm_password
    }
  }
}
