
import {computed, defineComponent, onBeforeUnmount, reactive} from 'vue'
import {RailwayActionType} from '@/store/modules/railway/actions'
import {useStore} from 'vuex'
import {Config} from '@/config'
import {useRouter} from 'vue-router'
import {
  setRailwayPolygons,
  getRailwayPolygons,
  getSelectedRailwayStation,
} from '@/utils/storage'
import FilterNavigation from '@/components/subviews/FilterNavigation.vue'
import StationPolygonSelector from '@/components/subviews/railway/StationPolygonSelector.vue'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import PolygonColors from '@/components/UI/railway/PolygonColors.vue'
import PolygonDrawingManager from '@/manager/polygonDrawing'
import {WarningPromptDialog} from "@/service/dialog";
import {NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE} from "@/constant";

interface State {
  map: any
}

export default defineComponent({
  name: 'RailwayPolygon',
  components: {
    PolygonColors,
    UIGoogleMap,
    StationPolygonSelector,
    FilterNavigation
  },
  setup () {
    let manager
    const store = useStore()
    const router = useRouter()
    const state = reactive<State>({
      map: null
    })
    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const hasActivePolygons = computed(() => {
      return store.getters['railway/hasActivePolygons']
    })
    const defaultZoom = computed(() => {
      const lZoom = Number(localStorage.getItem('zoom'))
      return !isNaN(lZoom) ? lZoom : store.state.defaultZoom
    })
    const handleMapLoad = (map: any) => {
      state.map = map
      store.dispatch(`railway/${RailwayActionType.setCreate}`, false)

      localStorage.setItem('zoom', defaultZoom.value)
      state.map.addListener('zoom_changed', () => {
        localStorage.setItem('zoom', state.map.zoom)
      })

      // center the map
      const service = new window.google.maps.places.PlacesService(state.map)
      const station = getSelectedRailwayStation()
      // const line = getSelectedRailwayLine()
      const request = {
        query: `${station}駅`,
        fields: ['name', 'geometry']
      }
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const geometry = results[0].geometry
          if (geometry !== undefined) {
            if (geometry.location?.lat() !== undefined && geometry.location?.lng() !== undefined) {
              const pos = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
              }
              state.map.setCenter(pos)
              const zoom = localStorage.getItem('zoom')
              if (zoom) {
                state.map.setZoom(Number(zoom))
              }
            }
          }
        }
      })

      const polygon = getRailwayPolygons()
      manager = new PolygonDrawingManager(state.map)
      manager.emitter.on('first', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setFirstPolygon}`, polygon !== undefined)
      })
      manager.emitter.on('second', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setSecondPolygon}`, polygon !== undefined)
      })
      manager.emitter.on('third', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setThirdPolygon}`, polygon !== undefined)
      })
      manager.emitter.on('fourth', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setFourthPolygon}`, polygon !== undefined)
      })
      manager.emitter.on('fifth', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setFifthPolygon}`, polygon !== undefined)
      })
      manager.emitter.on('sixth', (polygon) => {
        store.dispatch(`railway/${RailwayActionType.setSixthPolygon}`, polygon !== undefined)
      })

      manager.seedPolygons(polygon)
      store.dispatch(`railway/${RailwayActionType.setActivePolygon}`, manager.getPolygons())
      store.dispatch(`railway/${RailwayActionType.setFirstPolygon}`, 'first' in polygon)
      store.dispatch(`railway/${RailwayActionType.setSecondPolygon}`, 'second' in polygon)
      store.dispatch(`railway/${RailwayActionType.setThirdPolygon}`, 'third' in polygon)
      store.dispatch(`railway/${RailwayActionType.setFourthPolygon}`, 'fourth' in polygon)
      store.dispatch(`railway/${RailwayActionType.setFifthPolygon}`, 'fifth' in polygon)
      store.dispatch(`railway/${RailwayActionType.setSixthPolygon}`, 'sixth' in polygon)
    }
    const handleClearFirst = () => {
      if (manager && manager.polygons.first) {
        manager.polygons.first.setMap(null)
        delete manager.polygons.first
        store.dispatch(`railway/${RailwayActionType.setFirstPolygon}`, false)
      }
    }
    const handleClearSecond = () => {
      if (manager && manager.polygons.second) {
        manager.polygons.second.setMap(null)
        delete manager.polygons.second
        store.dispatch(`railway/${RailwayActionType.setSecondPolygon}`, false)
      }
    }
    const handleClearThird = () => {
      if (manager && manager.polygons.third) {
        manager.polygons.third.setMap(null)
        delete manager.polygons.third
        store.dispatch(`railway/${RailwayActionType.setThirdPolygon}`, false)
      }
    }
    const handleClearFourth = () => {
      if (manager && manager.polygons.fourth) {
        manager.polygons.fourth.setMap(null)
        delete manager.polygons.fourth
        store.dispatch(`railway/${RailwayActionType.setFourthPolygon}`, false)
      }
    }
    const handleClearFifth = () => {
      if (manager && manager.polygons.fifth) {
        manager.polygons.fifth.setMap(null)
        delete manager.polygons.fifth
        store.dispatch(`railway/${RailwayActionType.setFifthPolygon}`, false)
      }
    }
    const handleClearSixth = () => {
      if (manager && manager.polygons.sixth) {
        manager.polygons.sixth.setMap(null)
        delete manager.polygons.sixth
        store.dispatch(`railway/${RailwayActionType.setSixthPolygon}`, false)
      }
    }

    const handleFirst = () => {
      if (manager !== null) {
        manager.start('first', {
          strokeColor: Config.Instance.polygonColor.first.hex,
          fillColor: Config.Instance.polygonColor.first.hex
        })
      }
    }
    const handleSecond = () => {
      if (manager !== null) {
        manager.start('second', {
          strokeColor: Config.Instance.polygonColor.second.hex,
          fillColor: Config.Instance.polygonColor.second.hex
        })
      }
    }
    const handleThird = () => {
      if (manager !== null) {
        manager.start('third', {
          strokeColor: Config.Instance.polygonColor.third.hex,
          fillColor: Config.Instance.polygonColor.third.hex
        })
      }
    }
    const handleFourth = () => {
      if (manager !== null) {
        manager.start('fourth', {
          strokeColor: Config.Instance.polygonColor.fourth.hex,
          fillColor: Config.Instance.polygonColor.fourth.hex
        })
      }
    }
    const handleFifth = () => {
      if (manager !== null) {
        manager.start('fifth', {
          strokeColor: Config.Instance.polygonColor.fifth.hex,
          fillColor: Config.Instance.polygonColor.fifth.hex
        })
      }
    }
    const handleSixth = () => {
      if (manager !== null) {
        manager.start('sixth', {
          strokeColor: Config.Instance.polygonColor.sixth.hex,
          fillColor: Config.Instance.polygonColor.sixth.hex
        })
      }
    }

    onBeforeUnmount(() => {
      manager.emitter.off('first')
      manager.emitter.off('second')
      manager.emitter.off('third')
      manager.emitter.off('fourth')
      manager.emitter.off('fifth')
      manager.emitter.off('sixth')
      manager = null
    })
    const handleMinimum = () => store.dispatch(`railway/${RailwayActionType.toggleSidebar}`)
    const handleSearchPolygon = () => {
      WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
        const polygons = manager.getPolygons()
        setRailwayPolygons(manager.getPolygonsForSave())
        store.dispatch(`railway/${RailwayActionType.setActivePolygon}`, polygons)
        store.dispatch(`railway/${RailwayActionType.setCreate}`, true)
        router.push('/railway_report/comparison')
      })
    }
    return {
      sbMinimum,
      hasActivePolygons,
      manager,
      handleMapLoad,
      handleMinimum,
      handleFirst,
      handleSecond,
      handleThird,
      handleFourth,
      handleFifth,
      handleSixth,
      handleClearFirst,
      handleClearSecond,
      handleClearThird,
      handleClearFourth,
      handleClearFifth,
      handleClearSixth,
      handleSearchPolygon
    }
  }
})
