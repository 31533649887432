
import {computed, defineComponent, inject, onMounted, reactive, toRefs} from 'vue'
import {useRoute} from 'vue-router'
import {AreaActionType} from '@/store/modules/area/actions'
import {useStore} from 'vuex'
import {Config} from '@/config'
import {Emitter, EventType} from 'mitt'
import UIHourRangePicker from '@/components/UI/UIHourRangePicker.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import UIButton from '@/components/UI/UIButton.vue'
import {HintDialog} from '@/service/dialog'
import AreaFrequency from '@/components/hints/AreaFrequency.vue'

export interface CheckboxModel {
  name: string
  isSelected: boolean
  value: number
}

interface State {
  selectedTimeRange: number[]
  minimum: boolean
  isAllDays: boolean
  isAllTransportation: boolean
  isAllFrequencies: boolean
  isAllAttributes: boolean
  except: boolean
  week: CheckboxModel[]
  transportations: CheckboxModel[]
  frequencies: CheckboxModel[]
  attributes: CheckboxModel[]
}

export const TRANSPORTATIONS = Config.Instance.transportationTypes.map((t) => {
  return {
    name: t.name,
    isSelected: true,
    value: t.id
  }
})

export const WEEK = [
  { name: '平日', isSelected: true, value: 1 },
  { name: '土日祝', isSelected: true, value: 0 }
]

export const ATTRIBUTES = [
  { name: '居住者', isSelected: true, value: 0 },
  { name: '勤務者', isSelected: true, value: 1 },
  { name: 'エリア外来訪者', isSelected: true, value: 2 }
]
export const FREQUENCIES = [
  { name: 'リピーター', isSelected: true, value: 0 },
  { name: 'ビジター', isSelected: true, value: 1 }
]
export default defineComponent({
  name: 'AreaSearchFilter',
  components: {
    UIButton,
    UICheckbox,
    UIHourRangePicker
  },
  emits: ['search', 'resize'],
  setup (_, { emit }) {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const route = useRoute()
    const store = useStore()
    const state = reactive<State>({
      selectedTimeRange: store.state.area.timeRange,
      minimum: false,
      isAllDays: true,
      isAllTransportation: true,
      isAllFrequencies: true,
      isAllAttributes: true,
      except: false,
      week: WEEK,
      transportations: TRANSPORTATIONS,
      frequencies: FREQUENCIES,
      attributes: ATTRIBUTES.map(a => {
        if (route.path.indexOf('area_report/timing') >= 0 && !store.state.area.hasAttrChange && a.name === '居住者') {
          a.isSelected = false
        }
        return a
      })
    })
    const isCapturing = computed(() => store.state.isCapturing)
    const isSameDay = computed(() => route.path === '/area_report/same_day_action')
    const isAllDaySelected = computed(() => state.week.every(w => w.isSelected))
    const isAllTransportationSelected = computed(() => state.transportations.every(t => t.isSelected))
    const isAllFrequenciesSelected = computed(() => state.frequencies.every(f => f.isSelected))
    const isAllAttributeSelected = computed(() => state.attributes.every(a => a.isSelected))
    onMounted(() => {
      state.isAllDays = store.state.area.week.some(w => w.isSelected)
      state.isAllTransportation = store.state.area.transportations.some(t => t.isSelected)
      state.isAllFrequencies = store.state.area.frequencies.some(f => f.isSelected)
      state.isAllAttributes = store.state.area.attributes.some(a => a.isSelected)
      // store.dispatch(`area/${AreaActionType.setAttributes}`, state.attributes.filter(a => a.isSelected && a.name !== '居住者'))
    })
    emitter?.on('notifyAreaFilter', () => {
      const selectedWeek = state.week.filter(w => w.isSelected)
      const selectedTransportations = state.transportations.filter(t => t.isSelected)
      const selectedFrequencies = state.frequencies.filter(f => f.isSelected)
      const selectedAttributes = state.attributes.filter(a => a.isSelected)

      store.dispatch(`area/${AreaActionType.setTimeRange}`, state.selectedTimeRange)
      store.dispatch(`area/${AreaActionType.setWeek}`, selectedWeek)
      store.dispatch(`area/${AreaActionType.setTransportations}`, selectedTransportations)
      store.dispatch(`area/${AreaActionType.setFrequencies}`, selectedFrequencies)
      store.dispatch(`area/${AreaActionType.setAttributes}`, selectedAttributes)
      store.dispatch(`area/${AreaActionType.setExcept}`, state.except)
    })

    // handlers
    const handleSearch = () => {
      const selectedWeek = state.week.filter(w => w.isSelected)
      const selectedTransportations = state.transportations.filter(t => t.isSelected)
      const selectedFrequencies = state.frequencies.filter(f => f.isSelected)
      const selectedAttributes = state.attributes.filter(a => a.isSelected)

      const supplyParams: Record<string, any> = {
        dashboard: false,
        except_target_area: state.except ? 1 : 0,
        time_range: {
          from: state.selectedTimeRange[0],
          to: state.selectedTimeRange[1]
        }
      }

      if (state.week.some(w => !w.isSelected)) {
        supplyParams.week = selectedWeek.map(w => w.value)
      }

      if (state.transportations.some(t => !t.isSelected)) {
        supplyParams.type = selectedTransportations.map(t => t.value)
      }

      if (state.attributes.some(a => !a.isSelected)) {
        supplyParams.visitor_attribute = selectedAttributes.map(a => a.value)
      }

      if (state.frequencies.some(f => !f.isSelected)) {
        supplyParams.frequencies = selectedFrequencies.map(f => f.value)
      }
      emit('search', supplyParams)
    }
    const handleAllDaysChange = (value: boolean) => {
      state.week.forEach(w => {
        w.isSelected = value
      })
    }
    const handleAllTransportationChange = (value: boolean) => {
      state.transportations.forEach(t => {
        t.isSelected = value
      })
    }
    const handleAllFrequencyChange = (value: boolean) => {
      state.frequencies.forEach(f => {
        f.isSelected = value
      })
    }
    const handleAllAttributeChange = (value: boolean) => {
      state.attributes.forEach(a => {
        a.isSelected = value
      })
    }
    const handleWeekChange = () => state.isAllDays = state.week.some(w => w.isSelected)
    const handleTransportationChange = () => state.isAllTransportation = state.transportations.some(t => t.isSelected)
    const handleFrequencyChange = () => state.isAllFrequencies = state.frequencies.some(f => f.isSelected)
    const handleAttributeChange = () => state.isAllAttributes = state.attributes.some(attr => attr.isSelected)

    // events
    const clickClose = () => {
      state.minimum = !state.minimum
      emit('resize', state.minimum)
    }
    const onClickHint = () => {
      HintDialog(AreaFrequency)
    }

    return {
      ...toRefs(state),
      handleSearch,
      handleAllDaysChange,
      handleAllTransportationChange,
      handleAllFrequencyChange,
      handleAllAttributeChange,
      handleTransportationChange,
      handleFrequencyChange,
      handleAttributeChange,
      handleWeekChange,
      clickClose,
      onClickHint,
      isAllDaySelected,
      isAllTransportationSelected,
      isAllFrequenciesSelected,
      isAllAttributeSelected,
      isSameDay,
      isCapturing
    }
  }
})
