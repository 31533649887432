import {ITranslatorType} from '@/types'
import {IAreaType} from '@/data/area/type'

export default class AreaJsonTranslator implements ITranslatorType<Record<string, any>, IAreaType> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
  translate(input: Record<string, any>, ...args): IAreaType {
    return {
      name: input.name,
      isCollapsed: true,
      districts: input.districts.map(district => {
        return {
          name: district.name,
          ranking: district.ranking,
          polygon: district.polygon,
          isSelected: false,
        }
      })
    }
  }
}
