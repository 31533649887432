export enum BarChartColor {
  Primary = '#1967D3',
  Secondary = '#28B9F0',
  Male = '#1967D2',
  Female = '#E66231'
}

export enum DayAbbr {
  Sunday = 'sun',
  Monday = 'mon',
  Tuesday = 'tue',
  Wednesday = 'wed',
  Thursday = 'thu',
  Friday = 'fri',
  Saturday = 'sat'
}

export enum DayCode {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
  EveryDay = 9
}

export enum WeekdayFlag {
  Weekend = 0,
  Weekday = 1
}

export enum CheckboxFilterType {
  DayDiffer = 1,
  WeekDiffer = 2
}
