import {TransportationState} from '@/store/modules/area/search/transportation/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/transportation/getters'
import {actions} from '@/store/modules/area/search/transportation/actions'
import {mutations} from '@/store/modules/area/search/transportation/mutations'

export const state: TransportationState = {
  inAreas: [],
  visitAreas: [],
  plots: []
}

const namespaced = true
export const transportation: Module<TransportationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
