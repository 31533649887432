import {ActionTree} from 'vuex'
import {RailwayState} from '@/store/modules/railway/type'
import {RootState} from '@/store/types'
import {RailwayMutationType} from '@/store/modules/railway/mutations'
import {ICoordinateType} from '@/types'
import {IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import {CheckboxModel} from '@/components/subviews/area/AreaSearchFilter.vue'
import { RailwayStationTab } from '@/enum/station'

export enum RailwayActionType {
  toggleSidebar = 'toggleSidebar',
  setFirstPolygon = 'setFirstPolygon',
  setSecondPolygon = 'setSecondPolygon',
  setThirdPolygon = 'setThirdPolygon',
  setFourthPolygon = 'setFourthPolygon',
  setFifthPolygon = 'setFifthPolygon',
  setSixthPolygon = 'setSixthPolygon',
  setActivePolygon = 'setActivePolygon',
  setRailwayStation = 'setRailwayStation',
  setSelectedTimeRange = 'setSelectedTimeRange',
  setWeek = 'setWeek',
  setSelectedTransportation = 'setSelectedTransportation',
  setSelectedResident = 'setSelectedResident',
  setComparison = 'setComparison',
  setCreate = 'setCreate',
  setLoadingComparison = 'setLoadingComparison',
  setRailwayStationTab = 'setRailwayStationTab'
}

export const actions: ActionTree<RailwayState, RootState> = {
  [RailwayActionType.toggleSidebar] ({ commit }): void {
    commit(RailwayMutationType.TOGGLE_SIDEBAR)
  },
  [RailwayActionType.setFirstPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_FIRST_POLYGON, payload)
  },
  [RailwayActionType.setSecondPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_SECOND_POLYGON, payload)
  },
  [RailwayActionType.setThirdPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_THIRD_POLYGON, payload)
  },
  [RailwayActionType.setFourthPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_FOURTH_POLYGON, payload)
  },
  [RailwayActionType.setFifthPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_FIFTH_POLYGON, payload)
  },
  [RailwayActionType.setSixthPolygon] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_SIXTH_POLYGON, payload)
  },
  [RailwayActionType.setActivePolygon] ({ commit }, payload: ICoordinateType[][]): void {
    commit(RailwayMutationType.SET_ACTIVE_POLYGON, payload)
  },
  [RailwayActionType.setRailwayStation] ({ commit }, payload: IRailwayStationBarchartType): void {
    commit(RailwayMutationType.SET_RAILWAY_STATION, payload)
  },
  [RailwayActionType.setSelectedTimeRange] ({ commit }, payload: number[]): void {
    commit(RailwayMutationType.SET_SELECTED_TIME_RANGE, payload)
  },
  [RailwayActionType.setWeek] ({ commit }, payload: CheckboxModel[]): void {
    commit(RailwayMutationType.SET_WEEK, payload)
  },
  [RailwayActionType.setSelectedTransportation] ({ commit }, payload: CheckboxModel[]): void {
    commit(RailwayMutationType.SET_SELECTED_TRANSPORTATION, payload)
  },
  [RailwayActionType.setSelectedResident] ({ commit }, payload: CheckboxModel[]): void {
    commit(RailwayMutationType.SET_SELECTED_RESIDENT, payload)
  },
  [RailwayActionType.setComparison] ({ commit }, payload: IRailwayComparisonType): void {
    commit(RailwayMutationType.SET_COMPARISON, payload)
  },
  [RailwayActionType.setCreate] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_CREATE, payload)
  },
  [RailwayActionType.setLoadingComparison] ({ commit }, payload: boolean): void {
    commit(RailwayMutationType.SET_LOADING_COMPARISON, payload)
  },
  [RailwayActionType.setRailwayStationTab] ({ commit }, payload: RailwayStationTab): void {
    commit(RailwayMutationType.SET_RAILWAY_STATION_TAB, payload)
  }
}
