import { ICoordinateType } from '@/types'
import mitt, {Emitter, EventType} from 'mitt'
import _ from 'lodash'
import {Config} from "@/config";

export interface IPolygonDrawingManagerType {
  start(event: string, options?: google.maps.PolygonOptions): void
  stop(): void
  dispose(): void
  getPolygons(): ICoordinateType[][]
  getPolygonsForSave(): Record<string, {lat: number; lng: number}[]>
  seedPolygons(polygons: Record<string, {lat: number; lng: number}[]>): void
  onEscClick(e: KeyboardEvent): void
  emitter: Emitter<Record<EventType, any>>
}

const BASIC_POLYGON_OPTIONS = {
  editable: true,
  fillOpacity: 0.3,
  strokeWeight: 2,
  zIndex: 100
}
export default class PolygonDrawingManager implements IPolygonDrawingManagerType {
  // private members
  private readonly map: google.maps.Map
  private readonly drawManager: google.maps.drawing.DrawingManager
  private completeEvent: google.maps.MapsEventListener
  protected polygons = {}
  private activeEvent = ''

  // public members
  public clearPolygonOnClick = false
  emitter = mitt<Record<EventType, any>>()

  constructor (map: google.maps.Map, options?: google.maps.PolygonOptions) {
    this.map = map
    this.drawManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
      polygonOptions: {
        ...BASIC_POLYGON_OPTIONS,
        ...options
      }
    })
    this.drawManager.setMap(this.map)
    this.completeEvent = window.google.maps.event.addListener(this.drawManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
      this.stop()
      this.polygons[this.activeEvent] = polygon
      this.emitter.emit(this.activeEvent, polygon)
      if (this.clearPolygonOnClick) {
        polygon.addListener('click', () => {
          polygon.setMap(null)
        })
      }
    })
  }

  /**
   * Start drawing a polygon
   * @param event event for notifying the drawn polygon
   * @param options strokeColor, fillColor...etc
   */
  start(event: string, options?: google.maps.PolygonOptions): void {
    document.addEventListener('keydown', this.onEscClick.bind(this))
    this.activeEvent = event
    this.drawManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
    if (options) {
      this.drawManager.setOptions({
        polygonOptions: {
          ...BASIC_POLYGON_OPTIONS,
          ...options
        }
      })
    }
  }

  /**
   * Stop polygon drawing
   */
  stop(): void {
    this.drawManager.setDrawingMode(null)
    document.removeEventListener('keydown', this.onEscClick.bind(this))
  }
  
  /**
   * Handle on esc key press
   * @param e
   */
  onEscClick (e: KeyboardEvent): void {
    if (this.activeEvent === '') {
      return
    }
    
    if (e.key == 'Escape') {
      this.activeEvent = ''
      const evt = this.activeEvent
      this.stop()
      if (this.polygons[evt]) {
        this.polygons[evt].setPaths([])
        delete this.polygons[evt]
        this.emitter.emit(evt)
      }
    }
  }

  /**
   * Remove listen such as polygon complete...etc
   */
  dispose(): void {
    this.completeEvent.remove()
    if (this.activeEvent !== '') {
      this.emitter.off(this.activeEvent)
      this.activeEvent = ''
    }
  }
  
  /**
   * Get the drawn polygons data
   */
  getPolygons(): ICoordinateType[][] {
    const polygons = _.values(this.polygons) as google.maps.Polygon[]
    return this.serializePolygon(polygons)
  }
  
  /**
   * Get polygons for saving to localStorage when displaying on comparison page
   */
  getPolygonsForSave(): Record<string, { lat: number; lng: number }[]> {
    return _.mapValues(this.polygons, function (p) {
      const paths = p.getPath()
      if (paths.getLength() > 0) {
        const start = {
          lat: paths.getAt(0).lat(),
          lng: paths.getAt(0).lng()
        }
        const coordinates: {lat: number; lng: number}[] = []
        for (let i = 0; i < paths.getLength(); i++) {
          coordinates.push({
            lat: paths.getAt(i).lat(),
            lng: paths.getAt(i).lng()
          })
        }
        return coordinates.concat(start)
      } else {
        return []
      }
    })
  }
  
  /**
   * set some polygons
   * @param polygons
   */
  seedPolygons(polygons: Record<string, { lat: number; lng: number }[]>): void {
    for (const index in polygons) {
      const color = Config.Instance.polygonColor[index]
      const extra = {}
      if (color) {
        extra['strokeColor'] = color.hex
        extra['fillColor'] = color.hex
      }
      this.polygons[index] = new google.maps.Polygon({
        paths: polygons[index],
        ...BASIC_POLYGON_OPTIONS,
        ...extra
      })
      this.polygons[index].setMap(this.map)
    }
  }
  
  /**
   * Convert drawn polygon to geometry polygon format
   * @param polygons polygons with uuid
   * @returns
   */
  private serializePolygon(polygons: google.maps.Polygon[]): ICoordinateType[][] {
    return polygons.map(p => {
      const paths = p.getPath()
      if (paths.getLength() > 0) {
        const coordinates: ICoordinateType[] = []
        const start: ICoordinateType = {
          latitude: paths.getAt(0).lat(),
          longitude: paths.getAt(0).lng()
        }
        for (let i = 0; i < paths.getLength(); i++) {
          coordinates.push({
            latitude: paths.getAt(i).lat(),
            longitude: paths.getAt(i).lng()
          })
        }
        return coordinates.concat(start)
      }
      return []
    })
  }
}
