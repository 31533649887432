
import {computed, defineComponent} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {PromptDialog} from '@/service/dialog'
import {LOGOUT_QUESTION, SESSION_NAMESPACE} from '@/constant'
import {filter, tap} from 'rxjs/operators'
import {ActionType} from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isSideNav = computed(() => store.state.isSideNavigation)
    const username = computed(() => store.state.username)
    const isShowUsername = computed(() => route.meta.auth_required)
    const isLogin = computed(() => route.name === 'Login')
    const isPasswordReset = computed(() => route.name === 'PasswordReset')
    const isForgetPassword = computed(() => route.name === 'ForgetPassword')
    const isCapturing = computed(() => store.state.isCapturing)

    const handleLogout = () => {
      PromptDialog(LOGOUT_QUESTION).pipe(
          filter((decision: boolean) => decision),
          tap(() => store.dispatch(ActionType.setAppLoading, true))
      ).subscribe(() => {
        localStorage.removeItem(SESSION_NAMESPACE)
        localStorage.removeItem('latlng')
        store.dispatch(ActionType.setAppLoading, false)
        router.push('/login')
      })
    }

    const onclickLogo = (event) => {
      event.preventDefault()
      const base = (localStorage.getItem('appUrl') || window.location.href.split('#')[0] + '#').replace(/\/$/, '')
      location.replace(base);
      setTimeout(()=> {location.reload()},250)
    }

    return {
      route,
      isSideNav,
      username,
      isShowUsername,
      isLogin,
      isPasswordReset,
      isForgetPassword,
      isCapturing,
      handleLogout,
      onclickLogo
    }
  },
})
