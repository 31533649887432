import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bb4a1ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-flex jr-fixed jr-w-full jr-h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIColorToggler = _resolveComponent("UIColorToggler")!
  const _component_UIGoogleMap = _resolveComponent("UIGoogleMap")!
  const _component_RailwaySearchFilter = _resolveComponent("RailwaySearchFilter")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIGoogleMap, {
      onLoad: _ctx.handleMapLoad,
      right: _ctx.captureClip ? 18 : _ctx.filterMinimum ? 72 : 302,
      class: _normalizeClass([{ 'content': !_ctx.captureClip, 'jr-h-screen': _ctx.captureClip }, "jr-absolute jr-w-full jr-bottom-0"]),
      "max-zoom": _ctx.maxZoom
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UIColorToggler, {
          class: "transition-indicator jr-absolute jr-bottom-3.9",
          "capture-clip": _ctx.captureClip,
          style: _normalizeStyle({ left: _ctx.captureClip ? '10px' : _ctx.sbMinimum ? '74px' : '250px' }),
          colors: _ctx.colors
        }, null, 8, ["capture-clip", "style", "colors"])
      ]),
      _: 1
    }, 8, ["onLoad", "right", "class", "max-zoom"]),
    _createVNode(_component_RailwaySearchFilter, {
      onResize: _ctx.handleFilterResize,
      onSearch: _ctx.handleSearch
    }, null, 8, ["onResize", "onSearch"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}