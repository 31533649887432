
import {computed, defineComponent, PropType} from 'vue'
import {BarChart, useBarChart} from 'vue-chart-3'
import {Chart, registerables} from 'chart.js'
import {useStore} from 'vuex'
import {Config} from '@/config'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export enum TransportationBarchartType {
  InArea,
  VisitArea
}

const ALTER_THRESHOLD = 10

Chart.register(...registerables, ChartDataLabels)
export default defineComponent({
  name: 'TransportationBarchart',
  components: { BarChart },
  props: {
    label: { type: String, required: false, default: '' },
    type: { type: Number as PropType<TransportationBarchartType>, required: false, default: () => TransportationBarchartType.InArea }
  },
  setup (props) {
    const store = useStore()
    const chartHeight = computed(() => {
      return (window.innerHeight - 300) / 2
    })
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      // events: [], // remove this setting to show event related items such as tooltips etc..
      categoryPercentage: 1.0,
      barPercentage: 0.6,
      plugins: {
        tooltip: {
          position: 'nearest',
          callbacks: {
            title: function() {
              return ''
            },
            label: function(chart) {
              return `${chart.dataset.label} ${chart.formattedValue}%`
            }
          }
        },
        legend: { display: false },
        datalabels: { display: false }
      },
      scales: {
        x: {
          display: false,
          stacked: true,
          grid: { display: false }
        },
        y: {
          stacked: true,
          grid: {
            display: true,
            drawBorder: false,
            drawTicks: false,
            color: function (context) {
              // this setting is depended on the stepSize
              // See: https://github.com/chartjs/Chart.js/blob/4d918f5afa6f3392201761c4c299bbe093bb96e8/docs/axes/cartesian/linear.md#step-size
              if (context.tick.value === 0 || context.tick.value === 100) {
                return '#DADCE0'
              }
              return null
            }
          },
          ticks: {
            color: 'black',
            font: { size: 12 },
            stepSize: 20,
            padding: 14
          }
        }
      }
    }))

    // data format
    const chartData = computed(() => {
      const transportations = Config.Instance.transportationTypes
      const inAreas = store.state.transportation.inAreas
      const visitAreas = store.state.transportation.visitAreas
      return {
        labels: ['エリア来訪時の交通手段比率'],
        datasets: transportations.map(t => {
          const target = props.type === TransportationBarchartType.InArea ? inAreas.find(f => f.type === t.id) : visitAreas.find(f => f.type === t.id)
          const data = [target ? target.volume : 0]
          return {
            label: t.name,
            data: data,
            backgroundColor: [t.color]
          }
        })
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps, chartData, chartHeight }
  }
})
