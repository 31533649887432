
import {computed, defineComponent} from 'vue'
import {BarChart, useBarChart} from 'vue-chart-3'
import {Chart, registerables} from 'chart.js'
import {useStore} from 'vuex'
import {Config} from '@/config'
import {ITransportationType} from '@/types'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import annotationPlugin from 'chartjs-plugin-annotation'
import _ from 'lodash'

Chart.register(...registerables, ChartDataLabels)
Chart.register(annotationPlugin)

const ANNOTATION_MIN_X = 0.3
const ANNOTATION_MAX_X = 0.7

export default defineComponent({
  name: 'TransportationDashboardBarchart',
  components: {BarChart},
  setup () {
    const store = useStore()
    const inAreaLegend = computed(() => {
      return Config.Instance.transportationTypes.map(t => {
        const inArea = store.state.area.inArea.find(a => a.type === t.id)
        return {
          name: t.name,
          color: t.color,
          volume: inArea ? inArea.volume : 0
        }
      })
    })
    const visitAreaLegend = computed(() => {
      return Config.Instance.transportationTypes.map(t => {
        const visitArea = store.state.area.visitArea.find(a => a.type === t.id)
        return {
          name: t.name,
          color: t.color,
          volume: visitArea ? visitArea.volume : 0
        }
      })
    })
    const options = computed(() => {
      const transportations = Config.Instance.transportationTypes
      const inAreas = store.state.area.inArea
      const visitAreas = store.state.area.visitArea
      const first = _.first<ITransportationType>(transportations)
      const last = _.last<ITransportationType>(transportations)

      let annotations = {}
      if (transportations.length > 0 && inAreas.length > 0 && visitAreas.length > 0) {
        annotations = {
          line0: {
            type: 'line',
            yMin: 0,
            yMax: 0,
            xMin: ANNOTATION_MIN_X,
            xMax: ANNOTATION_MAX_X,
            borderColor: first ? first.color : '#999999',
            borderWidth: 2,
          },
          lastLine: {
            type: 'line',
            yMin: 100,
            yMax: 100,
            xMin: ANNOTATION_MIN_X,
            xMax: ANNOTATION_MAX_X,
            borderColor: last ? last.color : '#999999',
            borderWidth: 2
          }
        }

        // conforming to config sort order
        const constraint = Config.Instance.transportationTypes.map(t => t.id)
        let accInAreas = inAreas
        let accVisitAreas = visitAreas
        const sortedInAreas: ITransportationChart[] = []
        const sortedVisitAreas: ITransportationChart[] = []
        constraint.forEach(function(key) {
          let foundInArea = false
          accInAreas = accInAreas.filter(function (item) {
            if (!foundInArea && item.type === key) {
              sortedInAreas.push(item)
              foundInArea = true
              return false
            } else {
              return true
            }
          })
          let foundVisitArea = false
          accVisitAreas = accVisitAreas.filter(function (item) {
            if (!foundVisitArea && item.type === key) {
              sortedVisitAreas.push(item)
              foundVisitArea = true
              return false
            } else {
              return true
            }
          })
        })

        let inArea = sortedInAreas[0].volume
        let visitArea = sortedVisitAreas[0].volume
        for (let i = 1; i < transportations.length; i++) {
          annotations[`line${i}`] = {
            type: 'line',
            yMin: visitArea,
            yMax: inArea,
            xMin: ANNOTATION_MIN_X,
            xMax: ANNOTATION_MAX_X,
            borderColor: transportations[i].color,
            borderWidth: 2
          }
          if (sortedInAreas[i]) {
            inArea += sortedInAreas[i].volume
          }

          if (sortedVisitAreas[i]) {
            visitArea += sortedVisitAreas[i].volume
          }
        }
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        // events: [], // remove this setting to show event related items such as tooltips etc
        categoryPercentage: 1.0,
        barPercentage: 0.9,
        plugins: {
          tooltip: {
            position: 'nearest',
            callbacks: {
              label: function(chart) {
                return `${chart.dataset.label} ${chart.formattedValue}%`
              }
            }
          },
          annotation: { annotations: annotations },
          legend: { display: false },
          datalabels: { display: false }
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            grid: { display: false },
            ticks: {
              color: '#334A7C',
              font: { size: 14, weight: '400' },
              stepSize: 20,
              padding: 14
            }
          },
          y: {
            stacked: true,
            grid: {
              display: true,
              drawBorder: false,
              drawTicks: false,
              color: function (context) {
                // this setting is depended on the stepSize
                // See: https://github.com/chartjs/Chart.js/blob/4d918f5afa6f3392201761c4c299bbe093bb96e8/docs/axes/cartesian/linear.md#step-size
                if (context.tick.value === 0 || context.tick.value === 100) {
                  return '#DADCE0'
                }
                return null
              }
            },
            ticks: {
              color: 'black',
              font: { size: 14 },
              stepSize: 20,
              padding: 14
            }
          }
        }
      }
    })

    // data format
    const chartData = computed(() => {
      return {
        labels: ['エリア来訪時', 'エリア内'],
        datasets: Config.Instance.transportationTypes.map(t => {
          const inArea = store.state.area.inArea.find(a => a.type === t.id)
          const visitArea = store.state.area.visitArea.find(a => a.type === t.id)
          return {
            label: t.name,
            data: [visitArea ? visitArea.volume : 0, inArea ? inArea.volume : 0],
            backgroundColor: [t.color],
            barPercentage: 0.6
          }
        })
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps, chartData, inAreaLegend, visitAreaLegend }
  }
})
