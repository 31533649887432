import {HttpRequest} from '@/core/httpRequest'
import {IAreaFacilityMasterType} from '@/data/master/area/facility/type'
import {HttpClient} from '@/core/httpClient'

export interface IAreaFacilityMasterAPIStoreType {
  fetch(request: HttpRequest<IAreaFacilityMasterType[]>): Promise<IAreaFacilityMasterType[]>
}

export default class AreaFacilityMasterAPIStore implements IAreaFacilityMasterAPIStoreType {
  async fetch(request: HttpRequest<IAreaFacilityMasterType[]>): Promise<IAreaFacilityMasterType[]> {
    const client = new HttpClient<IAreaFacilityMasterType[]>()
    const response = await client.request<IAreaFacilityMasterType[]>(request)
    return response.data
  }
}
