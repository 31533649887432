
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {useStore} from 'vuex'
import {Emitter, EventType} from 'mitt'
import {getSelectedRailwayStation, setAccessToken} from '@/utils/storage'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {heatmapAnimationFactory} from '@/layers/heatmap'
import {DOWNLOAD_RAILWAY_ANIMATION, HINT_RAILWAY_ANIMATION, globalEmitter} from '@/service/GlobalEmmiter'
import {ActionType} from '@/store'
import {ConvertToImage} from '@/service/file'
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  RAILWAY_NO_WEEK
} from '@/constant'
import {useRoute} from 'vue-router'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import RailwaySearchFilter from '@/components/subviews/railway/RailwaySearchFilter.vue'
import AppLoader from '@/components/AppLoader.vue'
import UIBarchartTimeController from '@/components/UI/railway/UIBarchartTimeController.vue'
import RailwayStubStore from '@/data/railway/store/stub'
import RailwayAPIStore from '@/data/railway/store'
import RailwayRepository from '@/data/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import HeatmapService from '@/service/heatmap'
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import {Config} from "@/config";
import {HintDialog} from '@/service/dialog'
import RailwayAnimation from '@/components/hints/RailwayAnimation.vue'

interface State {
  map: any
  filterMinimum: boolean
  isLoading: boolean
  captureClip: boolean
  chart: number[]
  timeHeatmaps: Record<string, (number | string)[][]>
  colorDomain: number[]
  activeTime: number
  isLess: boolean
}

export default defineComponent({
  name: 'Animation',
  components: {
    UIBarchartTimeController,
    RailwaySearchFilter,
    UIGoogleMap,
    AppLoader,
    UIColorToggler
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const colors = Config.Instance.heatmapColors.hex
    const state = reactive<State>({
      map: null,
      filterMinimum: false,
      isLoading: false,
      captureClip: false,
      chart: [],
      activeTime: 0,
      timeHeatmaps: {},
      colorDomain: [],
      isLess: false
    })
    const service = new HeatmapService()

    // data layer instances
    const stub = new RailwayStubStore()
    const api = new RailwayAPIStore()
    const repository = new RailwayRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })

    const draw = () => {
      const target = state.timeHeatmaps[state.activeTime]
      if (target) {
        deckOverlay.setProps({
          layers: [heatmapAnimationFactory(target, state.colorDomain)]
        })
      }
    }

    const maxZoom = computed(() => {
      const max = store.state.heatmapMaxZoom || 16
      if (state.map) {
        if (state.map.getZoom() > max) {
          state.map.setZoom(max)
        }
      }
      return max
    })

    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(state.map)

      // center the map
      const service = new window.google.maps.places.PlacesService(state.map)
      const station = getSelectedRailwayStation()
      const request = {
        query: `${station}駅`,
        fields: ['name', 'geometry']
      }
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const geometry = results[0].geometry
          if (geometry !== undefined) {
            if (geometry.location?.lat() !== undefined && geometry.location?.lng() !== undefined) {
              const pos = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
              }
              state.map.setCenter(pos)
              const zoom = localStorage.getItem('zoom')
              if (zoom) {
                state.map.setZoom(Number(zoom))
              }
            }
          }
        }
      })
    }
    const onTimeControllerChange = (value: number) => {
      state.activeTime = value
      draw()
    }
    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const makeAPICall = (params: Record<string, any>) => {
      state.isLoading = true
      state.isLess = false
      state.timeHeatmaps = {}
      state.colorDomain = []
      deckOverlay.setProps({ layers: [] })
      repository.fetchAnimation(params).then(res => {
        const timeData = service.getTimeHeatmap(res.map)
        state.timeHeatmaps = timeData.times
        state.colorDomain = timeData.colorDomain
        state.chart = res.chart
        draw()
        emitter?.emit('notifyRailwayFilter')
      }).catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetchAnimation(params))
          .then(res => {
            const timeData = service.getTimeHeatmap(res.map)
            state.chart = res.chart
            state.timeHeatmaps = timeData.times
            state.colorDomain = timeData.colorDomain
            draw()
            emitter?.emit('notifyRailwayFilter')
          })
          .catch(err => {
            if (err.status === 404) {
              state.isLess = true
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
        } else if (err.status === 404) { // amount of search result is less, and the API return this error
          state.isLess = true
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => state.isLoading = false)
    }
    const handleSearch = (params: Record<string, any>) => {
      delete params.time_range
      delete params.type
      delete params.resident
      const week = params.week && params.week.length === 0
      if (week) {
        AlertDialog(RAILWAY_NO_WEEK)
        return
      }
      makeAPICall(params)
    }
    const week = store.state.railway.week
      .filter(w => w.isSelected)
      .map(w => w.value)
    const params = {}
    if (week.length == 1) {
      params['week'] = week
    }
    makeAPICall(params)

    globalEmitter.on(DOWNLOAD_RAILWAY_ANIMATION, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_RAILWAY_ANIMATION, () => {
      HintDialog(RailwayAnimation)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_RAILWAY_ANIMATION)
      globalEmitter.off(HINT_RAILWAY_ANIMATION)
    })
    return {
      ...toRefs(state),
      sbMinimum,
      colors,
      handleSearch,
      handleMapLoad,
      handleFilterResize,
      onTimeControllerChange,
      maxZoom
    }
  }
})
