
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import UISpeechBubble from '@/components/UI/UISpeechBubble.vue'
import {Config} from "@/config";
import {useStore} from "vuex";
import {ActionType} from "@/store";

enum ReportNavigationType {
  PeopleVolume = 0,
  SamedayAction = 1,
  OneHourGap = 2
}

export default defineComponent({
  name: "UITimeSeriesController",
  components: { UISpeechBubble },
  setup() {
    const route = useRoute()
    const store = useStore()
    const changeNav = (nav: ReportNavigationType) => state.selectedNav = nav
    const state = reactive({
      selectedNav: ReportNavigationType.PeopleVolume,
      minimum: false,
      colors: Config.Instance.heatmapColors.hex,
      activeNav: -1
    })
    const isCapturing = computed(() => store.state.isCapturing)

    const handleMouseHover = (nav: ReportNavigationType) => state.activeNav = nav
    const handleMouseLeave = () => state.activeNav = -1

    const isPeopleVolumneSelected = computed(() => route.path === '/report/graph')
    const isSamedayActionSelected = computed(() => route.path === '/report/same_day_series')
    const isOneHourGapSelected = computed(() => route.path === '/report/one_hour_series')

    const onClickClose = (event) => {
      event.preventDefault();
      state.minimum = !state.minimum;
      store.dispatch(ActionType.setRoadSbMinimum, state.minimum)
    }

    return {
      ...toRefs(state),
      changeNav,
      handleMouseHover,
      handleMouseLeave,
      ReportNavigationType,
      isCapturing,
      isPeopleVolumneSelected,
      isSamedayActionSelected,
      isOneHourGapSelected,
      onClickClose,
    }
  },
})
