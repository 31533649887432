import { HttpClient } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import {IAnimationDaysType} from "@/data/animationday/type";

export interface IAnimationDayAPIStoreType {
    fetch(request: HttpRequest<IAnimationDaysType>): Promise<IAnimationDaysType>
}

export default class AnimationDayAPIStore implements IAnimationDayAPIStoreType {
    async fetch(request: HttpRequest<IAnimationDaysType>): Promise<IAnimationDaysType> {
        const client = new HttpClient<IAnimationDaysType>()
        const response = await client.request<IAnimationDaysType>(request)
        return response.data
    }
}