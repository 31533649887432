import {HttpRequest} from '@/core/httpRequest'
import {HttpClient} from '@/core/httpClient'

export interface IAreaTableAPIStoreType {
  dataEntryToTable1(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
  dataEntryToTable1Detail(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
  dataEntryToTable2(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
  dataEntryToTable3(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
  dataEntryToTable3Detail(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
  dataEntryToTable4(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>>
}

export default class AreaTableAPIStore implements IAreaTableAPIStoreType {
  async dataEntryToTable1(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
  
  async dataEntryToTable1Detail(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
  
  async dataEntryToTable2(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
  
  async dataEntryToTable3(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
  
  async dataEntryToTable3Detail(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
  
  async dataEntryToTable4(request: HttpRequest<Record<string, any>>): Promise<Record<string, any>> {
    const client = new HttpClient<Record<string, any>>()
    const response = await client.request<Record<string, any>>(request)
    return response.data
  }
}
