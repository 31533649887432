
import {defineComponent, PropType} from 'vue'

export default defineComponent({
  name: 'RailwayFilterBadge',
  props: {
    data: {
      type: Object as PropType<{title: string; content: string}>,
      required: false,
      default: () => ({
        title: '',
        content: ''
      })
    }
  }
})
