
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {Config} from '@/config'
import {DOWNLOAD_SAME_DAY_SERIES, HINT_AREA_ANIMATION, globalEmitter} from '@/service/GlobalEmmiter'
import {ConvertToImage} from '@/service/file'
import {
  AREA_NO_FREQUENCY,
  AREA_NO_WEEK,
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  NO_CONDITION_SPECIFIED
} from '@/constant'
import {useStore} from 'vuex'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {AreaHeatmapActionType} from '@/store/modules/area/search/heatmap/actions'
import {getAreaSelectedPolygon, setAccessToken} from '@/utils/storage'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {formatHourToLeadingZero} from '@/utils/time'
import {areaHeatmapAnimationFactory} from '@/layers/heatmap'
import {useRoute} from 'vue-router'
import {Emitter, EventType} from 'mitt'
import {ActionType} from '@/store'
import {polygonFactory} from '@/layers/polygon'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import UITimeSeriesController from '@/components/UI/UITimeSeriesController.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import HeatmapService from '@/service/heatmap'
import AppLoader from '@/components/AppLoader.vue'
import UIAreaToggle from '@/components/UI/area/UIAreaToggle.vue'
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import {HintDialog} from '@/service/dialog'
import AreaAnimation from '@/components/hints/AreaAnimation.vue'

interface State {
  map: any
  colorDomain: number[]
  timeHeatmaps: Record<string, (number | string)[][]>
  activeTime: string
  isLoading: boolean
  filterMinimum: boolean
  captureClip: boolean
}

export default defineComponent({
  name: 'SameDaySeries',
  components: {
    UIAreaToggle,
    AppLoader,
    UITimeSeriesController,
    UIGoogleMap,
    UIColorToggler,
    AreaSearchFilter
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      map: null,
      colorDomain: [],
      timeHeatmaps: {},
      activeTime: '',
      isLoading: false,
      filterMinimum: false,
      captureClip: false
    })
    const service = new HeatmapService()
    const stub = new AreaStubStore()
    const api = new AreaAPIStore()
    const repository = new AreaRepository(stub, api)
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const tableUnitVal = computed(() => Config.Instance.tableUnitVal())
    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    })
    const polygonOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })
    const isSameDay = computed(() => route.path === '/area_report/same_day_action')
    const colors = Config.Instance.heatmapColors.hex
    const maxZoom = computed(() => {
      const max = store.state.heatmapMaxZoom || 16
      if (state.map) {
        if (state.map.getZoom() > max) {
          state.map.setZoom(max)
        }
      }
      return max
    })

    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(map)
      polygonOverlay.setMap(map)
    }

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const onTimeSeriesChange = (time: number) => {
      const hour = formatHourToLeadingZero(Math.floor(time))
      const timeStr = `${hour}:00:00`
      if (state.activeTime !== timeStr) {
        state.activeTime = timeStr
        draw()
      }
    }

    const isPrepareHeatmap = computed(() => {
      let count = 0
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (let _ in state.timeHeatmaps) {
        count++
      }
      return count > 0
    })

    const draw = () => {
      const target = state.timeHeatmaps[state.activeTime]
      if (target) {
        deckOverlay.setProps({
          layers: [areaHeatmapAnimationFactory(target, state.colorDomain)]
        })
      }
    }

    const handleToggleArea = (value: boolean) => {
      if (value) {
        const polygons = getAreaSelectedPolygon()
        polygonOverlay.setProps({
          layers: [polygonFactory(polygons)]
        })
      } else {
        polygonOverlay.setProps({ layers: [] })
      }
    }

    const makeAPICall = async (params: Record<string, any>) => {
      state.isLoading = true
      state.timeHeatmaps = {}
      state.colorDomain = []
      deckOverlay.setProps({ layers: [] })
      repository.fetchHeatmap(params)
      .then(response => {
        store.dispatch(`areaHeatmap/${AreaHeatmapActionType.setPlotData}`, response)
        const timeData = service.getTimeHeatmap(response)
        state.timeHeatmaps = timeData.times
        state.colorDomain = timeData.colorDomain
        state.activeTime = ''
      })
      .catch(err => {
        if (isSameDay.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetchHeatmap(params))
            .then(response => {
              store.dispatch(`areaHeatmap/${AreaHeatmapActionType.setPlotData}`, response)
              const timeData = service.getTimeHeatmap(response)
              state.timeHeatmaps = timeData.times
              state.colorDomain = timeData.colorDomain
            }).catch(err => {
              if (err.status === 404) {
                WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
              } else if (err.status === 416) {
                WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
              } else {
                AlertDialog(err.message)
              }
            })
          } else if (err.status === 404) {
            WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        }
      }).finally(() => {
        state.isLoading = false
        emitter?.emit('notifyAreaFilter')
      })
    }

    // API call
    const handleSearch = async (params: Record<string, any>) => {
      // user didn't specify any filter condition
      const freq = params.frequencies && params.frequencies.length === 0
      const week = params.week && params.week.length === 0
      delete params.type
      delete params.visitor_attribute
      delete params.time_range
      if (week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      makeAPICall(params)
    }

    globalEmitter.on(DOWNLOAD_SAME_DAY_SERIES, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_AREA_ANIMATION, () => {
      HintDialog(AreaAnimation)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_SAME_DAY_SERIES)
      globalEmitter.off(HINT_AREA_ANIMATION)
    })

    const initParams = store.getters['area/getAPIParams']
    delete initParams.type
    delete initParams.visitor_attribute
    delete initParams.time_range
    delete initParams.dashboard
    if (initParams.week && initParams.week.length > 0) {
      initParams.week = initParams.week[0]
    }
    makeAPICall(initParams)

    return {
      ...toRefs(state),
      isPrepareHeatmap,
      sbMinimum,
      handleMapLoad,
      handleSearch,
      handleFilterResize,
      onTimeSeriesChange,
      handleToggleArea,
      tableUnitVal,
      colors,
      maxZoom
    }
  }
})
