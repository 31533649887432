import { defineRule, configure } from 'vee-validate'
import AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { EmailReg } from './regex'

localize('ja')
configure({
  validateOnInput: true,
  validateOnModelUpdate: true
})


Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule])
})

/**
 * Required
 */
defineRule('required', (value: string, params: string) => {
  if (!value || !value.length) {
    return params + 'を空白にすることはできません'
  }
  return true
})

/**
 * Email
 */
defineRule('email', (value: string, params: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if email
  if (!EmailReg.test(value)) {
    return params + 'は有効なメールアドレスである必要があります'
  }
  return true
})

defineRule('jinryu_password', (value: string, params: string) => {
  if (value.length < 8) {
    return params + 'は最低 8 文字以上必要です'
  }
  if (!isNaN(Number(value))) {
    return '数字だけの' + params + 'にはできません'
  }
  return true
})
