import { IHeatmapQueryType, IQueryType } from '@/data/query/type'
import _ from 'lodash'

export interface IHeatmapServiceType {
  getForPlot(queries: Array<Array<number>>): IHeatmapQueryType[]
  getForAnimation(queries: Array<Array<number>>): IHeatmapQueryType[]
  getTimeHeatmap(data: (number | string)[][]): {times: Record<string, (number | string)[][]>; colorDomain: number[]}
}

export default class HeatmapService implements IHeatmapServiceType {
  getForPlot(queries: Array<Array<number>>): IHeatmapQueryType[] {
    return _.map<IHeatmapQueryType>(queries, (q) => {
      return {
        coordinates: [q[0], q[1]],
        weight: q[2]
      }
    })
  }

  getForAnimation(queries: Array<Array<number>>): IHeatmapQueryType[] {
    const timeGroup = _.groupBy<IQueryType>(queries, (value: IQueryType) => {
      return value.jst_hour
    })
    for (const hour in timeGroup) {
      timeGroup[hour] = this.getForPlot(timeGroup[hour])
    }
    return timeGroup
  }
  
  getTimeHeatmap(data: (number | string)[][]): {times: Record<string, (number | string)[][]>; colorDomain: number[]} {
    const timeAry: Record<string, (number | string)[][]> = {}
    let minCol = 0
    let maxCol = 0
    let init = false
    data.forEach(timeData => {
      if (!timeAry[timeData[2]]) {
        timeAry[timeData[2]] = []
      }
      timeAry[timeData[2]].push(timeData)
      // initialization
      if (!init) {
        minCol = Number(timeData[3])
        maxCol = Number(timeData[3])
        init = true
      } else {
        minCol = Number(timeData[3] < minCol ? timeData[3] : minCol)
        maxCol = Number(timeData[3] > maxCol ? timeData[3] : maxCol)
      }
    })
    return {
      times: timeAry,
      colorDomain: [minCol, maxCol]
    }
  }
}
