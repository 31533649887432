
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import {getSelectedRailwayLine, setAccessToken, setRailwayPolygons, setSelectedRailwayStation} from '@/utils/storage'
import {useStore} from 'vuex'
import {AlertDialog} from '@/service/dialog'
import {RailLineActionType} from '@/store/modules/railway/master/line/actions'
import RailwayMasterStubStore from '@/data/master/railway/store/stub'
import RailwayMasterAPIStore from '@/data/master/railway/store'
import RailwayMasterRepository from '@/data/master/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import UIRadioButton from '@/components/UI/UIRadioButton.vue'

interface State {
  minimum: boolean
}

export default defineComponent({
  name: 'RailwayStationSelector',
  components: {UIRadioButton},
  emits: ['minimum'],
  setup (_, {emit}) {
    const router = useRouter()
    const store = useStore()
    const state = reactive<State>({
      minimum: false
    })
    const selectedLine = getSelectedRailwayLine()
    const selectedStation = computed(() => store.state.railLine.selectedStation)
    const isCapturing = computed(() => store.state.isCapturing)
    const stations = computed<string[]>(() => {
      const line = selectedLine.split('-')
      if (line.length > 1) {
        const target = store.state.railLine.lines.find(l => {
          return l.line_name === line[1] && l.company_name === line[0]
        })
        return target ? target.stations : []
      } else {
        return []
      }
    })

    // load lines if empty
    const lines = computed(() => store.state.railLine.lines)
    if (lines.value.length === 0) {
      // data layer
      const stub = new RailwayMasterStubStore()
      const api = new RailwayMasterAPIStore()
      const repository = new RailwayMasterRepository(stub, api)

      // auth
      const authApi = new AuthAPIStore()
      const authRepository = new AuthRepository(authApi)

      repository.fetchRailLine({}).then(lines => {
        store.dispatch(`railLine/${RailLineActionType.setLines}`, lines)
      })
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => {
            return repository.fetchRailLine({}).then(lines => {
              store.dispatch(`railLine/${RailLineActionType.setLines}`, lines)
            })
          })
        } else {
          AlertDialog(err.message)
        }
      })
    }
    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
      emit('minimum', state.minimum)
    }
    const handleChange = (value: string) => {
      store.dispatch(`railLine/${RailLineActionType.setSelectedStation}`, value)
    }
    const handleSearch = () => {
      setRailwayPolygons({})
      setSelectedRailwayStation(store.state.railLine.selectedStation)
      store.dispatch(`railLine/${RailLineActionType.setChosenStation}`, store.state.railLine.selectedStation)
      router.push('/railway_report/heatmap')
    }

    return {
      ...toRefs(state),
      lines,
      stations,
      selectedLine,
      selectedStation,
      isCapturing,
      clickClose,
      handleChange,
      handleSearch
    }
  }
})
