import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c3241d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-fixed jr-w-full jr-h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterNavigation = _resolveComponent("FilterNavigation")!
  const _component_ReportSideNavigation = _resolveComponent("ReportSideNavigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilterNavigation, { onDownload: _ctx.handleDownload }, null, 8, ["onDownload"]),
    _createVNode(_component_ReportSideNavigation),
    _createElementVNode("main", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["jr__content jr-bg-white jr-fixed jr-h-screen jr-min-w-report", { 'jr-overflow-scroll': _ctx.isPeopleVolumneSelected, 'jr-overflow-hidden': _ctx.isSamedayActionSelected || _ctx.isOneHourGapSelected }])
      }, [
        _createVNode(_component_router_view, { ref: "contentRef" }, null, 512)
      ], 2)
    ])
  ]))
}