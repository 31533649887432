import {MutationTree} from 'vuex'
import {AreaPolygonMasterState, AreaRankingPolygon} from '@/store/modules/area/master/polygon/type'
import {IAreaPolygonMasterType} from '@/data/master/area/polygon/type'

export enum AreaPolygonMasterMutationType {
  SET_AREA_POLYGON_MASTER = 'SetAreaPolygonMaster',
  SET_SELECTED_POLYGON = 'SetSelectedPolygon',
  TOGGLE_SELECTED_POLYGON = 'ToggleSelectedPolygon',
  SET_RANKING_POLYGON = 'SetRankingPolygon'
}

export const mutations: MutationTree<AreaPolygonMasterState> = {
  [AreaPolygonMasterMutationType.SET_AREA_POLYGON_MASTER] (state: AreaPolygonMasterState, payload: IAreaPolygonMasterType[]): void {
    state.polygons = payload
  },
  [AreaPolygonMasterMutationType.SET_SELECTED_POLYGON] (state: AreaPolygonMasterState, payload: (IAreaPolygonMasterType & {pref_id: string; isSelected?: boolean})[]): void {
    payload.forEach(p => delete p.isSelected)
    state.selectedPolygons = payload
  },
  [AreaPolygonMasterMutationType.SET_RANKING_POLYGON] (state: AreaPolygonMasterState, payload: AreaRankingPolygon[]): void {
    state.rankingPolygons = payload
  },
  [AreaPolygonMasterMutationType.TOGGLE_SELECTED_POLYGON] (state: AreaPolygonMasterState, payload: IAreaPolygonMasterType & {pref_id: string; isSelected?: boolean}): void {
    delete payload.isSelected
    const index = state.selectedPolygons.findIndex(p => {
      return p.pref_id === payload.pref_id && p.id === payload.id && p.name === payload.name
    })
    if (index < 0) {
      state.selectedPolygons = state.selectedPolygons.concat(payload)
    } else {
      state.selectedPolygons = state.selectedPolygons.filter(p => {
        return p.name !== payload.name && p.id !== payload.id
      })
    }
  }
}
