import {GetterTree} from 'vuex'
import {AreaTimeSpentState} from '@/store/modules/area/search/timeSpent/type'
import {RootState} from '@/store/types'
import {percentRound} from '@/utils/number'

export const getters: GetterTree<AreaTimeSpentState, RootState> = {
  labels (state: AreaTimeSpentState): string[] {
    return state.times.map(time => time.label)
  },
  volumes (state: AreaTimeSpentState): number[] {
    const volumes = state.times.map(time => time.volume)
    return percentRound(volumes)
  }
}
