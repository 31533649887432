import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6d9e458"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]
const _hoisted_2 = {
  key: 0,
  class: "jr-btn-loader jr-w-6 jr-h-6 jr-absolute jr-mx-auto jr-left-0 jr-right-0 jr-rounded-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["jr-m-0 jr-cursor-pointer", {
          'jr-bg-primary jr-text-white jr-border-2 jr-border-primary': _ctx.type === _ctx.ButtonType.primary,
          'jr-opacity-50 jr-pointer-events-none': _ctx.disabled,
          'jr-bg-white jr-border-2 jr-border-blue-light jr-text-blue-light hover:jr-bg-primary hover:jr-text-white hover:jr-border-primary': _ctx.type === _ctx.ButtonType.classic
        }])
  }, [
    _createElementVNode("button", {
      class: "jr-font-bold jr-text-center",
      type: _ctx.primitiveType
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
      _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.label), 513), [
        [_vShow, !_ctx.loading]
      ])
    ], 8, _hoisted_1),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}