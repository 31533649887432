import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { DaySeriesState } from './type'
import {leadingZero} from "@/utils/time";
import _ from 'lodash'
import { DayCode } from '@/enum/barchart';
import { Config } from '@/config';

export const getters: GetterTree<DaySeriesState, RootState> = {
    getDoInitFlg (state: DaySeriesState): boolean {
        return state.doInitFlg
    },
    getTimeRangeLabel (state: DaySeriesState): string {
        return `${leadingZero(state.time[0])} - ${leadingZero(state.time[1])}`
    },
    getActiveTimeRangeLabel (state: DaySeriesState): string {
        return state.activeTime
    },
    getDayLabel (state: DaySeriesState): string {
        const str = _.map(state.dayConstraint, (day: DayCode) => {
            return Config.Instance.translateDayByCode(day) + '/'
        })
        return _.reduce(str, (agg, val) => agg + val, '').replace(/\/$/, '')
    },
}
