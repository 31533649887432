import {HttpRequest} from '@/core/httpRequest'
import {IPersonaType} from '@/data/master/area/persona/type'
import {HttpClient} from '@/core/httpClient'

export interface IPersonaMasterAPIStoreType {
  fetch(request: HttpRequest<IPersonaType[]>): Promise<IPersonaType[]>
}

export default class PersonaAPIStore implements IPersonaMasterAPIStoreType {
  async fetch(request: HttpRequest<IPersonaType[]>): Promise<IPersonaType[]> {
    const client = new HttpClient<IPersonaType[]>()
    const response = await client.request<IPersonaType[]>(request)
    return response.data
  }
}
