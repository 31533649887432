import { IAreaAPIStoreType } from '@/data/area/store'
import { IAreaStubType } from '@/data/area/store/stub'
import {
  IAreaConfig,
  IAreaType,
  IFacilityType,
  IRankingType,
  ITimeSpentType,
  IVisitorAgeType,
  IVisitorPersonaType
} from '@/data/area/type'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'
import {
  AreaConfigRequest,
  AreaFacilityPinRequest,
  AreaHeatmapRequest, AreaRankingRequest, AreaScatterplotRequest,
  AreaTimeSpentRequest,
  AreaTransportationChartRequest,
  AreaVisitorRequest
} from '@/data/area/store/request'

export interface IAreaRepositoryType {
  api: IAreaAPIStoreType
  stub: IAreaStubType
  fetchConfig(params: Record<string, any>): Promise<IAreaConfig[]>
  fetch(params: Record<string, any>): Promise<IAreaType[]>
  fetchTransportation(params: Record<string, any>): Promise<Array<number[]>>
  fetchFacility(params: Record<string, any>): Promise<IFacilityType[]>
  fetchFacilityPin(params: Record<string, any>): Promise<Record<string, any>>
  fetchTransportationChart(params: Record<string, any>): Promise<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}>
  fetchHeatmap(params: Record<string, any>): Promise<(number | string)[][]>
  fetchTimeSpent(params: Record<string, any>): Promise<{average: number; data: ITimeSpentType[]}>
  fetchVisitor(params: Record<string, any>): Promise<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}>
  fetchRanking(params: Record<string, any>): Promise<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }>
}

export default class AreaRepository implements IAreaRepositoryType {
  stub: IAreaStubType
  api: IAreaAPIStoreType
  
  /// DI
  constructor (stub: IAreaStubType, api: IAreaAPIStoreType) {
    this.stub = stub
    this.api = api
  }
  
  async fetchConfig(params: Record<string, any>): Promise<IAreaConfig[]> {
    const request = new AreaConfigRequest(params)
    return await this.api.fetchConfig(request)
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetch(params: Record<string, any>): Promise<IAreaType[]> {
    return await this.stub.fetch()
  }
  
  async fetchTransportation(params: Record<string, any>): Promise<Array<number[]>> {
    const request = new AreaScatterplotRequest(params)
    return await this.api.fetchTransportation(request)
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetchFacility(params: Record<string, any>): Promise<IFacilityType[]> {
    return await this.stub.fetchFacility()
  }
  
  async fetchTransportationChart(params: Record<string, any>): Promise<{ in_area: ITransportationChart[]; visit_area: ITransportationChart[] }> {
    const request = new AreaTransportationChartRequest(params)
    return await this.api.fetchTransportationChart(request)
  }
  
  async fetchHeatmap(params: Record<string, any>): Promise<(number | string)[][]> {
    const request = new AreaHeatmapRequest(params)
    return await this.api.fetchHeatmap(request)
  }
  
  async fetchTimeSpent(params: Record<string, any>): Promise<{average: number; data: ITimeSpentType[]}> {
    const request = new AreaTimeSpentRequest(params)
    return await this.api.fetchTimeSpent(request)
  }
  
  async fetchVisitor(params: Record<string, any>): Promise<{ persona: IVisitorPersonaType[]; ages: IVisitorAgeType }> {
    const request = new AreaVisitorRequest(params)
    return await this.api.fetchVisitor(request)
  }
  
  async fetchRanking(params: Record<string, any>): Promise<{
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }> {
    const request = new AreaRankingRequest(params)
    return await this.api.fetchRanking(request)
  }
  
  async fetchFacilityPin(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaFacilityPinRequest(params)
    return await this.api.fetchFacilityPin(request)
    // return await this.stub.fetchFacilityPin()
  }
}
