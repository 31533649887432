
import { defineComponent } from 'vue'
import UIButton from '@/components/UI/UIButton.vue'

export default defineComponent({
  name: 'ModalDialog',
  components: {
    UIButton
  }
})
