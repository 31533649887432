
import {computed, defineComponent} from "vue";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "TableMakeLoader",
  setup () {
    const route = useRoute()
    const isArea = computed(() => route.path === "/area_report")
    return { isArea }
  }
});
