import {MutationTree} from 'vuex'
import {RailLineState} from '@/store/modules/railway/master/line/type'
import {IRailLineType} from '@/data/master/railway/type'

export enum RailLineMutationType {
  SET_LINES = 'SetLines',
  SET_SELECTED_LINE = 'SetSelectedLine',
  SET_SELECTED_STATION = 'SetSelectedStation',
  TOGGLE_LINE_COLLAPSE = 'ToggleLineCollapse',
  SET_SELECTED_LINE_STATION = 'SetSelectedLineStation',
  SET_CHOSEN_LINE = 'SetChosenLine',
  SET_CHOSEN_STATION = 'SetChosenStation'
}

export const mutations: MutationTree<RailLineState> = {
  [RailLineMutationType.SET_LINES] (state: RailLineState, payload: IRailLineType[]): void {
    state.lines = payload
  },
  [RailLineMutationType.SET_SELECTED_LINE] (state: RailLineState, payload: string): void {
    state.selectedLine = payload
  },
  [RailLineMutationType.SET_SELECTED_STATION] (state: RailLineState, payload: string): void {
    state.selectedStation = payload
  },
  [RailLineMutationType.SET_CHOSEN_LINE] (state: RailLineState, payload: string): void {
    state.chosenLine = payload
  },
  [RailLineMutationType.SET_CHOSEN_STATION] (state: RailLineState, payload: string): void {
    state.chosenStation = payload
  },
  [RailLineMutationType.TOGGLE_LINE_COLLAPSE] (state: RailLineState, payload: IRailLineType): void {
    const index = state.lines.findIndex(l => {
      return l.company_name === payload.company_name && l.line_name === payload.line_name
    })
    if (index >= 0) {
      const capture = state.lines[index].isCollapsed
      state.lines[index].isCollapsed = !capture
    }
  },
  [RailLineMutationType.SET_SELECTED_LINE_STATION] (state: RailLineState, payload: IRailLineType): void {
    const index = state.lines.findIndex(l => {
      return l.company_name === payload.company_name && l.line_name === payload.line_name
    })
    if (index >= 0) {
      state.lines[index].selectedStation = payload.selectedStation
    }
  }
}
