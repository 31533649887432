
import {computed, defineComponent, ref} from 'vue'
import FilterNavigation from '@/components/subviews/FilterNavigation.vue'
import ReportSideNavigation from '@/components/subviews/ReportSideNavigation.vue'
import {useRoute} from "vue-router";

export default defineComponent({
  name: "Report",
  components: { FilterNavigation, ReportSideNavigation },
  setup() {
    const route = useRoute()
    const contentRef = ref();
    const handleDownload = () => {
      if(contentRef.value) {
        contentRef.value.handleDownload();
      }
    }

    const isPeopleVolumneSelected = computed(() => route.path === '/report/graph')
    const isSamedayActionSelected = computed(() => route.path === '/report/same_day_series')
    const isOneHourGapSelected = computed(() => route.path === '/report/one_hour_series')

    return {
      isPeopleVolumneSelected,
      isSamedayActionSelected,
      isOneHourGapSelected,
      handleDownload,
      contentRef
    }
  },
})
