import {Component, createApp, DefineComponent, onMounted, onUnmounted, reactive, toRefs} from 'vue'
import ModalDialog from '@/components/modal/ModalDialog.vue'
import ModalPrompt from '@/components/modal/ModalPrompt.vue'
import ModalWarning from '@/components/modal/ModalWarning.vue'
import { ButtonType } from '@/enum/button'
import { Subject } from 'rxjs'
import ModalWarningPrompt from "@/components/modal/ModalWarningPrompt.vue";
import ModalHint from "@/components/modal/ModalHint.vue";

export const WarningDialog = function (title: string, message: string): void {
  const dialog = createApp({
    extends: ModalWarning,
    mixins: [ModalWarning],
    setup () {
      const onClose = () => {
        dialog.unmount()
      }
      const show = true
      
      onMounted(() => document.body.classList.add('jr-overflow-hidden'))
      onUnmounted(() => document.body.classList.remove('jr-overflow-hidden'))
      
      return {
        onClose,
        title,
        message,
        show,
        ButtonType
      }
    }
  }).component('warning-dialog', {})
  dialog.mount('#modal-root')
}

export const AlertDialog = function (message: string, title?: string): void {
  const dialog = createApp({
    extends: ModalDialog,
    mixins: [ModalDialog],
    setup () {
      const onClose = () => {
        dialog.unmount()
      }
      const show = true

      onMounted(() => document.body.classList.add('jr-overflow-hidden'))
      onUnmounted(() => document.body.classList.remove('jr-overflow-hidden'))

      return {
        onClose,
        title,
        message,
        show,
        ButtonType
      }
    }
  }).component('alert-dialog', {})
  dialog.mount('#modal-root')
}

export const PromptDialog = function (
  message: string,
  title?: string,
  submit = 'はい',
  cancel = 'いいえ'
): Subject<boolean> {
  const subject = new Subject<boolean>();
  const dialog = createApp({
    extends: ModalPrompt,
    mixins: [ModalPrompt],
    setup () {
      const onCancel = () => {
        subject.next(false)
        subject.complete()
        dialog.unmount()
      }

      const onSubmit = () => {
        subject.next(true)
        subject.complete()
        dialog.unmount()
      }

      onMounted(() => document.body.classList.add('jr-overflow-hidden'))
      onUnmounted(() => document.body.classList.remove('jr-overflow-hidden'))

      return {
        onCancel,
        onSubmit,
        title,
        message,
        submit,
        cancel
      }
    }
  }).component('prompt-dialog', {})
  dialog.mount('#modal-root')
  return subject
}

export const WarningPromptDialog = function (
  message: string,
  title?: string,
  submit = 'はい',
  cancel = 'いいえ',
  handleSubmit?: () => void,
  handleCancel?: () => void
): void {
  const dialog = createApp({
    extends: ModalWarningPrompt,
    mixins: [ModalWarningPrompt],
    setup () {
      const state = reactive<{show: boolean}>({
        show: true
      })
      const onCancel = () => {
        state.show = false
        if (handleCancel) {
          handleCancel()
        }
      }
      
      const onSubmit = () => {
        state.show = false
        if (handleSubmit) {
          handleSubmit()
        }
      }
      
      // onMounted(() => document.body.classList.add('jr-overflow-hidden'))
      // onUnmounted(() => document.body.classList.remove('jr-overflow-hidden'))
      
      return {
        onCancel,
        onSubmit,
        ...toRefs(state),
        title,
        message,
        submit,
        cancel,
        ButtonType
      }
    }
  }).component('warning-prompt-dialog', {})
  dialog.mount('#modal-notify-root')
}

export const HintDialog = function (content: Component): void {
  const dialog = createApp({
    extends: ModalHint,
    mixins: [ModalHint],
    components: { content },
    setup () {
      const state = reactive<{show: boolean}>({
        show: true
      })
      const onClose = () => {
        dialog.unmount()
      }
      
      onMounted(() => document.body.classList.add('jr-overflow-hidden'))
      onUnmounted(() => document.body.classList.remove('jr-overflow-hidden'))
      
      return {
        ...toRefs(state),
        onClose,
        ButtonType
      }
    }
  }).component('modal-hint', {})
  dialog.mount('#modal-hint')
}
