
import { defineComponent, reactive, toRefs } from 'vue'
import CryptoJS from 'crypto-js'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import UIInputField from '@/components/UI/UIInputField.vue'
import UIButton from '@/components/UI/UIButton.vue'
import AuthRepository from '@/data/auth/repository'
import AuthAPIStore from '@/data/auth/store'
import { AlertDialog } from '@/service/dialog'
import { ActionType } from '@/store'
import { SESSION_NAMESPACE } from '@/constant'
import {IAreaConfig} from "@/data/area/type";
import AreaStubStore from "@/data/area/store/stub";
import AreaAPIStore from "@/data/area/store";
import AreaRepository from "@/data/area/repository";
import {AreaActionType} from "@/store/modules/area/actions";
import {setAccessToken} from "@/utils/storage";

interface State {
  tenant: string
  username: string
  password: string
  showPassword: boolean
}

export default defineComponent({
  components: {
    Form,
    UIInputField,
    UIButton
  },
  setup () {
    const api = new AuthAPIStore()
    const repository = new AuthRepository(api)
    const state = reactive<State>({
      tenant: '',
      username: '',
      password: '',
      showPassword: false
    })
    const store = useStore()
    const router = useRouter()
    const toggleShowPassword = () => state.showPassword = !state.showPassword
    const submit = () => false
    const areaStub = new AreaStubStore()
    const areaApi = new AreaAPIStore()
    const areaRepository = new AreaRepository(areaStub, areaApi)

    const manageConfig = (configs: IAreaConfig[]) => {
      const t = configs.find(conf => conf.key === 'polygon_max_zoom')
      if (t) {
        store.dispatch(`area/${AreaActionType.setPolygonMaxZoom}`, t.value)
      }

      // display menus
      const menus = configs.find(conf => conf.key === 'display_menus')
      if (menus) {
        store.dispatch(ActionType.setDisplayMenus, menus.value)
      }

      // default center
      const lat = configs.find(conf => conf.key === 'default_latitude')
      const lng = configs.find(conf => conf.key === 'default_longitude')
      if (lat && lng) {
        const latLng = {
          lat: lat.value,
          lng: lng.value
        }
        localStorage.setItem('latlng', JSON.stringify(latLng))
        store.dispatch(ActionType.setDefaultCenter, {
          lat: lat.value,
          lng: lng.value
        })
      }

      // default zoom level
      const zoom = configs.find(conf => conf.key === 'default_zoom_level')
      if (zoom) {
        store.dispatch(ActionType.setDefaultZoom, zoom.value)
      }

      // percentage threshold
      const percentageThreshold = configs.find(conf => conf.key === 'percentage_threshold')
      if (percentageThreshold) {
        store.dispatch(ActionType.setPercentageThreshold, percentageThreshold.value)
      }

      // heatmap max zoom level
      const heatmapMaxZoom = configs.find(conf => conf.key === 'heatmap_max_zoom')
      if (heatmapMaxZoom) {
        store.dispatch(ActionType.setHeatmapMaxZoom, heatmapMaxZoom.value)
      }
    }
    const loadConfig = () => {
      return areaRepository.fetchConfig({}).then(configs => {
        manageConfig(configs)
      }).catch(err => {
        if (err.status === 401) {
          return repository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => areaRepository.fetchConfig({}))
          .then(configs => manageConfig(configs))
        }
      })
    }

    store.dispatch(ActionType.setUsername, 'username')
    const handleSubmit = () => {
      store.dispatch(ActionType.setAppLoading, true)
      repository.login(state.tenant, state.username, state.password)
        .then(response => {
          response.username = state.username
          store.dispatch(ActionType.setUsername, state.username)

          const session = CryptoJS.AES.encrypt(JSON.stringify(response), process.env.VUE_APP_SECRET)
          localStorage.setItem(SESSION_NAMESPACE, session.toString())
        })
        .then(() => loadConfig())
        .then(() => router.push('/'))
        .catch(err => AlertDialog(err.message))
        .finally(() => store.dispatch(ActionType.setAppLoading, false))
    }

    return {
      ...toRefs(state),
      toggleShowPassword,
      handleSubmit,
      submit
    }
  }
})
