
import {computed, defineComponent, reactive, toRefs} from "vue";
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import {IRoadType} from "@/data/road/type";
import {useStore} from "vuex";
import {Config} from "@/config";

export default defineComponent({
  name: "ReportSidebar",
  components: {
    UIColorToggler,
  },
  emits: ['clear', 'eager', 'polygon'],
  setup(_,{ emit }) {
    const store = useStore()
    const state = reactive({
      selected: '',
      minimum: false,
      colors: Config.Instance.heatmapColors.hex,
    })
    const clickEager = () => {
      state.selected = 'eager'
      emit('eager')
    }
    const clickPolygon = () => {
      state.selected = 'polygon'
      emit('polygon')
    }
    const onClickClose = (event) => {
      event.preventDefault();
      state.minimum = !state.minimum;
    }

    const roadSeachOpt = computed(() => {
      const roadMaster = store.getters['roadMaster']
      const opt: {label: string; value: string }[] = [];
      roadMaster.forEach((road: IRoadType) => {
        opt.push({
          label: road.name,
          value: road.name
        })
      })
      return opt.length > 0 ? opt : [];
    })

    return {
      ...toRefs(state),
      roadSeachOpt,
      onClickClose,
      clickEager,
      clickPolygon,
    }
  }
})
