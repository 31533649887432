import {ActionTree} from 'vuex'
import {AreaFacilityPinGroup, AreaFacilityPinState} from '@/store/modules/area/search/facility/type'
import {RootState} from '@/store/types'
import {AreaFacilityPinMutationType} from '@/store/modules/area/search/facility/mutations'

export enum AreaFacilityPinActionType {
  setLoading = 'setLoading',
  setPins = 'setPins',
  setFacilityPinGroups = 'setFacilityPinGroups',
  selectAllFacilityPinGroup = 'selectAllFacilityPinGroup',
  unselectAllFacilityPinGroup = 'unselectAllFacilityPinGroup'
}

export const actions: ActionTree<AreaFacilityPinState, RootState> = {
  [AreaFacilityPinActionType.setPins] ({ commit }, payload: Record<string, any>): void {
    commit(AreaFacilityPinMutationType.SET_PINS, payload)
  },
  [AreaFacilityPinActionType.setFacilityPinGroups] ({ commit }, payload: AreaFacilityPinGroup[]): void {
    commit(AreaFacilityPinMutationType.SET_FACILITY_PIN_GROUPS, payload)
  },
  [AreaFacilityPinActionType.setLoading] ({ commit }, payload: boolean): void {
    commit(AreaFacilityPinMutationType.SET_LOADING, payload)
  },
  [AreaFacilityPinActionType.selectAllFacilityPinGroup] ({ commit }): void {
    commit(AreaFacilityPinMutationType.SELECT_ALL_FACILITY_PIN_GROUP)
  },
  [AreaFacilityPinActionType.unselectAllFacilityPinGroup] ({ commit }): void {
    commit(AreaFacilityPinMutationType.UNSELECT_ALL_FACILITY_PIN_GROUP)
  }
}
