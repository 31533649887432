import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'
import Home from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
import Report from '../views/Report.vue'
import GraphReport from '../views/GraphReport.vue'
import DayTimeSeries from '../views/DayTimeSeries.vue'
import HourTimeSeries from '../views/HourTimeSeries.vue'
import Road from '../views/Road.vue'
import Area from '@/views/Area.vue'
import Railway from '@/views/Railway.vue'
import store, { ActionType } from '../store'
import { SESSION_NAMESPACE } from '@/constant'
import AreaReport from '@/views/AreaReport.vue'
import Ranking from '@/views/area/Ranking.vue'
import Transportation from '@/views/area/Transportation.vue'
import Facility from '@/views/area/Facility.vue'
import Timing from '@/views/area/Timing.vue'
import Visitor from '@/views/area/Visitor.vue'
import SameDaySeries from '@/views/area/SameDaySeries.vue'
import Debug from '@/views/Debug.vue'
import RailwayReport from '@/views/RailwayReport.vue'
import RailwayPolygon from '@/views/RailwayPolygon.vue'
import Heatmap from '@/views/railway/Heatmap.vue'
import Comparison from '@/views/railway/Comparison.vue'
import Animation from '@/views/railway/Animation.vue'
import RailwayStation from '@/views/RailwayStation.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    meta: {
      auth_required: true
    },
    children: [
      {
        path: 'road',
        name: 'Road',
        component: Road,
        meta: {
          description: '道路',
          auth_required: true,
          isRoad: true,
          hasHint: true
        }
      },
      {
        path: 'area',
        name: 'Area',
        component: Area,
        meta: {
          description: 'エリア',
          auth_required: true,
          isArea: true,
          hasHint: false
        }
      },
      {
        path: 'railway',
        name: 'Railway',
        component: Railway,
        meta: {
          description: '鉄道・駅',
          auth_required: true,
          isRailway: true,
          hasHint: false
        }
      }
    ]
  },
  {
    path: '/railway_polygon',
    name: 'Railway Polygon',
    component: RailwayPolygon,
    meta: {
      description: '鉄道・駅',
      auth_required: true,
      isRailway: true,
      hasHint: false
    }
  },
  {
    path: '/railway_station',
    name: 'Railway Station',
    component: RailwayStation,
    meta: {
      description: '鉄道・駅',
      filename: '路線利用者の居住地・勤務地分布',
      auth_required: true,
      isRailway: true,
      hasHint: true
    }
  },
  {
    path: '/railway_report',
    name: 'Railway Report',
    component: RailwayReport,
    meta: {
      description: '鉄道・駅',
      filename: '',
      auth_required: true,
      isRailway: true,
      hasHint: true
    },
    children: [
      {
        path: 'heatmap',
        name: 'Heatmap',
        component: Heatmap,
        meta: {
          description: '鉄道・駅',
          filename: '駅利用者の居住地・勤務地分布ヒートマップ',
          auth_required: true,
          isRailway: true,
          hasHint: true
        }
      },
      {
        path: 'comparison',
        name: 'Comparison',
        component: Comparison,
        meta: {
          description: '鉄道・駅',
          filename: '駅周辺移動元・先エリア分布',
          auth_required: true,
          isRailway: true,
          hasHint: true
        }
      },
      {
        path: 'animation',
        name: 'Animation',
        component: Animation,
        meta: {
          description: '鉄道・駅',
          filename: '時間帯別・駅周辺人数_時間帯別・駅周辺人流マップ',
          auth_required: true,
          isRailway: true,
          hasHint: true
        }
      }
    ]
  },
  {
    path: '/area_report',
    name: 'Area Report',
    component: AreaReport,
    meta: {
      description: 'エリア',
      filename: 'ダッシュボード',
      auth_required: true,
      isArea: true,
      hasHint: true
    },
    children: [
      {
        path: 'same_day_action',
        name: 'Same day action',
        component: SameDaySeries,
        meta: {
          description: 'エリア',
          filename: '同日行動',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      },
      {
        path: 'ranking',
        name: 'Area ranking',
        component: Ranking,
        meta: {
          description: 'エリア',
          filename: 'エリア来訪者の居住地_勤務地ランキング',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      },
      {
        path: 'transportation',
        name: 'Area transportation',
        component: Transportation,
        meta: {
          description: 'エリア',
          filename: '交通手段比率',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      },
      {
        path: 'facilities',
        name: 'Area facilities',
        component: Facility,
        meta: {
          description: 'エリア',
          filename: 'エリア内の訪問施設分析',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      },
      {
        path: 'timing',
        name: 'Area timing',
        component: Timing,
        meta: {
          description: 'エリア',
          filename: 'エリア内の滞在時間',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      },
      {
        path: 'visitor',
        name: 'Area visitor',
        component: Visitor,
        meta: {
          description: 'エリア',
          filename: 'エリア来訪者の属性',
          auth_required: true,
          isArea: true,
          hasHint: true
        }
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      auth_required: true,
      isRoad: true
    },
    children: [
      {
        path: 'graph',
        name: 'Graph',
        component: GraphReport,
        meta: {
          description: '道路',
          auth_required: true,
          isRoad: true,
          hasHint: true
        }
      },
      {
        path: 'same_day_series',
        name: 'Same day series',
        component: DayTimeSeries,
        meta: {
          description: '道路',
          auth_required: true,
          isRoad: true,
          hasHint: true
        },
      },
      {
        path: 'one_hour_series',
        name: 'One hour series',
        component: HourTimeSeries,
        meta: {
          description: '道路',
          auth_required: true,
          isRoad: true,
          hasHint: true
        },
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth_required: false
    }
  },
  {
    path: '/password_reset/:user_id/:token',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      auth_required: false
    }
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      auth_required: false
    }
  }
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/debug',
    name: 'Debug',
    component: Debug,
    meta: {
      auth_required: false
    }
  })
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// route guard
router.beforeEach((to: RouteLocationNormalized, _, next: NavigationGuardNext) => {
  const session = localStorage.getItem(SESSION_NAMESPACE)
  const displayMenus = store.state.displayMenus
  const hasRoad = displayMenus.includes('road')
  const hasRailway = displayMenus.includes('railway')
  const hasArea = displayMenus.includes('area')
  
  if (!hasRoad && to.meta.isRoad) {
    next('/')
    return
  }
  if (!hasRailway && to.meta.isRailway) {
    next('/')
    return
  }
  if (!hasArea && to.meta.isArea) {
    next('/')
    return
  }
  
  if (to.path === '/debug') {
    next()
    return
  }
  
  if (to.path === '/password_reset' || to.path === '/password_reset/') {
    next('/login')
    return
  }
  
  if (to.meta.auth_required && !session) {
    next('/login')
    return
  }
  if (!to.meta.auth_required && session) {
    next('/')
    return
  }

  switch (to.path) {
    case '/':
      store.dispatch(ActionType.setQueries, [])
      next()
      break
    case '/report':
      next('/report/graph')
      break
    
    case '/railway_report':
      next('/railway_polygon')
      break
  
    case '/railway_report/':
      next('/railway_polygon')
      break

    default: next()
  }
})

export default router
