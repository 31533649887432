
import {defineComponent, reactive, toRefs} from 'vue'

interface State {
  minimum: boolean
}

export default defineComponent({
  name: 'RailwaySidebar',
  emits: ['minimum', 'line', 'user'],
  setup(_, {emit}) {
    const state = reactive<State>({
      minimum: false
    })

    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
      emit('minimum', state.minimum)
    }

    const handleLine = () => emit('line')
    const handleUser = () => emit('user')
    return {
      ...toRefs(state),
      clickClose,
      handleLine,
      handleUser
    }
  },
})
