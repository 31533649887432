import {MutationTree} from 'vuex'
import {PrefectureMasterState} from '@/store/modules/area/master/prefecture/type'
import {ICityType, IPrefectureType} from '@/data/master/area/prefecture/type'

export enum PrefectureMasterMutationType {
  SET_PREFECTURES = 'SetPrefectures',
  SET_COLLAPSED = 'SetCollapsed',
  CLEAR_SELECTED = 'ClearSelected',
  TOGGLE_SELECTED = 'ToggleSelected',
  SET_SELECTED = 'SetSelected',
  SELECT_ALL_CITIES = 'SelectAllCities',
  UNSELECT_ALL_CITIES = 'UnselectAllCities'
}

export const mutations: MutationTree<PrefectureMasterState> = {
  [PrefectureMasterMutationType.SET_PREFECTURES] (state: PrefectureMasterState, payload: IPrefectureType[]): void {
    state.prefectures = payload.map(pref => {
      pref.cities.forEach(city => city.pref_id = pref.id)
      return pref
    })
  },
  [PrefectureMasterMutationType.SET_COLLAPSED] (state: PrefectureMasterState, payload: { name: string; isCollapsed: boolean }): void {
    const index = state.prefectures.findIndex(pref => pref.name === payload.name)
    if (index >= 0) {
      state.prefectures[index].isCollapsed = payload.isCollapsed
    }
  },
  [PrefectureMasterMutationType.CLEAR_SELECTED] (state: PrefectureMasterState): void {
    state.prefectures.forEach(pref => {
      pref.cities.forEach(city => city.isSelected = false)
    })
  },
  [PrefectureMasterMutationType.TOGGLE_SELECTED] (state: PrefectureMasterState, payload: string): void {
    state.prefectures.forEach(pref => {
      const targetIndex = pref.cities.findIndex(city => city.name === payload)
      if (targetIndex >= 0) {
        pref.cities[targetIndex].isSelected = !pref.cities[targetIndex].isSelected
        return
      }
    })
  },
  [PrefectureMasterMutationType.SET_SELECTED] (state: PrefectureMasterState, payload: ICityType[]): void {
    const targetPrefs = payload.map(p => p.pref_id)
    for (let i = 0; i < state.prefectures.length - 1; i++) {
      if (targetPrefs.includes(state.prefectures[i].id)) {
        const targetCities = payload.map(p => p.id)
        for (let j = 0; j < state.prefectures[i].cities.length - 1; j++) {
          state.prefectures[i].cities[j].isSelected = targetCities.includes(state.prefectures[i].cities[j].id);
        }
      } else {
        for (let j = 0; j < state.prefectures[i].cities.length - 1; j++) {
          state.prefectures[i].cities[j].isSelected = false
        }
      }
    }
    
  },
  [PrefectureMasterMutationType.SELECT_ALL_CITIES] (state: PrefectureMasterState, payload: IPrefectureType): void {
    const prefIndex = state.prefectures.findIndex(pref => pref.name === payload.name)
    if (prefIndex >= 0) {
      state.prefectures[prefIndex].cities.forEach(city => {
        city.isSelected = true
      })
    }
  },
  [PrefectureMasterMutationType.UNSELECT_ALL_CITIES] (state: PrefectureMasterState, payload: IPrefectureType): void {
    const prefIndex = state.prefectures.findIndex(pref => pref.name === payload.name)
    if (prefIndex >= 0) {
      state.prefectures[prefIndex].cities.forEach(city => {
        city.isSelected = false
      })
    }
  }
}
