import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "jr-flex jr-text-xs jr-px-2.5 jr-py-1 jr-mr-1.5 jr-bg-gray-light jr-rounded"
}
const _hoisted_2 = { class: "jr-mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.data.title.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.title) + ":", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.data.content), 1)
      ]))
    : _createCommentVNode("", true)
}