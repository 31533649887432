import { IChartType } from '@/data/chart/type'
import { CheckboxFilterType, DayCode, WeekdayFlag } from '@/enum/barchart'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { ChartMutationType } from './mutations'
import { ChartState } from './type'

export enum ChartActionType {
  setChart = 'setChart',
  setFilterType = 'setFilterType',
  setActiveFilterType = 'setActiveFilterType',
  setDayConstraint = 'setDayConstraint',
  setWeekConstraint = 'setWeekConstraint',
  setActiveTime = 'setActiveTime',
  setDoInitFlg = 'setDoInitFlg',
  resetDay = 'resetDay',
  resetWeek = 'resetWeek',
  resetTime = 'resetTime',
  clearTime = 'clearTime',
  clearWeek = 'clearWeek',
  clearDay = 'clearDay',
}

export const actions: ActionTree<ChartState, RootState> = {
  [ChartActionType.setChart] ({ commit }, payload: IChartType): void {
    commit(ChartMutationType.SET_CHART, payload)
  },
  [ChartActionType.setFilterType] ({ commit }, payload: CheckboxFilterType): void {
    commit(ChartMutationType.SET_FILTER_TYPE, payload)
  },
  [ChartActionType.setActiveFilterType] ({ commit }, payload: CheckboxFilterType): void {
    commit(ChartMutationType.SET_ACTIVE_FILTER_TYPE, payload)
  },
  [ChartActionType.setDayConstraint] ({ commit }, payload: DayCode[]): void {
    commit(ChartMutationType.SET_DAY_CONSTRAINT, payload)
  },
  [ChartActionType.setWeekConstraint] ({ commit }, payload: WeekdayFlag[]): void {
    commit(ChartMutationType.SET_WEEK_CONSTRAINT, payload)
  },
  [ChartActionType.setActiveTime] ({ commit }, payload: number[]): void {
    commit(ChartMutationType.SET_ACTIVE_TIME, payload)
  },
  [ChartActionType.setDoInitFlg] ({ commit }, payload: boolean): void {
    commit(ChartMutationType.SET_DO_INIT_FLG, payload)
  },
  [ChartActionType.resetDay] ({ commit }): void {
    commit(ChartMutationType.RESET_DAY)
  },
  [ChartActionType.resetWeek] ({ commit }): void {
    commit(ChartMutationType.RESET_WEEK)
  },
  [ChartActionType.resetTime] ({ commit }): void {
    commit(ChartMutationType.RESET_TIME)
  },
  [ChartActionType.clearTime] ({ commit }): void {
    commit(ChartMutationType.CLEAR_TIME)
  },
  [ChartActionType.clearWeek] ({ commit }): void {
    commit(ChartMutationType.CLEAR_WEEK)
  },
  [ChartActionType.clearDay] ({ commit }): void {
    commit(ChartMutationType.CLEAR_DAY)
  }
}
