
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {setAreaSelectedPolygon, setSelectedAreaLabels} from '@/utils/storage'
import {WarningPromptDialog} from "@/service/dialog";
import {NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE} from "@/constant";

interface State {
  minimum: boolean;
}

export default defineComponent({
  name: 'AreaPolygonSelector',
  emits: ['clear', 'esc'],
  setup (_, { emit }) {
    // vuex
    const store = useStore()
    const router = useRouter()

    // internal state object
    const state = reactive<State>({
      minimum: false,
    })
    const hasPolygon = computed(() => {
      return store.getters['hasPolygon']
    })

    const clearAll = (event: Event) => {
      event.preventDefault()
      emit('clear')
    }

    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
    }

    const handleClear = () => emit('esc')
    const handleSearch = () => {
      WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
        const drawnPolygon = store.state.polygon
        const poly = {
          polygon: drawnPolygon.map(p => {
            return [p.longitude, p.latitude]
          })
        }
        setAreaSelectedPolygon([poly])
        setSelectedAreaLabels('')
        router.push('/area_report')
      })
    }

    return {
      ...toRefs(state),
      hasPolygon,
      clickClose,
      clearAll,
      handleSearch,
      handleClear
    }
  }
})
