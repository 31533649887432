
import { defineComponent, onBeforeUnmount, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'UISearchField',
  props: {
    id: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' }
  },
  setup(_, { emit }) {
    const state = reactive({
      keyword: ''
    })

    const onEscClick = (e: KeyboardEvent) => {
      if (e.key == 'Enter') emit('search', state.keyword) 
    }

    document.addEventListener('keydown', onEscClick)
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscClick))
    return { ...toRefs(state) }
  },
})
