
import { filter, tap } from 'rxjs/operators'
import {computed, defineComponent, reactive, toRefs} from 'vue'
import { useRouter } from 'vue-router'
import UISpeechBubble from '@/components/UI/UISpeechBubble.vue'
import { PromptDialog } from '@/service/dialog'
import { LOGOUT_QUESTION, SESSION_NAMESPACE } from '@/constant'
import store, { ActionType } from '@/store'


enum Nav {
  Road = 1,
  Train = 2,
  Area = 3
}

export default defineComponent({
  components: { UISpeechBubble },
  setup() {
    const router = useRouter()
    const state = reactive({
      activeNav: 0
    })
    const displayMenus = computed<string[]>(() => store.state.displayMenus)
    const handleNav = (nav: Nav) => {
      switch (nav) {
        case Nav.Road:
          router.push('/road')
          break

        case Nav.Area:
          router.push('/area')
          break

        case Nav.Train:
          router.push('/railway')
          break

        default: break
      }
    }
    const handleMouseHover = (nav: Nav) => state.activeNav = nav
    const handleMouseLeave = () => state.activeNav = -1

    const handleLogout = () => {
      PromptDialog(LOGOUT_QUESTION).pipe(
        filter((decision: boolean) => decision),
        tap(() => store.dispatch(ActionType.setAppLoading, true))
      ).subscribe(() => {
        localStorage.removeItem(SESSION_NAMESPACE)
        store.dispatch(ActionType.setAppLoading, false)
        router.push('/login')
      })
    }
    return {
      displayMenus,
      Nav,
      handleNav,
      handleLogout,
      handleMouseHover,
      handleMouseLeave,
      ...toRefs(state)
    }
  },
})
