import {RailwayState} from '@/store/modules/railway/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/railway/getters'
import {actions} from '@/store/modules/railway/actions'
import {mutations} from '@/store/modules/railway/mutations'
import {RAILWAY_RESIDENTS, RAILWAY_TRANSPORTATIONS, WEEK} from '@/components/subviews/railway/RailwaySearchFilter.vue'
import {RailwayStationTab} from "@/enum/station";

export const state: RailwayState = {
  sbMinimum: false,
  firstPolygon: false,
  secondPolygon: false,
  thirdPolygon: false,
  fourthPolygon: false,
  fifthPolygon: false,
  sixthPolygon: false,
  isCreate: false,
  isLoadingComparison: false,
  activePolygons: [],
  timeRange: [0, 24],
  transportations: RAILWAY_TRANSPORTATIONS,
  residents: RAILWAY_RESIDENTS,
  week: WEEK,
  stationTab: RailwayStationTab.Resident,
  railwayStation: {
    labels: [],
    data: {}
  },
  comparison: {
    comparison: {
      base: '',
      labels: [],
      main: [],
      peripheral: []
    },
    attribute: {
      labels: [],
      men: [],
      women: []
    }
  }
}

const namespaced = true
export const railway: Module<RailwayState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
