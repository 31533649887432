
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {globalEmitter, HINT_AREA_TIME_SPENT, DOWNLOAD_AREA_TIME_SPENT} from '@/service/GlobalEmmiter'
import {ConvertToImage} from '@/service/file'
import {
  AREA_WARNING_TITLE,
  AREA_WARNING_MESSAGE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  NO_CONDITION_SPECIFIED,
  AREA_NO_WEEK,
  AREA_NO_TRANSPORTATION,
  AREA_NO_FREQUENCY,
  AREA_NO_ATTRIBUTE,
  MAX_THRESHOLD_TITLE,
  MAX_THRESHOLD_MESSAGE
} from '@/constant'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {setAccessToken} from '@/utils/storage'
import {AreaTimeSpentActionType} from '@/store/modules/area/search/timeSpent/actions'
import {Emitter, EventType} from 'mitt'
import {useRoute} from 'vue-router'
import {ActionType} from '@/store'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import TimingDoughnutChart from '@/components/UI/area/TimingDoughnutChart.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AppLoader from '@/components/AppLoader.vue'
import {HintDialog} from '@/service/dialog'
import AreaTimeSpent from '@/components/hints/AreaTimeSpent.vue'

interface State {
  isLoading: boolean
  filterMinimum: boolean
}

export default defineComponent({
  name: 'Timing',
  components: {
    AppLoader,
    TimingDoughnutChart,
    AreaSearchFilter
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      isLoading: false,
      filterMinimum: false
    })
    const labels = computed(() => store.getters['areaTimeSpent/labels'])
    const data = computed(() => store.getters['areaTimeSpent/volumes'])
    const average = computed(() => store.state.areaTimeSpent.average)
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const isTiming = computed(() => route.path === '/area_report/timing')
    const isCapturing = computed(() => store.state.isCapturing)

    // data layer instances
    const stub = new AreaStubStore()
    const api = new AreaAPIStore()
    const repository = new AreaRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const makeAPICall = async (params: Record<string, any>) => {
      state.isLoading = true
      store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setTimes}`, [])
      store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setAverage}`, 0)
      repository.fetchTimeSpent(params).then(times => {
        store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setTimes}`, times.data)
        store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setAverage}`, times.average)
      }).catch(err => {
        if (isTiming.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetchTimeSpent(params))
            .then(times => {
              store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setTimes}`, times.data)
              store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setAverage}`, times.average)
            }).catch(err => {
              if (err.status === 404) {
                WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
              } else if (err.status === 416) {
                WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
              } else {
                AlertDialog(err.message)
              }
            })
          } else if (err.status === 404) {
            WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => {
        state.isLoading = false
        emitter?.emit('notifyAreaFilter')
      })
    }
    const params = store.getters['area/getAPIParams']
    delete params.except_target_area
    if (!store.state.area.hasAttrChange) {
      params.visitor_attribute = store.state.area.attributes.filter(a => a.isSelected && a.name !== '居住者').map(a => a.value)
    }
    if (params.week && params.week.length > 0) {
      params.week = params.week[0]
    }
    makeAPICall(params)

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const handleSearch = (params: Record<string, any>) => {
      // if (!store.state.area.hasAttrChange) {
      //   params.attributes = store.state.area.attributes.filter(a => a.isSelected && a.name !== '居住者').map(a => a.value)
      // }

      // user didn't specify any filter condition
      const trans = params.type && params.type.length === 0
      const freq = params.frequencies && params.frequencies.length === 0
      const attr = params.visitor_attribute && params.visitor_attribute.length === 0
      const week = params.week && params.week.length === 0
      delete params.except_target_area

      if (trans && attr && week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (trans) {
        AlertDialog(AREA_NO_TRANSPORTATION)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (attr) {
        AlertDialog(AREA_NO_ATTRIBUTE)
        return
      }

      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      makeAPICall(params)
    }

    globalEmitter.on(DOWNLOAD_AREA_TIME_SPENT, () => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_AREA_TIME_SPENT, () => {
      HintDialog(AreaTimeSpent)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_TIME_SPENT)
      globalEmitter.off(HINT_AREA_TIME_SPENT)
    })

    return {
      ...toRefs(state),
      sbMinimum,
      labels,
      data,
      average,
      isCapturing,
      handleSearch,
      handleFilterResize
    }
  }
})
