
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {ButtonType} from '@/enum/button'
import {useRouter} from 'vue-router'
import {ICityType} from '@/data/master/area/prefecture/type'
import {PrefectureMasterActionType} from '@/store/modules/area/master/prefecture/actions'
import {setAreaSelectedPolygon} from '@/utils/storage'
import AreaAccordion from '@/components/UI/area/AreaAccordion.vue'
import {WarningPromptDialog} from "@/service/dialog";
import {NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE} from "@/constant";

interface State {
  minimum: boolean;
}

export default defineComponent({
  name: 'AreaDistrictSelector',
  components: { AreaAccordion },
  component: [AreaAccordion],
  setup (_, { emit }) {
    const store = useStore()
    const router = useRouter()

    // internal state object
    const state = reactive<State>({
      minimum: false,
    })
    const prefectures = computed(() => store.state.prefectureMaster.prefectures)
    const isNoSelected = computed(() => {
      const selected = store.getters['prefectureMaster/selectedCities'] as ICityType[]
      return selected.length === 0
    })

    const clearAll = (event) => {
      event.preventDefault()
      store.dispatch(`prefectureMaster/${PrefectureMasterActionType.clearSelected}`)
      emit('clear')
    }

    const clickClose = (event) => {
      event.preventDefault()
      state.minimum = !state.minimum
    }

    const handleClear = () => store.dispatch(`prefectureMaster/${PrefectureMasterActionType.clearSelected}`)
    const handleSearch = () => {
      WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
        const cities = store.getters['prefectureMaster/selectedCities']
        const mapper = cities.map(city => city.id + '-' + city.name)
        const selectedPolygons = store.state.areaPolygonMaster.polygons.filter(p => {
          return mapper.includes(p.id + '-' + p.name)
        }).map(p => p.polygon.map(poly => {
          return {
            id: p.id,
            name: p.name,
            polygon: poly
          }
        })).flat()
        setAreaSelectedPolygon(selectedPolygons)
        router.push('/area_report')
      })
    }

    return {
      ...toRefs(state),
      ButtonType,
      prefectures,
      isNoSelected,
      clearAll,
      clickClose,
      handleClear,
      handleSearch
    }
  }
})
