
import {defineComponent, ref, onBeforeMount, reactive, toRefs, computed, onBeforeUnmount} from 'vue'
import ContentWrapper from '@/components/ContentWrapper.vue'
import FilterWrapper from "@/components/FilterWrapper.vue"
import UIBarChart from "@/components/UI/UIBarChart.vue"
import ChartRepository from '@/data/chart/repository'
import { ButtonType } from '@/enum/button'
import {useStore} from "vuex";
import UISelection from "@/components/UI/UISelection.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITimeSlider from "@/components/UI/UITimeSlider.vue";
import UICheckboxFilter from "@/components/UI/UICheckboxFilter.vue";
import {Config} from "@/config";
import {ConvertToImage} from "@/service/file";
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ROAD_GRAPH,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  HOME_CITY_LABEL, MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  NOT_HOME_CITY_LABEL,
  PROCESS_CANCELED_MESSAGE,
  PROCESS_CANCELED_TITLE,
} from "@/constant";
import { ChartActionType } from '@/store/modules/chart/actions'
import ChartAPIStore from '@/data/chart/store'
import ChartStubStore from '@/data/chart/store/stub'
import { BarChartColor, CheckboxFilterType } from '@/enum/barchart'
import {AlertDialog, WarningDialog} from "@/service/dialog";
import {getSelectedRoad, setAccessToken, setSelectedRoad} from "@/utils/storage";
import {isPolygonIntersect} from '@/utils/geometry';
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import AppLoader from "@/components/AppLoader.vue";
import {IRoadType} from "@/data/road/type";
import {DOWNLOAD_GRAPH_REPORT, HINT_ROAD_BAR_CHART, globalEmitter} from "@/service/GlobalEmmiter";
import {HintDialog} from "@/service/dialog";
import RoadBarChart from '@/components/hints/RoadBarChart.vue'
import {DayCode} from "@/enum/barchart";

import _ from 'lodash'
import {ActionType} from "@/store";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "GraphReport",
  components: {
    UIButton,
    UIBarChart,
    ContentWrapper,
    FilterWrapper,
    UISelection,
    UITimeSlider,
    UICheckboxFilter,
    AppLoader
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const availableDayFilter = Config.Instance.availableDayFilter;
    const availableWeekFilter = Config.Instance.availableWeekFilter;
    const timeRange = reactive({ min: 0, max: 24})
    const wrapperElementID = CONTENT_DOWNLOAD_ROAD_GRAPH
    const isCapturing = computed(() => store.state.isCapturing)

    const selectionOptions = [{'label': "曜日別", "value": 1}, {'label': "平日・土日祝日", "value": 2}];
    const state = reactive({
      startDate: Config.Instance.from,
      endDate: Config.Instance.to,
      title: store.getters['selectedRoad'].name,
      subtitle: '人数ボリュームの変化',
      selectionValue: 1,
      time: [0, 24],
      maxDataSetlength: 0,
    })

    const datasets = ref({});
    const stub = new ChartStubStore()
    const api = new ChartAPIStore()
    const repository = new ChartRepository(stub, api)
    const dataLabel = ref<boolean>(false)
    const maxValue = ref<boolean>(false)
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const isLoading = computed(() => {
      return store.getters['chart/getDoInitFlg'];
    })

    const loadTitles = computed(() => {
      const roads:IRoadType[] = store.getters['selectedRoad'];
      let title = '';
      if(roads.length > 0) {
        for(let i=0;i<roads.length;i++) {
          if (i < roads.length-1) {
            title += roads[i].name + ', ';
          } else {
            title += roads[i].name;
          }
        }
      }
      return title;
    })

    const handleClearFilter = () => {
      if (store.state.chart.selectedFilterType === CheckboxFilterType.DayDiffer) {
        store.dispatch(`chart/${ChartActionType.resetDay}`)
      }

      if (store.state.chart.selectedFilterType === CheckboxFilterType.WeekDiffer) {
        store.dispatch(`chart/${ChartActionType.resetWeek}`)
      }
    }

    const handleSearch = () => {
      const time = store.state.chart.time // 時間帯
      let selectedRoad: IRoadType[] = store.getters['selectedRoad']; // 道路IDs
      if (selectedRoad && selectedRoad.length === 0) {
        selectedRoad = getSelectedRoad();
      }
      let roadIds: string[] = [];
      selectedRoad.forEach((road) => {
        roadIds = roadIds.concat(road.id)
      })

      let request: any = {
        from: time[0],
        to: time[1],
        road_ids: roadIds,
        diff_polygon: false,
        week: store.state.chart.dayFilter
      }

      // decide between day or week filter
      const isWeekFilter = store.getters['chart/filterWeek']
      if (isWeekFilter) {
        // const flg = store.getters['chart/getWeekdayFlag']
        let theWeek: DayCode[] = []
        // request['weekday_flag'] = flg
        if (store.state.chart.weekFilter.includes(0)) {
          theWeek = theWeek.concat([
            DayCode.Saturday,
            DayCode.Sunday
          ])
        }
        if (store.state.chart.weekFilter.includes(1)) {
          theWeek = theWeek.concat([
            DayCode.Monday,
            DayCode.Tuesday,
            DayCode.Wednesday,
            DayCode.Thursday,
            DayCode.Friday
          ])
        }
        request['week'] = theWeek
      }

      store.dispatch(`chart/${ChartActionType.setDoInitFlg}`, true)
      //
      repository.fetch(request)
      .then((response) => {
        store.dispatch(`chart/${ChartActionType.setChart}`, response)
        setStateData()
      })
      .catch(err => {
        if(err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetch(request))
          .catch(err => {
            if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
          .then((response) => {
            store.dispatch(`chart/${ChartActionType.setChart}`, response)
            setStateData()
            return
          })
        } else if (err.status === 404) {
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 409) {
          WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
        } else if (err.status === 424) {
          WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => {
        store.dispatch(`chart/${ChartActionType.setDoInitFlg}`, false)
      })
    }

    const setStateData = () => {
      store.dispatch(`chart/${ChartActionType.setDayConstraint}`, store.state.chart.dayFilter)
      store.dispatch(`chart/${ChartActionType.setWeekConstraint}`, store.state.chart.weekFilter)
      store.dispatch(`chart/${ChartActionType.setActiveFilterType}`, store.state.chart.selectedFilterType)
      store.dispatch(`chart/${ChartActionType.setActiveTime}`, store.state.chart.time)
    }

    const setDatalabels = () => {
      dataLabel.value = !dataLabel.value
      if ('datasets' in datasets.value) {
        // for (let i = 0; i < (datasets.value as BarChartFilterType).datasets.length; i++) {
        //   Math.max(...(datasets.value as BarChartFilterType).datasets[i].data) >= 97 ? maxValue.value = true : maxValue.value = false;
        // }
      }
    }

    const normalChart = computed(() => {
      const dayFilter = {
        labels: store.getters['chart/getChartLegends'],
        datasets: [{
          label: HOME_CITY_LABEL,
          data: store.getters['chart/getCountHomeCity'],
          backgroundColor: BarChartColor.Primary,
          barPercentage: 0.9
        },
        {
          label: NOT_HOME_CITY_LABEL,
          data: store.getters['chart/getCountNotHomeCity'],
          backgroundColor: BarChartColor.Secondary,
          barPercentage: 0.9
        }]
      }

      const weekFilter = {
        labels: store.getters['chart/getWeekLegends'],
        datasets: [{
          label: HOME_CITY_LABEL,
          data: store.getters['chart/getCountHomeCityWeek'],
          backgroundColor: BarChartColor.Primary,
          barPercentage: 0.9
        },
        {
          label: NOT_HOME_CITY_LABEL,
          data: store.getters['chart/getCountNotHomeCityWeek'],
          backgroundColor: BarChartColor.Secondary,
          barPercentage: 0.9
        }]
      }

      if (store.getters['chart/filteringDay']) {
        return dayFilter
      }

      if (store.getters['chart/filteringWeek']) {
        return weekFilter
      }

      return {
        labels: '',
        data: [],
        backgroundColor: BarChartColor.Primary,
        barPercentage: 0.9
      }
    })

    const maxVolumne = computed(() => {
      if (store.getters['chart/filteringDay']) {
        let res = _.max(store.getters['chart/getVolumeHomeCity'].concat(store.getters['chart/getVolumnNotHomeCity']))
        res = isFinite(res) ? res : 0
        res !== undefined ? Math.ceil(res / 100) * 100 : 0
        return res <= 100 ? 200 : Math.ceil(res / 100) * 100
      }

      if (store.getters['chart/filteringWeek']) {
        let res = _.max(store.getters['chart/getVolumnHomeCityWeek'].concat(store.getters['chart/getVolumnNotHomeCityWeek']))
        res = isFinite(res) ? res : 0
        res !== undefined ? Math.ceil(res / 100) * 100 : 0
        return res <= 100 ? 200 : Math.ceil(res / 100) * 100
      }

      return 0
    })
    const volumnChart = computed(() => {
      const dayFilter = {
        labels: store.getters['chart/getChartLegends'],
        datasets: [{
          label: HOME_CITY_LABEL,
          data: store.getters['chart/getVolumeHomeCity'],
          backgroundColor: BarChartColor.Primary,
          barPercentage: 0.9
        },
        {
          label: NOT_HOME_CITY_LABEL,
          data: store.getters['chart/getVolumnNotHomeCity'],
          backgroundColor: BarChartColor.Secondary,
          barPercentage: 0.9
        }]
      }

      const weekFilter = {
        labels: store.getters['chart/getWeekLegends'],
        datasets: [{
          label: HOME_CITY_LABEL,
          data: store.getters['chart/getVolumnHomeCityWeek'],
          backgroundColor: BarChartColor.Primary,
          barPercentage: 0.9
        },
        {
          label: NOT_HOME_CITY_LABEL,
          data: store.getters['chart/getVolumnNotHomeCityWeek'],
          backgroundColor: BarChartColor.Secondary,
          barPercentage: 0.9
        }]
      }

      if (store.getters['chart/filteringDay']) {
        return dayFilter
      }

      if (store.getters['chart/filteringWeek']) {
        return weekFilter
      }

      return {
        labels: '',
        data: [],
        backgroundColor: BarChartColor.Primary,
        barPercentage: 0.9
      }
    })

    onBeforeMount( async () => {
      await prepareChart();
      // if (store.getters['chart/getDoInitFlg']) {
      //
      // }
    })

    const prepareChart = () => {
      store.dispatch(`chart/${ChartActionType.setDoInitFlg}`, true)
      const time = store.state.chart.time // 時間帯
      const reSelectpolygon = store.getters['getNewPolygon']
      let selectedRoad: IRoadType[] = store.getters['selectedRoad']; // 道路IDs
      if (selectedRoad && selectedRoad.length === 0) {
        selectedRoad = getSelectedRoad();
      }
      let roadIds: string[] = [];

      const diffPolygonFlg = reSelectpolygon.length > 0;
      let request: any = {
        from: time[0],
        to: time[1],
        road_ids: roadIds,
        diff_polygon: diffPolygonFlg,
        week: store.state.chart.dayFilter
      }

      // decide between day or week filter
      const isWeekFilter = store.getters['chart/filterWeek']
      if (isWeekFilter) {
        // const flg = store.getters['chart/getWeekdayFlag']
        let theWeek: DayCode[] = []
        // request['weekday_flag'] = flg
        if (store.state.chart.weekFilter.includes(0)) {
          theWeek = theWeek.concat([
            DayCode.Saturday,
            DayCode.Sunday
          ])
        }
        if (store.state.chart.weekFilter.includes(1)) {
          theWeek = theWeek.concat([
            DayCode.Monday,
            DayCode.Tuesday,
            DayCode.Wednesday,
            DayCode.Thursday,
            DayCode.Friday
          ])
        }
        request['week'] = theWeek
      }

      if (reSelectpolygon.length > 0) {
        request.polygon = reSelectpolygon;
        selectedRoad.forEach(road => {
          for (const name in road.polygon) {
            const isValid = isPolygonIntersect(reSelectpolygon, road.polygon[name]);
            if (!isValid) {
              delete road.polygon[name];
              road.id = road.id.filter(i => i !== name);
            }
          }
        });
        selectedRoad = selectedRoad.filter(road => Object.keys(road.polygon).length > 0);
      }
      setSelectedRoad(selectedRoad);
      selectedRoad.forEach((road) => {
        roadIds = roadIds.concat(road.id)
      })
      request.road_ids = roadIds;

      return repository.fetch(request)
      .then(response => store.dispatch(`chart/${ChartActionType.setChart}`, response))
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetch(request))
          .then(response => store.dispatch(`chart/${ChartActionType.setChart}`, response))
          .catch(err => {
            if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
        } else if (err.status === 404) {
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 409) {
          WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
        } else if (err.status === 424) {
          WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => {
        store.dispatch(`chart/${ChartActionType.setDoInitFlg}`, false)
      })
    }

    const resetWeek = () => store.dispatch(`chart/${ChartActionType.resetWeek}`)
    const resetDay = () => store.dispatch(`chart/${ChartActionType.resetDay}`)
    const resetTime = () => store.dispatch(`chart/${ChartActionType.resetTime}`)

    const dayLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getDayLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return store.getters['daySeries/getDayLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return store.getters['hourSeries/getDayLabel'];
      } else {
        return [];
      }
    })
    const weekLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getWeekLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return '';
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return '';
      } else {
        return '';
      }

    })
    const timeLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getTimeRangeLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return store.getters['daySeries/getActiveTimeRangeLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return store.getters['hourSeries/getActiveTimeRangeLabel'];
      } else {
        return [];
      }
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_GRAPH_REPORT)
      globalEmitter.off(HINT_ROAD_BAR_CHART)
    })

    globalEmitter.on(DOWNLOAD_GRAPH_REPORT, (e) => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = "人数ボリュームの変化.png"
          link.target = '_blank'
          link.click();
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    });

    globalEmitter.on(HINT_ROAD_BAR_CHART, () => {
      HintDialog(RoadBarChart)
    })

    return {
      ...toRefs(state),
      ...toRefs(timeRange),
      isLoading,
      loadTitles,
      ButtonType,
      dataLabel,
      maxValue,
      datasets,
      availableDayFilter,
      availableWeekFilter,
      selectionOptions,
      normalChart,
      volumnChart,
      maxVolumne,
      isCapturing,
      CheckboxFilterType,
      setDatalabels,
      resetWeek,
      resetDay,
      resetTime,
      handleSearch,
      handleClearFilter,
      dayLabel,
      weekLabel,
      timeLabel
    }
  },
})
