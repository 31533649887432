import { ILoginResponseType, IRefreshTokenType } from '../@types';
import { IAuthAPIStoreType } from '../store'
import {
  LoginRequest,
  LogoutRequest, PasswordResetRequest,
  PasswordResetTokenCheckRequest,
  RefreshTokenRequest,
  SendPasswordResetRequest
} from '../store/request';

export interface IAuthRepositoryType {
  api: IAuthAPIStoreType
  login(tenant: string, login_id: string, password: string): Promise<ILoginResponseType>
  logout(): Promise<boolean>
  refreshToken(): Promise<IRefreshTokenType>
  requestPasswordReset(email: string): Promise<boolean>
  passwordResetTokenCheck(user_id: string, token: string): Promise<boolean>
  passwordReset(user_id: string, token: string, password: string, confirm_password: string): Promise<boolean>
}

export default class AuthRepository implements IAuthRepositoryType {
  api: IAuthAPIStoreType

  constructor(api: IAuthAPIStoreType) {
    this.api = api
  }

  login(tenant: string, login_id: string, password: string): Promise<ILoginResponseType> {
    const request = new LoginRequest(tenant, login_id, password)
    return this.api.login(request)
  }

  refreshToken(): Promise<IRefreshTokenType> {
    const request = new RefreshTokenRequest()
    return this.api.refreshToken(request)
  }

  logout(): Promise<boolean> {
    const request = new LogoutRequest()
    return this.api.logout(request)
  }
  
  requestPasswordReset(email: string): Promise<boolean> {
    const request = new SendPasswordResetRequest(email)
    return this.api.requestPasswordReset(request)
  }
  
  passwordResetTokenCheck(user_id: string, token: string): Promise<boolean> {
    const request = new PasswordResetTokenCheckRequest(user_id, token)
    return this.api.requestPasswordReset(request)
  }
  
  passwordReset(user_id: string, token: string, password: string, confirm: string): Promise<boolean> {
    const request = new PasswordResetRequest(user_id, token, password, confirm)
    return this.api.passwordReset(request)
  }
}
