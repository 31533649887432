<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <div class="jr-font-base jr-text-sm">
        <p>リピーター・ビジターの定義は以下の通りです。</p>
        <p>リピーター：エリアに来訪した週数が2週以上</p>
        <p>ビジター：それ以外</p>
        <p class="jr-pt-4">
          <span class="jr-pr-2.5">※</span>
          <span class="wordbreak-keepall"> 1月第1週と1月第2週の計2回来訪 （来訪した週数は2）の場合は「リピーター」、1月第1週に2泊3日で来訪（来訪した週数は1）の場合は「ビジター」となります。</span>
        </p>
      </div>
    </div>
  </div>
</template>
