import mitt from 'mitt'
import {NEW_POLYGON_OPTIONS, POLYLGON_OPTIONS} from '@/constant'
import {ICoordinateType} from '@/types'
import store, {ActionType} from '@/store'

export interface IPolygonDrawingServiceType {
  assignMap(map: google.maps.Map): void
  init(): void
  start(): void
  startNew(): void
  cancel(): void
  dispose(): void
  hideInfo(): void
  clear(): void
  clearNewPolygon(): void
  lock(): void
  unlock(): void
  invalidate(): void
  onEscClick(e: KeyboardEvent): void
}

interface GoogleMapWindow extends Window {
  roadMap: any
  polygonDrawer: any
  newPolygonDrawer: any
  google: any
  polygon: any
  polygonAction: any
  newPolygon: any
  isPolygonDragging: boolean
  isNewPolygonDragging: boolean
  isDrawingNewPolygon: boolean
  isLockDraw: boolean
}
declare const window: GoogleMapWindow

export default class PolygonManager implements IPolygonDrawingServiceType {
  private infoWindow?: google.maps.InfoWindow
  private marker?: google.maps.Marker
  public emitter = mitt()

  // events
  private completeEvent?: google.maps.MapsEventListener
  private insertAtEvent?: google.maps.MapsEventListener
  private setAtEvent?: google.maps.MapsEventListener

  constructor () {
    window.polygonDrawer = new window.google.maps.drawing.DrawingManager({
      drawingControl: false,
      polygonOptions: POLYLGON_OPTIONS
    })
    window.newPolygonDrawer = new window.google.maps.drawing.DrawingManager({
      drawingControl: false,
      polygonOptions: NEW_POLYGON_OPTIONS
    })
    window.isDrawingNewPolygon = false
    window.isLockDraw = false
    this.init()
  }

  assignMap(map: google.maps.Map): void {
    window.roadMap = map
    window.polygonDrawer.setMap(window.roadMap)

    window.polygonAction = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(window.roadMap?.getCenter()),
      visible: true,
      zIndex: 999,
      icon: require('@/assets/icon-button-polygon.svg')
    })
  }

  init (): void {
    document.addEventListener('keydown', this.onEscClick.bind(this))
    this.completeEvent = window.google.maps.event.addListener(window.polygonDrawer, 'polygoncomplete', (polygon) => {

      window.polygonDrawer.setDrawingMode(null)
      window.newPolygonDrawer.setDrawingMode(null)

      this.hideInfo()
      if (window.isDrawingNewPolygon) {
        window.newPolygon = polygon
        this.emitter.emit('newPolygon', this.normalizeNewPolygon())
      } else {
        window.polygon = polygon
        this.emitter.emit('polygon', this.normalizePolygon())
      }

      //window.polygonAction.addListener('click', () => this.emitter.emit('search'))
      window.google.maps.event.addListener(polygon, 'dragstart', () => window.isPolygonDragging = true)
      window.google.maps.event.addListener(polygon, 'dragend', () => {
        window.isPolygonDragging = false
        if (window.isDrawingNewPolygon) {
          this.emitter.emit('newPolygon', this.normalizeNewPolygon())
        } else {
          this.emitter.emit('polygon', this.normalizePolygon())
        }
      })

      window.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        if (window.isDrawingNewPolygon) {
          this.emitter.emit('newPolygon', this.normalizeNewPolygon())
        } else {
          this.emitter.emit('polygon', this.normalizePolygon())
        }
      })
      window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        if (!window.isPolygonDragging) {
          if (window.isDrawingNewPolygon) {
            this.emitter.emit('newPolygon', this.normalizeNewPolygon())
          } else {
            this.emitter.emit('polygon', this.normalizePolygon())
          }
        }
      })

      window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        if (window.isDrawingNewPolygon) {
          this.emitter.emit('newPolygon', this.normalizeNewPolygon())
        } else {
          this.emitter.emit('polygon', this.normalizePolygon())
        }
      })

      /*if (window.roadMap && window.polygonAction && !window.isDrawingNewPolygon) {
        window.polygonAction.setMap(window.roadMap)
      }*/

      window.isDrawingNewPolygon = false
    })
  }

  start (): void {
    if (!window.isLockDraw) {
      this.clear()
      window.polygonDrawer.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
    }
  }

  startNew (): void {
    window.isDrawingNewPolygon = true
    window.polygonDrawer.setOptions({
      polygonOptions: {
        drawingControl: true
      }
    })
    window.polygonDrawer.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
  }

  cancel (): void {
    window.polygonAction.setMap(null)
    window.polygonDrawer.setDrawingMode(null)
    window.newPolygonDrawer.setDrawingMode(null)
    if (window.polygon) {
      window.polygon.setPath([])
      store.dispatch(ActionType.setPolygon, [])
    }

    if (window.newPolygon) {
      window.newPolygon.setPath([])
      store.dispatch(ActionType.setNewPolygon, [])
    }
    this.unlock()
    this.hideInfo()
  }

  clear (): void {
    window.polygonAction.setMap(null)
    if (window.polygon) {
      window.polygon.setPath([])
      store.dispatch(ActionType.setPolygon, [])
    }

    if (window.newPolygon) {
      window.newPolygon.setPath([])
      store.dispatch(ActionType.setNewPolygon, [])
    }
  }

  clearNewPolygon (): void {
    if (window.newPolygon) {
      window.newPolygon.setPath([])
      store.dispatch(ActionType.setNewPolygon, [])
    }
  }

  onEscClick (e: KeyboardEvent): void {
    if (e.key == 'Escape') {
      this.cancel()
      this.start()
    }
  }

  dispose (): void {
    this.completeEvent?.remove()
    this.setAtEvent?.remove()
    this.insertAtEvent?.remove()
    document.removeEventListener('keydown', this.onEscClick.bind(this))
  }

  lock (): void {
    if (window.polygon) {
      window.isLockDraw = true
      window.polygon.setEditable(false)
      window.polygon.setDraggable(false)
    }
  }

  unlock(): void {
    if (window.polygon) {
      window.isLockDraw = false
      window.polygon.setEditable(true)
      window.polygon.setDraggable(true)
    }
  }

  normalizePolygon (): ICoordinateType[] {
    if (window.polygon) {
      const paths = window.polygon.getPath()
      if (paths.length > 0) {
        const start: ICoordinateType = {
          latitude: paths.getAt(0).lat(),
          longitude: paths.getAt(0).lng()
        }

        const coordinates: ICoordinateType[] = []
        for (let i = 0; i < paths.length; i++) {
          coordinates.push({
            latitude: paths.getAt(i).lat(),
            longitude: paths.getAt(i).lng()
          })
        }
        return coordinates.concat(start)
      }
      return []
    }
    return []
  }

  normalizeNewPolygon (): ICoordinateType[] {
    if (window.newPolygon) {
      const paths = window.newPolygon.getPath()
      if (paths.length > 0) {
        const start: ICoordinateType = {
          latitude: paths.getAt(0).lat(),
          longitude: paths.getAt(0).lng()
        }

        const coordinates: ICoordinateType[] = []
        for (let i = 0; i < paths.length; i++) {
          coordinates.push({
            latitude: paths.getAt(i).lat(),
            longitude: paths.getAt(i).lng()
          })
        }
        return coordinates.concat(start)
      }
      return []
    }
    return []
  }

  hideInfo (): void {
    this.infoWindow?.close()
    this.marker?.setMap(null)
    window.polygonAction.setMap(null)
  }

  invalidate (): void {
    window.polygonAction.setMap(null)
    window.polygonDrawer.setDrawingMode(null)
    window.newPolygonDrawer.setDrawingMode(null)
  }
}
