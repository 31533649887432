<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">エリア来訪者の居住地／勤務地ランキング</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>選択したエリア来訪者の居住地/勤務地の構成比をランキングで表示しています。</p>
        <div class="jr-pt-4">
          <p>集計は「ユニーク人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合も1とカウント。</span>
          </p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>選択したエリア内の居住者/勤務者も集計に含まれます。</span>
          </p>
        </div>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">交通手段比率</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>エリア来訪時の交通手段比率：選択したエリアに来訪する直前の移動を抽出し交通手段ごとの構成比を表示しています。</p>
        <p>エリア内の交通手段比率：選択したエリア内の移動を抽出し交通手段ごとの構成比を表示しています。</p>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">エリア内の訪問施設分析</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>選択したエリア来訪者数を100%としたときの施設カテゴリごとの来訪者数の構成比をランキングで表示しています。</p>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">エリア内の滞在時間</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>来訪日ごとの滞在時間を集計しています。</p>
        <p>選択したエリア居住者は初期表示では除外されています。</p>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">エリア来訪者の属性</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>性別・年代別比：選択したエリア来訪者の性別・年代別比を表示しています。</p>
        <p>ペルソナ：選択したエリア来訪者のペルソナの構成比をランキング（5位以内）で表示しています。</p>
      </div>

      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>「居住地／勤務地ランキング」以外、集計は「日のべ人数」です。</p>
        <p>
          <span class="jr-pr-2.5">※</span>
          <span>同一人物が2日間来訪した場合は2とカウント。</span>
        </p>
        <p>
          <span class="jr-pr-2.5">※</span>
          <span>「交通手段比率」「訪問施設分析」については、同一人物がエリア内で複数交通手段を利用している場合はそれぞれの交通手段にカウント。</span>
        </p>
      </div>
    </div>
  </div>
</template>
