
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {RailwayActionType} from '@/store/modules/railway/actions'
import {useRoute, useRouter} from 'vue-router'

interface State {
  isHoverReselect: boolean
}

export default defineComponent({
  name: 'RailwayReportSidebar',
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const state = reactive<State>({
      isHoverReselect: false
    })
    const isCapturing = computed(() => store.state.isCapturing)

    // pages
    const isHeatmap = computed(() => route.path === '/railway_report/heatmap')
    const isComparison = computed(() => route.path === '/railway_report/comparison')
    const isAnimation = computed(() => route.path === '/railway_report/animation')

    // loading
    const isLoadingComparison = computed(() => store.state.railway.isLoadingComparison)

    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const onClickClose = () => store.dispatch(`railway/${RailwayActionType.toggleSidebar}`)
    const hoverReselect = (value: boolean) => state.isHoverReselect = value
    const handleNavReselect = () => router.push('/railway_polygon')
    return {
      ...toRefs(state),
      sbMinimum,
      isHeatmap,
      isComparison,
      isAnimation,
      isLoadingComparison,
      isCapturing,
      onClickClose,
      hoverReselect,
      handleNavReselect
    }
  }
})
