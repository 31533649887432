import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RailwaySidebar = _resolveComponent("RailwaySidebar")!
  const _component_StationLineSelector = _resolveComponent("StationLineSelector")!
  const _component_StationUserSelector = _resolveComponent("StationUserSelector")!
  const _component_VisualizePanel = _resolveComponent("VisualizePanel")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_RailwaySidebar, {
      onMinimum: _ctx.onMinimum,
      onUser: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNav(_ctx.Mode.User))),
      onLine: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleNav(_ctx.Mode.Line)))
    }, null, 8, ["onMinimum"]), [
      [_vShow, _ctx.mode === _ctx.Mode.None]
    ]),
    _withDirectives(_createVNode(_component_StationLineSelector, {
      onMinimum: _ctx.onMinimum,
      onClear: _ctx.onClear
    }, null, 8, ["onMinimum", "onClear"]), [
      [_vShow, _ctx.mode === _ctx.Mode.Line]
    ]),
    _withDirectives(_createVNode(_component_StationUserSelector, {
      onMinimum: _ctx.onMinimum,
      onClear: _ctx.onClear
    }, null, 8, ["onMinimum", "onClear"]), [
      [_vShow, _ctx.mode === _ctx.Mode.User]
    ]),
    _createVNode(_component_VisualizePanel, {
      style: _normalizeStyle({ width: _ctx.minimum ? 'calc(100% - 64px)' : 'calc(100% - 240px)' }),
      "is-show-badge": _ctx.mode === _ctx.Mode.None || _ctx.mode === _ctx.Mode.Line
    }, null, 8, ["style", "is-show-badge"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}