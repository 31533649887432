
import {Emitter, EventType} from 'mitt'
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {ActionType} from '@/store'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {iconFactory} from '@/layers/icon'
import {globalEmitter, HINT_AREA_FACILITY, DOWNLOAD_AREA_FACILITY} from '@/service/GlobalEmmiter'
import {ConvertToImage} from '@/service/file'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {
  CONTENT_DOWNLOAD_ELEMENT_ID,
  NO_CONDITION_SPECIFIED,
  AREA_WARNING_TITLE,
  AREA_WARNING_MESSAGE,
  AREA_NO_WEEK,
  AREA_NO_TRANSPORTATION,
  AREA_NO_FREQUENCY,
  AREA_NO_ATTRIBUTE,
  MAX_THRESHOLD_TITLE,
  MAX_THRESHOLD_MESSAGE
} from '@/constant'
import {AreaFacilityPinActionType} from '@/store/modules/area/search/facility/actions'
import {setAccessToken} from '@/utils/storage'
import {IAreaFacilityMasterType} from '@/data/master/area/facility/type'
import {FacilityMasterActionType} from '@/store/modules/area/master/facility/actions'
import {useRoute} from 'vue-router'
import {polygonFactory} from '@/layers/polygon'
import {getAreaSelectedPolygon} from '@/utils/storage'
import FacilityIndicator from '@/components/UI/area/FacilityIndicator.vue'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import AreaRepository from '@/data/area/repository'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AreaFacilityMasterAPIStore from '@/data/master/area/facility/store'
import AreaFacilityMasterRepository from '@/data/master/area/facility/repository'
import AppLoader from '@/components/AppLoader.vue'
import UILayerToggle from "@/components/UI/UILayerToggle.vue";
import {HintDialog} from '@/service/dialog'
import AreaFacility from '@/components/hints/AreaFacility.vue'

interface State {
  map: any
  filterMinimum: boolean
  isAllFacilitySelect: boolean
  captureClip: boolean
  pinModel: Record<string, boolean>
  currentPins: number[][]
}

export enum FacilityPinType {
  Default = 0,
  Hotel = 1,
  ConvenienceStore = 2,
  Medical = 3,
  Cultural = 4,
  Commercial = 5,
  Restaurant = 6,
  DepartmentStore = 7,
  SuperMarket = 8
}

export default defineComponent({
  name: 'Facility',
  components: {UILayerToggle, AppLoader, FacilityIndicator, UICheckbox, AreaSearchFilter, UIGoogleMap},
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const state = reactive<State>({
      map: null,
      isAllFacilitySelect: false,
      filterMinimum: false,
      captureClip: false,
      pinModel: {},
      currentPins: []
    })
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const areaStub = new AreaStubStore()
    const areaApi = new AreaAPIStore()
    const areaRepository = new AreaRepository(areaStub, areaApi)
    const areaFacilityMasterApi = new AreaFacilityMasterAPIStore()
    const areaFacilityMasterRepository = new AreaFacilityMasterRepository(areaFacilityMasterApi)
    const pins = computed(() => store.state.areaFacilityPin.pins)
    const pinGroups = computed(() => store.state.areaFacilityPin.pinGroups)
    const isLoading = computed(() => store.state.areaFacilityPin.isLoading)
    const isFacilities = computed(() => route.path === '/area_report/facilities')
    const pinMaster = computed(() => store.getters['areaFacilityMaster/transform'])

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    })
    const polygonOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })

    const loadPinModel = (dPins: Record<string, any>) => {
      state.pinModel = {}
      Object.keys(dPins).forEach(p => state.pinModel[p] = true)
      state.isAllFacilitySelect = true

      let pinPoints = []
      for (let pin in state.pinModel) {
        let target = dPins[pin][1]
        let threshold = 1
        if (target.length > 4) {
          threshold = Math.floor(target.length / 4)
        }
        target = target.map((t, i) => {
          const type = Math.floor(i / threshold) + 1
          return t.concat([pin, type <= 4 ? type : 4])
        })
        pinPoints = pinPoints.concat(target)
      }
      let sortedPinPoints = pinPoints.sort(function(a, b) { return b[3] - a[3]; });
      state.currentPins = sortedPinPoints
      deckOverlay.setProps({ layers: [iconFactory(sortedPinPoints, store.getters['areaFacilityMaster/transform'])] })
    }

    const handleAreaToggle = (value) => {
      if (value) {
        const polygons = getAreaSelectedPolygon()
        polygonOverlay.setProps({
          layers: [polygonFactory(polygons)]
        })
      } else {
        polygonOverlay.setProps({ layers: [] })
      }
    }

    const fetchFacilityPins = async (params: Record<string, any>) => {
      store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setPins}`, [])
      deckOverlay.setProps({ layers: [iconFactory([], store.getters['areaFacilityMaster/transform'])] })
      state.isAllFacilitySelect = false

      const facilities = store.state.areaFacilityMaster.facilities
      let streams: Promise<IAreaFacilityMasterType[] | Record<string, any>>[] = [
          Promise.resolve(facilities),
          areaRepository.fetchFacilityPin(params)
      ]
      if (facilities.length === 0) {
        streams[0] = areaFacilityMasterRepository.fetch({})
      }
      store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setLoading}`, true)
      Promise.all(streams).then(([facilities, pins]) => {
        store.dispatch(`areaFacilityMaster/${FacilityMasterActionType.setFacilityMaster}`, facilities)
        store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setPins}`, pins)
        loadPinModel(pins)
      }).catch(err => {
        if (isFacilities.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              streams = [Promise.resolve(facilities), areaRepository.fetchFacilityPin(params)]
              if (facilities.length === 0) {
                streams[0] = areaFacilityMasterRepository.fetch({})
              }
              return Promise.all(streams).then(([facilities, pins]) => {
                store.dispatch(`areaFacilityMaster/${FacilityMasterActionType.setFacilityMaster}`, facilities)
                store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setPins}`, pins)
                loadPinModel(pins)
              }).catch(err => {
                if (err.status === 404) {
                  WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
                } else if (err.status === 416) {
                  WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
                } else {
                  AlertDialog(err.message)
                }
                store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setLoading}`, false)
              })
            })
          } else if (err.status === 404) { // amount of search result is less, and the API return this error
            WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else {
            AlertDialog(err.message)
            store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setLoading}`, false)
          }
        } else {
          store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setLoading}`, false)
        }
      })
      .finally(() => {
        store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setLoading}`, false)
        emitter?.emit('notifyAreaFilter')
       })
    }
    const params = store.getters['area/getAPIParams']
    delete params.except_target_area
    if (params.week && params.week.length > 0) {
      params.week = params.week[0]
    }
    fetchFacilityPins(params)

    const facilities = computed(() => store.state.area.facilities)
    const onAllFacilityChange = (value: boolean) => {
      let pinPoints = []
      for (let pin in state.pinModel) {
        state.pinModel[pin] = value
        if (value) {
          let target = pins.value[pin][1]
          let threshold = 1
          if (target.length > 4) {
            threshold = Math.floor(target.length / 4)
          }
          target = target.map((t, i) => {
            const type = Math.floor(i / threshold) + 1
            return t.concat([pin, type <= 4 ? type : 4])
          })
          pinPoints = pinPoints.concat(target)
        }
      }
      let sortedPinPoints = pinPoints.sort(function(a, b) { return b[3] - a[3]; });
      state.currentPins = sortedPinPoints
      deckOverlay.setProps({ layers: [iconFactory(sortedPinPoints, store.getters['areaFacilityMaster/transform'])] })
    }
    const onFacilityChange = () => {
      let pinPoints = []
      for (let pin in state.pinModel) {
        if (state.pinModel[pin]) {
          let target = pins.value[pin][1]
          let threshold = 1
          if (target.length > 4) {
            threshold = Math.floor(target.length / 4)
          }
          target = target.map((t, i) => {
            const type = Math.floor(i / threshold) + 1
            return t.concat([pin, type <= 4 ? type : 4])
          })
          pinPoints = pinPoints.concat(target)
        }
      }
      let sortedPinPoints = pinPoints.sort(function(a, b) { return b[3] - a[3]; });
      state.currentPins = sortedPinPoints
      deckOverlay.setProps({ layers: [iconFactory(sortedPinPoints, store.getters['areaFacilityMaster/transform'])] })
    }
    onFacilityChange()
    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(state.map)
      polygonOverlay.setMap(state.map)
    }

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const handleSearch = (params: Record<string, any>) => {
      // user didn't specify any filter condition
      const trans = params.type && params.type.length === 0
      const freq = params.frequencies && params.frequencies.length === 0
      const attr = params.visitor_attribute && params.visitor_attribute.length === 0
      const week = params.week && params.week.length === 0
      delete params.except_target_area

      if (trans && attr && week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (trans) {
        AlertDialog(AREA_NO_TRANSPORTATION)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (attr) {
        AlertDialog(AREA_NO_ATTRIBUTE)
        return
      }
      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      fetchFacilityPins(params)
    }

    globalEmitter.on(DOWNLOAD_AREA_FACILITY, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_AREA_FACILITY, () => {
      HintDialog(AreaFacility)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_FACILITY)
      globalEmitter.off(HINT_AREA_FACILITY)
    })

    return {
      ...toRefs(state),
      sbMinimum,
      facilities,
      isLoading,
      pinGroups,
      pins,
      pinMaster,
      handleAreaToggle,
      handleMapLoad,
      handleSearch,
      handleFilterResize,
      onAllFacilityChange,
      onFacilityChange
    }
  }
})
