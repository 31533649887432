
import { defineComponent } from 'vue'
import { ButtonType } from '@/enum/button'
import UIButton from '@/components/UI/UIButton.vue'

export default defineComponent({
  name: 'TypeSearchController',
  components: { UIButton },
  setup() {
    return { ButtonType }
  },
})
