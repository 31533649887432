
import {computed, defineComponent, PropType} from 'vue'
import {BarChart} from 'vue-chart-3'
import {FEMALE_LABEL, MALE_LABEL} from '@/constant'
import {BarChartColor} from '@/enum/barchart'
import {useBarChart} from 'vue-chart-3'
import {IRailwayComparisonAttrType} from '@/data/railway/type'

export default defineComponent({
  name: 'AttributeBarchart',
  components: {BarChart},
  props: {
    data: {
      type: Object as PropType<IRailwayComparisonAttrType>,
      required: false,
      default: () => {
        return {
          labels: [],
          men: [],
          women: []
        }
      }
    }
  },
  setup (props) {
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: { display: false,  drawBorder: false },
          ticks: {
            color: 'black',
            font: { size: 13 }
          }
        },
        y: {
          grid: {
            drawBorder: false,
            borderColor: '#231815',
            lineWidth: 1.5,
            z: 1
          },
          // max: 20, // TODO: replace with computed max value
          ticks: {
            color: 'black',
            font: { size: 12 },
            stepSize: 5,
            padding: 14,
            // callback: function(val) {
            //   return val % 10 === 0 ? val : ''
            // },
          }
        }
      },
      events: [],
      plugins: {
        datalabels: {
          display: false,
          anchor: 'end',
          align: 'end',
          color: '#000',
          padding: -10,
          // formatter: function (value) {
          //   return `${Math.round(value)}%`
          // }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            color: 'black',
            padding: 20,
            boxWidth: 14,
            boxHeight: 14
          }
        }
      }
    }))
    const chartData = computed(() => ({
      labels: props.data ? props.data.labels : [],
      datasets: [
        {
          label: MALE_LABEL,
          data: props.data ? props.data.men : [],
          backgroundColor: BarChartColor.Male,
          barPercentage: 0.75
        },
        {
          label: FEMALE_LABEL,
          data: props.data ? props.data.women : [],
          backgroundColor: BarChartColor.Female,
          barPercentage: 0.75
        }
      ]
    }))
    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps }
  }
})
