import {Module} from 'vuex'
import {RailLineState} from '@/store/modules/railway/master/line/type'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/railway/master/line/getters'
import {actions} from '@/store/modules/railway/master/line/actions'
import {mutations} from '@/store/modules/railway/master/line/mutations'

export const state: RailLineState = {
  lines: [],
  selectedLine: '',
  selectedStation: '',
  chosenLine: '',
  chosenStation: ''
}

const namespaced = true
export const railLine: Module<RailLineState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
