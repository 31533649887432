import {
  AREA_SELECTED_CITY_NAMESPACE,
  AREA_SELECTED_POLYGON_NAMESPACE,
  RAILWAY_POLYGON_NAMESPACE,
  RAILWAY_SELECTED_LINE_NAMESPACE,
  RAILWAY_SELECTED_STATION_NAMESPACE,
  SELECTED_ROAD_NAMESPACE,
  SESSION_NAMESPACE
} from '@/constant'
import {ILoginResponseType} from '@/data/auth/@types'
import CryptoJS from 'crypto-js'
import {IRoadType} from "@/data/road/type";

export function getAccessToken(): string | undefined {
  try {
    const hash = localStorage.getItem(SESSION_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    const serialized = JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as ILoginResponseType
    return serialized.access
  } catch(e) {
    console.warn('Unable to get accessToken', e)
    return undefined
  }
}

export function getUsername(): string {
  try {
    const hash = localStorage.getItem(SESSION_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    const serialized = JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as ILoginResponseType
    return serialized.username ? serialized.username : 'username'
  } catch (e) {
    return 'username'
  }
}

export function setAccessToken(access: string, username?: string): void {
  try {
    const hash = localStorage.getItem(SESSION_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    const serialized = JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as ILoginResponseType
    serialized.access = access
    if (username) {
      serialized.username = username
    }
    const session = CryptoJS.AES.encrypt(JSON.stringify(serialized), process.env.VUE_APP_SECRET)
    localStorage.setItem(SESSION_NAMESPACE, session.toString())
  } catch(e) {
    console.warn('Unable to get accessToken', e)
    return undefined
  }
}

export function getRefreshToken(): string | undefined {
  try {
    const hash = localStorage.getItem(SESSION_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    const serialized = JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as ILoginResponseType
    return serialized.refresh
  } catch(e) {
    console.warn('Unable to get refreshToken', e)
    return undefined
  }
}

export function setAreaSelectedPolygon(polygons: any[]): void {
  try {
    const target = CryptoJS.AES.encrypt(JSON.stringify(polygons), process.env.VUE_APP_SECRET)
    localStorage.setItem(AREA_SELECTED_POLYGON_NAMESPACE, target.toString())
  } catch(e) {
    console.warn('Unable to set area selected polygon', e)
  }
}

export function getAreaSelectedPolygon(): any[] {
  try {
    const hash = localStorage.getItem(AREA_SELECTED_POLYGON_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as any[]
  } catch(e) {
    console.warn('Unable to get area selected polygon', e)
    return []
  }
}

export function setSelectedAreaLabels (labels: string): void {
  try {
    const target = CryptoJS.AES.encrypt(labels, process.env.VUE_APP_SECRET)
    localStorage.setItem(AREA_SELECTED_CITY_NAMESPACE, target.toString())
  } catch(e) {
    console.warn('Unable to set area selected cities', e)
  }
}

export function getSelectedAreaLabels (): string  {
  try {
    const hash = localStorage.getItem(AREA_SELECTED_CITY_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return decipher.toString(CryptoJS.enc.Utf8) as string
  } catch(e) {
    console.warn('Unable to get area selected cities', e)
    return ''
  }
}

export function setRailwayPolygons (polygons: Record<string, { lat: number; lng: number }[]>): void {
  try {
    const target = CryptoJS.AES.encrypt(JSON.stringify(polygons), process.env.VUE_APP_SECRET)
    localStorage.setItem(RAILWAY_POLYGON_NAMESPACE, target.toString())
  } catch (e) {
    console.warn('Unable to set railway polygons', e)
  }
}

export function getRailwayPolygons (): Record<string, { lat: number; lng: number }[]> {
  try {
    const hash = localStorage.getItem(RAILWAY_POLYGON_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as Record<string, {lat: number; lng: number}[]>
  } catch (e) {
    console.warn('Unable to get railway polygons', e)
    return {}
  }
}

export function setSelectedRailwayLine(line: string): void {
  try {
    const target = CryptoJS.AES.encrypt(line, process.env.VUE_APP_SECRET)
    localStorage.setItem(RAILWAY_SELECTED_LINE_NAMESPACE, target.toString())
  } catch (e) {
    console.warn('Unable to set selected railway line', e)
  }
}

export function getSelectedRailwayLine(): string {
  try {
    const hash = localStorage.getItem(RAILWAY_SELECTED_LINE_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return decipher.toString(CryptoJS.enc.Utf8)
  } catch (e) {
    console.warn('Unable to get selected railway line', e)
    return ''
  }
}

export function setSelectedRailwayStation(station: string): void {
  try {
    const target = CryptoJS.AES.encrypt(station, process.env.VUE_APP_SECRET)
    localStorage.setItem(RAILWAY_SELECTED_STATION_NAMESPACE, target.toString())
  } catch (e) {
    console.warn('Unable to set selected station', e)
  }
}

export function getSelectedRailwayStation(): string {
  try {
    const hash = localStorage.getItem(RAILWAY_SELECTED_STATION_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return decipher.toString(CryptoJS.enc.Utf8)
  } catch (e) {
    console.warn('Unable to get selected railway station', e)
    return ''
  }
}

export function setSelectedRoad (roads: IRoadType[]): void {
  try {
    const target = CryptoJS.AES.encrypt(JSON.stringify(roads), process.env.VUE_APP_SECRET);
    localStorage.setItem(SELECTED_ROAD_NAMESPACE, target.toString())
  } catch (e) {
    console.warn('Unable to set selected roadIds');
  }
}

export function getSelectedRoad(): IRoadType[] {
  try {
    const hash = localStorage.getItem(SELECTED_ROAD_NAMESPACE) || ''
    const decipher = CryptoJS.AES.decrypt(hash, process.env.VUE_APP_SECRET)
    return JSON.parse(decipher.toString(CryptoJS.enc.Utf8)) as IRoadType[]
  } catch (e) {
    return []
  }
}
