import {IAreaTableAPIStoreType} from '@/data/master/area/table/store'
import {
  AreaTable1DetailRequest,
  AreaTable1Request,
  AreaTable2Request, AreaTable3DetailRequest,
  AreaTable3Request, AreaTable4Request
} from '@/data/master/area/table/store/request'

export interface IAreaTableRepositoryType {
  api: IAreaTableAPIStoreType
  dataEntryToTable1(params: Record<string, any>): Promise<Record<string, any>>
  dataEntryToTable1Detail(params: Record<string, any>): Promise<Record<string, any>>
  dataEntryToTable2(params: Record<string, any>): Promise<Record<string, any>>
  dataEntryToTable3(params: Record<string, any>): Promise<Record<string, any>>
  dataEntryToTable3Detail(params: Record<string, any>): Promise<Record<string, any>>
  dataEntryToTable4(params: Record<string, any>): Promise<Record<string, any>>
}

export default class AreaTableRepository implements IAreaTableRepositoryType {
  api: IAreaTableAPIStoreType
  constructor(api: IAreaTableAPIStoreType) {
    this.api = api
  }
  
  async dataEntryToTable1(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable1Request(params)
    return await this.api.dataEntryToTable1(request)
  }
  
  async dataEntryToTable1Detail(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable1DetailRequest(params)
    return await this.api.dataEntryToTable1Detail(request)
  }
  
  async dataEntryToTable2(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable2Request(params)
    return await this.api.dataEntryToTable2(request)
  }
  
  async dataEntryToTable3(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable3Request(params)
    return await this.api.dataEntryToTable3(request)
  }
  
  async dataEntryToTable3Detail(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable3DetailRequest(params)
    return await this.api.dataEntryToTable3Detail(request)
  }
  
  async dataEntryToTable4(params: Record<string, any>): Promise<Record<string, any>> {
    const request = new AreaTable4Request(params)
    return await this.api.dataEntryToTable4(request)
  }
}

