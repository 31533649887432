import { APIType } from '@/core/apiType'
import { HTTPMethod } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import { getAccessToken } from '@/utils/storage'
import { IQueryRequestType } from '../type'

export class QuerySearchRequest implements HttpRequest<Array<Array<number>>> {
  public response!: Array<Array<number>>
  public path = '/road/heatmap/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: IQueryRequestType
  public apiType = APIType.Query
  
  constructor (params: IQueryRequestType) {
    this.params = params
  } 
}