import {IAreaFacilityMasterType} from '@/data/master/area/facility/type'
import {IAreaFacilityMasterAPIStoreType} from '@/data/master/area/facility/store'
import {AreaFacilityMasterRequest} from '@/data/master/area/facility/store/request'

export interface IAreaFacilityMasterRepositoryType {
  api: IAreaFacilityMasterAPIStoreType
  fetch(params: Record<string, any>): Promise<IAreaFacilityMasterType[]>
}

export default class AreaFacilityMasterRepository implements IAreaFacilityMasterRepositoryType {
  api: IAreaFacilityMasterAPIStoreType
  
  // DI
  constructor (api: IAreaFacilityMasterAPIStoreType) {
    this.api = api
  }
  
  async fetch(params: Record<string, any>): Promise<IAreaFacilityMasterType[]> {
    const request = new AreaFacilityMasterRequest(params)
    return await this.api.fetch(request)
  }
}
