import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: "arrow jr-absolute jr-inline-block jr-align-middle focus:jr-outline-none jr-px-2 jr-py-2 jr-text-sm-1 jr-bg-white jr-w-53 jr-h-10 jr-border-gray jr-border-1 jr-rounded jr-left-5",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt) => {
      return (_openBlock(), _createElementBlock("option", {
        key: `selection-option-${opt}`,
        value: opt.value
      }, _toDisplayString(opt.label), 9, _hoisted_1))
    }), 128))
  ], 544)), [
    [_vModelSelect, _ctx.selected]
  ])
}