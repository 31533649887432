import { HttpClient } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import {IAnimationHoursType} from "@/data/animationhour/type";

export interface IAnimationHourAPIStoreType {
    fetch(request: HttpRequest<IAnimationHoursType>): Promise<IAnimationHoursType>
}

export default class AnimationHourAPIStore implements IAnimationHourAPIStoreType {
    async fetch(request: HttpRequest<IAnimationHoursType>): Promise<IAnimationHoursType> {
        const client = new HttpClient<IAnimationHoursType>()
        const response = await client.request<IAnimationHoursType>(request)
        return response.data
    }
}