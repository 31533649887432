<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <div class="jr-font-base jr-text-sm">
        <p>対象期間中の全ての移動行動を交通手段別に色分けして表示しています。</p>
        <p>エリア来訪時の交通手段比率：選択したエリアに来訪する直前の移動を抽出し交通手段ごとの構成比を表示しています。</p>
        <p>エリア内の交通手段比率：選択したエリア内の移動を抽出し交通手段ごとの構成比を表示しています。</p>
        <div class="jr-pt-4">
          <p>集計は「日のべ人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合は2とカウント。</span>
          </p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物がエリア内で複数交通手段を利用している場合はそれぞれの交通手段にカウント。</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
