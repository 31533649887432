
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UISpeechBubble',
  props: {
    label: { type: String, required: false, default: '' }
  },
  setup() {
    return {}
  },
})
