import {MutationTree} from 'vuex'
import {ITransportationChart, TransportationState} from '@/store/modules/area/search/transportation/type'

export enum TransportationMutationType {
  SET_IN_AREA = 'SetInArea',
  SET_VISIT_AREA = 'SetVisitArea',
  SET_PLOTS = 'SetPlots'
}

export const mutations: MutationTree<TransportationState> = {
  [TransportationMutationType.SET_IN_AREA] (state: TransportationState, payload: ITransportationChart[]): void {
    state.inAreas = payload
  },
  [TransportationMutationType.SET_VISIT_AREA] (state: TransportationState, payload: ITransportationChart[]): void {
    state.visitAreas = payload
  },
  [TransportationMutationType.SET_PLOTS] (state: TransportationState, payload: number[][]): void {
    state.plots = payload
  }
}
