<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <div class="jr-font-base jr-text-sm">
        <p>選択したエリア来訪者の居住地/勤務地の構成比をランキング（30位以内）で表示しています。</p>
        <div class="jr-pt-4">
          <p>集計は「ユニーク人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合も1とカウント。</span>
          </p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>選択したエリア内の居住者/勤務者も集計に含まれます。</span>
          </p>
        </div>
        <p class="jr-pt-4">市区町村名をマウスオーバーすると地図上でハイライトされます。</p>
      </div>
    </div>
  </div>
</template>
