import {AreaState} from '@/store/modules/area/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/getters'
import {actions} from '@/store/modules/area/actions'
import {mutations} from '@/store/modules/area/mutations'
import {FREQUENCIES, TRANSPORTATIONS, ATTRIBUTES, WEEK} from '@/components/subviews/area/AreaSearchFilter.vue'

export const state: AreaState = {
  sbMinimum: false,
  selectPage: 'ダッシュボード',
  areas: [],
  facilities: [],
  
  // for filtering
  timeRange: [0, 24],
  except: false,
  week: WEEK,
  transportations: TRANSPORTATIONS,
  frequencies: FREQUENCIES,
  attributes: ATTRIBUTES,
  isDetailLoaded: false,
  polygonMaxZoom: 10,
  hasAttrChange: false,
  
  // for dashboard display
  isLessRanking: false,
  isLessTransportation: false,
  isLessFacility: false,
  isLessTimeSpent: false,
  isLessVisitor: false,
  resident: [],
  workLocation: [],
  average: 0,
  times: [],
  visitArea: [],
  inArea: [],
  persona: [],
  age: {
    constraints: [],
    men: [],
    women: []
  },
  pins: {}
}
const namespaced = true
export const area: Module<AreaState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
