import {ActionTree} from 'vuex'
import {RootState} from '@/store/types'
import {AreaRankingState} from '@/store/modules/area/search/ranking/type'
import {AreaRankingMutationType} from '@/store/modules/area/search/ranking/mutations'
import {IRankingType} from '@/data/area/type'

export enum AreaRankingActionType {
  setLoading = 'setLoading',
  setMin = 'setMin',
  setMax = 'setMax',
  setResident = 'setResident',
  setWorkLocation = 'setWorkLocation'
}

export const actions: ActionTree<AreaRankingState, RootState> = {
  [AreaRankingActionType.setLoading] ({ commit }, payload: boolean): void {
    commit(AreaRankingMutationType.SET_LOADING, payload)
  },
  [AreaRankingActionType.setMin] ({ commit }, payload: number): void {
    commit(AreaRankingMutationType.SET_MIN, payload)
  },
  [AreaRankingActionType.setMax] ({ commit }, payload: number): void {
    commit(AreaRankingMutationType.SET_MAX, payload)
  },
  [AreaRankingActionType.setResident] ({ commit }, payload: IRankingType[]): void {
    commit(AreaRankingMutationType.SET_RESIDENT, payload)
  },
  [AreaRankingActionType.setWorkLocation] ({ commit }, payload: IRankingType[]): void {
    commit(AreaRankingMutationType.SET_WORK_LOCATION, payload)
  }
}
