import {GetterTree} from 'vuex'
import {AreaVisitorState} from '@/store/modules/area/search/visitor/type'
import {RootState} from '@/store/types'
import {percentRound} from '@/utils/number'
import _ from 'lodash'

export const getters: GetterTree<AreaVisitorState, RootState> = {
  personaLabels (state: AreaVisitorState): string[] {
    return state.persona.map(p => p.label)
  },
  personaVolumes (state: AreaVisitorState): number[] {
    const volumes = state.persona.map(p => p.volume)
    return percentRound(volumes)
  },
  ages (state: AreaVisitorState): {men: number[]; women: number[]; max: number} {
    const menTotal = state.ages.men.reduce((acc, val) => acc + val, 0)
    const womenTotal = state.ages.women.reduce((acc, val) => acc + val, 0)
    const subTotal = menTotal + womenTotal
    const men = state.ages.men.map(m => (m * 100) / subTotal)
    const women = state.ages.women.map(w => (w * 100) / subTotal)
    const max = Math.ceil(_.max(men.concat(women)))
    const maxOffset = (10 - (max % 10))
    return {
      max: max + maxOffset,
      men: men,
      women: women
    }
  }
}
