import {ITranslatorType} from '@/types'
import {IRailLineType} from '../type'

export default class RailLineJsonTranslator implements ITranslatorType<Record<string, any>, IRailLineType> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
  translate(input: Record<string, any>, ...args): IRailLineType {
    return {
      company_name: input.company_name,
      line_name: input.line_name,
      stations: input.stations,
      isCollapsed: true,
      selectedStation: ''
    }
  }
}
