import {ActionTree} from 'vuex'
import {ITransportationChart, TransportationState} from '@/store/modules/area/search/transportation/type'
import {RootState} from '@/store/types'
import {TransportationMutationType} from '@/store/modules/area/search/transportation/mutations'

export enum TransportationActionType {
  setInArea = 'setInArea',
  setVisitArea = 'setVisitArea',
  setPlots = 'setPlots'
}

export const actions: ActionTree<TransportationState, RootState> = {
  [TransportationActionType.setInArea] ({ commit }, payload: ITransportationChart[]): void {
    commit(TransportationMutationType.SET_IN_AREA, payload)
  },
  [TransportationActionType.setVisitArea] ({ commit }, payload: ITransportationChart[]): void {
    commit(TransportationMutationType.SET_VISIT_AREA, payload)
  },
  [TransportationActionType.setPlots] ({ commit }, payload: number[][]): void {
    commit(TransportationMutationType.SET_PLOTS, payload)
  }
}
