<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">市民／市外の比率</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>選択した道路利用者の市民・市外比率を表示しています。</p>
        <p>市民・市外の定義は以下の通りです。</p>
        <p>市民：選択した道路が通っている場所の市区町村に居住している人</p>
        <p>市外：上記以外</p>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">ボリュームの変化</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>市民・市外それぞれについて日曜日の道路利用者数を基準とした割合で表示しています。</p>
        <p>いずれも集計は「日のべ人数」です。</p>
        <p class="jr-pt-4">
          <span class="jr-pr-2.5">※</span>
          <span>同一人物が2日間対象道路を利用した場合は2とカウント。</span>
        </p>
      </div>
    </div>
  </div>
</template>
