import {HttpRequest} from "@/core/httpRequest";
import {HttpClient} from "@/core/httpClient";
import {IRoadType} from "@/data/road/type";

export interface IRoadAPIStoreType {
    fetch(request: HttpRequest<IRoadType[]>): Promise<IRoadType[]>
}

export default class RoadAPIStore implements IRoadAPIStoreType {
    async fetch(request: HttpRequest<IRoadType[]>): Promise<IRoadType[]> {
        const client = new HttpClient<IRoadType[]>()
        const response = await client.request<IRoadType[]>(request)
        return response.data
    }
}