import data from "@/assets/animationDay.json";
import {IAnimationDaysType} from "@/data/animationday/type";

export interface IAnimationDayStubType {
    fetch(): Promise<IAnimationDaysType>
}

export default class AnimationDayStubStore implements IAnimationDayStubType {
    fetch (): Promise<IAnimationDaysType> {
        return new Promise((resolve) => resolve(data as IAnimationDaysType))
    }
}