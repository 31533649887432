
import {computed, defineComponent, inject, onMounted, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {CheckboxModel} from '@/components/subviews/area/AreaSearchFilter.vue'
import {useRoute} from 'vue-router'
import {Emitter, EventType} from 'mitt'
import {RailwayActionType} from '@/store/modules/railway/actions'
import UIHourRangePicker from '@/components/UI/UIHourRangePicker.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import {RailwayStationTab} from "@/enum/station";

interface State {
  minimum: boolean
  isAllDays: boolean
  isAllTransportation: boolean
  isAllResident: boolean
  week: CheckboxModel[]
  transportations: CheckboxModel[]
  residents: CheckboxModel[]
  selectedTimeRange: number[]
}

export const WEEK = [
  { name: '平日', isSelected: true, value: 1 },
  { name: '土日祝', isSelected: true, value: 0 }
]

export const RAILWAY_TRANSPORTATIONS = [
  { name: '車', isSelected: true, value: 1 },
  { name: '徒歩', isSelected: true, value: 2 }
]
export const RAILWAY_RESIDENTS = [
  { name: '居住地', isSelected: true, value: 1 },
  { name: '勤務地', isSelected: true, value: 2 }
]

export default defineComponent({
  name: 'RailwaySearchFilter',
  components: {UIHourRangePicker, UICheckbox},
  emits: ['search', 'resize'],
  setup (_, {emit}) {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      minimum: false,
      isAllDays: true,
      isAllTransportation: true,
      isAllResident: true,
      week: WEEK,
      transportations: RAILWAY_TRANSPORTATIONS,
      residents: RAILWAY_RESIDENTS,
      selectedTimeRange: store.state.railway.timeRange
    })

    // pages
    const isHeatmap = computed(() => route.path === '/railway_report/heatmap')
    const isComparison = computed(() => route.path === '/railway_report/comparison')
    const isAnimation = computed(() => route.path === '/railway_report/animation')

    const isAllDaySelected = computed(() => state.week.every(w => w.isSelected))
    const isAllTransportationSelected = computed(() => state.transportations.every(t => t.isSelected))
    const isAllResidentSelected = computed(() => state.residents.every(r => r.isSelected))

    onMounted(() => {
      // adjust according to the selected railway station tab
      if (isHeatmap.value) {
        const name = store.state.railway.stationTab === RailwayStationTab.Resident ? '居住地' : '勤務地'
        state.residents.forEach(s => {
          s.isSelected = s.name === name;
        })

        const selectedResidents = state.residents.filter(r => r.isSelected)
        store.dispatch(`railway/${RailwayActionType.setSelectedResident}`, selectedResidents)
      }

      state.isAllDays = store.state.railway.week.some(w => w.isSelected)
      state.isAllTransportation = store.state.railway.transportations.some(t => t.isSelected)
      state.isAllResident = store.state.railway.residents.some(r => r.isSelected)
    })
    emitter?.on('notifyRailwayFilter', () => {
      const selectedWeek = state.week.filter(w => w.isSelected)
      const selectedTransportations = state.transportations.filter(t => t.isSelected)
      const selectedResidents = state.residents.filter(r => r.isSelected)

      store.dispatch(`railway/${RailwayActionType.setSelectedTimeRange}`, state.selectedTimeRange)
      store.dispatch(`railway/${RailwayActionType.setWeek}`, selectedWeek)
      store.dispatch(`railway/${RailwayActionType.setSelectedTransportation}`, selectedTransportations)
      store.dispatch(`railway/${RailwayActionType.setSelectedResident}`, selectedResidents)
    })

    const handleAllDaysChange = (value: boolean) => {
      state.week.forEach(w => {
        w.isSelected = value
      })
    }
    const handleAllTransportationChange = (value: boolean) => {
      state.transportations.forEach(t => {
        t.isSelected = value
      })
    }
    const handleAllResidentChange = (value: boolean) => {
      state.residents.forEach(r => {
        r.isSelected = value
      })
    }
    const handleWeekChange = () => state.isAllDays = state.week.some(w => w.isSelected)
    const handleTransportationChange = () => state.isAllTransportation = state.transportations.some(t => t.isSelected)
    const handleResidentChange = () => state.isAllResident = state.residents.some(r => r.isSelected)
    const handleSearch = () => {
      const selectedWeek = state.week.filter(w => w.isSelected)
      const selectedTransportations = state.transportations.filter(t => t.isSelected)
      const selectedResidents = state.residents.filter(r => r.isSelected)
      const supplyParams: Record<string, any> = {
        time_range: {
          from: state.selectedTimeRange[0],
          to: state.selectedTimeRange[1]
        }
      }
      if (state.week.some(w => !w.isSelected)) {
        supplyParams.week = selectedWeek.map(w => w.value)
      }
      if (state.transportations.some(t => !t.isSelected)) {
        supplyParams.type = selectedTransportations.map(t => t.value)
      }
      if (state.residents.some(r => !r.isSelected)) {
        supplyParams.resident = selectedResidents.map(r => r.value)
      }
      emit('search', supplyParams)
    }

    // events
    const clickClose = () => {
      state.minimum = !state.minimum
      emit('resize', state.minimum)
    }
    return {
      ...toRefs(state),
      isAllDaySelected,
      isAllTransportationSelected,
      isAllResidentSelected,
      isHeatmap,
      isComparison,
      isAnimation,
      clickClose,
      handleSearch,
      handleAllDaysChange,
      handleAllTransportationChange,
      handleAllResidentChange,
      handleTransportationChange,
      handleResidentChange,
      handleWeekChange
    }
  }
})
