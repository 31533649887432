import mitt from "mitt";

export const DOWNLOAD_GRAPH_REPORT = Symbol("downloadGraphReport")
export const DOWNLOAD_ROAD_DAY_ANIM = Symbol("downloadRoadSameDayAnim")
export const DOWNLOAD_ROAD_HOUR_ANIM = Symbol("downloadRoadHourAnim")
export const DOWNLOAD_AREA_DASHBOARD = Symbol("downloadAreaDashboard")
export const DOWNLOAD_AREA_RANKING_REPORT = Symbol("downloadAreaRankingReport")
export const DOWNLOAD_AREA_TRANSPORTATION = Symbol("downloadAreaTransportation")
export const DOWNLOAD_AREA_FACILITY = Symbol("downloadAreaFacility")
export const DOWNLOAD_AREA_TIME_SPENT = Symbol("downloadAreaTimeSpent")
export const DOWNLOAD_AREA_VISITOR = Symbol("downloadAreaVisitor")
export const DOWNLOAD_SAME_DAY_SERIES = Symbol("downloadSameDaySeries")

// railway
export const DOWNLOAD_RAILWAY_STATION = Symbol("downloadRailwayStation")
export const DOWNLOAD_RAILWAY_HEATMAP = Symbol("downloadRailwayHeatmap")
export const DOWNLOAD_RAILWAY_COMPARISON = Symbol("downloadRailwayComparison")
export const DOWNLOAD_RAILWAY_ANIMATION = Symbol("downloadRailwayAnimation")

// hint
export const HINT_ROAD_HEATMAP = Symbol('showRoadHeatmapHint')
export const HINT_ROAD_BAR_CHART = Symbol('showRoadBarchartHint')
export const HINT_ROAD_SAME_DAY_ANIMATION = Symbol('showRoadSameDayAnimationHint')
export const HINT_ROAD_HOUR_ANIMATION = Symbol('showRoadHourAnimationHint')
export const HINT_RAILWAY_STATION = Symbol('showRailwayStationHint')
export const HINT_RAILWAY_HEATMAP = Symbol('showRailwayHeatmapHint')
export const HINT_RAILWAY_COMPARISON = Symbol('showRailwayComparisonHint')
export const HINT_RAILWAY_ANIMATION = Symbol('showRailwayAnimationHint')
export const HINT_AREA_DASHBOARD = Symbol('showAreaDashboardHint')
export const HINT_AREA_RANKING = Symbol('showAreaRankingHint')
export const HINT_AREA_TRANSPORTATION = Symbol('showAreaTransportationHint')
export const HINT_AREA_FACILITY = Symbol('showAreaFacilityHint')
export const HINT_AREA_TIME_SPENT = Symbol('showAreaTimeSpentHint')
export const HINT_AREA_VISITOR = Symbol('showAreaVisitorHint')
export const HINT_AREA_ANIMATION = Symbol('showAreaAnimationHint')

export const globalEmitter = mitt();
