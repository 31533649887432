import {ActionTree} from 'vuex'
import {AreaVisitorState} from '@/store/modules/area/search/visitor/type'
import {RootState} from '@/store/types'
import {IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'
import {AreaVisitorMutationType} from '@/store/modules/area/search/visitor/mutations'

export enum AreaVisitorActionType {
  setVisitorPersona = 'setVisitorPersona',
  setVisitorAge = 'setVisitorAge'
}

export const actions: ActionTree<AreaVisitorState, RootState> = {
  [AreaVisitorActionType.setVisitorPersona] ({ commit }, payload: IVisitorPersonaType[]): void {
    commit(AreaVisitorMutationType.SET_VISITOR_PERSONA, payload)
  },
  [AreaVisitorActionType.setVisitorAge] ({ commit }, payload: IVisitorAgeType[]): void {
    commit(AreaVisitorMutationType.SET_VISITOR_AGE, payload)
  }
}
