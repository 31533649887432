import { DayCode } from '@/enum/barchart'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { HourSeriesMutationType } from './mutations'
import { HourSeriesState } from './type'
import store, {ActionType} from "@/store";
import {setAccessToken} from "@/utils/storage";
import {AlertDialog, WarningDialog} from "@/service/dialog";
import AnimationHourStubStore from "@/data/animationhour/store/stub";
import AnimationHourAPIStore from "@/data/animationhour/store";
import AnimationHourRepository from "@/data/animationhour/repository";
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import {Config} from "@/config";
import {
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE, MAX_THRESHOLD_MESSAGE, MAX_THRESHOLD_TITLE,
  PROCESS_CANCELED_MESSAGE,
  PROCESS_CANCELED_TITLE
} from "@/constant";

export enum HourSeriesActionType {
  setDayConstraint = 'setDayConstraint',
  setDayFilter = 'setDayFilter',
  setDoInitFlg = 'setDoInitFlg',
  loadAnimationHour = 'loadAnimationHour',
  setTimeLabel = 'setTimeLabel',
  setActiveTime = 'setActiveTimeLabel',
}

export const actions: ActionTree<HourSeriesState, RootState> = {
  [HourSeriesActionType.setTimeLabel] ({ commit }, payload: string): void {
    commit(HourSeriesMutationType.SET_TIME_LABEL, payload)
  },
  [HourSeriesActionType.setActiveTime] ({ commit }, payload: string): void {
    commit(HourSeriesMutationType.SET_ACTIVE_TIME, payload)
  },
  [HourSeriesActionType.setDayFilter] ({ commit }, payload: DayCode[]): void {
    commit(HourSeriesMutationType.SET_DAY_FILTER, payload)
  },
  [HourSeriesActionType.setDayConstraint] ({ commit }, payload: DayCode[]): void {
    commit(HourSeriesMutationType.SET_DAY_CONSTRAINT, payload)
  },
  [HourSeriesActionType.setDoInitFlg] ({ commit }, payload: boolean): void {
    commit(HourSeriesMutationType.SET_DO_INIT_FLG, payload)
  },
  [HourSeriesActionType.loadAnimationHour] ({ dispatch }): Promise<any> {
    // animation hour
    const stub = new AnimationHourStubStore()
    const api = new AnimationHourAPIStore()
    const repository = new AnimationHourRepository(stub, api)
    // auth
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    //
    const request = {
      create: true,
      unit: Config.Instance.makeTableUnitHourTime,
      week: [],
      except_target_area: 0,
      holiday_flag: true,
    };
    return repository.fetch(request)
      .then(response => {
        store.dispatch(ActionType.setAnimationHours, response)
        return true
      })
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetch(request))
            .then(response => {
              store.dispatch(ActionType.setAnimationHours, response)
              return true
            })
        } else if (err.status === 424) {
          WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else if (err.status === 409) {
          WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => {
        dispatch(`hourSeries/${HourSeriesActionType.setDoInitFlg}`, false, {root: true});
      })
  }
}
