import {ActionTree} from 'vuex'
import {AreaTimeSpentState} from '@/store/modules/area/search/timeSpent/type'
import {RootState} from '@/store/types'
import {ITimeSpentType} from '@/data/area/type'
import {AreaTimeSpentMutationType} from '@/store/modules/area/search/timeSpent/mutations'

export enum AreaTimeSpentActionType {
  setTimes = 'setTimes',
  setAverage = 'setAverage'
}

export const actions: ActionTree<AreaTimeSpentState, RootState> = {
  [AreaTimeSpentActionType.setTimes] ({ commit }, payload: ITimeSpentType[]): void {
    commit(AreaTimeSpentMutationType.SET_TIMES, payload)
  },
  [AreaTimeSpentActionType.setAverage] ({ commit }, payload: number): void {
    commit(AreaTimeSpentMutationType.SET_AVERAGE, payload)
  }
}
