
import {computed, defineComponent} from 'vue'
import {DoughnutChart, useDoughnutChart} from 'vue-chart-3'
import {useStore} from 'vuex'
import {Config} from '@/config'

export default defineComponent({
  name: 'VisitorDoughnutChart',
  components: {DoughnutChart},
  props: {
    label: { type: String, required: false, default: '' },
    width: { type: Number, required: false, default: 0 },
    height: { type: Number, required: false, default: 0 }
  },
  setup (props) {
    const store = useStore()
    const doughnutStyle = computed(() => {
      return {
        width: props.width > 0 ? `${props.width}px` : 'auto',
        height: props.height > 0 ? `${props.height}px` : 'auto'
      }
    })
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      cutout: 50,
      // events: [], // remove this setting to show event related such as tooltips
      elements: {
        arc: { borderWidth: 0 }
      },
      plugins: {
        tooltip: {
          position: 'nearest',
          callbacks: {
            label: function(chart) {
              return `${chart.label} ${chart.formattedValue}%`
            }
          }
        },
        legend: { display: false },
        datalabels: {
          display: false,
          color: '#fff',
          font: { weight: '400', size: 20 },
          textAlign: 'center',
          formatter: function (value, context) {
            return `${chartData.value.labels[context.dataIndex]}\n${value}%`
          }
        }
      }
    }))
    const chartData = computed(() => ({
      labels: store.getters['areaVisitor/personaLabels'],
      datasets: [
        {
          data: store.getters['areaVisitor/personaVolumes'],
          backgroundColor: Config.Instance.personaRankingColors
        }
      ]
    }))

    const { doughnutChartProps } = useDoughnutChart({
      chartData,
      options
    })
    return {
      chartData,
      doughnutChartProps,
      doughnutStyle
    }
  }
})
