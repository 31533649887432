import {MutationTree} from 'vuex'
import {FacilityMasterState} from '@/store/modules/area/master/facility/type'
import {IAreaFacilityMasterType} from '@/data/master/area/facility/type'

export enum FacilityMasterMutationType {
  SET_FACILITIES = 'SetFacilities'
}

export const mutations: MutationTree<FacilityMasterState> = {
  [FacilityMasterMutationType.SET_FACILITIES] (state: FacilityMasterState, payload: IAreaFacilityMasterType[]): void {
    state.facilities = payload
  }
}
