import {GetterTree} from 'vuex'
import {FacilityMasterState} from '@/store/modules/area/master/facility/type'
import {RootState} from '@/store/types'

export const getters: GetterTree<FacilityMasterState, RootState> = {
  transform (state: FacilityMasterState): Record<string, string> {
    const res = {}
    state.facilities.forEach(f => {
      res[f.name] = f.icon
    })
    return res
  }
}
