
import { computed, defineComponent, PropType } from 'vue'
import { BarChart, useBarChart } from "vue-chart-3"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart, registerables } from 'chart.js'
import { BarChartFilterType } from "@/data/chart/type"

Chart.register(...registerables, ChartDataLabels)

interface DataChart {
  label: string
  data: Array<number>
  backgroundColor: string
  barPercentage: number
}

export default defineComponent({
  props: {
    datasets: { type: Object as PropType<BarChartFilterType>, required: false, default: () => null },
    stackBar: { type: Boolean, required: false, default: false },
    dataLabel: { type: Boolean, required: false, default: false },
    maxValue: { type: Boolean, required: false, default: false },
    selectionValue: { type: Number, required: false, default: 1},
    maxDataSetlength: { type: Number, required: false, default: 0},
    max: { type: Number, required: false, default: 100 }
  },
  components: {
    BarChart,
  },
  setup (props) {
    const options = computed(() => ({
      scales: {
        x: {
          stacked: props.stackBar,
          grid: { display: false },
          ticks: { color: 'black', font: { family: "'Noto Sans JP', sans-serif", size: 14 } }
        },
        y: {
          stacked: props.stackBar,
          beginAtZero: true,
          max: props.max,
          ticks: { color: 'black', font: { family: "'Noto Sans JP', sans-serif", size: 14 }, stepSize: 20 },
          grid: { drawBorder: false }
        }
      },
      plugins: {
        datalabels: {
          display: props.dataLabel,
          color: props.stackBar==false ? function(context) {
            return context.dataset.backgroundColor;
          } : '#fff',
          anchor: !props.stackBar ? 'end' : 'center',
          align: !props.stackBar ? 'end' : 'center',
          clamp: true,
          labels: { title: { font: { weight: 'bold', size: 12, align: 'end' } } },
          formatter: function(value, context) {
            if (value < 4 && props.stackBar == true || context.chart.data.labels[context.dataIndex]=='') {
              return ""
            }
            return value
          }
        },
        legend: {
          position: 'bottom',
          align: 'end',
          labels: {
            padding: 30,
            boxWidth: 14,
            boxHeight: 14
          },
          onClick: false
        }
      }
    }))
    const chartData = computed(() => props.datasets)
    const { barChartProps } = useBarChart({
      chartData,
      options,
    })

    return {
      barChartProps
    }
  }
})
