
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {getRailwayPolygons, getSelectedRailwayLine, getSelectedRailwayStation, setAccessToken} from '@/utils/storage'
import {Config} from '@/config'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {Emitter, EventType} from 'mitt'
import {RailwayActionType} from '@/store/modules/railway/actions'
import {IRailwayComparisonCompareType} from '@/data/railway/type'
import {DOWNLOAD_RAILWAY_COMPARISON, HINT_RAILWAY_COMPARISON, globalEmitter} from '@/service/GlobalEmmiter'
import {ActionType} from '@/store'
import {ConvertToImage} from '@/service/file'
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_RAILWAY_COMPARISON,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  PROCESS_CANCELED_TITLE,
  PROCESS_CANCELED_MESSAGE,
  RAILWAY_NO_WEEK
} from '@/constant'
import {useRoute} from 'vue-router'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import PolygonColors from '@/components/UI/railway/PolygonColors.vue'
import RailwaySearchFilter from '@/components/subviews/railway/RailwaySearchFilter.vue'
import ComparisonBarchart from '@/components/UI/railway/ComparisonBarchart.vue'
import AttributeBarchart from '@/components/UI/railway/AttributeBarchart.vue'
import RailwayStubStore from '@/data/railway/store/stub'
import RailwayAPIStore from '@/data/railway/store'
import RailwayRepository from '@/data/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AppLoader from '@/components/AppLoader.vue'
import RailwayFilterBadge from "@/components/UI/railway/RailwayFilterBadge.vue";
import {HintDialog} from '@/service/dialog'
import RailwayComparison from '@/components/hints/RailwayComparison.vue'

interface State {
  map: any
  captureClip: boolean
  filterMinimum: boolean
  isLoading: boolean
  isLess: boolean
}

export default defineComponent({
  name: 'Comparison',
  components: {
    AppLoader,
    AttributeBarchart,
    ComparisonBarchart,
    RailwaySearchFilter,
    UIGoogleMap,
    PolygonColors,
    RailwayFilterBadge
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      map: null,
      filterMinimum: false,
      isLoading: false,
      captureClip: false,
      isLess: false
    })
    const attribute = computed<IRailwayComparisonCompareType>(() => store.state.railway.comparison.attribute)
    const sbMinimum = computed(() => store.state.railway.sbMinimum)
    const wrapperElementID = CONTENT_DOWNLOAD_RAILWAY_COMPARISON
    const lessAmountMessage = AREA_WARNING_TITLE
    const railwayTimeRange = computed(() => store.getters['railway/timeRangeBadge'])
    const railwayWeek = computed(() => store.getters['railway/weekBadge'])
    const selectedRailwayLine = computed(() => {
      const line = getSelectedRailwayLine()
      const splitter = line.split('-')
      return store.state.railLine.chosenLine || splitter.length > 1 ? splitter[1] : ''
    })
    const selectedRailwayStation = computed(() => {
      return store.state.railLine.chosenStation || getSelectedRailwayStation()
    })

    // data layer instances
    const stub = new RailwayStubStore()
    const api = new RailwayAPIStore()
    const repository = new RailwayRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const station = getSelectedRailwayStation()
    const isCapturing = computed(() => store.state.isCapturing)

    const handleMapLoad = (map: any) => {
      const polygons = getRailwayPolygons()
      state.map = map

      // center the map
      const service = new window.google.maps.places.PlacesService(state.map)
      const request = {
        query: `${station}駅`,
        fields: ['name', 'geometry']
      }
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const geometry = results[0].geometry
          if (geometry !== undefined) {
            if (geometry.location?.lat() !== undefined && geometry.location?.lng() !== undefined) {
              const pos = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
              }
              state.map.setCenter(pos)
              const zoom = localStorage.getItem('zoom')
              if (zoom) {
                state.map.setZoom(Number(zoom))
              }
            }
          }
        }
      })

      for (let index in polygons) {
        const plotPolygon = new window.google.maps.Polygon({
          paths: polygons[index],
          strokeColor: Config.Instance.polygonColor[index].hex,
          fillColor: Config.Instance.polygonColor[index].hex,
          clickable: false
        })
        plotPolygon.setMap(state.map)
      }
    }
    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const makeAPICall = (params: Record<string, any>) => {
      state.isLoading = true
      state.isLess = false
      store.dispatch(`railway/${RailwayActionType.setLoadingComparison}`, true)
      store.dispatch(`railway/${RailwayActionType.setComparison}`, {
        comparison: {
          base: '',
          labels: [],
          main: [],
          peripheral: []
        },
        attribute: {
          labels: [],
          men: [],
          women: []
        }
      })
      repository.fetchComparison(params).then(res => {
        store.dispatch(`railway/${RailwayActionType.setComparison}`, res)
        emitter?.emit('notifyRailwayFilter')
        if (params.create) {
          store.dispatch(`railway/${RailwayActionType.setCreate}`, false)
        }
      }).catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetchComparison(params))
          .then(res => {
            store.dispatch(`railway/${RailwayActionType.setComparison}`, res)
            emitter?.emit('notifyRailwayFilter')
          })
          .catch(err => {
            if (err.status === 404) {
              state.isLess = true
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              if (err.status !== 401) {
                AlertDialog(err.message)
              }
            }
          })
        } else if (err.status === 404) { // amount of search result is less and the API return this error
          state.isLess = true
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 409) {
          WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
        } else if (err.status === 424) {
          WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => {
        state.isLoading = false
        store.dispatch(`railway/${RailwayActionType.setLoadingComparison}`, false)
      })
    }
    const handleSearch = (params: Record<string, any>) => {
      delete params.type
      delete params.resident
      params.create = store.state.railway.isCreate
      const week = params.week && params.week.length === 0
      const polygons = getRailwayPolygons()
      if (polygons.first) {
        params['area1'] = polygons.first.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }
      if (polygons.second) {
        params['area2'] = polygons.second.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }
      if (polygons.third) {
        params['area3'] = polygons.third.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }
      if (polygons.fourth) {
        params['area4'] = polygons.fourth.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }
      if (polygons.fifth) {
        params['area5'] = polygons.fifth.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }
      if (polygons.sixth) {
        params['area6'] = polygons.sixth.map(p => ({ latitude: p.lat, longitude: p.lng }))
      }

      if (week) {
        AlertDialog(RAILWAY_NO_WEEK)
        return
      }
      makeAPICall(params)
    }

    const timeRange = store.state.railway.timeRange
    const week = store.state.railway.week
      .filter(w => w.isSelected)
      .map(w => w.value)
    const polygons = getRailwayPolygons()
    const params = {
      create: store.state.railway.isCreate,
      time_range: {
        from: timeRange[0],
        to: timeRange[1]
      }
    }

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_RAILWAY_COMPARISON)
      globalEmitter.off(HINT_RAILWAY_COMPARISON)
    })

    if (polygons.first) {
      params['area1'] = polygons.first.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }
    if (polygons.second) {
      params['area2'] = polygons.second.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }
    if (polygons.third) {
      params['area3'] = polygons.third.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }
    if (polygons.fourth) {
      params['area4'] = polygons.fourth.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }
    if (polygons.fifth) {
      params['area5'] = polygons.fifth.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }
    if (polygons.sixth) {
      params['area6'] = polygons.sixth.map(p => ({ latitude: p.lat, longitude: p.lng }))
    }

    if (week.length == 1) {
      params['week'] = week
    }
    makeAPICall(params)

    globalEmitter.on(DOWNLOAD_RAILWAY_COMPARISON, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_RAILWAY_COMPARISON, () => {
      HintDialog(RailwayComparison)
    })

    return {
      ...toRefs(state),
      sbMinimum,
      attribute,
      lessAmountMessage,
      isCapturing,
      railwayTimeRange,
      railwayWeek,
      selectedRailwayLine,
      selectedRailwayStation,
      handleSearch,
      handleFilterResize,
      handleMapLoad
    }
  }
})
