import data from '@/assets/response.json'
import { IQueryType } from '../type'

export interface IQueryStubType {
  fetch(): Promise<Array<Array<number>>>
}

export default class QueryStubStore implements IQueryStubType {
  fetch (): Promise<Array<Array<number>>> {
    return new Promise((resolve) => resolve(data as Array<Array<number>>))
  }
}