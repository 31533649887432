
import {computed, defineComponent} from 'vue'
import {BarChart, useBarChart} from 'vue-chart-3'
import {Chart, registerables} from 'chart.js'
import {useStore} from 'vuex'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'

Chart.register(...registerables, ChartDataLabels)
export default defineComponent({
  name: 'FacilityBarChart',
  components: {BarChart},
  setup () {
    const store = useStore()
    const chartHeight = computed(() => {
      return store.getters['area/facilityLabels'].length * 30
    })
    const options = computed(() => {
      const scaleX = {
        display: true,
        grid: {
          drawBorder: false,
          drawTicks: false
        },
        position: 'top',
        ticks: {
          color: 'black',
          font: { size: 13 },
          // stepSize: 10,
          callback: function (value) {
            if (value % 10 === 0) {
              return value
            }
            return ''
          }
        }
      }
      const data = store.getters['area/facilityDataset']
      const max = _.max(data)
      const remainder = 100 - (max % 100)
      let consumeMax = max + remainder
      if (consumeMax - max < 5) {
        consumeMax = consumeMax + 100
      }
      scaleX['max'] = consumeMax
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        events: [], // remove this setting to show event related items such as tooltips
        categoryPercentage: 1.0,
        barPercentage: 0.5,
        plugins: {
          legend: { display: false },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            color: '#000',
            padding: -2,
            font: {
              size: 10
            },
            formatter: function (value) {
              return typeof value === 'number' ? `${value}%` : value
            }
          }
        },
        scales: {
          x: scaleX,
          y: {
            display: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              crossAlign: 'far',
              align: 'center',
              color: 'black',
              font: { size: 16 }
            }
          }
        }
      }
    })

    const chartData = computed(() => {
      const data = store.getters['area/facilityDataset']
      const labels = store.getters['area/facilityLabels']
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: ['#1967D2']
          }
        ]
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps, chartHeight }
  }
})
