import {HttpRequest} from '@/core/httpRequest'
import {HTTPMethod} from '@/core/httpClient'
import {getAccessToken} from '@/utils/storage'
import {APIType} from '@/core/apiType'

export class AreaTable1Request implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table1/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable1
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTable1DetailRequest implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table1_detail/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable1Detail
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTable2Request implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table2/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable2
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTable3Request implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table3/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable3
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTable3DetailRequest implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table3_detail/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable3Detail
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTable4Request implements HttpRequest<Record<string, any>> {
  public response!: Record<string, any>
  public path = '/area/table4/input/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTable4
  constructor (params: Record<string, any>) {
    this.params = params
  }
}
