
import {defineComponent, reactive, toRefs} from 'vue'
import {Form} from 'vee-validate'
import {AlertDialog} from '@/service/dialog'
import {PASSWORD_RESET_MAIL_SEND} from '@/constant'
import {useStore} from 'vuex'
import {ActionType} from '@/store'
import UIInputField from '@/components/UI/UIInputField.vue'
import UIButton from '@/components/UI/UIButton.vue'
import AuthRepository from '@/data/auth/repository'
import AuthAPIStore from '@/data/auth/store'

interface State {
  email: string
}

export default defineComponent({
  name: 'ForgetPassword',
  components: {
    Form,
    UIInputField,
    UIButton
  },
  setup () {
    const api = new AuthAPIStore()
    const repository = new AuthRepository(api)
    const store = useStore()
    const state = reactive<State>({
      email: ''
    })
    const submit = () => false
    const handleSubmit = () => {
      store.dispatch(ActionType.setAppLoading, true)
      repository.requestPasswordReset(state.email)
        .then(() => {
          const inputs = document.querySelectorAll('input[type=email]') as unknown as HTMLInputElement[]
          inputs.forEach(i => {
            i.dispatchEvent(new Event('input', {
              bubbles: true,
              cancelable: true,
            }))
            i.value = ''
          })
          AlertDialog(PASSWORD_RESET_MAIL_SEND)
        })
        .catch(err => AlertDialog(err.message))
        .finally(() => store.dispatch(ActionType.setAppLoading, false))
    }

    return {
      ...toRefs(state),
      handleSubmit,
      submit
    }
  }
})
