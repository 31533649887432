import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UICheckbox = _resolveComponent("UICheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["jr-grid", {
               'jr-grid-cols-3 jr-gap-x-4.252': (_ctx.type==='day'),
               'jr-grid-cols-2 jr-w-40': (_ctx.type==='week')
              }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOptions, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "jr-pt-3.9",
        key: index
      }, [
        _createVNode(_component_UICheckbox, {
          label: _ctx.getLabel(option.code),
          modelValue: option.status,
          "onUpdate:modelValue": ($event: any) => ((option.status) = $event),
          onChange: _ctx.handleFilterChange
        }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "onChange"])
      ]))
    }), 128))
  ], 2))
}