import {AreaRankingState} from '@/store/modules/area/search/ranking/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/ranking/getters'
import {actions} from '@/store/modules/area/search/ranking/actions'
import {mutations} from '@/store/modules/area/search/ranking/mutations'

export const state: AreaRankingState = {
  isLoading: false,
  min: 0,
  max: 0,
  resident: [],
  workLocation: []
}

const namespaced = true
export const areaRanking: Module<AreaRankingState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
