
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue"
import "@luma.gl/debug"
import { GoogleMap, CustomControl } from "vue3-google-map";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SideNavigation from "@/components/subviews/SideNavigation.vue";
import FilterNavigation from "@/components/subviews/FilterNavigation.vue";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { LineLayer } from "@deck.gl/layers";
import { Config } from "@/config";
import { ActionType } from "@/store";
import GeolocationPosition from "@google/maps";
import { Emitter, EventType } from "mitt"
import { IQueryRequestType } from "@/data/query/type";
import QueryRepository from '@/data/query/repository'
import QueryAPIStore from '@/data/query/store'
import QueryStubStore from '@/data/query/store/stub'
import AuthRepository from '@/data/auth/repository'
import AuthAPIStore from '@/data/auth/store'
import {AlertDialog, WarningDialog, WarningPromptDialog} from "@/service/dialog";
import PolygonManager from "@/service/polygon";
import {
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  NOTIFY_LONG_PROCESSING_TITLE,
  NOTIFY_LONG_PROCESSING_MESSAGE,
  EMPTY_ROAD_ERROR_MESSAGE,
  EMPTY_ROAD_ERROR_TITLE,
  LOCATION_NOT_FOUND,
  LOCATION_TURN_OFF, PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE
} from "@/constant";
import { setAccessToken } from '@/utils/storage'
import RoadStubStore from "@/data/road/store/stub";
import RoadAPIStore from "@/data/road/store";
import RoadRepository from "@/data/road/repository";
import {IRoadRequestType} from "@/data/road/type";
import {polygonFactory} from "@/layers/polygon";
import {v4 as uuidv4} from 'uuid'
import {PrefectureMasterActionType} from "@/store/modules/area/master/prefecture/actions";
import {ICityType} from "@/data/master/area/prefecture/type";
import _ from 'lodash'
import AppLoader from "@/components/AppLoader.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    AppLoader,
    GoogleMap,
    CustomControl,
    SideNavigation,
    FilterNavigation
  },
  setup() {
    const emitter = inject<Emitter<Record<EventType, any>>>("emitter");
    const store = useStore();
    const route = useRoute();
    const apiKey = process.env.VUE_APP_MAP_API_KEY;
    const isHome = computed(() => route.path === "/");
    const isRoad = computed(() => route.path === "/road")
    const isArea = computed(() => route.path === "/area")
    const isRailway = computed(() => route.path === "/railway")
    const stub = new QueryStubStore()
    const api = new QueryAPIStore()
    const repository = new QueryRepository(stub, api)
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    let polygonManager: PolygonManager | null = null
    const gMap = ref()
    const state = reactive<{
      isMaxZoom: boolean,
      map: any,
      heatmapLayer: any,
      lineLayer: any,
      center: any,
      isCenterLoaded: boolean,
      initMap: boolean,
      isLoadingRoad: boolean
    }>({
      isMaxZoom: true,
      map: null,
      heatmapLayer: null,
      lineLayer: null,
      center: store.state.defaultCenter ? store.state.defaultCenter : { lat: 36.7018311, lng: 137.2104371 },
      isCenterLoaded: false,
      initMap: false,
      isLoadingRoad: false
    })
    const mapStyle = [{
      stylers: [{ saturation: -100 }],
    }]
    const defaultZoom = computed(() => store.state.defaultZoom)
    const maxZoom = computed(() => {
      if (route.path === "/road" && store.getters['hasQueries']) {
        const max = store.state.heatmapMaxZoom || 16
        if (state.map) {
          if (state.map.getZoom() > max) {
            state.map.setZoom(max)
          }
        }
        return max
      }

      if (store.getters['prefectureMaster/selectedCities'].length > 0) {
        const max = store.state.area.polygonMaxZoom || 10
        if (state.map) {
          if (state.map.getZoom() > max) {
            state.map.setZoom(max)
          }
        }
        return max
      }
      return 18
    })
    localStorage.setItem('appUrl', window.location.href.split('#')[0] + '#')
    const deckOverlay = new DeckOverlay({
      onAfterRender: () => store.dispatch(ActionType.setAppLoading, false),
    })

    // #region emitter
    emitter?.on("placeSearch", (keyword: string) => {
      store.dispatch(ActionType.setAppLoading, true)
      const request = {
        query: keyword,
        fields: ["name", "geometry"],
      };

      const service = new window.google.maps.places.PlacesService(state.map);
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          const geometry = results[0].geometry
          if (geometry !== undefined) {
            if (geometry.location?.lat() !== undefined && geometry.location.lng() !== undefined) {
              const pos = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng(),
              }
              state.map.setCenter(pos)
            }
          }
        }
        store.dispatch(ActionType.setAppLoading, false)
      })
    })

    emitter?.on('newPolygon', (status: boolean) => {
      if (status) {
        polygonManager?.startNew()
      }
    })

    emitter?.on('eagerSearch', (status: boolean) => {
      if (status) {
        store.dispatch(ActionType.touchFullDisplay, true)
        const bounds = state.map.getBounds()
        const NECorner = bounds.getNorthEast()
        const SWCorner = bounds.getSouthWest()
        const NWCorner = new window.google.maps.LatLng(NECorner.lat(), SWCorner.lng())
        const SECorner = new window.google.maps.LatLng(SWCorner.lat(), NECorner.lng())

        // ポリゴンをstoreに設定
        store.dispatch(
            ActionType.setPolygon,
            [SWCorner, NWCorner, NECorner, SECorner, SWCorner].map(p => {
              return {latitude: p.lat(), longitude: p.lng()}
            })
        )

        const request: IQueryRequestType = {
          polygon: [SWCorner, NWCorner, NECorner, SECorner, SWCorner].map(p => {
            return {
              latitude: p.lat(),
              longitude: p.lng()
            }
          })
        }

        const roadRequest: IRoadRequestType = {
          polygon: [SWCorner, NWCorner, NECorner, SECorner, SWCorner].map(p => {
            return {
              latitude: p.lat(),
              longitude: p.lng()
            }
          })
        }
        store.dispatch(ActionType.setAppLoading, true)
        store.dispatch(ActionType.setHasSearch,true)
        repository.fetch(request)
        .then(response => store.dispatch(ActionType.setQueries, response))
        .then(() => getRoadMaster(roadRequest))
        .catch(err => {
          if(err.status === 401) {
            // retry only once
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetch(request))
            .catch(err => {
              if (err.status === 409) {
                WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
              }
              if (err.status === 424) {
                WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
              }
            })
            .then(response => store.dispatch(ActionType.setQueries, response))
            .then(() => getRoadMaster(roadRequest))
          } else if (err.status === 409) {
            WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
          } else if (err.status === 424) {
            WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
        })
      }
    })
    window.addEventListener('load', () => store.dispatch(ActionType.setAppLoading, false))
    emitter?.on('drawPolygon', () => {
      polygonManager?.start()
      store.dispatch(ActionType.touchPolygon, true)
    })
    emitter?.on("selectPolygonRange", (status: boolean) => {
      if (status) {
        const request: IQueryRequestType = {
          polygon: store.state.polygon
        }

        const roadRequest: IRoadRequestType = {
          polygon: store.state.polygon
        }

        store.dispatch(ActionType.setAppLoading, true)
        repository.fetch(request)
        .then(response => store.dispatch(ActionType.setQueries, response))
        .then(() => getRoadMaster(roadRequest))
        .catch(err => {
          if(err.status === 401) {
            // retry only once
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetch(request))
            .catch(err => {
              if (err.status === 409) {
                WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
              }
              if (err.status === 424) {
                WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
              }
            })
            .then(() => getRoadMaster(roadRequest))
            .then(response => store.dispatch(ActionType.setQueries, response))
          } else if (err.status === 409) {
            WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
          } else if (err.status === 424) {
            WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
        })
      }
    })
    const onChangeMapPosition = () => {
      store.dispatch(
          ActionType.setMapPos,
          {
            lat: state.map.getCenter().lat(),
            lng: state.map.getCenter().lng()
          })
      const latLng = {
        lat: state.map.getCenter().lat(),
        lng: state.map.getCenter().lng()
      };
      localStorage.setItem('zoom', state.map.zoom)
      localStorage.setItem('latlng', JSON.stringify(latLng));
    }

    emitter?.on('clearBackPolygon', () => {
      polygonManager?.clearNewPolygon()
    });

    emitter?.on('clear', () => {
      polygonManager?.cancel()
      deckOverlay.setProps({ layers: [] })
      store.dispatch(ActionType.touchFullDisplay, false)
      store.dispatch(ActionType.touchPolygon, false)
    })
    emitter?.on('allClear', () => {
      window.location.reload()
    })
    emitter?.on('escArea', () => {
      polygonManager?.cancel()
      polygonManager?.start()
    })
    // #endregion

    // Road master
    const getRoadMaster = (request: IRoadRequestType) => {
      const stubRoad = new RoadStubStore()
      const apiRoad = new RoadAPIStore()
      const reposRoad = new RoadRepository(stubRoad, apiRoad)
      state.isLoadingRoad = true
      reposRoad.fetch(request).then(
          (response) => {
            store.dispatch(ActionType.setRoadMaster, response)
            if (response.length === 0) {
              WarningDialog(EMPTY_ROAD_ERROR_TITLE, EMPTY_ROAD_ERROR_MESSAGE)
            }
          },
          (error) => {
            if (error.status === 401) {
              return authRepository.refreshToken()
                .then(response => setAccessToken(response.access))
                .then(() => {return reposRoad.fetch(request)})
                .then((response) => {
                  store.dispatch(ActionType.setRoadMaster, response)
                  if (response.length === 0) {
                    WarningDialog(EMPTY_ROAD_ERROR_TITLE, EMPTY_ROAD_ERROR_MESSAGE)
                  }
                });
            }
          }
      ).finally(() => state.isLoadingRoad = false)
    }

    // #region watch
    watch(() => store.getters['prefectureMaster/selectedCities'], (cities: ICityType[]) => {
      const constraints = cities.map(city => city.id + '-' + city.name)
      const prefs = store.getters['prefectureMaster/hasCitySelectedPref']
      const displayConstraints = prefs.map(pref => pref.cities.map(city => city.id + '-' + city.name)).flat()
      const polygons = store.state.areaPolygonMaster.polygons
      .filter(p => displayConstraints.includes(p.id + '-' + p.name))
      .map(p => p.polygon.map(poly => ({ isSelected: constraints.includes(p.id + '-' + p.name), polygon: poly, name: p.name, id: p.id })))
      .flat()
      const sortedPolygons = _.orderBy(polygons, ['isSelected'])
      deckOverlay.setProps({
        layers: [polygonFactory(sortedPolygons, undefined, undefined, (info) => {
          store.dispatch(`prefectureMaster/${PrefectureMasterActionType.toggleSelected}`, info.object.name)
        })]
      })
    })

    watch(() => store.state.defaultCenter, (center) => {
      if (state.map) {
        state.map.setCenter(center)
      }
    })

    watch(() => store.state.queries, (queries: Array<Array<number>>) => {
      polygonManager?.lock()
      polygonManager?.invalidate()
      if (queries.length > 0) {
        let minValue = store.getters['searchHeatmaps'][0].weight
        let maxValue = store.getters['searchHeatmaps'].slice(-1)[0].weight
        let hm = new HeatmapLayer({
          data: store.getters['searchHeatmaps'],
          visible: true,
          getPosition: (d) => d.coordinates,
          getWeight: (d) => d.weight,
          intensity: Config.Instance.heatmapTopProps.intensity,
          opacity: Config.Instance.heatmapTopProps.opacity,
          threshold: Config.Instance.heatmapTopProps.threshold,
          radiusPixels: Config.Instance.heatmapTopProps.radiusPixels,
          colorDomain: [minValue, maxValue],
          colorRange: Config.Instance.heatmapColors.rgb,
          aggregation: "SUM",
        })
        const layers:any = [];
        layers.push(hm);
        if (state.lineLayer !== null) {
          layers.push(state.lineLayer);
        }
        deckOverlay.setProps({ layers: layers })
        state.heatmapLayer = hm;
        store.dispatch(ActionType.setShowHeatmap, true)
        polygonManager?.hideInfo()
      } else {
        const layers:any = [];
        if (state.lineLayer !== null) {
          layers.push(state.lineLayer);
        }
        deckOverlay.setProps({ layers: layers })
        // polygonManager?.clear()
        state.heatmapLayer = null;
        store.dispatch(ActionType.setShowHeatmap, false)
      }
    })

    // make line data
    watch(() => store.getters['selectedRoad'], (roads) => {
      const layers: LineLayer[] = []
      roads.forEach((road) => {
        for (let prop in road.polygon) {
          const data: any = [];
          for (let i = 0; i < road.polygon[prop].length - 1; i++) {
            data.push({
              from: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i].latitude), Number(road.polygon[prop][i].longitude)]
              },
              to: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i + 1].latitude), Number(road.polygon[prop][i + 1].longitude)]
              }
            })
          }
          const layer = new LineLayer({
            id: uuidv4(),
            data,
            getWidth: 10,
            getSourcePosition: d => [d.from.coordinates[1], d.from.coordinates[0]],
            getTargetPosition: d => [d.to.coordinates[1], d.to.coordinates[0]],
            getColor: () => [51, 74, 124, 153]
          });
          layers.push(layer);
        }
      })
      if (state.heatmapLayer !== null) {
        layers.unshift(state.heatmapLayer);
      }
      deckOverlay.setProps({ layers: layers })
    })
    // #endregion

    // #region callback
    onBeforeUnmount(() => {
      if (emitter) {
        emitter?.off('*')
      }
      if (state.map) {
        window.google.maps.event.clearListeners(state.map, 'zoom_changed')
      }
    })
    onMounted(() => window.dispatchEvent(new Event('load')))
    // #endregion

    // #region handler
    const handleZoomIn = () => state.map.setZoom(state.map.getZoom() + 1)
    const handleZoomOut = () => state.map.setZoom(state.map.getZoom() - 1)
    const handleCurrentLocation = (init = false) => {
      store.dispatch(ActionType.setAppLoading, true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          state.map.setCenter(pos)

          // const storage = localStorage.getItem('latlng')
          // if (storage) {
          //   const latLng = JSON.parse(storage)
          //   store.dispatch(ActionType.setAppLoading, false)
          //   if (latLng) {
          //     state.map.setCenter(latLng)
          //   } else {
          //     state.map.setCenter(pos)
          //   }
          // } else {
          //   state.map.setCenter(pos)
          // }

          store.dispatch(ActionType.setMapPos, pos)
        }, (e) => {
          if (e.code === 1 || e.code === 2) {
            AlertDialog(LOCATION_TURN_OFF)
          } else {
            AlertDialog(LOCATION_NOT_FOUND)
          }
          store.dispatch(ActionType.setAppLoading, false)
        })
      }
    }

    // #endregion
    store.dispatch(ActionType.setAppLoading, true)
    const onLoad = () => {
      if (state.initMap) {
        return;
      }
      state.initMap = true;
      state.map = gMap.value.map
      state.isMaxZoom = state.map.maxZoom === state.map.getZoom()
      deckOverlay.setMap(gMap.value.map)
      polygonManager = new PolygonManager()
      polygonManager.assignMap(state.map)

      localStorage.setItem('zoom', defaultZoom.value)
      state.map.addListener('zoom_changed', () => {
        localStorage.setItem('zoom', state.map.zoom)
      })

      polygonManager.emitter.on('polygon', (polygons) => store.dispatch(ActionType.setPolygon, polygons))
      polygonManager.emitter.on('newPolygon', (polygons) => store.dispatch(ActionType.setNewPolygon, polygons))
      polygonManager.emitter.on('search', () => {
        WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
          const request: IQueryRequestType = {
            polygon: store.state.polygon
          }

          const roadRequest: IRoadRequestType = {
            polygon: store.state.polygon
          }

          store.dispatch(ActionType.setAppLoading, true)
          store.dispatch(ActionType.setHasSearch, true)
          repository.fetch(request)
          .then(response => store.dispatch(ActionType.setQueries, response))
          .then(() => getRoadMaster(roadRequest))
          .catch(err => {
            if(err.status === 401) {
              // retry only once
              return authRepository.refreshToken()
              .then(response => setAccessToken(response.access))
              .then(() => repository.fetch(request))
              .catch(err => {
                if (err.status === 409) {
                  WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
                }
                if (err.status === 424) {
                  WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
                }
              })
              .then(() => getRoadMaster(roadRequest))
              .then(response => store.dispatch(ActionType.setQueries, response))
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
          .finally(() => {
            store.dispatch(ActionType.setAppLoading, false)
          })
        })
      })

      if (!state.isCenterLoaded && navigator.geolocation) {
        state.map.addListener('bounds_changed', () => {
          if (!state.isCenterLoaded) {
            store.dispatch(ActionType.setAppLoading, false)
            state.isCenterLoaded = true
            window.google.maps.event.clearListeners(state.map, 'bounds_changed')
          }
        })
        // handleCurrentLocation()
      }

      state.map.setCenter(store.state.defaultCenter)
    }

    return {
      ...toRefs(state),
      apiKey,
      mapStyle,
      gMap,
      isHome,
      isRoad,
      isArea,
      maxZoom,
      isRailway,
      defaultZoom,
      onChangeMapPosition,
      onLoad,
      handleZoomIn,
      handleZoomOut,
      handleCurrentLocation
    };
  },
});
