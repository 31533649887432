import { DayCode } from '@/enum/barchart'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { DaySeriesMutationType } from './mutations'
import { DaySeriesState } from './type'
import {IRoadType} from "@/data/road/type";
import store, {ActionType} from "@/store";
import {IAnimationDaysRequestType} from "@/data/animationday/type";
import AnimationDayStubStore from "@/data/animationday/store/stub";
import AnimationDayAPIStore from "@/data/animationday/store";
import AnimationDayRepository from "@/data/animationday/repository";
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import {getSelectedRoad, setAccessToken} from "@/utils/storage";
import {AlertDialog, WarningDialog} from "@/service/dialog";
import {Config} from "@/config";
import {ICoordinateType} from "@/types";
import {
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE, MAX_THRESHOLD_MESSAGE, MAX_THRESHOLD_TITLE,
  PROCESS_CANCELED_MESSAGE,
  PROCESS_CANCELED_TITLE
} from "@/constant";

export enum DaySeriesActionType {
  setDayConstraint = 'setDayConstraint',
  setDayFilter = 'setDayFilter',
  setActiveTime = 'setActiveTime',
  setDoInitFlg = 'setDoInitFlg',
  setTimeLabel = 'setTimeLabel',
  prepareAnimationDay = 'prepareAnimationDay',
}

export const actions: ActionTree<DaySeriesState, RootState> = {
  [DaySeriesActionType.setTimeLabel] ({ commit }, payload: string): void {
    commit(DaySeriesMutationType.SET_TIME_LABEL, payload)
  },
  [DaySeriesActionType.setDayFilter] ({ commit }, payload: DayCode[]): void {
    commit(DaySeriesMutationType.SET_DAY_FILTER, payload)
  },
  [DaySeriesActionType.setDayConstraint] ({ commit }, payload: DayCode[]): void {
    commit(DaySeriesMutationType.SET_DAY_CONSTRAINT, payload)
  },
  [DaySeriesActionType.setActiveTime] ({ commit }, payload: string): void {
    commit(DaySeriesMutationType.SET_ACTIVE_TIME, payload)
  },
  [DaySeriesActionType.setDoInitFlg] ({ commit }, payload: boolean): void {
    commit(DaySeriesMutationType.SET_DO_INIT_FLG, payload)
  },
  [DaySeriesActionType.prepareAnimationDay] ({ rootGetters, dispatch }): Promise<any> {
    const hour: Array<number> = [];
    for (let i = 0; i < 24; i++) { hour.push(i); }
    let roadIds: string[] = [];
    let selectedRoad: IRoadType[] = rootGetters['selectedRoad'];
    if (selectedRoad && selectedRoad.length === 0) {
      selectedRoad = getSelectedRoad();
    }
    selectedRoad.forEach((road) => {
      roadIds = roadIds.concat(road.id)
    })

    const reSelectpolygon: ICoordinateType[] = store.getters['getNewPolygon']
    const polygons = store.getters['getPolygon']

    const request: IAnimationDaysRequestType = {
      road_ids: roadIds,
      unit: Config.Instance.makeTableUnit,
      create: true,
      week: [],
      holiday_flag: true,
      except_target_area: 0,
      polygon: reSelectpolygon.length > 0 ? reSelectpolygon : polygons,
    }
    const stub = new AnimationDayStubStore()
    const api = new AnimationDayAPIStore()
    const repository = new AnimationDayRepository(stub, api)
    return repository.fetch(request)
        .then(response => {
          dispatch(ActionType.setAnimationDays, response, {root:true})
          return true;
        })
        .catch(err => {
          if (err.status === 401) {
            const authApi = new AuthAPIStore()
            const authRepository = new AuthRepository(authApi)
            return authRepository.refreshToken()
                .then(response => setAccessToken(response.access))
                .then(() => repository.fetch(request))
                .then(response => {
                  dispatch(ActionType.setAnimationDays, response, {root:true})
                  return true;
                  //return store.dispatch(ActionType.setQueries, response)
                })
          } else if (err.status === 424) {
            WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else if (err.status === 409) {
            WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
          } else {
            AlertDialog(err.message)
            return false;
          }
        })
        .finally(() => {
          // 読み込み完了
          dispatch(`daySeries/${DaySeriesActionType.setDoInitFlg}`, false, {root: true});
        })

  }
}
