import {ActionTree} from 'vuex'
import {PrefectureMasterState} from '@/store/modules/area/master/prefecture/type'
import {RootState} from '@/store/types'
import {ICityType, IPrefectureType} from '@/data/master/area/prefecture/type'
import {PrefectureMasterMutationType} from '@/store/modules/area/master/prefecture/mutations'

export enum PrefectureMasterActionType {
  setPrefectures = 'setPrefectures',
  setCollapsed = 'setCollapsed',
  clearSelected = 'clearSelected',
  toggleSelected = 'toggleSelected',
  setSelected = 'setSelected',
  selectAllCities = 'selectAllCities',
  unselectAllCities = 'unselectAllCities'
}

export const actions: ActionTree<PrefectureMasterState, RootState> = {
  [PrefectureMasterActionType.setPrefectures] ({ commit }, payload: IPrefectureType[]): void {
    commit(PrefectureMasterMutationType.SET_PREFECTURES, payload)
  },
  [PrefectureMasterActionType.setCollapsed] ({ commit }, payload: { name: string; isCollapsed: boolean }): void {
    commit(PrefectureMasterMutationType.SET_COLLAPSED, payload)
  },
  [PrefectureMasterActionType.clearSelected] ({ commit }): void {
    commit(PrefectureMasterMutationType.CLEAR_SELECTED)
  },
  [PrefectureMasterActionType.toggleSelected] ({ commit }, payload: string): void {
    commit(PrefectureMasterMutationType.TOGGLE_SELECTED, payload)
  },
  [PrefectureMasterActionType.selectAllCities] ({ commit }, payload: IPrefectureType): void {
    commit(PrefectureMasterMutationType.SELECT_ALL_CITIES, payload)
  },
  [PrefectureMasterActionType.unselectAllCities] ({ commit }, payload: IPrefectureType): void {
    commit(PrefectureMasterMutationType.UNSELECT_ALL_CITIES, payload)
  },
  [PrefectureMasterActionType.setSelected] ({ commit }, payload: ICityType[]): void {
    commit(PrefectureMasterMutationType.SET_SELECTED, payload)
  }
}
