import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "jr-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      class: "jr-w-full jr-bg-gray-light jr-appearance-none jr-border jr-border-gray-medium jr-rounded jr-py-4 jr-px-4 jr-leading-tight jr-text-black focus:jr-outline-none",
      name: `input_${_ctx.id}`,
      type: _ctx.type,
      readonly: _ctx.readonly,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onInput: _ctx.onInput,
      rules: _ctx.rules
    }, null, 8, ["name", "type", "readonly", "value", "placeholder", "onInput", "rules"]),
    _createVNode(_component_ErrorMessage, {
      name: `input_${_ctx.id}`,
      class: "jr-absolute jr-left-0 jr--bottom-5 jr-text-xs jr-text-red"
    }, null, 8, ["name"])
  ]))
}