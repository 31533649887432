import {IPersonaType} from '@/data/master/area/persona/type'
import PersonaMasterJsonTranslator from '@/data/master/area/persona/translator'

export interface IPersonaMasterStubType {
  fetch(): Promise<IPersonaType[]>
}

export default class PersonaStubStore implements IPersonaMasterStubType {
  protected translator: PersonaMasterJsonTranslator
  constructor() {
    this.translator = new PersonaMasterJsonTranslator()
  }
  
  fetch(): Promise<IPersonaType[]> {
    return new Promise<IPersonaType[]>((resolve) => {
      resolve([])
    })
  }
}
