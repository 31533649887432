
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs, watch} from 'vue'
import {useStore} from 'vuex'
import {ActionType} from '@/store'
import {
  CONTENT_DOWNLOAD_ELEMENT_ID,
  NO_CONDITION_SPECIFIED,
  AREA_WARNING_TITLE,
  AREA_WARNING_MESSAGE,
  AREA_NO_WEEK,
  AREA_NO_TRANSPORTATION,
  AREA_NO_FREQUENCY,
  AREA_NO_ATTRIBUTE,
  MAX_THRESHOLD_TITLE,
  MAX_THRESHOLD_MESSAGE
} from '@/constant'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {polygonFactory} from '@/layers/polygon'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {DOWNLOAD_AREA_RANKING_REPORT, HINT_AREA_RANKING, globalEmitter} from '@/service/GlobalEmmiter'
import {ConvertToImage} from '@/service/file'
import {AreaRankingActionType} from '@/store/modules/area/search/ranking/actions'
import {setAccessToken} from '@/utils/storage'
import {Emitter, EventType} from 'mitt'
import {AreaPolygonMasterActionType} from '@/store/modules/area/master/polygon/actions'
import {useRoute} from 'vue-router'
import RankingColors from '@/components/UI/area/RankingColors.vue'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AreaPolygonMasterApi from '@/data/master/area/polygon/store'
import AreaPolygonMasterRepository from '@/data/master/area/polygon/repository'
import AppLoader from '@/components/AppLoader.vue'
import _ from 'lodash'
import {HintDialog} from '@/service/dialog'
import AreaRanking from '@/components/hints/AreaRanking.vue'

export enum TabType {
  ResidencePlace,
  WorkLocation
}

interface State {
  activeTab: TabType
  map: any
  hoveringCity?: string
  filterMinimum: boolean
  captureClip: boolean
}

export default defineComponent({
  name: 'AreaRanking',
  components: {
    AppLoader,
    AreaSearchFilter,
    UIGoogleMap,
    RankingColors
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const state = reactive<State>({
      activeTab: TabType.ResidencePlace,
      map: null,
      hoveringCity: undefined,
      filterMinimum: false,
      captureClip: false
    })
    const polygonMaxZoom = computed(() => store.state.area.polygonMaxZoom || 10)
    const isRanking = computed(() => route.path === '/area_report/ranking')
    const isLoading = computed(() => store.state.areaRanking.isLoading)
    const showColors = computed(() => store.state.areaRanking.min !== store.state.areaRanking.max)
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const polygons = computed(() => store.state.areaPolygonMaster.polygons)
    const min = store.state.areaRanking.min
    const max = store.state.areaRanking.max
    const residents = computed(() => store.state.areaRanking.resident)
    const workLocations = computed(() => store.state.areaRanking.workLocation)
    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })
    const polygonOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })

    // data layer instances
    const stub = new AreaStubStore()
    const api = new AreaAPIStore()
    const repository = new AreaRepository(stub, api)
    const areaPolygonMasterApi = new AreaPolygonMasterApi()
    const areaPolygonMasterRepository = new AreaPolygonMasterRepository(areaPolygonMasterApi)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const makeAPICall = async (params: Record<string, any>) => {
      store.dispatch(`areaRanking/${AreaRankingActionType.setMin}`, 0)
      store.dispatch(`areaRanking/${AreaRankingActionType.setMax}`, 0)
      store.dispatch(`areaRanking/${AreaRankingActionType.setResident}`, [])
      store.dispatch(`areaRanking/${AreaRankingActionType.setWorkLocation}`, [])
      store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setRankingPolygon}`, [])

      let streams = [Promise.resolve(polygons.value), repository.fetchRanking(params)]
      if (polygons.value.length === 0) {
        streams[0] = areaPolygonMasterRepository.fetch({})
      }
      store.dispatch(`areaRanking/${AreaRankingActionType.setLoading}`, true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Promise.all(streams).then(([polygons, ranking]) => {
        const targetRanking = state.activeTab === TabType.WorkLocation ? ranking.work_location : ranking.resident
        const rankingPolygons = targetRanking.map(r => {
          const tp = polygons.find(p => p.name === r.polygon)
          if (tp) {
            return tp.polygon.map(p => {
              return p.map(poly => {
                return {
                  name: r.name,
                  ranking: r.rank,
                  polygon: poly
                }
              })
            }).flat()
          }
          return null
        })
        .filter(p => p)
        .flat()

        store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setRankingPolygon}`, rankingPolygons)
        store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setAreaPolygonMaster}`, polygons)
        store.dispatch(`areaRanking/${AreaRankingActionType.setMin}`, ranking.min)
        store.dispatch(`areaRanking/${AreaRankingActionType.setMax}`, ranking.max)
        store.dispatch(`areaRanking/${AreaRankingActionType.setResident}`, ranking.resident)
        store.dispatch(`areaRanking/${AreaRankingActionType.setWorkLocation}`, ranking.work_location)
      }).catch(err => {
        if (isRanking.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              streams = [Promise.resolve(polygons.value), repository.fetchRanking(params)]
              if (polygons.value.length === 0) {
                streams[0] = areaPolygonMasterRepository.fetch({})
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              return Promise.all(streams).then(([polygons, ranking]) => {
                const targetRanking = state.activeTab === TabType.WorkLocation ? ranking.work_location : ranking.resident
                const rankingPolygons = targetRanking.map(r => {
                  const tp = polygons.find(p => p.name === r.polygon)
                  if (tp) {
                    return tp.polygon.map(p => {
                      return p.map(poly => {
                        return {
                          name: r.name,
                          ranking: r.rank,
                          polygon: poly
                        }
                      })
                    }).flat()
                  }
                  return null
                })
                .filter(p => p)
                .flat()

                store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setRankingPolygon}`, rankingPolygons)
                store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setAreaPolygonMaster}`, polygons)
                store.dispatch(`areaRanking/${AreaRankingActionType.setMin}`, ranking.min)
                store.dispatch(`areaRanking/${AreaRankingActionType.setMax}`, ranking.max)
                store.dispatch(`areaRanking/${AreaRankingActionType.setResident}`, ranking.resident)
                store.dispatch(`areaRanking/${AreaRankingActionType.setWorkLocation}`, ranking.work_location)
              }).catch(err => {
                if (err.status === 404) {
                  WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
                } else if (err.status === 416) {
                  WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
                } else {
                  AlertDialog(err.message)
                }
              })
            })
          } else if (err.status === 404) {
            WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => {
        store.dispatch(`areaRanking/${AreaRankingActionType.setLoading}`, false)
        emitter?.emit('notifyAreaFilter')
      })
    }
    const params = store.getters['area/getAPIParams']
    delete params.except_target_area
    if (params.week && params.week.length > 0) {
      params.week = params.week[0]
    }
    makeAPICall(params)

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(state.map)
      polygonOverlay.setMap(state.map)
    }
    const handleTabChange = (tab: TabType) => {
      state.activeTab = tab
      const polygons = store.state.areaPolygonMaster.polygons
      const resident = store.state.areaRanking.resident
      const workLocation = store.state.areaRanking.workLocation
      const targetRanking = state.activeTab === TabType.WorkLocation ? workLocation : resident

      const rankingPolygons = targetRanking.map(r => {
        const tp = polygons.find(p => p.name === r.polygon)
        if (tp) {
          return tp.polygon.map(p => {
            return p.map(poly => {
              return {
                name: r.name,
                ranking: r.rank,
                polygon: poly
              }
            })
          }).flat()
        }
        return null
      })
      .filter(p => p)
      .flat()
      store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setRankingPolygon}`, rankingPolygons)
    }
    const handleHoverCity = (value: string | undefined) => state.hoveringCity = value
    const handleSearch = (params: Record<string, any>) => {
      // user didn't specify any filter condition
      const trans = params.type && params.type.length === 0
      const freq = params.frequencies && params.frequencies.length === 0
      const attr = params.visitor_attribute && params.visitor_attribute.length === 0
      const week = params.week && params.week.length === 0
      delete params.except_target_area

      if (trans && attr && week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (trans) {
        AlertDialog(AREA_NO_TRANSPORTATION)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (attr) {
        AlertDialog(AREA_NO_ATTRIBUTE)
        return
      }

      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      makeAPICall(params)
    }

    // watchers
    watch(() => store.state.areaPolygonMaster.rankingPolygons, (polygons) => {
      deckOverlay.setProps({
        layers: [
          polygonFactory(
            polygons,
            store.getters['areaRanking/colors']
          )
        ]
      })
    })
    watch(() => state.hoveringCity, (city: string | undefined) => {
      const targetPolygons = store.state.areaPolygonMaster.rankingPolygons
      const markedPolygons = targetPolygons.map(p => {
        return { ...p, isSelected: city === p.name }
      })
      const sortedPolygons = _.orderBy(markedPolygons, ['isSelected'], ['asc'])
      deckOverlay.setProps({
        layers: [polygonFactory(sortedPolygons, store.getters['areaRanking/colors'], undefined, undefined, [239, 92, 51, 255])]
      })
    })

    globalEmitter.on(DOWNLOAD_AREA_RANKING_REPORT, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_AREA_RANKING, () => {
      HintDialog(AreaRanking)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_RANKING_REPORT)
      globalEmitter.off(HINT_AREA_RANKING)
    })
    return {
      ...toRefs(state),
      sbMinimum,
      isLoading,
      handleMapLoad,
      handleHoverCity,
      handleSearch,
      handleTabChange,
      handleFilterResize,
      TabType,
      min,
      max,
      residents,
      workLocations,
      polygonMaxZoom,
      showColors
    }
  }
})
