import {ActionTree} from 'vuex'
import {AreaState} from '@/store/modules/area/type'
import {RootState} from '@/store/types'
import {IFacilityType, IRankingType, ITimeSpentType, IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'
import {AreaMutationType} from '@/store/modules/area/mutations'
import {CheckboxModel} from '@/components/subviews/area/AreaSearchFilter.vue'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'

export enum AreaActionType {
  toggleSidebar = 'toggleSidebar',
  setFacilities = 'SetFacilities',
  selectAllFacilities = 'selectAllFacilities',
  unselectAllFacilities = 'unselectAllFacilities',
  setTimeRange = 'setTimeRange',
  setExcept = 'setExcept',
  setWeek = 'setWeek',
  setTransportations = 'setTransportations',
  setFrequencies = 'setFrequencies',
  setAttributes = 'setAttributes',
  setSelectPage = 'setSelectPage',
  setIsDetailLoaded = 'setIsDetailLoaded',
  setPolygonMaxZoom = 'setPolygonMaxZoom',
  
  // for dashboard display
  setDBRanking = 'setDBRanking',
  setDBTimeSpent = 'setDBTimeSpent',
  setAverage = 'setAverage',
  setDBTransportation = 'setDBTransportation',
  setDBVisitor = 'setDBVisitor',
  setDBFacility = 'setDBFacility',
  setLessRanking = 'setLessRanking',
  setLessTransportation = 'setLessTransportation',
  setLessFacility = 'setLessFacility',
  setLessTimeSpent = 'setLessTimeSpent',
  setLessVisitor = 'setLessVisitor'
}

export const actions: ActionTree<AreaState, RootState> = {
  [AreaActionType.toggleSidebar] ({ commit }): void {
    commit(AreaMutationType.TOGGLE_SIDEBAR)
  },
  [AreaActionType.setFacilities] ({ commit }, payload: IFacilityType[]): void {
    commit(AreaMutationType.SET_FACILITIES, payload)
  },
  [AreaActionType.selectAllFacilities] ({ commit }): void {
    commit(AreaMutationType.SELECT_ALL_FACILITIES)
  },
  [AreaActionType.unselectAllFacilities] ({ commit }): void {
    commit(AreaMutationType.UNSELECT_ALL_FACILITIES)
  },
  [AreaActionType.setExcept] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_EXCEPT, payload)
  },
  [AreaActionType.setTimeRange] ({ commit }, payload: number[]): void {
    commit(AreaMutationType.SET_TIME_RANGE, payload)
  },
  [AreaActionType.setWeek] ({ commit }, payload: CheckboxModel[]): void {
    commit(AreaMutationType.SET_WEEK, payload)
  },
  [AreaActionType.setTransportations] ({ commit }, payload: CheckboxModel[]): void {
    commit(AreaMutationType.SET_TRANSPORTATIONS, payload)
  },
  [AreaActionType.setFrequencies] ({ commit }, payload: CheckboxModel[]): void {
    commit(AreaMutationType.SET_FREQUENCIES, payload)
  },
  [AreaActionType.setAttributes] ({ commit }, payload: CheckboxModel[]): void {
    commit(AreaMutationType.SET_ATTRIBUTES, payload)
  },
  [AreaActionType.setSelectPage] ({ commit }, payload: string): void {
    commit(AreaMutationType.SET_SELECT_PAGE, payload)
  },
  [AreaActionType.setDBRanking] ({ commit }, payload: {resident: IRankingType[]; workLocation: IRankingType[]}): void {
    commit(AreaMutationType.SET_DB_RANKING, payload)
  },
  [AreaActionType.setDBTimeSpent] ({ commit }, payload: ITimeSpentType[]): void {
    commit(AreaMutationType.SET_DB_TIME_SPENT, payload)
  },
  [AreaActionType.setAverage] ({ commit }, payload: number): void {
    commit(AreaMutationType.SET_AVERAGE, payload)
  },
  [AreaActionType.setDBTransportation] ({ commit }, payload: {inArea: ITransportationChart[]; visitArea: ITransportationChart[]}): void {
    commit(AreaMutationType.SET_DB_TRANSPORTATION, payload)
  },
  [AreaActionType.setDBVisitor] ({ commit }, payload: {persona: IVisitorPersonaType[]; age: IVisitorAgeType}): void {
    commit(AreaMutationType.SET_DB_VISITOR, payload)
  },
  [AreaActionType.setDBFacility] ({ commit }, payload: Record<string, any>): void {
    commit(AreaMutationType.SET_DB_FACILITY, payload)
  },
  [AreaActionType.setLessRanking] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_LESS_RANKING, payload)
  },
  [AreaActionType.setLessTransportation] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_LESS_TRANSPORTATION, payload)
  },
  [AreaActionType.setLessFacility] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_LESS_FACILITY, payload)
  },
  [AreaActionType.setLessTimeSpent] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_LESS_TIME_SPENT, payload)
  },
  [AreaActionType.setLessVisitor] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_LESS_VISITOR, payload)
  },
  [AreaActionType.setIsDetailLoaded] ({ commit }, payload: boolean): void {
    commit(AreaMutationType.SET_IS_DETAIL_LOADED, payload)
  },
  [AreaActionType.setPolygonMaxZoom] ({ commit }, payload: number): void {
    commit(AreaMutationType.SET_POLYGON_MAX_ZOOM, payload)
  }
}

