<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">ヒートマップ</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>設定したエリア全体の来訪者の来訪同日中の行動をヒートマップで表示しています。</p>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">バーチャート</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>時間帯ごとのボリューム推移は1日のピーク時を100%とした割合で算出しています。</p>
        <div class="jr-pt-4">
          <p>いずれも集計は「日のべ人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合は2とカウント。</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
