
import { computed, defineComponent, onMounted, PropType, reactive, toRefs } from 'vue'
import { TimeSeriesType } from '@/enum/timeseries'
import { v4 as uuidv4 } from 'uuid'

/**
 * There are two type of time series slider in this application
 * 1. 24h range
 * 2. Time diff range
 */
export default defineComponent({
  name: "UITimeSeriesController",
  emits: ['change'],
  props: {
    mode: {
      type: Number as PropType<TimeSeriesType>,
      required: false,
      default: () => TimeSeriesType.Day,
      validator: (value: TimeSeriesType) => value === TimeSeriesType.Day || value === TimeSeriesType.Hour
    },
    from: { type: Number, required: false, validator: (value: number) => value >= 0 && value <= 24 },
    to: { type: Number, required: false, validator: (value: number) => value >= 0 && value <= 24 },
    center: { type: Number, required: false, validator: (value: number) => value >= 0 && value <= 24 },
    gap: { type: Number, required: false, default: 1 },
    frame: { type: Number, required: false, default: 0.07 },
    step: { type: Number, required: false, default: 1 },
    fixNum: { type: Number, required: false, default: 0 },
  },
  setup(props, { emit }) {
    const rangeId = `input_range_${uuidv4()}`
    const state = reactive({
      play: false,
      model: 0,
      min: 0,
      max: 0,
      framePerSec: 0
    })

    // input type=range seem to be not coming with input event by default
    onMounted(() => {
      let slider = document.getElementById(rangeId)
      var evObj = document.createEvent('Events')
      evObj.initEvent('input', true, false)
      slider?.dispatchEvent(evObj)
    })
    const handlePlay = () => {
      state.play = !state.play
      if (state.play) {
        if (state.model >= state.max) {
          state.model = state.min
        }
        window.requestAnimationFrame(loopAnimation)
      }
    }

    //const percentage = computed(() => ((state.model - state.min) / (state.max - state.min)) * 100)
    const percentage = computed(() => {
      const percentage = ((state.model - state.min) / (state.max - state.min)) * 100
      return Math.round((percentage/100) * props.step) / props.step * 100
    })

    // loop using requestAnimationFrame
    const loopAnimation = () => {
      if (state.play) {
        const id = window.requestAnimationFrame(loopAnimation)
        state.model = Number(state.model) + state.framePerSec
        if (state.model >= state.max) {
          window.cancelAnimationFrame(id)
          state.model = state.max
          state.play = false
        }
        handleChange()
      }
    }

    // set min & max & frame
    state.framePerSec = Number(props.frame)
    if (props.center !== undefined) {
      //state.model = Number(props.center)
      state.min = Number(props.center) - 1
      state.max = Number(props.center) + 1
      state.framePerSec = props.frame / 10
      state.model = state.min;
    } else {
      state.min = props.from !== undefined ? props.from : 0
      state.max = props.to !== undefined ? props.to : 0
    }
    const displayFrom = computed(() => {
      if (props.center !== undefined) return `-${props.gap}h`
      if (props.from !== undefined) return props.from
      return ''
    })
    const displayTo = computed(() => {
      if (props.center !== undefined) return `+${props.gap}h`
      if (props.to !== undefined) return props.to
      return ''
    })
    const handleChange = () => {
      const value = Number(state.model)
      emit('change', value)
    }
    //const displayModel = computed(() => Number(state.model).toFixed(2))
    const displayModel = computed(() => {
      let per = ((state.model - state.min) / (state.max - state.min));
      per = Math.round(per * props.step) / props.step;
      return (Number(per * (state.max - state.min)) + state.min).toFixed(props.fixNum)
    })

    const stepAry = computed(() => {
      const ary:number[] = [];
      const len = state.max - state.min;
      const stepNum = len / props.step;
      for(let i=0; i<=len; i+=stepNum) {
        ary.push(i/len);
      }
      return ary;
    })

    return {
      ...toRefs(state),
      handlePlay,
      handleChange,
      rangeId,
      percentage,
      displayFrom,
      displayTo,
      displayModel,
      stepAry,
    }
  },
})
