import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "jr-flex jr-w-content jr-items-center jr-rounded-md jr-z-10 jr-bg-white jr-px-3 jr-py-2" }
const _hoisted_2 = { class: "jr-flex jr-items-center" }
const _hoisted_3 = { class: "jr-text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: color.name,
          class: _normalizeClass(["jr-flex jr-items-center", { 'jr-mr-5': index < _ctx.colors.length - 1 }])
        }, [
          _createElementVNode("div", {
            class: "jr-w-3.5 jr-h-3.5 jr-mr-2.5",
            style: _normalizeStyle({ 'background-color': color.color })
          }, null, 4),
          _createElementVNode("p", _hoisted_3, _toDisplayString(color.name), 1)
        ], 2))
      }), 128))
    ])
  ], 512)), [
    [_vShow, _ctx.colors !== undefined]
  ])
}