
import {defineComponent, reactive, toRefs} from 'vue'
import {v4 as uuidv4} from 'uuid'

export default defineComponent({
  name: 'UIRoadToggle',
  emits: ['toggle'],
  setup (_, { emit }) {
    const id = uuidv4()
    const state = reactive<{value: boolean}>({
      value: false
    })
    const handleChange = (e) => {
      e.preventDefault()
      emit('toggle', state.value)
    }
    return {
      ...toRefs(state),
      id,
      handleChange
    }
  }
})
