
import {computed, defineComponent, onBeforeUnmount, reactive, toRefs, watch} from 'vue'
import {getSelectedRailwayLine, setAccessToken} from '@/utils/storage'
import {RailwayStationTab} from '@/enum/station'
import {useStore} from 'vuex'
import {RailwayActionType} from '@/store/modules/railway/actions'
import {AlertDialog, HintDialog} from '@/service/dialog'
import {DOWNLOAD_RAILWAY_STATION, globalEmitter, HINT_RAILWAY_STATION} from '@/service/GlobalEmmiter'
import {ActionType} from '@/store'
import {ConvertToImage} from '@/service/file'
import {useRoute} from 'vue-router'
import {CONTENT_DOWNLOAD_RAILWAY_STATION} from '@/constant'
import {RAILWAY_RESIDENTS} from '@/components/subviews/railway/RailwaySearchFilter.vue'
import AppLoader from '@/components/AppLoader.vue'
import RailwayStationSelector from "@/components/subviews/railway/RailwayStationSelector.vue";
import StationBarchart from '@/components/UI/railway/StationBarchart.vue'
import FilterNavigation from '@/components/subviews/FilterNavigation.vue'
import RailwayStubStore from '@/data/railway/store/stub'
import RailwayAPIStore from '@/data/railway/store'
import RailwayRepository from '@/data/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import RailwayStationBarChart from '@/components/hints/RailwayStationBarChart.vue'

interface State {
  isLoading: boolean
  minimum: boolean
  captureClip: boolean
}

export default defineComponent({
  name: 'RailwayStation',
  components: {
    FilterNavigation,
    StationBarchart,
    RailwayStationSelector,
    AppLoader
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      isLoading: false,
      minimum: false,
      captureClip: false
    })
    const selectedLine = getSelectedRailwayLine()
    const selectedRailwayLine = computed(() => {
      const line = getSelectedRailwayLine()
      const splitter = line.split('-')
      return store.state.railLine.chosenLine || splitter.length > 1 ? splitter[1] : ''
    })
    const selectedTab = computed(() => store.state.railway.stationTab)
    const stations = computed<string[]>(() => {
      const line = selectedLine.split('-')
      if (line.length > 1) {
        const target = store.state.railLine.lines.find(l => {
          return l.line_name === line[1] && l.company_name === line[0]
        })
        return target ? target.stations : []
      } else {
        return []
      }
    })
    const wrapperElementID = CONTENT_DOWNLOAD_RAILWAY_STATION
    const isCapturing = computed(() => store.state.isCapturing)

    // data layer instances
    const stub = new RailwayStubStore()
    const api = new RailwayAPIStore()
    const repository = new RailwayRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const name = store.state.railway.stationTab === RailwayStationTab.Resident ? '居住地' : '勤務地'
    const selectedResidents = RAILWAY_RESIDENTS.map(r => {
      r.isSelected = r.name === name;
      return r
    })
    store.dispatch(`railway/${RailwayActionType.setSelectedResident}`, selectedResidents)

    watch(() => store.state.railLine.lines, (lines) => {
      const line = selectedLine.split('-')
      let stations = []
      if (line.length > 1) {
        const target = lines.find(l => {
          return l.line_name === line[1] && l.company_name === line[0]
        })
        stations = target ? target.stations : []
      }

      const companyStation = getSelectedRailwayLine()
      const splitter = companyStation.split('-')
      if (splitter.length > 1) {
        load({
          labels: stations,
          company_name: splitter[0],
          line_name: splitter[1]
        })
      }
    })

    // load
    const load = (params: Record<string, any>) => {
      state.isLoading = true
      repository.fetchRailwayStationBarchart(params).then(res => {
        store.dispatch(`railway/${RailwayActionType.setRailwayStation}`, res)
      }).catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetchRailwayStationBarchart(params))
          .then((res) => store.dispatch(`railway/${RailwayActionType.setRailwayStation}`, res))
          .catch(err => AlertDialog(err.message))
        } else {
          AlertDialog(err.message)
        }
      }).finally(() => state.isLoading = false)
    }
    const handleMinimum = (value: boolean) => {
      state.minimum = value
    }
    const companyStation = getSelectedRailwayLine()
    const splitter = companyStation.split('-')
    if (stations.value.length === 0) {
      state.isLoading = true
    }
    if (splitter.length > 1 && stations.value.length > 0) {
      load({
        labels: stations.value,
        company_name: splitter[0],
        line_name: splitter[1]
      })
    }

    const handleTabChange = (tab: RailwayStationTab) => {
      const name = tab === RailwayStationTab.Resident ? '居住地' : '勤務地'
      const selectedResidents = RAILWAY_RESIDENTS.map(r => {
        r.isSelected = r.name === name;
        return r
      })
      store.dispatch(`railway/${RailwayActionType.setSelectedResident}`, selectedResidents)
      store.dispatch(`railway/${RailwayActionType.setRailwayStationTab}`, tab)
    }

    globalEmitter.on(DOWNLOAD_RAILWAY_STATION, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_RAILWAY_STATION, () => {
      HintDialog(RailwayStationBarChart)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_RAILWAY_STATION)
      globalEmitter.off(HINT_RAILWAY_STATION)
    })
    return {
      ...toRefs(state),
      RailwayStationTab,
      selectedTab,
      handleMinimum,
      isCapturing,
      selectedRailwayLine,
      handleTabChange
    }
  }
})
