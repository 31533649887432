
import { GoogleMap, CustomControl } from 'vue3-google-map'
import AppLoader from '@/components/AppLoader.vue'
import {defineComponent, ref, reactive, toRefs, computed, onBeforeUnmount} from 'vue'
import ContentWrapper from '@/components/ContentWrapper.vue'
import {ActionType} from '@/store'
import { ButtonType } from '@/enum/button'
import UITimeSeriesController from '@/components/UI/UITimeSeriesController.vue'
import UITimeSlider from "@/components/UI/UITimeSlider.vue";
import { useStore } from 'vuex'
import GeolocationPosition from '@google/maps'
import FilterWrapper from "@/components/FilterWrapper.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UICheckboxFilter from "@/components/UI/UICheckboxFilter.vue";
import {ConvertToImage} from "@/service/file";
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  PROCESS_CANCELED_TITLE,
  PROCESS_CANCELED_MESSAGE
} from "@/constant";
import AnimationHourStubStore from "@/data/animationhour/store/stub";
import AnimationHourAPIStore from "@/data/animationhour/store";
import AnimationHourRepository from "@/data/animationhour/repository";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps"
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import {IAnimationHoursType, IAnimationHourType} from "@/data/animationhour/type";
import { HeatmapLayer } from "@deck.gl/aggregation-layers"
import {LineLayer} from '@deck.gl/layers'
import {Config} from "@/config";
import {HourSeriesActionType} from "@/store/modules/hourseries/actions";
import {DayCode} from "@/enum/barchart";
import _ from 'lodash'
import {leadingZero} from "@/utils/time";
import {getSelectedRoad, setAccessToken} from "@/utils/storage";
import {AlertDialog, WarningDialog} from "@/service/dialog";
import {IRoadType} from "@/data/road/type";
import {v4 as uuidv4} from 'uuid'
import UIRoadToggle from '@/components/UI/UIRoadToggle.vue'
import UICheckbox from '@/components/UI/UICheckbox.vue'
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import {HINT_ROAD_HOUR_ANIMATION, DOWNLOAD_ROAD_HOUR_ANIM, globalEmitter} from '@/service/GlobalEmmiter'
import {HintDialog} from '@/service/dialog'
import RoadHourAnim from '@/components/hints/RoadHourAnim.vue'

interface State {
  labelForTime: string
  startDate: string
  endDate: string
  timeLabel: string
  title: string
  subtitle: string
  activeTime: string
  dayFilter: DayCode[]
  heatmaps: HeatmapLayer[]
  resultDayFilter: DayCode[]
  resultTime: number[]
  minimum: boolean
  except: boolean
  roadData: Array<RoadData[]>
  timeTrack: string
  map: any
}

interface RoadData {
  from: RoadDataLine
  to: RoadDataLine
}

interface RoadDataLine {
  name: string
  coordinates: number[]
}

export default defineComponent({
  name: "DayTimeSeries",
  components: {
    ContentWrapper,
    GoogleMap,
    CustomControl,
    UITimeSeriesController,
    AppLoader,
    UITimeSlider,
    FilterWrapper,
    UIButton,
    UICheckboxFilter,
    UIRoadToggle,
    UICheckbox,
    UIColorToggler
  },
  setup() {
    const store = useStore()
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const availableDayFilter = [1, 2, 3, 4, 5, 6, 7, 9];
    const getDefaultDayFilter = () => [1, 2, 3, 4, 5, 6, 7, 9]; // [月, 火, 水, 木, 金, 土, 日, 祝日]
    const state = reactive<State>({
      labelForTime: '',
      startDate: '2021/05/18',
      endDate: '2021/11/18',
      timeLabel: '',
      title: store.getters['selectedRoad'].name,
      subtitle: '前後1時間行動',
      activeTime: '',
      dayFilter: store.state.hourSeries.dayFilter,
      heatmaps:[] as HeatmapLayer[],
      resultDayFilter: getDefaultDayFilter(),
      resultTime: store.state.hourSeries.time,
      minimum: false,
      except: false,
      roadData: [],
      timeTrack: '',
      map: null
    })
    const apiKey = process.env.VUE_APP_MAP_API_KEY
    const latlngStr: string | null = localStorage.getItem('latlng');
    const latlng = latlngStr !== null && latlngStr !== '' ? JSON.parse(latlngStr) : '';
    const center = latlng ? latlng : store.getters['getMapPos']
    const sbMinimum = computed(() => store.state.roadSbMinimum)
    const isCapturing = computed(() => store.state.isCapturing)
    const mapStyle = [{
      stylers: [{
        saturation: -100
      }]
    }]
    const colors = Config.Instance.heatmapColors.hex
    const defaultZoom = computed(() => {
      const lZoom = Number(localStorage.getItem('zoom'))
      const res = !isNaN(lZoom) ? lZoom : store.state.defaultZoom
      return res
    })
    const stackBar = ref<boolean>(true);
    const stub = new AnimationHourStubStore()
    const api = new AnimationHourAPIStore()
    const repository = new AnimationHourRepository(stub, api)
    const dataLabel = ref<boolean>(false);
    const setDatalabels = function () {
      dataLabel.value = !dataLabel.value
    }
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const minimumHandler = (e) => {
      state.minimum = e;
    }

    const maxZoom = computed(() => {
      const max = store.state.heatmapMaxZoom || 16
      if (state.map) {
        if (state.map.getZoom() > max) {
          state.map.setZoom(max)
        }
      }
      return max
    })

    const tableUnitVal = computed(() => Config.Instance.tableUnitValHourTime())

    const gMap = ref()
    const handleZoomIn = () => {
      gMap.value.map.setZoom(gMap.value.map.getZoom() + 1)
    }
    const handleZoomOut = () => {
      gMap.value.map.setZoom(gMap.value.map.getZoom() - 1)
    }
    const handleCurrentLocation = () => {
      store.dispatch(ActionType.setAppLoading, true)
      gMap.value.map.addListener('bounds_changed', () => {
        store.dispatch(ActionType.setAppLoading, false)
        window.google.maps.event.clearListeners(gMap.value.map, 'bounds_changed');
      })
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            const latlng = localStorage.getItem('latlng');
            const buflatlng = store.getters['getMapPos'];
            if (!latlng && !buflatlng) {
             gMap.value.map.setCenter(pos)
            }
          }
        )
      }
    }

    const isLoading = computed(() => {
      return store.getters['hourSeries/getDoInitFlg'];
    })

    const isPrepareHeatmap = computed(() => {
      let count = 0;
      for(let prop in state.heatmaps) {
        count++
      }
      return count > 0
    })

    const timeRangeLabel = computed(() => {
      return `${leadingZero(state.resultTime[0])} - ${leadingZero(state.resultTime[1])}`
    })

    const getDayLabel = computed(() => {
      const str = _.map(store.state.hourSeries.dayConstraint, (day: DayCode) => {
        if (day === 9) {
          return '祝/'
        } else {
          return Config.Instance.translateDayByCode(day) + '/'
        }
      })
      return _.reduce(str, (agg, val) => agg + val, '' ).replace(/\/$/, '')
    });

    const loadTitles = computed(() => {
      const roads:IRoadType[] = store.getters['selectedRoad'];
      let title = '';
      if(roads.length > 0) {
        for(let i=0;i<roads.length;i++) {
          if (i < roads.length-1) {
            title += roads[i].name + ', ';
          } else {
            title += roads[i].name;
          }
        }
      }
      return title;
    })

    const onResetWeek = () => {
      store.state.timeSeries.dayFilter = getDefaultDayFilter()
      state.dayFilter = store.state.timeSeries.dayFilter
    }

    const onResetTime = () => {
      store.state.hourSeries.time = [0,23];
    }

    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    });
    const roadOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    });
    const handleResetDayFilter = () => {
      state.dayFilter = getDefaultDayFilter() // reset day filter to default
    }

    const onTimeSeriesChange = (time: number) => {
      if (state.activeTime != String(time)) {
        state.activeTime = time.toFixed(2)
        if (Math.abs(Number(time.toFixed(2)) % 0.25) === 0) {
          state.timeTrack = state.activeTime
        }
        draw()
      }
    }

    const draw = () => {
      if (state.heatmaps[state.activeTime.toString()]) {
        deckOverlay.setProps({layers: [ state.heatmaps[state.activeTime.toString()] ] });
      }
    }

    const handleShowRoadChange = (value: boolean) => {
      const layers: LineLayer[] = []
      if (value) {
        state.roadData.forEach(data => {
          layers.push(new LineLayer({
            id: uuidv4(),
            data,
            getWidth: 10,
            getSourcePosition: d => [d.from.coordinates[1], d.from.coordinates[0]],
            getTargetPosition: d => [d.to.coordinates[1], d.to.coordinates[0]],
            getColor: () => [51, 74, 124, 153]
          }))
        })
      }
      roadOverlay.setProps({ layers: layers })
    }

    const onLoad = () => {
      deckOverlay.setMap(gMap.value.map)
      roadOverlay.setMap(gMap.value.map)
      state.map = gMap.value.map

      localStorage.setItem('zoom', defaultZoom.value)
      gMap.value.map.addListener('zoom_changed', () => {
        localStorage.setItem('zoom', gMap.value.map.zoom)
      })
    }
    const filterContent = () => {
      store.dispatch(`hourSeries/${HourSeriesActionType.setDoInitFlg}`, true)
      //
      const hourRange = store.state.hourSeries.time;
      const hour: Array<number> = [];
      for (let i = hourRange[0]; i < hourRange[1]; i++) {
        hour.push(i);
      }
      loadAnimationDay(hour, false)
    }

    /**
     * API アクセス
     **/
    const loadAnimationDay = (hour, isCreate = false) => {
      // animation hour
      const stub = new AnimationHourStubStore()
      const api = new AnimationHourAPIStore()
      const repository = new AnimationHourRepository(stub, api)
      let selectedRoad: IRoadType[] = store.getters['selectedRoad']
      if (selectedRoad && selectedRoad.length === 0) {
        selectedRoad = getSelectedRoad();
      }
      const roadIds: string[] = []
      const roadData: Array<RoadData[]> = []
      selectedRoad.forEach((road) => {
        for (let prop in road.polygon) {
          const data: RoadData[] = []
          for (let i = 0; i < road.polygon[prop].length - 1; i++) {
            data.push({
              from: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i].latitude), Number(road.polygon[prop][i].longitude)]
              },
              to: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i + 1].latitude), Number(road.polygon[prop][i + 1].longitude)]
              }
            })
          }
          roadData.push(data)
        }
      })
      state.roadData = roadData

      //曜日
      const days = state.dayFilter
      const filterdDays = days.filter((day) => {
        return day !== 9
      })
      //祝日
      const holiday = days.find((day) => {
        return day === 9
      })
      const holidayFlg = holiday !== undefined

      // auth
      const authApi = new AuthAPIStore()
      const authRepository = new AuthRepository(authApi)
      //
      const requestParams = {
        create: isCreate,
        hour: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        visit_hour: hour,
        holiday_flag: holidayFlg,
        week: filterdDays,
        except_target_area: state.except ? 1 : 0,
        unit: Config.Instance.makeTableUnitHourTime,
      }
      //
      store.dispatch(`hourSeries/${HourSeriesActionType.setDoInitFlg}`, true);
      return repository.fetch(requestParams)
          .then(response => {
            store.dispatch(ActionType.setAnimationHours, response)
            prepareTimeHeatmapData()
            return true
          })
          .catch(err => {
            if (err.status === 401) {
              return authRepository.refreshToken()
                  .then(response => setAccessToken(response.access))
                  .then(() => repository.fetch(requestParams))
                  .then(response => {
                    store.dispatch(ActionType.setAnimationHours, response)
                    prepareTimeHeatmapData()
                    return true
                  })
                  .catch(err => {
                    if (err.status === 404) {
                      WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
                    } else if (err.status === 409) {
                      WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
                    } else if (err.status === 424) {
                      WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
                    } else if (err.status === 416) {
                      WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
                    } else {
                      AlertDialog(err.message)
                    }
                  })
            } else if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
          .finally(() => {
            store.dispatch(`hourSeries/${HourSeriesActionType.setDoInitFlg}`, false);
            store.dispatch(`hourSeries/${HourSeriesActionType.setDayConstraint}`, state.dayFilter)
            store.dispatch(`hourSeries/${HourSeriesActionType.setDayFilter}`, state.dayFilter)
            store.dispatch(`hourSeries/${HourSeriesActionType.setTimeLabel}`, timeRangeLabel.value)
            store.dispatch(`hourSeries/${HourSeriesActionType.setActiveTime}`, timeRangeLabel.value)
          })
    }

    /**
     * ヒートマップ作成
     **/
    const prepareTimeHeatmapData = () => {
      const timeHeatmaps: IAnimationHoursType = store.getters['selectAnimationHours'];
      const timeAry: any = [];
      let minCol = 0
      let maxCol = 0
      let init = false
      timeHeatmaps.forEach((timeData:IAnimationHourType) => {
        let timeData3 = timeData[2].toFixed(2);

        if (!timeAry[timeData3]) {
          timeAry[timeData3] = [];
        }
        //初期化
        if (init === false) {
          minCol = timeData[3]
          maxCol = timeData[3]
          init = true
        } else {
          minCol = timeData[3] < minCol ? timeData[3] : minCol
          maxCol = timeData[3] > maxCol ? timeData[3] : maxCol
        }
        timeAry[timeData3].push(timeData)
      });
      //
      const hmAry: HeatmapLayer[] = []
      for(let prop in timeAry) {
        const hm = new HeatmapLayer({
          data: timeAry[prop],
          intensity: Config.Instance.heatmapDetailProps.intensity,
          opacity: Config.Instance.heatmapDetailProps.opacity,
          threshold: Config.Instance.heatmapDetailProps.threshold,
          radiusPixels: Config.Instance.heatmapDetailProps.radiusPixels,
          colorDomain:[minCol, maxCol],
          getPosition: (d) => { return [d[1], d[0]] },
          getWeight: (d) => d[3],
          colorRange: Config.Instance.heatmapColors.rgb,
          aggregation: "SUM",
        })
        hmAry[ Number(prop).toFixed(2) ] = hm
      }
      state.heatmaps = hmAry
      deckOverlay.setProps({layers: [ state.heatmaps[state.timeTrack] ] })
      store.dispatch(`hourSeries/${HourSeriesActionType.setDoInitFlg}`, false)
      //
      state.resultDayFilter = state.dayFilter
      state.resultTime = store.state.hourSeries.time
    }

    filterContent()

    const handleDownload = () => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = "HourTimeSeries.png";
          link.target = '_blank';
          link.click();
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 250)
    }

    onBeforeUnmount(() => {
      globalEmitter.off(HINT_ROAD_HOUR_ANIMATION)
      globalEmitter.off(DOWNLOAD_ROAD_HOUR_ANIM)
    })

    globalEmitter.on(DOWNLOAD_ROAD_HOUR_ANIM, () => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = '前後一時間行動.png'
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_ROAD_HOUR_ANIMATION, () => {
      HintDialog(RoadHourAnim)
    })

    return {
      ...toRefs(state),
      gMap,
      center,
      apiKey,
      loadTitles,
      mapStyle,
      stackBar,
      dataLabel,
      isLoading,
      getDayLabel,
      timeRangeLabel,
      isPrepareHeatmap,
      tableUnitVal,
      defaultZoom,
      availableDayFilter,
      colors,
      sbMinimum,
      maxZoom,
      isCapturing,
      ButtonType,
      onResetWeek,
      onResetTime,
      onLoad,
      onTimeSeriesChange,
      setDatalabels,
      handleZoomIn,
      handleZoomOut,
      handleCurrentLocation,
      handleShowRoadChange,
      handleResetDayFilter,
      filterContent,
      handleDownload,
      minimumHandler
    }
  },
})
