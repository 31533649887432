export const percentRound = (ipt: number[]): number[] => {
  if (!Array.isArray(ipt)) {
    throw new Error('percentRound input should be an Array')
  }
  
  if (ipt.length === 0) {
    return ipt
  }
  
  const iptPercents = ipt.slice()
  const max = iptPercents.reduce((acc, val) => acc + val, 0)
  let check100 = 0
  const rounded = iptPercents.map(i => {
    const m = Math.round((i * 100) / max)
    check100 += m
    return m
  })
  if (check100 === 100) {
    return rounded
  }
  
  let m = rounded[0]
  let maxIdx = 0
  for (let i = 1; i < rounded.length; i++) {
    if (rounded[i] > m) {
      maxIdx = i
      m = rounded[i]
    }
  }
  
  let accM = 0
  for (let i = 0; i < rounded.length; i++) {
    if (i !== maxIdx) {
      accM += rounded[i]
    }
  }
  rounded[maxIdx] = 100 - accM
  return rounded
}
