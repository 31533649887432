
import {computed, defineComponent, PropType} from 'vue'
import { BarChart, useBarChart } from 'vue-chart-3'
import {FEMALE_LABEL, MALE_LABEL} from '@/constant'
import {BarChartColor} from '@/enum/barchart'
import {useStore} from 'vuex'

export default defineComponent({
  name: 'VisitorBarChart',
  components: {BarChart},
  props: {
    label: { type: String, required: false, default: '' },
    showBottomLabel: { type: Boolean, required: false, default: true },
    labels: { type: Array as PropType<Array<string>>, required: false, default: () => ([]) },
    men: { type: Array as PropType<Array<number>>, required: false, default: () => ([]) },
    women: { type: Array as PropType<Array<number>>, required: false, default: () => ([]) }
  },
  setup (props) {
    const store = useStore()
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: { display: false,  drawBorder: false },
          ticks: {
            color: 'black',
            font: { size: 13 }
          }
        },
        y: {
          grid: {
            drawBorder: false,
            borderColor: '#231815',
            lineWidth: 1.5,
            z: 1
          },
          max: store.getters['areaVisitor/ages'].max,
          ticks: {
            color: 'black',
            font: { size: 12 },
            stepSize: 5,
            padding: 14,
            callback: function(val) {
              return val % 10 === 0 ? val : ''
            },
          }
        }
      },
      events: [],
      plugins: {
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'end',
          color: '#000',
          padding: -10,
          formatter: function (value) {
            return `${Math.round(value)}%`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            color: 'black',
            padding: 20,
            boxWidth: 14,
            boxHeight: 14
          }
        }
      }
    }))
    const chartData = computed(() => ({
      labels: props.labels,
      datasets: [
        {
          label: MALE_LABEL,
          data: props.men,
          backgroundColor: BarChartColor.Male,
          barPercentage: 0.75
        },
        {
          label: FEMALE_LABEL,
          data: props.women,
          backgroundColor: BarChartColor.Female,
          barPercentage: 0.75
        }
      ]
    }))
    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps }
  }
})
