import {ITranslatorType} from '@/types'
import {IPersonaType} from '@/data/master/area/persona/type'

export default class PersonaMasterJsonTranslator implements ITranslatorType<Record<string, any>, IPersonaType> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
  translate(input: Record<string, any>, ...args): IPersonaType {
    return {
      id: input.id,
      gender: input.gender,
      age: input.age,
      color: input.color,
      slogan: input.slogan,
      avatar: input.avatar
    }
  }
}
