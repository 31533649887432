import {GetterTree} from 'vuex'
import {AreaRankingState} from '@/store/modules/area/search/ranking/type'
import {RootState} from '@/store/types'
import {IRankingType} from '@/data/area/type'
import {ColorRGBA} from "@/types";
import {lightenColorGL} from "@/utils/color";

export const getters: GetterTree<AreaRankingState, RootState> = {
  cities (state: AreaRankingState): IRankingType[] {
    return state.resident.concat(state.workLocation)
  },
  colors (state: AreaRankingState): ColorRGBA[] {
    const slope = 8
    const colors = [{ Red: 25, Green: 103, Blue: 210, Alpha: 255 }]
    for (let i = 0; i < state.max; i++) {
      const track = colors[colors.length - 1]
      colors.push(lightenColorGL(track, slope))
    }
    return colors
  }
}

