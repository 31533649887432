import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icon-chevron-down.svg'


const _hoisted_1 = { class: "jr-text-s" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "jr-pl-2 jr-py-3 jr-border-gray-light jr-border-b"
}
const _hoisted_4 = { class: "jr-pl-8 jr-py-3 jr-border-gray-light jr-border-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UICheckbox = _resolveComponent("UICheckbox")!

  return (_openBlock(), _createElementBlock("figure", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args))),
      class: _normalizeClass(["jr-flex jr-py-2.5 jr-border-gray-light jr-cursor-pointer", { 'jr-border-b-1': _ctx.border }])
    }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "icon-chevron-down",
        class: _normalizeClass(["jr-mx-3 jr-transform jr-transition-all", { 'jr-rotate-180': !_ctx.pref.isCollapsed }])
      }, null, 2),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.pref.name), 1)
    ], 2),
    (!_ctx.pref.isCollapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.pref.cities.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_UICheckbox, {
                  label: "全ての市区町村を選択",
                  icon: _ctx.isAllSelected ? 'tick' : 'minus',
                  modelValue: _ctx.bulkSelected,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bulkSelected) = $event)),
                  onChange: _ctx.handleBulkToggle
                }, null, 8, ["icon", "modelValue", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pref.cities, (city, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_UICheckbox, {
                  label: city.name,
                  modelValue: city.isSelected,
                  "onUpdate:modelValue": ($event: any) => ((city.isSelected) = $event)
                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}