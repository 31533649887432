
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'

export default defineComponent({
  name: 'RankingColors',
  setup () {
    const store = useStore()
    const colors = computed(() => store.getters['areaRanking/colors'])
    const min = computed(() => store.state.areaRanking.min)
    const max = computed(() => store.state.areaRanking.max)
    return { colors, min, max }
  }
})
