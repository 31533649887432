import {IRoadAPIStoreType} from "@/data/road/store";
import { RoadSearchRequest } from '../store/request'
import {IRoadStubType} from "../store/stub";
import {IRoadType} from "@/data/road/type";

export interface IRoadRepositoryType {
    api: IRoadAPIStoreType
    stub: IRoadStubType
    fetch(params: Record<string, any>): Promise<IRoadType[]>
}

export default class RoadRepository implements IRoadRepositoryType {
    stub: IRoadStubType
    api: IRoadAPIStoreType

    /// DI
    constructor (stub: IRoadStubType, api: IRoadAPIStoreType) {
        this.stub = stub
        this.api = api
    }

    /**
     * Fetch
     * @returns collection of order entity
     */
    async fetch(params: Record<string, any>): Promise<IRoadType[]> {
        const req = new RoadSearchRequest(params)
        const response = await this.api.fetch(req)
        return response
        //return await this.stub.fetch()
    }
}