import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cad9c75"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-block" }
const _hoisted_2 = ["id", "value", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id,
      value: _ctx.value,
      checked: _ctx.isChecked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      class: _normalizeClass(["jr-relative jr-text-s-1 jr-font-medium", _ctx.labelClasses]),
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 11, _hoisted_3)
  ]))
}