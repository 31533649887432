import html2canvas from "html2canvas";
import jsPDF from "jspdf";

/**
 * For convert html content to PDF
 * @param element content download element ID
 * @returns doc jsPDF object
 */
export const ConvertToPDF = function (element: string): Promise<jsPDF> {
    let doc = new jsPDF();
    return html2canvas(document.getElementById(element) as HTMLElement, { allowTaint: true, useCORS: true })
        .then(function (canvas) {
            const imgData = canvas.toDataURL('image/png')
            const imgProps= doc.getImageProperties(imgData)
            doc = new jsPDF({
              orientation: 'p',
              unit: 'px',
              format: [imgProps.width, imgProps.height],
              hotfixes: ['px_scaling'],
            })
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(imgData,'PNG',0, 0, pdfWidth, pdfHeight)
            return doc
        })
}

/**
 * For convert html content to Image
 * @param element content download element ID
 * @returns link a tag html element with href image
 */
export const ConvertToImage = function (element: string): Promise<HTMLAnchorElement> {
    const container = document.getElementById(element);
    return html2canvas(container as HTMLElement, {
        allowTaint: true,
        useCORS: true,
        scrollY: -window.scrollY,
        scrollX: 0,
        logging: false
    }).then(function (canvas) {
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = canvas.toDataURL("image/jpg");
        return link
    });
}
