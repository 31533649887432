
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {globalEmitter, HINT_AREA_VISITOR, DOWNLOAD_AREA_VISITOR} from '@/service/GlobalEmmiter'
import {ConvertToImage} from '@/service/file'
import {
  AREA_WARNING_TITLE,
  AREA_WARNING_MESSAGE,
  NO_CONDITION_SPECIFIED,
  AREA_NO_WEEK,
  AREA_NO_TRANSPORTATION,
  AREA_NO_FREQUENCY,
  AREA_NO_ATTRIBUTE,
  MAX_THRESHOLD_TITLE,
  MAX_THRESHOLD_MESSAGE,
  CONTENT_DOWNLOAD_AREA_VISITOR
} from '@/constant'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {AreaVisitorActionType} from '@/store/modules/area/search/visitor/actions'
import {getSelectedAreaLabels, setAccessToken} from '@/utils/storage'
import {Emitter, EventType} from 'mitt'
import {Config} from '@/config'
import {useRoute} from 'vue-router'
import {ActionType} from '@/store'
import {PersonaMasterActionType} from '@/store/modules/area/master/persona/actions'
import {IPersonaType} from '@/data/master/area/persona/type'
import {IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import VisitorDoughnutChart from '@/components/UI/visitor/VisitorDoughnutChart.vue'
import VisitorBarChart from '@/components/UI/visitor/VisitorBarChart.vue'
import UIPersona from '@/components/UI/area/UIPersona.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AppLoader from '@/components/AppLoader.vue'
import PersonaMasterStubStore from '@/data/master/area/persona/store/stub'
import PersonaMasterAPIStore from '@/data/master/area/persona/store'
import PersonaMasterRepository from '@/data/master/area/persona/repository'
import AreaFilterBadge from "@/components/UI/area/AreaFilterBadge.vue";
import {HintDialog} from '@/service/dialog'
import AreaVisitor from '@/components/hints/AreaVisitor.vue'

interface State {
  isLoading: boolean
  filterMinimum: boolean
}

export default defineComponent({
  name: 'Visitor',
  components: {
    AppLoader,
    UIPersona,
    VisitorBarChart,
    VisitorDoughnutChart,
    AreaSearchFilter,
    AreaFilterBadge
  },
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const store = useStore()
    const route = useRoute()
    const state = reactive<State>({
      isLoading: false,
      filterMinimum: false
    })
    const prefLabels = computed(() => {
      return store.getters['prefectureMaster/selectedCityLabels'] || getSelectedAreaLabels()
    })
    const badges = computed(() => store.getters['area/getFilterBadges'])
    const labels = computed(() => store.state.areaVisitor.ages.constraints)
    const women = computed(() => store.getters['areaVisitor/ages'].women)
    const men  = computed(() => store.getters['areaVisitor/ages'].men)
    const isCapturing = computed(() => store.state.isCapturing)
    const isErrorDoughnut = computed(() => {
      return store.getters['areaVisitor/personaVolumes'].some(d => d < store.state.percentageThreshold)
    })
    const isErrorChart = computed(() => {
      const vol = store.getters['areaVisitor/ages']
      const isMen = vol.men.some(m => m < store.state.percentageThreshold)
      const isWomen = vol.women.some(w => w < store.state.percentageThreshold)
      return isMen || isWomen
    })
    const title = AREA_WARNING_TITLE
    const wrapperElementID = CONTENT_DOWNLOAD_AREA_VISITOR
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const personaColors = computed(() => Config.Instance.personaRankingColors)
    const isVisitor = computed(() => route.path === '/area_report/visitor')
    const personas = computed(() => {
      const rankedPersona = store.state.areaVisitor.persona.map(p => p.id).filter(id => id)
      return rankedPersona.map(id => {
        return store.state.personaMaster.personas.find(persona => persona.id === id)
      }).filter(p => p)
    })
    const persona = computed(() => store.state.areaVisitor.persona)
    const ages = computed(() => store.state.areaVisitor.ages)
    const hasAges = computed(() => {
      const constraints = store.state.areaVisitor.ages.constraints
      const men = store.state.areaVisitor.ages.men
      const women = store.state.areaVisitor.ages.women
      return constraints.length > 0 && men.length > 0 && women.length > 0
    })

    // persona master
    const personaMasterStub = new PersonaMasterStubStore()
    const personaMasterApi = new PersonaMasterAPIStore()
    const personaMasterRepository = new PersonaMasterRepository(personaMasterStub, personaMasterApi)

    // data layer instances
    const stub = new AreaStubStore()
    const api = new AreaAPIStore()
    const repository = new AreaRepository(stub, api)

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const getStreams = (params: Record<string, any>): Promise<IPersonaType[] | {persona: IVisitorPersonaType[], ages: IVisitorAgeType}>[] => {
      let streams = [Promise.resolve(store.state.personaMaster.personas), repository.fetchVisitor(params)]
      if (store.state.personaMaster.personas.length === 0) {
        streams[0] = personaMasterRepository.fetch({})
      }
      return streams
    }

    const makeAPICall = async (params: Record<string, any>) => {
      state.isLoading = true
      store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorPersona}`, [])
      store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorAge}`, {
        constraints: [],
        men: [],
        women: []
      })

      Promise.all(getStreams(params)).then(([personas, visitor]) => {
        const v = visitor as {persona: IVisitorPersonaType[], ages: IVisitorAgeType}
        store.dispatch(`personaMaster/${PersonaMasterActionType.setPersonaMaster}`, personas)
        store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorPersona}`, v.persona)
        store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorAge}`, v.ages)
      }).catch(err => {
        if (isVisitor.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => Promise.all(getStreams(params)))
            .then(([personas, visitor]) => {
              const v = visitor as {persona: IVisitorPersonaType[], ages: IVisitorAgeType}
              store.dispatch(`personaMaster/${PersonaMasterActionType.setPersonaMaster}`, personas)
              store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorPersona}`, v.persona)
              store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorAge}`, v.ages)
            })
            .catch(() => {
              if (err.status === 404) {
                WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
              } else if (err.status === 416) {
                WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
              } else {
                AlertDialog(err.message)
              }
            })
          } else if (err.status === 404) {
            WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
          } else if (err.status === 416) {
            WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => {
        state.isLoading = false
        emitter?.emit('notifyAreaFilter')
      })
    }
    const params = store.getters['area/getAPIParams']
    delete params.except_target_area
    if (params.week && params.week.length > 0) {
      params.week = params.week[0]
    }
    makeAPICall(params)

    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const handleSearch = (params: Record<string, any>) => {
      // user didn't specify any filter condition
      const trans = params.type && params.type.length === 0
      const freq = params.frequencies && params.frequencies.length === 0
      const attr = params.visitor_attribute && params.visitor_attribute.length === 0
      const week = params.week && params.week.length === 0
      delete params.except_target_area

      if (trans && attr && week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (trans) {
        AlertDialog(AREA_NO_TRANSPORTATION)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (attr) {
        AlertDialog(AREA_NO_ATTRIBUTE)
        return
      }

      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      makeAPICall(params)
    }

    globalEmitter.on(DOWNLOAD_AREA_VISITOR, () => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500);
    })

    globalEmitter.on(HINT_AREA_VISITOR, () => {
      HintDialog(AreaVisitor)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_VISITOR)
      globalEmitter.off(HINT_AREA_VISITOR)
    })

    return {
      ...toRefs(state),
      sbMinimum,
      personas,
      personaColors,
      isErrorDoughnut,
      isErrorChart,
      persona,
      ages,
      hasAges,
      labels,
      men,
      women,
      title,
      isCapturing,
      prefLabels,
      badges,
      handleSearch,
      handleFilterResize
    }
  }
})
