import {PrefectureMasterState} from '@/store/modules/area/master/prefecture/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/master/prefecture/getters'
import {actions} from '@/store/modules/area/master/prefecture/actions'
import {mutations} from '@/store/modules/area/master/prefecture/mutations'

export const state: PrefectureMasterState = {
  prefectures: []
}

const namespaced = true
export const prefectureMaster: Module<PrefectureMasterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
