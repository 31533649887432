import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayRangeController = _resolveComponent("DisplayRangeController")!
  const _component_CustomControl = _resolveComponent("CustomControl")!
  const _component_UISearchField = _resolveComponent("UISearchField")!
  const _component_RoadSidebar = _resolveComponent("RoadSidebar")!
  const _component_RoadMultiSelector = _resolveComponent("RoadMultiSelector")!
  const _component_RoadPolygonSidebar = _resolveComponent("RoadPolygonSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("figure", null, [
      _createVNode(_component_CustomControl, { position: "TOP_LEFT" }, {
        default: _withCtx(() => [
          (_ctx.$store.getters['hasPolygon'] && !_ctx.$store.getters['hasQueries'] && false)
            ? (_openBlock(), _createBlock(_component_DisplayRangeController, {
                key: 0,
                onSelectRange: _ctx.handleSelectRange
              }, null, 8, ["onSelectRange"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_CustomControl, { position: "TOP_RIGHT" }, {
        default: _withCtx(() => [
          _createVNode(_component_UISearchField, {
            id: _ctx.inputSearchId,
            class: "jr-w-controller",
            placeholder: "地図を検索",
            onSearch: _ctx.onKeywordSearch
          }, null, 8, ["id", "onSearch"])
        ]),
        _: 1
      })
    ]),
    (!_ctx.eagerMode && !_ctx.polygonMode)
      ? (_openBlock(), _createBlock(_component_RoadSidebar, {
          key: 0,
          onEager: _ctx.onEagerSearch,
          onPolygon: _ctx.handleDrawMap
        }, null, 8, ["onEager", "onPolygon"]))
      : _createCommentVNode("", true),
    (_ctx.eagerMode)
      ? (_openBlock(), _createBlock(_component_RoadMultiSelector, {
          key: 1,
          onClear: _ctx.handleClear,
          onClearBackPolygon: _ctx.handlerPolygon,
          onNewPolygon: _ctx.handleNewPolygon
        }, null, 8, ["onClear", "onClearBackPolygon", "onNewPolygon"]))
      : _createCommentVNode("", true),
    (_ctx.polygonMode)
      ? (_openBlock(), _createBlock(_component_RoadPolygonSidebar, {
          key: 2,
          onClear: _ctx.handleClear,
          onClearBackPolygon: _ctx.handlerPolygon,
          onNewPolygon: _ctx.handleNewPolygon,
          onSelectRange: _ctx.handleSelectRange
        }, null, 8, ["onClear", "onClearBackPolygon", "onNewPolygon", "onSelectRange"]))
      : _createCommentVNode("", true)
  ], 64))
}