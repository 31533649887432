
import {defineComponent, reactive, toRefs} from 'vue'
import {Config} from '@/config'
import {ButtonType} from '@/enum/button'

export default defineComponent({
  name: 'AreaSidebar',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup (_, { emit }) {
    const state = reactive({
      selected: '',
      minimum: false,
      colors: Config.Instance.heatmapColors.hex,
    })
    const clickDistrict = () => {
      state.selected = 'district'
      emit('district')
    }
    const clickArea = () => {
      state.selected = 'area'
      emit('area')
    }
    const clickClose = (event) => {
      event.preventDefault()
      state.minimum = !state.minimum
    }

    return {
      ...toRefs(state),
      ButtonType,
      clickDistrict,
      clickArea,
      clickClose
    }
  }
})
