import {AreaHeatmapState} from '@/store/modules/area/search/heatmap/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/heatmap/getters'
import {actions} from '@/store/modules/area/search/heatmap/actions'
import {mutations} from '@/store/modules/area/search/heatmap/mutations'

export const state: AreaHeatmapState = {
  plotData: []
}

const namespaced = true
export const areaHeatmap: Module<AreaHeatmapState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
