import data from "@/assets/animationHour.json";
import {IAnimationHoursType} from "@/data/animationhour/type";

export interface IAnimationHourStubType {
    fetch(): Promise<IAnimationHoursType>
}

export default class AnimationHourStubStore implements IAnimationHourStubType {
    fetch (): Promise<IAnimationHoursType> {
        return new Promise((resolve) => resolve(data as IAnimationHoursType))
    }
}
