import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { HourSeriesState } from './type'
import _ from 'lodash'
import { DayCode } from '@/enum/barchart'
import { Config } from '@/config'
import {leadingZero} from "@/utils/time";

export const getters: GetterTree<HourSeriesState, RootState> = {
    getTime (state: HourSeriesState): number[] {
        return state.time
    },
    getActiveTimeRangeLabel (state: HourSeriesState): string {
        return state.activeTime
    },
    getTimeRangeLabel (state: HourSeriesState): string {
        return `${leadingZero(state.time[0])} - ${leadingZero(state.time[1])}`
    },
    getDoInitFlg (state: HourSeriesState): boolean {
        return state.doInitFlg
    },
    getDayLabel (state: HourSeriesState): string {
        const str = _.map(state.dayConstraint, (day: DayCode) => {
            return Config.Instance.translateDayByCode(day) + '/'
        })
        return _.reduce(str, (agg, val) => agg + val, '').replace(/\/$/, '')
    },
}
