
import {computed, defineComponent} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {IRoadType} from "@/data/road/type";
import {useStore} from "vuex";
import {CheckboxFilterType} from '@/enum/barchart';
import {
  DOWNLOAD_AREA_DASHBOARD,
  DOWNLOAD_AREA_FACILITY,
  DOWNLOAD_AREA_RANKING_REPORT,
  DOWNLOAD_AREA_TIME_SPENT,
  DOWNLOAD_AREA_TRANSPORTATION,
  DOWNLOAD_AREA_VISITOR,
  DOWNLOAD_GRAPH_REPORT, DOWNLOAD_RAILWAY_ANIMATION,
  DOWNLOAD_RAILWAY_COMPARISON,
  DOWNLOAD_RAILWAY_HEATMAP,
  DOWNLOAD_RAILWAY_STATION,
  DOWNLOAD_SAME_DAY_SERIES,
  HINT_ROAD_HEATMAP,
  HINT_ROAD_BAR_CHART,
  HINT_ROAD_SAME_DAY_ANIMATION,
  HINT_ROAD_HOUR_ANIMATION,
  HINT_RAILWAY_STATION,
  HINT_RAILWAY_HEATMAP,
  HINT_RAILWAY_COMPARISON,
  HINT_RAILWAY_ANIMATION,
  HINT_AREA_DASHBOARD,
  HINT_AREA_RANKING,
  HINT_AREA_TRANSPORTATION,
  HINT_AREA_FACILITY,
  HINT_AREA_TIME_SPENT,
  HINT_AREA_VISITOR,
  HINT_AREA_ANIMATION,
  DOWNLOAD_ROAD_DAY_ANIM,
  DOWNLOAD_ROAD_HOUR_ANIM,
  globalEmitter,
} from "@/service/GlobalEmmiter";
import AreaFilterBadge from "@/components/UI/area/AreaFilterBadge.vue";
import {getSelectedAreaLabels, getSelectedRailwayLine, getSelectedRailwayStation} from "@/utils/storage";
import RailwayFilterBadge from "@/components/UI/railway/RailwayFilterBadge.vue";

export default defineComponent({
  components: {RailwayFilterBadge, AreaFilterBadge},
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isRoad = computed(() => route.path === '/road')
    const isArea = computed(() => route.meta.description === 'エリア')
    const isRailway = computed(() => route.meta.description === '鉄道・駅')
    const badges = computed(() => store.getters['area/getFilterBadges'])
    const heatmapBadges = computed(() => store.getters['area/getHeatmapFilterBadges'])
    const areaPage = computed(() => (route.meta.filename as string).replace('_', '/'))
    const railwayPage = computed(() => (route.meta.filename as string).replace('_', ' / '))
    const isCapturing = computed(() => store.state.isCapturing);
    const selectedRailwayLine = computed(() => {
      const line = getSelectedRailwayLine()
      const splitter = line.split('-')
      return store.state.railLine.chosenLine || splitter.length > 1 ? splitter[1] : ''
    })
    const selectedRailwayStation = computed(() => {
      return store.state.railLine.chosenStation || getSelectedRailwayStation()
    })
    const prefLabels = computed(() => {
      return store.getters['prefectureMaster/selectedCityLabels'] || getSelectedAreaLabels()
    })

    // railway badges
    const railwayTimeRange = computed(() => store.getters['railway/timeRangeBadge'])
    const railwayWeek = computed(() => store.getters['railway/weekBadge'])
    const railwayTransportation = computed(() => store.getters['railway/transportationBadge'])
    const railwayResident = computed(() => store.getters['railway/residentBadge'])

    const handleGoTop = (event) => {
      event.preventDefault()
      const base = (localStorage.getItem('appUrl') || window.location.href.split('#')[0] + '#').replace(/\/$/, '')
      location.replace(base)
      setTimeout(()=> {
        location.reload()
      },250)
    }
    const handleGotoRoad = (event) => {
      event.preventDefault()
      const base = (localStorage.getItem('appUrl') || window.location.href.split('#')[0] + '#').replace(/\/$/, '')
      console.log(route.meta)
      if (route.meta.isArea) {
        location.replace(`${base}/area`)
      }
      if (route.meta.isRoad) {
        location.replace(`${base}/road`)
      }
      if (route.meta.isRailway) {
        location.replace(`${base}/railway`)
      }
      setTimeout(()=> {
        location.reload()
      },250)
    }

    const isReportPage = computed(() => {
      return router.currentRoute.value.fullPath.indexOf('/report/') >= 0;
    })

    const pageName = computed( () => {
      let name = '';
      switch(router.currentRoute.value.fullPath) {
        case '/report/graph':
          name = '人数ボリュームの変化';
            break;
        case '/report/same_day_series':
          name = '同日行動';
          break;
        case '/report/one_hour_series':
          name = '前後一時間行動';
          break;
      }
      return name;
    } );

    const roadTitles = computed(() => {
      const roads:IRoadType[] = store.getters['selectedRoad'];
      let title = '';
      if(roads && roads.length > 0) {
        for(let i=0;i<roads.length;i++) {
          if (i < roads.length-1) {
            title += roads[i].name + ', ';
          } else {
            title += roads[i].name;
          }
        }
      }
      return title;
    })

    const dayLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getDayLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return store.getters['daySeries/getDayLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return store.getters['hourSeries/getDayLabel'];
      } else {
        return [];
      }
    })
    const weekLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getWeekLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return '';
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return '';
      } else {
        return '';
      }

    })
    const timeLabel = computed(() => {
      if (router.currentRoute.value.fullPath === '/report/graph') {
        return store.getters['chart/getTimeRangeLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/same_day_series') {
        return store.getters['daySeries/getActiveTimeRangeLabel'];
      } else if (router.currentRoute.value.fullPath === '/report/one_hour_series') {
        return store.getters['hourSeries/getActiveTimeRangeLabel'];
      } else {
        return [];
      }
    })

    const filters = true;
    const isReport = computed(() => {
      return route.path.indexOf('report') >= 0 || route.path.indexOf('railway_polygon') >= 0 || route.path.indexOf('railway_station') >= 0
    })
    const isShowAreaFilterNav = computed(() => {
      const isRanking = router.currentRoute.value.fullPath === '/area_report/ranking'
      const isTransportation = router.currentRoute.value.fullPath === '/area_report/transportation'
      const isFacility = router.currentRoute.value.fullPath === '/area_report/facilities'
      const isTimeSpent = router.currentRoute.value.fullPath === '/area_report/timing'
      const isVisitor = router.currentRoute.value.fullPath === '/area_report/visitor'
      return isRanking || isTransportation || isFacility || isTimeSpent || isVisitor
    })
    const isHeatmap = computed(() => {
      return router.currentRoute.value.fullPath === '/area_report/same_day_action'
    })
    const isGraph = router.currentRoute.value.fullPath === '/report/graph'
    const isAreaVisitor = router.currentRoute.value.fullPath === '/area_report/visitor'

    const showDownload = computed(() => {
      const isGraph = router.currentRoute.value.fullPath.indexOf('/report/graph') >= 0
      const isArea = router.currentRoute.value.fullPath.indexOf('area_report') >= 0
      const isRailwayReport = router.currentRoute.value.fullPath.indexOf('railway_report') >= 0
      const isRailwayStation = router.currentRoute.value.fullPath.indexOf('railway_station') >= 0
      const isRoadDayAnim = router.currentRoute.value.fullPath.indexOf('/report/same_day_series') >= 0
      const isRoadHourAnim = router.currentRoute.value.fullPath.indexOf('/report/one_hour_series') >= 0
      return isGraph || isArea || isRailwayReport || isRailwayStation || isRoadDayAnim || isRoadHourAnim
    });

    const onClickDownload = () => {
      // road
      if (router.currentRoute.value.fullPath === '/report/graph' || router.currentRoute.value.fullPath === '/report/graph/') {
        globalEmitter.emit(DOWNLOAD_GRAPH_REPORT, {test: 'test'});
      }

      // road same day animation
      if (router.currentRoute.value.fullPath === '/report/same_day_series' || router.currentRoute.value.fullPath === '/report/same_day_series/') {
        globalEmitter.emit(DOWNLOAD_ROAD_DAY_ANIM)
      }

      // road hour animation
      if (router.currentRoute.value.fullPath === '/report/one_hour_series' || router.currentRoute.value.fullPath === '/report/one_hour_series/') {
        globalEmitter.emit(DOWNLOAD_ROAD_HOUR_ANIM)
      }

      // area dashboard
      if (router.currentRoute.value.fullPath === '/area_report' || router.currentRoute.value.fullPath === '/area_report/') {
        globalEmitter.emit(DOWNLOAD_AREA_DASHBOARD, {test: 'test'})
      }

      // area ranking
      if (router.currentRoute.value.fullPath === '/area_report/ranking' || router.currentRoute.value.fullPath === '/area_report/ranking/') {
        globalEmitter.emit(DOWNLOAD_AREA_RANKING_REPORT)
      }

      // transportation
      if (router.currentRoute.value.fullPath === '/area_report/transportation' || router.currentRoute.value.fullPath === '/area_report/transportation/') {
        globalEmitter.emit(DOWNLOAD_AREA_TRANSPORTATION)
      }

      // facilities
      if (router.currentRoute.value.fullPath === '/area_report/facilities' || router.currentRoute.value.fullPath === '/area_report/facilities/') {
        globalEmitter.emit(DOWNLOAD_AREA_FACILITY)
      }

      // area time spent
      if (router.currentRoute.value.fullPath === '/area_report/timing' || router.currentRoute.value.fullPath === '/area_report/timing/') {
        globalEmitter.emit(DOWNLOAD_AREA_TIME_SPENT)
      }

      // visitors
      if (router.currentRoute.value.fullPath === '/area_report/visitor' || router.currentRoute.value.fullPath === '/area_report/visitor/') {
        globalEmitter.emit(DOWNLOAD_AREA_VISITOR)
      }

      // area same day animation
      if (router.currentRoute.value.fullPath === '/area_report/same_day_action' || router.currentRoute.value.fullPath === '/area_report/same_day_action/') {
        globalEmitter.emit(DOWNLOAD_SAME_DAY_SERIES)
      }

      // railway station
      if (router.currentRoute.value.fullPath === '/railway_station' || router.currentRoute.value.fullPath === '/railway_station/') {
        globalEmitter.emit(DOWNLOAD_RAILWAY_STATION)
      }

      // railway heatmap
      if (router.currentRoute.value.fullPath === '/railway_report/heatmap' || router.currentRoute.value.fullPath === '/railway_report/heatmap/') {
        globalEmitter.emit(DOWNLOAD_RAILWAY_HEATMAP)
      }

      // railway comparison
      if (router.currentRoute.value.fullPath === '/railway_report/comparison' || router.currentRoute.value.fullPath === '/railway_report/comparison/') {
        globalEmitter.emit(DOWNLOAD_RAILWAY_COMPARISON)
      }

      // railway animation
      if (router.currentRoute.value.fullPath === '/railway_report/animation' || router.currentRoute.value.fullPath === '/railway_report/animation/') {
        globalEmitter.emit(DOWNLOAD_RAILWAY_ANIMATION)
      }
    }

    const onClickHint = () => {
      // road heatmap
      if (router.currentRoute.value.fullPath === '/road' || router.currentRoute.value.fullPath === '/road/') {
        globalEmitter.emit(HINT_ROAD_HEATMAP)
      }

      // road barchart
      if (router.currentRoute.value.fullPath === '/report/graph' || router.currentRoute.value.fullPath === '/report/graph/') {
        globalEmitter.emit(HINT_ROAD_BAR_CHART)
      }

      // road same day animation
      if (router.currentRoute.value.fullPath === '/report/same_day_series' || router.currentRoute.value.fullPath === '/report/same_day_series/') {
        globalEmitter.emit(HINT_ROAD_SAME_DAY_ANIMATION)
      }

      // road hour animation
      if (router.currentRoute.value.fullPath === '/report/one_hour_series' || router.currentRoute.value.fullPath === '/report/one_hour_series/') {
        globalEmitter.emit(HINT_ROAD_HOUR_ANIMATION)
      }

      // railway station
      if (router.currentRoute.value.fullPath === '/railway_station' || router.currentRoute.value.fullPath === '/railway_station/') {
        globalEmitter.emit(HINT_RAILWAY_STATION)
      }

      // railway heatmap
      if (router.currentRoute.value.fullPath === '/railway_report/heatmap' || router.currentRoute.value.fullPath === '/railway_report/heatmap/') {
        globalEmitter.emit(HINT_RAILWAY_HEATMAP)
      }

      // railway comparison
      if (router.currentRoute.value.fullPath === '/railway_report/comparison' || router.currentRoute.value.fullPath === '/railway_report/comparison/') {
        globalEmitter.emit(HINT_RAILWAY_COMPARISON)
      }

      // railway animation
      if (router.currentRoute.value.fullPath === '/railway_report/animation' || router.currentRoute.value.fullPath === '/railway_report/animation/') {
        globalEmitter.emit(HINT_RAILWAY_ANIMATION)
      }

      // area dashboard
      if (router.currentRoute.value.fullPath === '/area_report' || router.currentRoute.value.fullPath === '/area_report/') {
        globalEmitter.emit(HINT_AREA_DASHBOARD)
      }

      // area ranking
      if (router.currentRoute.value.fullPath === '/area_report/ranking' || router.currentRoute.value.fullPath === '/area_report/ranking/') {
        globalEmitter.emit(HINT_AREA_RANKING)
      }

      // transportation
      if (router.currentRoute.value.fullPath === '/area_report/transportation' || router.currentRoute.value.fullPath === '/area_report/transportation/') {
        globalEmitter.emit(HINT_AREA_TRANSPORTATION)
      }

      // facilities
      if (router.currentRoute.value.fullPath === '/area_report/facilities' || router.currentRoute.value.fullPath === '/area_report/facilities/') {
        globalEmitter.emit(HINT_AREA_FACILITY)
      }

      // area time spent
      if (router.currentRoute.value.fullPath === '/area_report/timing' || router.currentRoute.value.fullPath === '/area_report/timing/') {
        globalEmitter.emit(HINT_AREA_TIME_SPENT)
      }

      // visitors
      if (router.currentRoute.value.fullPath === '/area_report/visitor' || router.currentRoute.value.fullPath === '/area_report/visitor/') {
        globalEmitter.emit(HINT_AREA_VISITOR)
      }

      // area same day animation
      if (router.currentRoute.value.fullPath === '/area_report/same_day_action' || router.currentRoute.value.fullPath === '/area_report/same_day_action/') {
        globalEmitter.emit(HINT_AREA_ANIMATION)
      }
    }

    return {
      handleGoTop,
      handleGotoRoad,
      onClickDownload,
      onClickHint,
      CheckboxFilterType,
      railwayTimeRange,
      railwayWeek,
      railwayTransportation,
      railwayResident,
      selectedRailwayLine,
      selectedRailwayStation,
      prefLabels,
      isReport,
      roadTitles,
      isReportPage,
      pageName,
      filters,
      dayLabel,
      weekLabel,
      timeLabel,
      showDownload,
      isRoad,
      isArea,
      isRailway,
      badges,
      heatmapBadges,
      areaPage,
      railwayPage,
      isHeatmap,
      isShowAreaFilterNav,
      isCapturing,
      isGraph,
      isAreaVisitor
    }
  },
})
