import {MutationTree} from 'vuex'
import {AreaTimeSpentState} from '@/store/modules/area/search/timeSpent/type'
import {ITimeSpentType} from '@/data/area/type'

export enum AreaTimeSpentMutationType {
  SET_TIMES = 'SetTimes',
  SET_AVERAGE = 'SetAverage'
}

export const mutations: MutationTree<AreaTimeSpentState> = {
  [AreaTimeSpentMutationType.SET_TIMES] (state: AreaTimeSpentState, payload: ITimeSpentType[]): void {
    state.times = payload
  },
  [AreaTimeSpentMutationType.SET_AVERAGE] (state: AreaTimeSpentState, payload: number): void {
    state.average = payload
  }
}
