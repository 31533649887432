
import _ from 'lodash'
import {CustomControl, GoogleMap} from 'vue3-google-map'
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs} from 'vue'
import AppLoader from '@/components/AppLoader.vue'
import ContentWrapper from '@/components/ContentWrapper.vue'
import {ActionType} from '@/store'
import {ButtonType} from '@/enum/button'
import UITimeSeriesController from '@/components/UI/UITimeSeriesController.vue'
import {useStore} from 'vuex'
import GeolocationPosition from '@google/maps'
import {Config} from '@/config'
import {GoogleMapsOverlay as DeckOverlay} from "@deck.gl/google-maps"
import {HeatmapLayer} from "@deck.gl/aggregation-layers"
import {LineLayer} from '@deck.gl/layers'
import FilterWrapper from "@/components/FilterWrapper.vue";
import UIButton from "@/components/UI/UIButton.vue";
import UITimeSlider from "@/components/UI/UITimeSlider.vue";
import UICheckboxFilter from "@/components/UI/UICheckboxFilter.vue";
import {ConvertToImage} from "@/service/file";
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  PROCESS_CANCELED_MESSAGE,
  PROCESS_CANCELED_TITLE
} from "@/constant";
import {AlertDialog, WarningDialog} from "@/service/dialog";
import AnimationDayStubStore from "@/data/animationday/store/stub";
import AnimationDayAPIStore from "@/data/animationday/store";
import AnimationDayRepository from "@/data/animationday/repository";
import {IAnimationDaysRequestType, IAnimationDaysType, IAnimationDayType} from "@/data/animationday/type";
import {IRoadType} from "@/data/road/type";
import {setAccessToken, getSelectedRoad} from "@/utils/storage";
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import {HourSeriesActionType} from "@/store/modules/hourseries/actions";
import {leadingZero} from "@/utils/time";
import {DayCode} from "@/enum/barchart";
import {DaySeriesActionType} from '@/store/modules/dayseries/actions'
import UIRoadToggle from "@/components/UI/UIRoadToggle.vue";
import UICheckbox from "@/components/UI/UICheckbox.vue"
import {v4 as uuidv4} from 'uuid'
import UIColorToggler from '@/components/UI/UIColorToggler.vue'
import {HINT_ROAD_SAME_DAY_ANIMATION, DOWNLOAD_ROAD_DAY_ANIM, globalEmitter} from '@/service/GlobalEmmiter'
import {HintDialog} from '@/service/dialog'
import RoadSameDayAnim from '@/components/hints/RoadSameDayAnim.vue'

interface State {
  map: any
  startDate: string
  endDate: string
  timeLabel: string
  title: string
  subtitle: string
  activeTime: string
  dayFilter: DayCode[]
  heatmaps: HeatmapLayer[]
  resultDayFilter: DayCode[]
  resultTime: number[]
  minimum: boolean
  except: boolean
  roadData: Array<RoadData[]>
}

interface RoadData {
  from: RoadDataLine
  to: RoadDataLine
}

interface RoadDataLine {
  name: string
  coordinates: number[]
}

export default defineComponent({
  name: "DayTimeSeries",
  components: {
    UIRoadToggle,
    ContentWrapper,
    GoogleMap,
    AppLoader,
    CustomControl,
    UITimeSeriesController,
    FilterWrapper,
    UIButton,
    UITimeSlider,
    UICheckboxFilter,
    UICheckbox,
    UIColorToggler
  },
  setup() {
    const store = useStore()
    const availableDayFilter = [1, 2, 3, 4, 5, 6, 7, 9];
    const getDefaultDayFilter = () => [1, 2, 3, 4, 5, 6, 7, 9]; // [月, 火, 水, 木, 金, 土, 日, 祝日]
    const state = reactive<State>({
      startDate: Config.Instance.from,
      endDate: Config.Instance.to,
      timeLabel: '',
      title: store.getters['selectedRoad'].name,
      subtitle: '同日行動',
      activeTime: '',
      dayFilter: store.state.daySeries.dayFilter,
      heatmaps:[] as HeatmapLayer[],
      resultDayFilter: getDefaultDayFilter(),
      resultTime: store.state.daySeries.time,
      minimum: false,
      except: false,
      roadData: [],
      map: null
    })
    const isCapturing = computed(() => store.state.isCapturing)
    const sbMinimum = computed(() => store.state.roadSbMinimum)
    const apiKey = process.env.VUE_APP_MAP_API_KEY
    const latlngStr: string | null = localStorage.getItem('latlng');
    const latlng = latlngStr !== null && latlngStr !== '' ? JSON.parse(latlngStr) : '';
    const center = latlng ? latlng : store.getters['getMapPos']
    const mapStyle = [{
      stylers: [{
        saturation: -100
      }]
    }]
    const stackBar = ref<boolean>(true);
    const stub = new AnimationDayStubStore()
    const api = new AnimationDayAPIStore()
    const repository = new AnimationDayRepository(stub, api)
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const dataLabel = ref<boolean>(false);
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const colors = Config.Instance.heatmapColors.hex
    const setDatalabels = function () {
      dataLabel.value = !dataLabel.value
    }
    const maxZoom = computed(() => {
      const max = store.state.heatmapMaxZoom || 16
      if (state.map) {
        if (state.map.getZoom() > max) {
          state.map.setZoom(max)
        }
      }
      return max
    })

    const defaultZoom = computed(() => {
      const lZoom = Number(localStorage.getItem('zoom'))
      const res = !isNaN(lZoom) ? lZoom : store.state.defaultZoom
      return res
    })
    const isLoading = computed(() => store.getters['daySeries/getDoInitFlg'])
    const minimumHandler = (e: boolean) => state.minimum = e
    const isPrepareHeatmap = computed(() => {
      let count = 0;
      for(let _ in state.heatmaps) {
        count++
      }
      return count > 0
    })
    const tableUnitVal = computed(() => Config.Instance.tableUnitVal())
    const timeRangeLabel = computed(() => {
      return `${leadingZero(state.resultTime[0])} - ${leadingZero(state.resultTime[1])}`
    })

    const getDayLabel = computed(() => {
      const str = _.map(store.state.daySeries.dayConstraint, (day: DayCode) => {
        if (day === 9) {
          return '祝/'
        } else {
          return Config.Instance.translateDayByCode(day) + '/'
        }
      })
      return _.reduce(str, (agg, val) => agg + val, '' ).replace(/\/$/, '')
    });

    const loadTitles = computed(() => {
      const roads:IRoadType[] = store.getters['selectedRoad'];
      let title = '';
      if(roads.length > 0) {
        for(let i=0;i<roads.length;i++) {
          if (i < roads.length-1) {
            title += roads[i].name + ', ';
          } else {
            title += roads[i].name;
          }
        }
      }
      return title;
    })

    const onResetWeek = () => {
      store.state.daySeries.dayFilter = getDefaultDayFilter()
      state.dayFilter = store.state.daySeries.dayFilter
    }

    const onResetTime = () => store.state.daySeries.time = [0,24]
    const gMap = ref()
    const handleZoomIn = () => {
      gMap.value.map.setZoom(gMap.value.map.getZoom() + 1)
    }
    const handleZoomOut = () => {
      gMap.value.map.setZoom(gMap.value.map.getZoom() - 1)
    }
    const handleCurrentLocation = () => {
      gMap.value.map.addListener('bounds_changed', () => {
        window.google.maps.event.clearListeners(gMap.value.map, 'bounds_changed');
      })
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            const latlng = localStorage.getItem('latlng');
            const buflatlng = store.getters['getMapPos'];
            if (!latlng && !buflatlng) {
              gMap.value.map.setCenter(pos)
            }
          }
        )
      }
    }

    const onTimeSeriesChange = (time: number) => {
      const n = Math.floor((time % 1) * 4);
      const hour =  getDoubleDigestNumber(Math.floor(time))
      const min = getDoubleDigestNumber(n*15)
      const timeStr = `${hour}:${min}:00`
      //
      if (state.activeTime != timeStr) {
        state.activeTime = timeStr
        draw()
      }
    }

    const getDoubleDigestNumber = (number): string => {
      return ("0" + number).slice(-2)
    }

    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    })
    const roadOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    })
    const draw = () => {
      if (state.heatmaps[state.activeTime]) {
        deckOverlay.setProps({ layers: [ state.heatmaps[state.activeTime] ] });
      }
    }
    const handleShowRoadChange = (value: boolean) => {
      const layers: LineLayer[] = []
      if (value) {
        state.roadData.forEach(data => {
          layers.push(new LineLayer({
            id: uuidv4(),
            data,
            getWidth: 10,
            getSourcePosition: d => [d.from.coordinates[1], d.from.coordinates[0]],
            getTargetPosition: d => [d.to.coordinates[1], d.to.coordinates[0]],
            getColor: () => [51, 74, 124, 153]
          }))
        })
      }
      roadOverlay.setProps({ layers: layers })
    }
    const onExceptChange = (value: boolean) => state.except = value

    const onLoad = () => {
      deckOverlay.setMap(gMap.value.map)
      roadOverlay.setMap(gMap.value.map)
      state.map = gMap.value.map

      localStorage.setItem('zoom', defaultZoom.value)
      gMap.value.map.addListener('zoom_changed', () => {
        localStorage.setItem('zoom', gMap.value.map.zoom)
      })
    }
    const loadAnimationDay = (hour, isCreate = false) => {
      // 道路
      let selectedRoad: IRoadType[] = store.getters['selectedRoad'];
      if (selectedRoad && selectedRoad.length === 0) {
        selectedRoad = getSelectedRoad();
      }
      let roadIds: string[] = []
      let roadData: Array<RoadData[]> = []
      selectedRoad.forEach((road) => {
        roadIds = roadIds.concat(road.id)
        for (let prop in road.polygon) {
          const data: RoadData[] = []
          for (let i = 0; i < road.polygon[prop].length - 1; i++) {
            data.push({
              from: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i].latitude), Number(road.polygon[prop][i].longitude)]
              },
              to: {
                name: road.name,
                coordinates: [Number(road.polygon[prop][i + 1].latitude), Number(road.polygon[prop][i + 1].longitude)]
              }
            })
          }
          roadData.push(data)
        }
      })
      state.roadData = roadData
      // 曜日
      const days = state.dayFilter;
      const filterdDays = days.filter((day) => {
        return day !== 9
      })
      //祝日
      const holiday = days.find((day) => {
        return day === 9
      })
      const holidayFlg = holiday !== undefined
      //
      const request: IAnimationDaysRequestType = {
        road_ids: roadIds,
        create: isCreate,
        visit_hour: hour,
        holiday_flag: holidayFlg,
        week: filterdDays,
        except_target_area: state.except ? 1 : 0,
        unit: Config.Instance.makeTableUnit,
        hour: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      }
      store.dispatch(`daySeries/${HourSeriesActionType.setDoInitFlg}`, true)
      repository.fetch(request)
          .then(response => {
            store.dispatch(ActionType.setAnimationDays, response)
            if (!isCreate) {
              prepareTimeHeatmapData();
            }
            return true
          })
          .catch(err => {
            if (err.status === 401) {
              return authRepository.refreshToken()
                  .then(response => setAccessToken(response.access))
                  .then(() => repository.fetch(request))
                  .then(response => {
                    store.dispatch(ActionType.setAnimationDays, response)
                    if (!isCreate) {
                      prepareTimeHeatmapData()
                    }
                    return true
                  })
                  .catch(err => {
                    if (err.status === 404) {
                      WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
                    } else if (err.status === 409) {
                      WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
                    } else if (err.status === 424) {
                      WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
                    } else if (err.status === 416) {
                      WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
                    } else {
                      AlertDialog(err.message)
                    }
                  })
            } else if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
          .finally(() => {
            store.dispatch(`daySeries/${DaySeriesActionType.setDoInitFlg}`, false)
            store.dispatch(`daySeries/${DaySeriesActionType.setDayConstraint}`, state.dayFilter)
            store.dispatch(`daySeries/${DaySeriesActionType.setDayFilter}`, state.dayFilter)
            store.dispatch(`daySeries/${DaySeriesActionType.setTimeLabel}`, timeRangeLabel.value)
            store.dispatch(`daySeries/${DaySeriesActionType.setActiveTime}`, timeRangeLabel.value)
          })
    }

    const prepareTimeHeatmapData = () => {
      const timeHeatmaps: IAnimationDaysType = store.getters['selectAnimationDays'];
      const timeAry: any = [];
      let minCol = 0
      let maxCol = 0
      let init = false
      timeHeatmaps.forEach((timeData: IAnimationDayType) => {
        if (!timeAry[timeData[2]]) {
          timeAry[timeData[2]] = [];
        }
        timeAry[timeData[2]].push(timeData)
        //初期化
        if (!init) {
          minCol = timeData[3]
          maxCol = timeData[3]
          init = true
        } else {
          minCol = timeData[3] < minCol ? timeData[3] : minCol
          maxCol = timeData[3] > maxCol ? timeData[3] : maxCol
        }
      });
      //
      const hmAry: HeatmapLayer[] = []
      for (let prop in timeAry) {
        hmAry[prop] = new HeatmapLayer({
          data: timeAry[prop],
          intensity: Config.Instance.heatmapDetailProps.intensity,
          opacity: Config.Instance.heatmapDetailProps.opacity,
          threshold: Config.Instance.heatmapDetailProps.threshold,
          radiusPixels: Config.Instance.heatmapDetailProps.radiusPixels,
          colorDomain: [minCol, maxCol],
          getPosition: (d) => {
            return [d[1], d[0]]
          },
          getWeight: (d) => d[3],
          colorRange: Config.Instance.heatmapColors.rgb,
          aggregation: "SUM",
        })
      }
      const timestamps = Object.keys(timeAry)
      state.heatmaps = hmAry
      state.activeTime = timestamps.length > 0 ? timestamps[0] : ''
      state.resultDayFilter = state.dayFilter
      state.resultTime = store.state.daySeries.time
      deckOverlay.setProps({ layers: [ state.heatmaps[state.activeTime] ] });
    }

    const handleResetDayFilter = () => {
      state.dayFilter = getDefaultDayFilter() // reset day filter to default
    }

    const handleSearch = () => {
      // API call
      const hourRange = store.state.daySeries.time;
      const hour: Array<number> = [];
      for (let i = hourRange[0]; i < hourRange[1]; i++) {
        hour.push(i);
      }
      //
      // 更新
      // 現在のヒートマップはリセット
      state.heatmaps = [];
      loadAnimationDay(hour, false);
    }

    onMounted(() => {
      // 現在のヒートマップはリセット
      state.heatmaps = [];
    })

    handleSearch()
    const handleDownload = () => {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = "DayTimeSeries.png";
          link.target = '_blank';
          link.click();
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 250)
    }

    onBeforeUnmount(() => {
      globalEmitter.off(HINT_ROAD_SAME_DAY_ANIMATION)
      globalEmitter.off(DOWNLOAD_ROAD_DAY_ANIM)
    })

    globalEmitter.on(DOWNLOAD_ROAD_DAY_ANIM, () =>  {
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = '同日行動.png'
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_ROAD_SAME_DAY_ANIMATION, () => {
      HintDialog(RoadSameDayAnim)
    })

    return {
      ...toRefs(state),
      gMap,
      center,
      apiKey,
      defaultZoom,
      loadTitles,
      tableUnitVal,
      mapStyle,
      stackBar,
      getDayLabel,
      timeRangeLabel,
      dataLabel,
      availableDayFilter,
      isLoading,
      isPrepareHeatmap,
      isCapturing,
      sbMinimum,
      colors,
      maxZoom,
      onResetWeek,
      onResetTime,
      ButtonType,
      handleSearch,
      onTimeSeriesChange,
      onLoad,
      setDatalabels,
      handleZoomIn,
      handleZoomOut,
      handleCurrentLocation,
      handleResetDayFilter,
      handleDownload,
      handleShowRoadChange,
      minimumHandler,
      onExceptChange
     }
  },
})
