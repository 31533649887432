import {IRailwayAnimationType, IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import data from '@/assets/stubs/railwayBarchart.json'
import comparison from '@/assets/stubs/railwayComparison.json'
import heatmap from '@/assets/stubs/railwayHeatmap.json'
import animation from '@/assets/stubs/railwayAnimation.json'

export interface IRailwayStubType {
  fetchRailwayStationBarchart(): Promise<IRailwayStationBarchartType>
  fetchHeatmap(): Promise<number[][]>
  fetchComparison(): Promise<IRailwayComparisonType>
  fetchAnimation(): Promise<IRailwayAnimationType>
}

export default class RailwayStubStore implements IRailwayStubType {
  fetchRailwayStationBarchart(): Promise<IRailwayStationBarchartType> {
    return new Promise<IRailwayStationBarchartType>((resolve) => {
      resolve(data)
    })
  }
  
  fetchHeatmap(): Promise<number[][]> {
    return new Promise<number[][]>((resolve) => {
      resolve(heatmap as number[][])
    })
  }
  
  fetchComparison(): Promise<IRailwayComparisonType> {
    return new Promise<IRailwayComparisonType>((resolve) => {
      resolve(comparison as IRailwayComparisonType)
    })
  }
  
  fetchAnimation(): Promise<IRailwayAnimationType> {
    return new Promise<IRailwayAnimationType>((resolve) => {
      resolve(animation as IRailwayAnimationType)
    })
  }
}
