import {IHttpClientType, IHttpErrorType} from './@types'
import {APIType} from './apiType'
import {HttpRequest} from './httpRequest'
import axios, {AxiosResponse} from 'axios'
import router from '@/router'
import {SESSION_NAMESPACE} from '@/constant'

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

export class HttpClient<T> implements IHttpClientType<T> {
  private BASE_PATH = process.env.VUE_APP_BASE_URL || ''

  async request<T>(request: HttpRequest<T>): Promise<AxiosResponse<T>> {
    const isRead = request.method === HTTPMethod.GET
    return axios.request<any, AxiosResponse<T>, any>({
      url: request.path,
      method: request.method,
      params: isRead && request.params,
      data: !isRead && request.params,
      baseURL: request.basePath || this.BASE_PATH,
      headers: this.createHeaders(request.bearer),
    }).catch((err) => {
      throw this.normalizeError(err, request.apiType)
    })
  }

  normalizeError(error: Record<string, any>, type: APIType): IHttpErrorType {
    let status = 9999
    let message = this.invalidErrorMessage(status)
    if (error.response) {
      if (error.response.status) {
        status = error.response.status
      }

      if (error.response.data.detail) {
        message = error.response.data.detail
      }
      
      if (error.response.data.error) {
        message = error.response.data.error
      }
    }
  
    // 500 error will be treated as unexpected error
    if (status === 500) {
      message = '予期せぬエラーが発生しました'
    }
    
    if (status === 401 && type === APIType.RefreshToken) {
      localStorage.removeItem(SESSION_NAMESPACE)
      router.push('/login')
    }

    return {
      message: message,
      status: status,
      body: {
        type: type
      }
    }
  }

  createHeaders(bearer?: string): Record<string, any> {
    const header = {}
    if (bearer !== null && bearer !== undefined) {
      header['X-Access-Token'] = `Bearer ${bearer}`
    }
    return header
  }

  invalidErrorMessage (code: number): string {
    switch (code) {
      case 400:
        return 'Bad Request'

      case 405:
        return 'Method not allowed'

      case 401:
        return '不正なエラー'

      case 408:
        return 'timeoutエラー'

      default: return '予期せぬエラーが発生しました'
    }
  }
}
