
import {computed, defineComponent, PropType} from 'vue'
import {IRailLineType} from '@/data/master/railway/type'
import {RailLineActionType} from '@/store/modules/railway/master/line/actions'
import {useStore} from 'vuex'
import UIRadioButton from '@/components/UI/UIRadioButton.vue'

export default defineComponent({
  name: 'RailwayAccordion',
  components: {UIRadioButton},
  props: {
    line: { type: Object as PropType<IRailLineType>, required: true },
    border: { type: Boolean, default: true }
  },
  emits: ['change'],
  setup(props, {emit}) {
    const store = useStore()
    const selectedStation = computed(() => store.state.railLine.selectedStation)
    const handleToggle = () => {
      store.dispatch(`railLine/${RailLineActionType.toggleLineCollapse}`, props.line)
    }
    const handleStationChange = (value: string) => {
      const acc = props.line
      acc.selectedStation = value
      store.dispatch(`railLine/${RailLineActionType.setSelectedLine}`, `${props.line.company_name}-${props.line.line_name}`)
      store.dispatch(`railLine/${RailLineActionType.setSelectedLineStation}`, acc)
      emit('change', value)
    }

    return {
      selectedStation,
      handleToggle,
      handleStationChange
    }
  },
})
