import {multiPolygon, points, pointsWithinPolygon, Position} from '@turf/turf';

export const isPolygonIntersect = function (left: Array<{latitude: number; longitude: number}>, right: Array<{latitude: number; longitude: number}>): boolean {
  const serializedLeft = serializePolygon(left);
  const parsedLeft = multiPolygon(serializedLeft);
  const serializedRight = serializePoints(right);
  const parsedRight = points(serializedRight);
  const inPolygon = pointsWithinPolygon(parsedRight, parsedLeft);
  return inPolygon.features.length > 0;
}

const serializePolygon = function (polygon: Array<{latitude: number; longitude: number}>): Position[][][] {
  const res = polygon.map(p => {
    return [Number(p.longitude), Number(p.latitude)];
  });
  if (res.length > 0) {
    const first = res[0];
    const last = res[res.length - 1];
    if (first.length === 2 && last.length === 2) {
      if (first[0] !== last[0] || first[1] !== last[1]) {
        res.push(first);
      }
    }
  }
  return [[res]];
}

const serializePoints = function (points: Array<{latitude: number; longitude: number}>): number[][] {
  return points.map(p => {
    return [Number(p.longitude), Number(p.latitude)];
  });
}
