import {PersonaMasterState} from '@/store/modules/area/master/persona/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/master/persona/getters'
import {actions} from '@/store/modules/area/master/persona/actions'
import {mutations} from '@/store/modules/area/master/persona/mutations'

export const state: PersonaMasterState = {
  personas: []
}

const namespaced = true
export const personaMaster: Module<PersonaMasterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
