<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <div class="jr-font-base jr-text-sm">
        <p>来訪日ごとの滞在時間を集計しています。</p>
        <div class="jr-pt-4">
          <p>集計は「日のべ人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合は2とカウント</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
