import {ITranslatorType} from '@/types'
import {IFacilityType} from '@/data/area/type'

export default class FacilityJsonTranslator implements ITranslatorType<Record<string, any>, IFacilityType> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
  translate(input: Record<string, any>, ...args): IFacilityType {
    return {
      name: input.name,
      volume: input.volume,
      type: input.type,
      coordinate: input.coordinate,
      isSelected: false
    }
  }
}
