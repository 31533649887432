import {AreaVisitorState} from '@/store/modules/area/search/visitor/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/visitor/getters'
import {actions} from '@/store/modules/area/search/visitor/actions'
import {mutations} from '@/store/modules/area/search/visitor/mutations'

export const state: AreaVisitorState = {
  persona: [],
  ages: {
    constraints: [],
    men: [],
    women: []
  }
}

const namespaced = true
export const areaVisitor: Module<AreaVisitorState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
