
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import { ButtonType } from '@/enum/button'
import UIListSelect from "@/components/UI/UIListSelect.vue";
import UIButton from '@/components/UI/UIButton.vue';
import UIColorToggler from '@/components/UI/UIColorToggler.vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {IRoadType} from "@/data/road/type";
import {ActionType} from "@/store";
import {DaySeriesActionType} from "@/store/modules/dayseries/actions";
import {HourSeriesActionType} from "@/store/modules/hourseries/actions";
import {Config} from "@/config";
import {ChartActionType} from "@/store/modules/chart/actions";
import {getSelectedRoad, setAccessToken, setSelectedRoad} from "@/utils/storage";
import {AlertDialog, WarningDialog, WarningPromptDialog} from "@/service/dialog";
import AuthAPIStore from "@/data/auth/store";
import AuthRepository from "@/data/auth/repository";
import ChartStubStore from "@/data/chart/store/stub";
import ChartAPIStore from "@/data/chart/store";
import ChartRepository from "@/data/chart/repository";
import {isPolygonIntersect} from "@/utils/geometry";
import {
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  MAX_THRESHOLD_MESSAGE,
  MAX_THRESHOLD_TITLE,
  NOTIFY_LONG_PROCESSING_MESSAGE,
  NOTIFY_LONG_PROCESSING_TITLE,
  PROCESS_CANCELED_TITLE,
  PROCESS_CANCELED_MESSAGE, EMPTY_ROAD
} from "@/constant";
import {DayCode} from "@/enum/barchart";

export default defineComponent({
  name: "RoadPolygonSidebar",
  components: {
    UIButton,
    UIListSelect,
    UIColorToggler,
  },
  emits: ['clear', 'select-range', 'clearBackPolygon', 'newPolygon'],
  setup(_,{ emit }) {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      minimum: false,
      step: 1,
      colors: Config.Instance.heatmapColors.hex,
    })
    const multiSelector = ref()

    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const stub = new ChartStubStore()
    const api = new ChartAPIStore()
    const repository = new ChartRepository(stub, api)

    const isSelectedRoad = computed(() => {
      return store.getters['selectedRoad'].length > 0;
    })

    const isEnablePolygonBtn = computed(() => {
      return store.getters['hasPolygon'] && !store.getters['hasQueries']
    })

    const onClickClose = (event) => {
      event.preventDefault();
      state.minimum = !state.minimum;
    }

    const clearAll = (event) => {
      event.preventDefault();
      emit('clear');
    }

    const onShowSelected = () => {
      WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
        state.step = 2;
        emit('select-range')
      })
    }

    const roadSeachOpt = computed(() => {
      const roadMaster = store.getters['roadMaster']
      const opt: {label: string; value: string }[] = [];
      roadMaster.forEach((road: IRoadType) => {
        opt.push({
          label: road.name,
          value: road.name
        })
      })
      return opt.length > 0 ? opt : [];
    })

    const handleClearHeatmap = (event) => {
      event.preventDefault();
      store.dispatch(ActionType.setSelectedRoad, []);
      multiSelector.value.clear()
      emit('clearBackPolygon')
    }

    const handleRoadOption = (opt) => {
      const roadMaster = store.getters['roadMaster']
      const selectedRoadDatas:IRoadType[] = [];
      opt.forEach((o) => {
        const selectedRoadData = roadMaster.find((road) => {
          return road.name === o;
        });
        if (selectedRoadData) {
          selectedRoadDatas.push(selectedRoadData);
        }
      })
      store.dispatch(ActionType.setSelectedRoad, selectedRoadDatas)
    }

    const handleNewPolygon = () => emit('newPolygon')

    const handleClearNewPolygon = (event) => {
      event.preventDefault();
      emit('clearBackPolygon')
    }

    const handleReport = () => {
      if (store.getters['selectedRoad'].length > 0) {
        WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', () => {
          store.dispatch(ActionType.setMakingTable, true)
          // chart table (1)
          prepareChart().then(() => {
            // day table (2)
            store.dispatch(`daySeries/${DaySeriesActionType.prepareAnimationDay}`).then(() => {
              // hour table (3)
              store.dispatch(`hourSeries/${HourSeriesActionType.loadAnimationHour}`).then(() => {
                // move to
                store.dispatch(ActionType.setMakingTable, false)
                router.push('/report')
              }).catch(err => {
                if (err.status === 424) {
                  WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
                }
                if (err.status === 409) {
                  WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
                }
              })
            }).catch(err => {
              if (err.status === 424) {
                WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
              }
              if (err.status === 409) {
                WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
              }
            })
          }).catch(err => {
            AlertDialog(err.message)
            store.dispatch(ActionType.setMakingTable, false)
          })
        })
      }
    }

    const prepareChart = () => {
      const time = store.state.chart.time // 時間帯
      const reSelectpolygon = store.getters['getNewPolygon']
      let selectedRoad: IRoadType[] = JSON.parse(JSON.stringify(store.getters['selectedRoad'])); // 道路IDs
      if (selectedRoad && selectedRoad.length === 0) {
        selectedRoad = getSelectedRoad();
      }
      let roadIds: string[] = [];

      const diffPolygonFlg = reSelectpolygon.length > 0;
      const request: any = {
        from: time[0],
        to: time[1],
        road_ids: roadIds,
        week: store.state.chart.dayFilter,
        diff_polygon: diffPolygonFlg,
      }

      // decide between day or week filter
      const isWeekFilter = store.getters['chart/filterWeek']
      if (isWeekFilter) {
        // const flg = store.getters['chart/getWeekdayFlag']
        let theWeek: DayCode[] = []
        // request['weekday_flag'] = flg
        if (store.state.chart.weekFilter.includes(0)) {
          theWeek = theWeek.concat([
            DayCode.Saturday,
            DayCode.Sunday
          ])
        }
        if (store.state.chart.weekFilter.includes(1)) {
          theWeek = theWeek.concat([
            DayCode.Monday,
            DayCode.Tuesday,
            DayCode.Wednesday,
            DayCode.Thursday,
            DayCode.Friday
          ])
        }
        request['week'] = theWeek
      }

      if (reSelectpolygon.length > 0) {
        request.polygon = reSelectpolygon;
        selectedRoad.forEach(road => {
          for (const name in road.polygon) {
            const isValid = isPolygonIntersect(reSelectpolygon, road.polygon[name]);
            if (!isValid) {
              delete road.polygon[name];
              road.id = road.id.filter(i => i !== name);
            }
          }
        });
        selectedRoad = selectedRoad.filter(road => Object.keys(road.polygon).length > 0);
      }
      setSelectedRoad(selectedRoad);
      selectedRoad.forEach((road) => {
        roadIds = roadIds.concat(road.id)
      });
      request.road_ids = roadIds;
      if (roadIds.length === 0) {
        return Promise.reject({ message: EMPTY_ROAD })
      }

      return repository.fetch(request)
      .then(response => store.dispatch(`chart/${ChartActionType.setChart}`, response))
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => repository.fetch(request))
          .then(response => store.dispatch(`chart/${ChartActionType.setChart}`, response))
          .catch(err => {
            if (err.status === 404) {
              WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            } else if (err.status === 416) {
              WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
            } else if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            } else if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            } else {
              AlertDialog(err.message)
            }
          })
        } else if (err.status === 404) {
          WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
        } else if (err.status === 416) {
          WarningDialog(MAX_THRESHOLD_TITLE, MAX_THRESHOLD_MESSAGE)
        } else if (err.status === 409) {
          WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
        } else if (err.status === 424) {
          WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
        } else {
          AlertDialog(err.message)
        }
      })
      .finally(() => {
        store.dispatch(`chart/${ChartActionType.setDoInitFlg}`, false)
      })
    }

    return {
      multiSelector,
      ButtonType,
      ...toRefs(state),
      isEnablePolygonBtn,
      isSelectedRoad,
      roadSeachOpt,
      handleRoadOption,
      handleClearHeatmap,
      handleNewPolygon,
      handleClearNewPolygon,
      clearAll,
      onShowSelected,
      onClickClose,
      handleReport
    }
  }
})
