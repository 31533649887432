
import {computed, defineComponent, PropType} from 'vue'
import {DoughnutChart, useDoughnutChart} from 'vue-chart-3'
import {useStore} from 'vuex'
import {AREA_WARNING_TITLE} from '@/constant'

export default defineComponent({
  name: 'TimingDoughnutChart',
  components: {
    DoughnutChart
  },
  props: {
    label: { type: String, required: false, default: '' },
    cutout: { type: Number, required: false, default: 70 },
    width: { type: Number, required: false, default: 0 },
    height: { type: Number, required: false, default: 0 },
    labels: { type: Array as PropType<Array<string>>, required: false, default: () => ([]) },
    data: { type: Array as PropType<Array<number>>, required: false, default: () => ([]) },
    average: { type: Number, required: false, default: 0 }
  },
  setup (props) {
    const store = useStore()
    const times = computed(() => store.state.areaTimeSpent.times)
    const isError = computed(() => {
      return props.data ? props.data.some(d => d < store.state.percentageThreshold) : false
    })
    const title = AREA_WARNING_TITLE
    const doughnutStyle = computed(() => {
      return {
        width: props.width > 0 ? `${props.width}px` : 'auto',
        height: props.height > 0 ? `${props.height}px` : 'auto'
      }
    })
    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      cutout: props.cutout,
      // events: [], // remove this setting to show event related items such as tooltips etc
      elements: {
        arc: { borderWidth: 0 }
      },
      plugins: {
        bodyFont: {
          size: 14
        },
        tooltip: {
          position: 'nearest',
          callbacks: {
            label: function(chart) {
              return `${chart.label} ${chart.formattedValue}%`
            }
          }
        },
        legend: {
          display: false,
          position: 'right',
          align: 'center',
          labels: {
            color: '#000',
            boxWidth: 14,
            boxHeight: 14,
            generateLabels: (chart) => {
              const datasets = chart.data.datasets;
              return datasets[0].data.map((data, i) => ({
                text: `${chart.data.labels[i]} ${data}%`,
                fillStyle: datasets[0].backgroundColor[i],
                volume: data
              }))
            },
            sort: (a, b) => a.volume - b.volume
          }
        },
        datalabels: {
          color: '#fff',
          anchor: 'center',
          display: false,
          font: { weight: '400', size: 16 },
          textAlign: 'center',
          formatter: function (value, context) {
            return `${chartData.value.labels[context.dataIndex]}\n${value}%`
          }
        }
      }
    }))
    const chartData = computed(() => ({
      labels: props.labels,
      datasets: [
        {
          data: props.data,
          backgroundColor: [
            'rgb(15, 65, 145)',
            'rgb(25, 103, 210)',
            'rgb(38, 160, 230)',
            'rgb(40, 185, 240)',
            'rgb(50, 200, 245)',
            'rgb(200, 200, 200)'
          ]
        }
      ]
    }))

    const { doughnutChartProps } = useDoughnutChart({
      chartData,
      options
    })
    return {
      chartData,
      doughnutChartProps,
      doughnutStyle,
      times,
      isError,
      title
    }
  }
})
