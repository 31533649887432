import { DayCode } from '@/enum/barchart'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { DaySeriesState } from './type'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state: DaySeriesState = {
  dayFilter: [
    DayCode.Monday,
    DayCode.Tuesday,
    DayCode.Wednesday,
    DayCode.Thursday,
    DayCode.Friday,
    DayCode.Saturday,
    DayCode.Sunday,
    DayCode.EveryDay
  ],
  timeLabel: '',
  dayConstraint: [],
  time: [0, 24],
  activeTime: '',
  doInitFlg: false,
}

const namespaced = true
export const daySeries: Module<DaySeriesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
