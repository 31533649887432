import {HttpRequest} from '@/core/httpRequest'
import {IPrefectureType} from '@/data/master/area/prefecture/type'
import {HttpClient} from '@/core/httpClient'
import PrefectureMasterJsonTranslator from '@/data/master/area/prefecture/translator'

export interface IPrefectureMasterAPIStoreType {
  fetch(request: HttpRequest<IPrefectureType[]>): Promise<IPrefectureType[]>
}

export default class PrefectureAPIStore implements IPrefectureMasterAPIStoreType {
  protected translator: PrefectureMasterJsonTranslator
  constructor () {
    this.translator = new PrefectureMasterJsonTranslator()
  }
  async fetch(request: HttpRequest<IPrefectureType[]>): Promise<IPrefectureType[]> {
    const client = new HttpClient<IPrefectureType[]>()
    const response = await client.request<IPrefectureType[]>(request)
    return response.data.map(d => this.translator.translate(d))
  }
}
