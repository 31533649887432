import {HttpRequest} from '@/core/httpRequest'
import {HTTPMethod} from '@/core/httpClient'
import {getAccessToken} from '@/utils/storage'
import {APIType} from '@/core/apiType'
import {IAreaConfig, IRankingType, ITimeSpentType, IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'

export class AreaHeatmapRequest implements HttpRequest<(number | string)[][]> {
  public response!: (number | string)[][]
  public path = '/area/day/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaSameDayAnimation
  
  constructor (params: Record<string, any>) {
    const cloneParams = JSON.parse(JSON.stringify(params))
    delete cloneParams.dashboard
    this.params = cloneParams
  }
}

export class AreaTimeSpentRequest implements HttpRequest<{average: number; data: ITimeSpentType[]}> {
  public response!: {average: number; data: ITimeSpentType[]}
  public path = '/area/time_spent/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTimeSpent
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaVisitorRequest implements HttpRequest<{persona: IVisitorPersonaType[]; ages: IVisitorAgeType}> {
  public response!: {persona: IVisitorPersonaType[]; ages: IVisitorAgeType}
  public path = '/area/visitor/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaVisitor
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaTransportationChartRequest implements HttpRequest<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}> {
  public response!: {in_area: ITransportationChart[]; visit_area: ITransportationChart[]}
  public path = '/area/transportations/chart/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaTransportationChart
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaRankingRequest implements HttpRequest<{
  min: number;
  max: number;
  resident: IRankingType[];
  work_location: IRankingType[]
}> {
  public response!: {
    min: number;
    max: number;
    resident: IRankingType[];
    work_location: IRankingType[]
  }
  public path = '/area/ranking/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaRanking
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaFacilityPinRequest implements HttpRequest<Array<(number | string)[]>> {
  public response!: Array<(number | string)[]>
  public method = HTTPMethod.POST
  public path = '/area/facility/pins/'
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaFacilityPin
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class AreaScatterplotRequest implements HttpRequest<Array<number[]>> {
  public response!: Array<number[]>
  public method = HTTPMethod.POST
  public path = '/area/transportations/scatterplot/'
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaScatterPlot
  
  constructor (params: Record<string, any>) {
    const cloneParams = JSON.parse(JSON.stringify(params))
    delete cloneParams.dashboard
    this.params = cloneParams
  }
}

export class AreaConfigRequest implements HttpRequest<IAreaConfig[]> {
  public response!: IAreaConfig[]
  public method = HTTPMethod.POST
  public path = '/area/app_configs/'
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.AreaConfig
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}
