import {IHeatmapQueryType, IQueryType} from '@/data/query/type'
import Vuex, {StoreOptions} from 'vuex'
import {RootState} from './types'
import HeatmapService from '@/service/heatmap'
import {ICoordinateType} from '@/types'
import {IRoadType} from '@/data/road/type'
import {IAnimationDaysType} from '@/data/animationday/type'
import {IAnimationHoursType} from '@/data/animationhour/type'
import {getUsername} from '@/utils/storage'

// modules import
import {chart} from './modules/chart'
import {daySeries} from './modules/dayseries'
import {hourSeries} from './modules/hourseries'
import {area} from './modules/area'
import {personaMaster} from '@/store/modules/area/master/persona'
import {transportation} from '@/store/modules/area/search/transportation'
import {areaHeatmap} from '@/store/modules/area/search/heatmap'
import {areaPolygonMaster} from '@/store/modules/area/master/polygon'
import {prefectureMaster} from '@/store/modules/area/master/prefecture'
import {areaTimeSpent} from '@/store/modules/area/search/timeSpent'
import {areaVisitor} from '@/store/modules/area/search/visitor'
import {areaRanking} from '@/store/modules/area/search/ranking'
import {areaFacilityPin} from '@/store/modules/area/search/facility'
import {areaFacilityMaster} from '@/store/modules/area/master/facility'
import {railway} from '@/store/modules/railway'
import {railLine} from '@/store/modules/railway/master/line'

export enum ActionType {
  setUsername = 'setUsername',
  setAppLoading = 'setAppLoading',
  setMakingTable = 'setMakingTable',
  setSideNavigation = 'setSideNavigation',
  setQueries = 'setQueries',
  setShowHeatmap = 'setShowHeatmap',
  setPolygon = 'setPolygon',
  setNewPolygon = 'setNewPolygon',
  setRoadMaster = 'setRoadMaster',
  setSelectedRoad = 'setSelectedRoad',
  setAnimationDays = 'setAnimationDays',
  setAnimationHours = 'setAnimationHours',
  setMapPos = 'setMapPos',
  setHasSearch = 'setHasSearch',
  touchFullDisplay = 'touchFullDisplay',
  touchPolygon = 'touchPolygon',
  setDefaultCenter = 'setDefaultCenter',
  setDisplayMenus = 'setDisplayMenus',
  setDefaultZoom = 'setDefaultZoom',
  setPercentageThreshold = 'setPercentageThreshold',
  setHeatmapMaxZoom = 'setHeatmapMaxZoom',
  setIsCapturing = 'setIsCapturing',
  setRoadSbMinimum = 'setRoadSbMinimum',
  setShowAreaVisitor = 'setShowAreaVisitor'
}

export enum MutationType {
  SET_USERNAME = 'SetUsername',
  SET_APP_LOADING = 'SetAppLoading',
  SET_MAKING_TABLE = 'SetMakingTable',
  SET_SIDE_NAVIGATION = 'SetSideNavigation',
  SET_QUERIES = 'SetQueries',
  SET_SHOW_HEATMAP = 'SetShowHeatmap',
  SET_POLYGON = 'SetPolygon',
  SET_NEW_POLYGON = 'SetNewPolygon',
  SET_ROAD_MASTER = 'SetRoadMaster',
  SET_SELECTED_ROAD = 'SetSelectedRoad',
  SET_ANIMATION_DAYS = 'SetAnimationDays',
  SET_ANIMATION_HOURS = 'SetAnimationHours',
  SET_MAP_POS = 'setMapPos',
  SET_HAS_SEARCH = 'setHasSearch',
  TOUCH_FULL_DISPLAY = 'touchFullDisplay',
  TOUCH_POLYGON = 'touchPolygon',
  SET_DEFAULT_CENTER = 'SetDefaultCenter',
  SET_DISPLAY_MENUS = 'SetDisplayMenus',
  SET_DEFAULT_ZOOM = 'SetDefaultZoom',
  SET_PERCENTAGE_THRESHOLD = 'SetPercentageThreshold',
  SET_IS_CAPTURING = 'SetIsCapturing',
  SET_ROAD_SB_MINIMUM = 'SetRoadSBMinimum',
  SET_HEATMAP_MAX_ZOOM = 'SetHeatmapMaxZoom',
  SET_SHOW_AREA_VISITOR = 'SetShowAreaVisitor',
}

const service = new HeatmapService()
const latlng = localStorage.getItem('latlng')
const store: StoreOptions<RootState> = {
  state: {
    username: getUsername(),
    isFullDisplayTouch: false,
    isPolygonTouch: false,
    isHasSearch: false,
    isShowHeatmap: false,
    isAppLoading: false,
    isMakingTable: false,
    isSideNavigation: true,
    isCapturing: false,
    roadSbMinimum: false,
    queries: [],
    polygon: [],
    newPolygon: [],
    roadMaster: [],
    selectedRoad: [],
    animationDays: [],
    animationHours: [],
    // defaultCenter: { lat: 36.7018311, lng: 137.2104371 },
    defaultCenter: latlng ? JSON.parse(latlng) as {lat: number; lng: number} : { lat: 36.7018311, lng: 137.2104371 },
    mapPos: { lat: 35.681236, lng: 139.767125 },
    displayMenus: ['road', 'area', 'railway'],
    defaultZoom: 10,
    percentageThreshold: 1,
    heatmapMaxZoom: 12,
  },
  actions: {
    [ActionType.setUsername] ({ commit }, payload: string): void {
      commit(MutationType.SET_USERNAME, payload)
    },
    [ActionType.setAppLoading] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_APP_LOADING, payload)
    },
    [ActionType.setMakingTable] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_MAKING_TABLE, payload)
    },
    [ActionType.setSideNavigation] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_SIDE_NAVIGATION, payload)
    },
    [ActionType.setQueries] ({ commit }, payload: IQueryType[]): void {
      commit(MutationType.SET_QUERIES, payload)
    },
    [ActionType.setShowHeatmap] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_SHOW_HEATMAP, payload)
    },
    [ActionType.setPolygon] ({ commit }, payload: ICoordinateType[]): void {
      commit(MutationType.SET_POLYGON, payload)
    },
    [ActionType.setNewPolygon] ({ commit }, payload: ICoordinateType[]): void {
      commit(MutationType.SET_NEW_POLYGON, payload)
    },
    [ActionType.setRoadMaster] ({ commit }, payload: IRoadType[]): void {
      commit(MutationType.SET_ROAD_MASTER, payload)
    },
    [ActionType.setSelectedRoad] ({ commit }, payload: IRoadType[]): void {
      commit(MutationType.SET_SELECTED_ROAD, payload)
    },
    [ActionType.setAnimationDays] ({ commit }, payload: IAnimationDaysType): void {
      commit(MutationType.SET_ANIMATION_DAYS, payload)
    },
    [ActionType.setAnimationHours] ({ commit }, payload: IAnimationHoursType): void {
      commit(MutationType.SET_ANIMATION_HOURS, payload)
    },
    [ActionType.setMapPos] ({ commit }, payload: {lat:number; lng: number}): void {
      commit(MutationType.SET_MAP_POS, payload)
    },
    [ActionType.setHasSearch] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_HAS_SEARCH, payload)
    },
    [ActionType.touchFullDisplay] ({ commit }, payload: boolean): void {
      commit(MutationType.TOUCH_FULL_DISPLAY, payload)
    },
    [ActionType.touchPolygon] ({ commit }, payload: boolean): void {
      commit(MutationType.TOUCH_POLYGON, payload)
    },
    [ActionType.setDefaultCenter] ({ commit }, payload: {lat: number; lng: number}): void {
      commit(MutationType.SET_DEFAULT_CENTER, payload)
    },
    [ActionType.setDisplayMenus] ({ commit }, payload: string[]): void {
      commit(MutationType.SET_DISPLAY_MENUS, payload)
    },
    [ActionType.setDefaultZoom] ({ commit }, payload: number): void {
      commit(MutationType.SET_DEFAULT_ZOOM, payload)
    },
    [ActionType.setPercentageThreshold] ({ commit }, payload: number): void {
      commit(MutationType.SET_PERCENTAGE_THRESHOLD, payload)
    },
    [ActionType.setIsCapturing] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_IS_CAPTURING, payload)
    },
    [ActionType.setRoadSbMinimum] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_ROAD_SB_MINIMUM, payload)
    },
    [ActionType.setHeatmapMaxZoom] ({ commit }, payload: boolean): void {
      commit(MutationType.SET_HEATMAP_MAX_ZOOM, payload)
    },
    [ActionType.setShowAreaVisitor] ({ commit }, payload: number): void {
      commit(MutationType.SET_SHOW_AREA_VISITOR, payload)
    }
  },
  mutations: {
    [MutationType.SET_USERNAME] (state: RootState, payload: string): void {
      state.username = payload
    },
    [MutationType.SET_APP_LOADING] (state: RootState, payload: boolean): void {
      state.isAppLoading = payload
    },
    [MutationType.SET_MAKING_TABLE] (state: RootState, payload: boolean): void {
      state.isMakingTable = payload
    },
    [MutationType.SET_SIDE_NAVIGATION] (state: RootState, payload: boolean): void {
      state.isSideNavigation = payload
    },
    [MutationType.SET_QUERIES] (state: RootState, payload: Array<Array<number>>): void {
      state.queries = payload
    },
    [MutationType.SET_SHOW_HEATMAP] (state: RootState, payload: boolean): void {
      state.isShowHeatmap = payload
    },
    [MutationType.SET_POLYGON] (state: RootState, payload: ICoordinateType[]): void {
      state.polygon = payload
    },
    [MutationType.SET_NEW_POLYGON] (state: RootState, payload: ICoordinateType[]): void {
      state.newPolygon = payload
    },
    [MutationType.SET_ROAD_MASTER] (state: RootState, payload: IRoadType[]): void {
      state.roadMaster = payload
    },
    [MutationType.SET_SELECTED_ROAD] (state: RootState, payload: IRoadType[]):  void {
      state.selectedRoad = payload
    },
    [MutationType.SET_ANIMATION_DAYS] (state: RootState, payload: IAnimationDaysType): void {
      state.animationDays = payload
    },
    [MutationType.SET_ANIMATION_HOURS] (state: RootState, payload: IAnimationHoursType): void {
      state.animationHours = payload
    },
    [MutationType.SET_MAP_POS] (state: RootState, payload: {lat: number; lng: number}): void {
      state.mapPos = payload
    },
    [MutationType.SET_HAS_SEARCH] (state: RootState, payload: boolean): void {
      state.isHasSearch = payload
    },
    [MutationType.TOUCH_FULL_DISPLAY] (state: RootState, payload: boolean): void
    {
      state.isFullDisplayTouch = payload
    },
    [MutationType.TOUCH_POLYGON] (state: RootState, payload: boolean): void
    {
      state.isPolygonTouch = payload
    },
    [MutationType.SET_DEFAULT_CENTER] (state: RootState, payload: {lat: number; lng: number}): void {
      state.defaultCenter = payload
    },
    [MutationType.SET_DISPLAY_MENUS] (state: RootState, payload: string[]): void {
      if (payload && Array.isArray(payload)) {
        state.displayMenus = payload
      }
    },
    [MutationType.SET_DEFAULT_ZOOM] (state: RootState, payload: number): void {
      if (payload && !isNaN(payload)) {
        state.defaultZoom = payload
      }
    },
    [MutationType.SET_PERCENTAGE_THRESHOLD] (state: RootState, payload: number): void {
      if (payload && !isNaN(payload)) {
        state.percentageThreshold = payload
      }
    },
    [MutationType.SET_IS_CAPTURING] (state: RootState, payload: boolean): void {
      state.isCapturing = payload;
    },
    [MutationType.SET_ROAD_SB_MINIMUM] (state: RootState, payload: boolean): void {
      state.roadSbMinimum = payload
    },
    [MutationType.SET_HEATMAP_MAX_ZOOM] (state: RootState, payload: number): void {
      state.heatmapMaxZoom = payload
    },
    [MutationType.SET_SHOW_AREA_VISITOR] (state: RootState, payload: number): void {
      state.showAreaVisitor = payload
    }
  },
  getters: {
    hasQueries (state: RootState): boolean {
      return state.queries.length > 0
    },
    searchHeatmaps (state: RootState): IHeatmapQueryType[] {
      return service.getForPlot(state.queries)
    },
    animationHeatmaps (state: RootState): IHeatmapQueryType[] {
      return service.getForAnimation(state.queries)
    },
    getPolygon (state: RootState): ICoordinateType[] {
      return state.polygon
    },
    hasPolygon (state: RootState): boolean {
      return state.polygon.length > 0
    },
    hasNewPolygon (state: RootState): boolean {
      return state.newPolygon.length > 0
    },
    getNewPolygon (state: RootState): ICoordinateType[] {
      return state.newPolygon
    },
    roadMaster (state: RootState): IRoadType[] {
      return state.roadMaster
    },
    selectedRoad (state: RootState): IRoadType[] {
      return state.selectedRoad
    },
    selectAnimationDays (state: RootState): IAnimationDaysType {
      return state.animationDays
    },
    selectAnimationHours (state: RootState): IAnimationHoursType {
      return state.animationHours
    },
    getMapPos (state: RootState): {lat: number; lng: number} {
      return state.mapPos
    },
    getShowAreaVisitor (state: RootState): number {
      return state.showAreaVisitor
    }
  },
  modules: {
    chart,
    daySeries,
    hourSeries,
    area,
    personaMaster,
    transportation,
    areaHeatmap,
    areaPolygonMaster,
    prefectureMaster,
    areaTimeSpent,
    areaVisitor,
    areaRanking,
    areaFacilityPin,
    areaFacilityMaster,
    railway,
    railLine
  }
}

export default new Vuex.Store<RootState>(store)
