
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue'

export default defineComponent({
  props: {
    captureClip: { type: Boolean, required: false, default: false },
    colors: { type: Object as PropType<Array<string>>, required: true },
    modelValue: { type: Boolean, required: false, default: false }
  },
  setup(props, { emit }) {
    const state = reactive({ model: ref(props.modelValue) })
    const onChange = () => emit('update:modelValue', state.model)
    return {
      onChange,
      ...toRefs(state)
    }
  },
})
