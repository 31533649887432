import {GetterTree} from 'vuex'
import {AreaFacilityPinState} from '@/store/modules/area/search/facility/type'
import {RootState} from '@/store/types'

export const getters: GetterTree<AreaFacilityPinState, RootState> = {
  labels (state: AreaFacilityPinState): string[] {
    return Object.keys(state.pins).map((k, i) => {
      return `${i + 1}. ${k}`
    })
  },
  dataset (state: AreaFacilityPinState): number[] {
    let set = []
    for (const pin in state.pins) {
      set = set.concat(state.pins[pin][0])
    }
    return set
  }
}
