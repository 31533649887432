
import {defineComponent, reactive, computed, watch, toRefs} from 'vue'

export default defineComponent({
  name: 'UIListSelect',
  props: {
    options: { type: Array, required: false, default: () => [] },
    modelValue: { type: [Boolean, String, Number], required: false, default: false },
    label: { type: String, required: false, default: '' },
    hasDefault: { type: Boolean, required: false, default: false }
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      selectedItems: [],
    })

    const selectedItemNames = computed(() => {
      if(state.selectedItems.length > 0) {
        return state.selectedItems.join(', ')
      } else {
        return props.label
      }
    })

    watch(() => state.selectedItems, (newSelectedItems, oldSelectedItems) => {
      if (JSON.stringify(newSelectedItems) !== JSON.stringify(oldSelectedItems)) {
        emit('update:modelValue', newSelectedItems)
      }
    })

    const clear = () => {
      state.selectedItems = []
    }

    const handleReset = () => {
      emit('update:modelValue', '')
      state.isOpen = false
    }

    const handleToggle = () => {
      state.isOpen = !state.isOpen
    }
    return {
      selectedItemNames,
      handleToggle,
      handleReset,
      clear,
      ...toRefs(state)
    }
  }
})
