export enum APIType {
  Query = 'Query',
  PlaceSearch = 'PlaceSearch',
  Login = 'Login',
  Logout = 'Logout',
  RefreshToken = 'RefreshToken',
  SendPasswordReset = 'SendPasswordReset',
  PasswordResetTokenCheck = 'PasswordResetTokenCheck',
  PasswordReset = 'PasswordReset',
  Chart = 'Chart',
  AnimationDay = 'AnimationDay',
  AnimationHour = 'AnimationHour',
  Road = 'Road',
  
  // Area
  AreaPolygonMaster = 'AreaPolygonMaster',
  AreaPersonaMaster = 'AreaPersonaMaster',
  AreaFacilityMaster = 'AreaFacilityMaster',
  AreaSameDayAnimation = 'AreaSameDayAnimation',
  AreaTimeSpent = 'AreaTimeSpent',
  AreaPrefectureMaster = 'AreaPrefectureMaster',
  AreaVisitor = 'AreaVisitor',
  AreaTransportationChart = 'AreaTransportationChart',
  AreaScatterPlot = 'AreaScatterPlot',
  AreaRanking = 'AreaRanking',
  AreaFacilityPin = 'AreaFacilityPin',
  AreaTable1 = 'AreaTable1',
  AreaTable1Detail = 'AreaTable1Detail',
  AreaTable2 = 'AreaTable2',
  AreaTable3 = 'AreaTable3',
  AreaTable3Detail = 'AreaTable3Detail',
  AreaTable4 = 'AreaTable4',
  AreaConfig = 'AreaConfig',
  
  // Railway
  RailwayLine = 'RailwayLine',
  RailwayStationBarchart = 'RailwayStationBarchart',
  RailwayHeatmap = 'RailwayHeatmap',
  RailwayComparison = 'RailwayComparison',
  RailwayAnimation = 'RailwayAnimation'
}
