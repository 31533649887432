import {IAnimationDayAPIStoreType} from "@/data/animationday/store";
import {IAnimationDayStubType} from "@/data/animationday/store/stub";
import {AnimationDayRequest} from "@/data/animationday/store/request";
import {IAnimationDaysType} from "@/data/animationday/type";

export interface IAnimationDayRepositoryType {
    api: IAnimationDayAPIStoreType
    stub: IAnimationDayStubType
    fetch(params: Record<string, any>): Promise<IAnimationDaysType>
}

export default class AnimationDayRepository implements IAnimationDayRepositoryType {
    api: IAnimationDayAPIStoreType
    stub: IAnimationDayStubType

    /// DI
    constructor (stub: IAnimationDayStubType, api: IAnimationDayAPIStoreType) {
        this.stub = stub
        this.api = api
    }

    /**
     * Fetch
     * @returns collection of order entity
     */
    async fetch(params: Record<string, any>): Promise<IAnimationDaysType> {
        const request = new AnimationDayRequest(params)
        return await this.api.fetch(request)
        //return await this.stub.fetch()
    }
}