import {IAnimationHourAPIStoreType} from "@/data/animationhour/store";
import {IAnimationHourStubType} from "@/data/animationhour/store/stub";
import {IAnimationHoursType} from "@/data/animationhour/type";
import {AnimationHourRequest} from "@/data/animationhour/store/request";

export interface IAnimationHourRepositoryType {
    api: IAnimationHourAPIStoreType
    stub: IAnimationHourStubType
    fetch(params: Record<string, any>): Promise<IAnimationHoursType>
}

export default class AnimationHourRepository implements IAnimationHourRepositoryType {
    api: IAnimationHourAPIStoreType
    stub: IAnimationHourStubType

    /// DI
    constructor (stub: IAnimationHourStubType, api: IAnimationHourAPIStoreType) {
        this.stub = stub
        this.api = api
    }

    /**
     * Fetch
     * @returns collection of order entity
     */
    async fetch(params: Record<string, any>): Promise<IAnimationHoursType> {
        const request = new AnimationHourRequest(params)
        return await this.api.fetch(request)
        //return await this.stub.fetch()
    }
}