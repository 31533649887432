import {ActionTree} from 'vuex'
import {FacilityMasterState} from '@/store/modules/area/master/facility/type'
import {RootState} from '@/store/types'
import {IAreaFacilityMasterType} from '@/data/master/area/facility/type'
import {FacilityMasterMutationType} from '@/store/modules/area/master/facility/mutations'

export enum FacilityMasterActionType {
  setFacilityMaster = 'setFacilityMaster'
}

export const actions: ActionTree<FacilityMasterState, RootState> = {
  [FacilityMasterActionType.setFacilityMaster] ({ commit }, payload: IAreaFacilityMasterType[]): void {
    commit(FacilityMasterMutationType.SET_FACILITIES, payload)
  }
}
