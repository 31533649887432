import {ActionTree} from 'vuex'
import {PersonaMasterState} from '@/store/modules/area/master/persona/type'
import {RootState} from '@/store/types'
import {IPersonaType} from '@/data/master/area/persona/type'
import {PersonaMasterMutationType} from '@/store/modules/area/master/persona/mutations'

export enum PersonaMasterActionType {
  setPersonaMaster = 'setPersonaMaster'
}

export const actions: ActionTree<PersonaMasterState, RootState> = {
  [PersonaMasterActionType.setPersonaMaster] ({ commit }, payload: IPersonaType[]): void {
    commit(PersonaMasterMutationType.SET_PERSONA_MASTER, payload)
  }
}
