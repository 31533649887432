import {IRailLineType} from '@/data/master/railway/type'
import RailLineJsonTranslator from '@/data/master/railway/translator'
import data from '@/assets/stubs/stationUser.json'

export interface IRailwayMasterStubType {
  fetchRaiLine(): Promise<IRailLineType[]>
}

export default class RailwayMasterStubStore implements IRailwayMasterStubType {
  protected translator: RailLineJsonTranslator
  constructor () {
    this.translator = new RailLineJsonTranslator()
  }
  
  fetchRaiLine(): Promise<IRailLineType[]> {
    return new Promise<IRailLineType[]>((resolve) => {
      resolve(data.map(rail => this.translator.translate(rail)))
    })
  }
}
