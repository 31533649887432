
import {defineComponent, inject, onMounted, reactive, toRefs, ref, onBeforeUnmount, onUnmounted} from 'vue'
import { CustomControl } from 'vue3-google-map'
import UISearchField from '@/components/UI/UISearchField.vue'
import DisplayRangeController from '@/components/subviews/DisplayRangeController.vue'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'
import { Emitter, EventType } from 'mitt'
import RoadSidebar from "@/components/UI/road/RoadSidebar.vue";
import { ActionType } from '@/store'
import RoadMultiSelector from "@/components/UI/road/RoadMultiSelector.vue";
import RoadPolygonSidebar from "@/components/UI/road/RoadPolygonSidebar.vue";
import {WarningPromptDialog} from "@/service/dialog";
import {NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE} from "@/constant";
import {HINT_ROAD_HEATMAP, globalEmitter} from '@/service/GlobalEmmiter'
import {HintDialog} from '@/service/dialog'
import RoadHeatmap from '@/components/hints/RoadHeatmap.vue'

export default defineComponent({
  components: {
    RoadPolygonSidebar,
    RoadMultiSelector,
    CustomControl,
    DisplayRangeController,
    UISearchField,
    RoadSidebar
  },
  setup() {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const inputSearchId = `input_search_${uuidv4()}`
    const store = useStore()
    const state = reactive({
      keyword: '',
      isShowHeatmap: false,
      eagerMode: false,
      polygonMode: false,
    })

    const handlerPolygon = () => {
      emitter?.emit('clearBackPolygon')
    }

    const handleClearMap = () => {
      console.log('handle clear map');
    }

    onMounted(() => {
      const root = document.getElementById('content-download')
      if (root) {
        root.classList.add('jr-overflow-hidden')
      }
    })
    onUnmounted(() => {
      const root = document.getElementById('content-download')
      if (root) {
        root.classList.remove('jr-overflow-hidden')
      }
    })

    const search = ref()

    const onKeywordSearch = (keyword: string) => {
      if(keyword.length > 0 ) emitter?.emit('placeSearch', keyword)
    }
    const onEagerSearch = () => {
      WarningPromptDialog(NOTIFY_LONG_PROCESSING_MESSAGE, NOTIFY_LONG_PROCESSING_TITLE, '実行', '閉じる', async () => {
        await emitter?.emit('eagerSearch', true)
        state.eagerMode = true;
        state.polygonMode = false;
      })
    }
    const handleDrawMap = () => {
      emitter?.emit('drawPolygon', true)
      state.eagerMode = false;
      state.polygonMode = true;
    }
    const handleSelectRange = () => {
      emitter?.emit('selectPolygonRange', true)
    }
    const handleClear = () => emitter?.emit('allClear', true)
    const handleNewPolygon = () => emitter?.emit('newPolygon', true)

    onMounted(() => {
      store.dispatch(ActionType.setQueries, [])
      store.dispatch(ActionType.setPolygon, [])
      store.dispatch(ActionType.setNewPolygon, [])
      store.dispatch(ActionType.setHasSearch, false)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(HINT_ROAD_HEATMAP)
    })

    globalEmitter.on(HINT_ROAD_HEATMAP, () => {
      HintDialog(RoadHeatmap)
    })

    return {
      ...toRefs(state),
      search,
      inputSearchId,
      handlerPolygon,
      handleClearMap,
      onKeywordSearch,
      onEagerSearch,
      handleDrawMap,
      handleSelectRange,
      handleNewPolygon,
      handleClear
    }
  },
})
