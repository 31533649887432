import {ActionTree} from 'vuex'
import {AreaPolygonMasterState, AreaRankingPolygon} from '@/store/modules/area/master/polygon/type'
import {RootState} from '@/store/types'
import {IAreaPolygonMasterType} from '@/data/master/area/polygon/type'
import {AreaPolygonMasterMutationType} from '@/store/modules/area/master/polygon/mutations'

export enum AreaPolygonMasterActionType {
  setAreaPolygonMaster = 'setAreaPolygonMaster',
  setSelectedPolygon = 'setSelectedPolygon',
  toggleSelectedPolygon = 'toggleSelectedPolygon',
  setRankingPolygon = 'setRankingPolygon'
}

export const actions: ActionTree<AreaPolygonMasterState, RootState> = {
  [AreaPolygonMasterActionType.setAreaPolygonMaster] ({ commit }, payload: IAreaPolygonMasterType[]): void {
    commit(AreaPolygonMasterMutationType.SET_AREA_POLYGON_MASTER, payload)
  },
  [AreaPolygonMasterActionType.setSelectedPolygon] ({ commit }, payload: (IAreaPolygonMasterType & {pref_id: string})[]): void {
    commit(AreaPolygonMasterMutationType.SET_SELECTED_POLYGON, payload)
  },
  [AreaPolygonMasterActionType.setRankingPolygon] ({ commit }, payload: AreaRankingPolygon[]): void {
    commit(AreaPolygonMasterMutationType.SET_RANKING_POLYGON, payload)
  },
  [AreaPolygonMasterActionType.toggleSelectedPolygon] ({ commit }, payload: IAreaPolygonMasterType & {pref_id: string}): void {
    commit(AreaPolygonMasterMutationType.TOGGLE_SELECTED_POLYGON, payload)
  }
}
