import {PolygonLayer} from '@deck.gl/layers'
import {ColorRGBA} from '@/types'
import {v4 as uuidv4} from 'uuid'

export const polygonFactory = function (
  data: any[],
  colors?: ColorRGBA[],
  getLineColor?: (polygon: any) => void,
  onClick?: (info) => void,
  selectedLineColor?: number[]
): PolygonLayer {
  return new PolygonLayer({
    id: uuidv4(),
    data: data,
    pickable: onClick !== undefined,
    lineWidthUnits: 'pixels',
    getLineWidth: d => {
      if (colors !== undefined) {
        return d.isSelected ? 0 : 2
      }
      return 2
    },
    getFillColor: d => {
      const defaultColor = [25, 103, 210, d.isSelected ? 128 : 51]
      if (colors !== undefined) {
        if (colors.length > 0 && d.ranking < colors.length - 1) {
          const targetColor = colors[d.ranking - 1]
          const theColor = [targetColor.Red, targetColor.Green, targetColor.Blue, targetColor.Alpha]
          return d.isSelected ? [239, 92, 51, 255] : theColor
        }
      }
      return defaultColor
    },
    getLineColor: getLineColor !== undefined ? getLineColor : d => {
      return d.isSelected ? selectedLineColor ? selectedLineColor : [0, 0, 0, 255]  : [25, 103, 210, 204]
    },
    onClick: onClick
  })
}
