import {MutationTree} from 'vuex'
import {AreaHeatmapState} from '@/store/modules/area/search/heatmap/type'

export enum AreaHeatmapMutationType {
  SET_PLOT_DATA = 'SetPlotData'
}

export const mutations: MutationTree<AreaHeatmapState> = {
  [AreaHeatmapMutationType.SET_PLOT_DATA] (state: AreaHeatmapState, payload: (number | string)[][]): void {
    state.plotData = payload
  }
}
