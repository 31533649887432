
import {computed, defineComponent, inject, onBeforeUnmount, reactive, toRefs} from 'vue'
import {DayCode} from '@/enum/barchart'
import {useStore} from 'vuex'
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps'
import {ActionType} from '@/store'
import {scatterPlotFactory} from '@/layers/scartterplot'
import {globalEmitter, HINT_AREA_TRANSPORTATION, DOWNLOAD_AREA_TRANSPORTATION} from '@/service/GlobalEmmiter'
import {
  AREA_NO_ATTRIBUTE,
  AREA_NO_FREQUENCY,
  AREA_NO_TRANSPORTATION,
  AREA_NO_WEEK,
  AREA_WARNING_MESSAGE,
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_ELEMENT_ID,
  MAX_THRESHOLD_TITLE,
  NO_CONDITION_SPECIFIED
} from '@/constant'
import {AlertDialog} from '@/service/dialog'
import {ConvertToImage} from '@/service/file'
import {TransportationBarchartType} from '@/components/UI/area/TransportationBarchart.vue'
import {Config} from '@/config'
import {TransportationActionType} from '@/store/modules/area/search/transportation/actions'
import {getAreaSelectedPolygon, setAccessToken} from '@/utils/storage'
import {Emitter, EventType} from 'mitt'
import {useRoute} from 'vue-router'
import {polygonFactory} from '@/layers/polygon'
import UIGoogleMap from '@/components/UI/UIGoogleMap.vue'
import AreaSearchFilter from '@/components/subviews/area/AreaSearchFilter.vue'
import TransportationBarchart from '@/components/UI/area/TransportationBarchart.vue'
import AreaStubStore from '@/data/area/store/stub'
import AreaAPIStore from '@/data/area/store'
import AreaRepository from '@/data/area/repository'
import TransportationColors from '@/components/UI/area/TransportationColors.vue'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AppLoader from '@/components/AppLoader.vue'
import UILayerToggle from '@/components/UI/UILayerToggle.vue'
import {HintDialog} from '@/service/dialog'
import AreaTransportation from '@/components/hints/AreaTransportation.vue'

interface State {
  map: any
  isLoadingChart
  isLoadingScatterPlot
  filterMinimum: boolean
  captureClip: boolean
  isPlotError: boolean
  isBarchartError: boolean
  errorTitle: string
  errorMessage: string
}

export default defineComponent({
  name: 'Transportation',
  components: {
    AppLoader,
    TransportationColors,
    TransportationBarchart,
    AreaSearchFilter,
    UIGoogleMap,
    UILayerToggle
  },
  setup () {
    const wrapperElementID = CONTENT_DOWNLOAD_ELEMENT_ID
    const lessAmountTitle = AREA_WARNING_TITLE
    const lessAmountMessage = AREA_WARNING_MESSAGE
    const availableDayFilter = [
        DayCode.Monday,
        DayCode.Tuesday,
        DayCode.Wednesday,
        DayCode.Thursday,
        DayCode.Friday,
        DayCode.Saturday,
        DayCode.Sunday
    ]
    const store = useStore()
    const route = useRoute()
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const isTransportation = computed(() => route.path === '/area_report/transportation')
    const state = reactive<State>({
      map: null,
      isLoadingChart: false,
      isLoadingScatterPlot: false,
      filterMinimum: false,
      captureClip: false,
      isPlotError: false,
      isBarchartError: false,
      errorTitle: '',
      errorMessage: ''
    })
    const colors = Config.Instance.transportationTypes
    const isDetailLoaded = computed(() => store.state.area.isDetailLoaded)
    const deckOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true },
    })
    const polygonOverlay = new DeckOverlay({
      glOptions: { preserveDrawingBuffer: true }
    })

    // auth instances
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)
    const areaStub = new AreaStubStore()
    const areaApi = new AreaAPIStore()
    const areaRepository = new AreaRepository(areaStub, areaApi)
    const handleMapLoad = (map: any) => {
      state.map = map
      deckOverlay.setMap(state.map)
      polygonOverlay.setMap(state.map)
    }

    const loadScatterPlotData = (params: Record<string, any>) => {
      state.isLoadingScatterPlot = true
      state.isPlotError = false
      state.errorTitle = ''
      state.errorMessage = ''
      store.dispatch(`transportation/${TransportationActionType.setPlots}`, [])
      deckOverlay.setProps({ layers: [] })
      areaRepository.fetchTransportation(params).then(plots => {
        deckOverlay.setProps({
          layers: [scatterPlotFactory(plots)]
        })
        // store.dispatch(`transportation/${TransportationActionType.setPlots}`, plots)
      }).catch(err => {
        if (isTransportation.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => areaRepository.fetchTransportation(params).then(plots => {
              deckOverlay.setProps({
                layers: [scatterPlotFactory(plots)]
              })
              // store.dispatch(`transportation/${TransportationActionType.setPlots}`, plots)
            }))
            .catch(() => {
              if (err.status === 404) {
                state.isPlotError = true
                state.isLoadingChart = false
                state.isLoadingScatterPlot = false
                state.errorTitle = AREA_WARNING_TITLE
                state.errorMessage = AREA_WARNING_MESSAGE
              } else if (err.status === 416) {
                state.isPlotError = true
                state.isLoadingChart = false
                state.isLoadingScatterPlot = false
                state.errorTitle = MAX_THRESHOLD_TITLE
                state.errorMessage = MAX_THRESHOLD_TITLE
              }
            })
          } else if (err.status === 404) {
            state.isPlotError = true
            state.isLoadingChart = false
            state.isLoadingScatterPlot = false
            state.errorTitle = AREA_WARNING_TITLE
            state.errorMessage = AREA_WARNING_MESSAGE
          } else if (err.status === 416) {
            state.isPlotError = true
            state.isLoadingChart = false
            state.isLoadingScatterPlot = false
            state.errorTitle = MAX_THRESHOLD_TITLE
            state.errorMessage = MAX_THRESHOLD_TITLE
          }
        }
      })
      .finally(() => {
        state.isLoadingScatterPlot = false
        emitter?.emit('notifyAreaFilter')
      })
    }
    const loadChartData = (params: Record<string, any>) => {
      state.isLoadingChart = true
      state.isBarchartError = false
      state.errorTitle = ''
      state.errorMessage = ''
      store.dispatch(`transportation/${TransportationActionType.setInArea}`, [])
      store.dispatch(`transportation/${TransportationActionType.setVisitArea}`, [])
      areaRepository.fetchTransportationChart(params).then(transportation => {
        store.dispatch(`transportation/${TransportationActionType.setInArea}`, transportation.in_area)
        store.dispatch(`transportation/${TransportationActionType.setVisitArea}`, transportation.visit_area)
      }).catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => areaRepository.fetchTransportationChart(params).then(transportation => {
            store.dispatch(`transportation/${TransportationActionType.setInArea}`, transportation.in_area)
            store.dispatch(`transportation/${TransportationActionType.setVisitArea}`, transportation.visit_area)
          }))
          .catch(() => {
            if (err.status === 404) {
              state.isBarchartError = true
              state.isLoadingChart = false
              state.isLoadingScatterPlot = false
              state.errorTitle = AREA_WARNING_TITLE
              state.errorMessage = AREA_WARNING_MESSAGE
            } else if (err.status === 416) {
              state.isPlotError = true
              state.isLoadingChart = false
              state.isLoadingScatterPlot = false
              state.errorTitle = MAX_THRESHOLD_TITLE
              state.errorMessage = MAX_THRESHOLD_TITLE
            }
          })
        } else if (err.status === 404) {
          state.isBarchartError = true
          state.isLoadingChart = false
          state.isLoadingScatterPlot = false
          state.errorTitle = AREA_WARNING_TITLE
          state.errorMessage = AREA_WARNING_MESSAGE
        } else if (err.status === 416) {
          state.isPlotError = true
          state.isLoadingChart = false
          state.isLoadingScatterPlot = false
          state.errorTitle = MAX_THRESHOLD_TITLE
          state.errorMessage = MAX_THRESHOLD_TITLE
        }
      })
      .finally(() => {
        state.isLoadingChart = false
        emitter?.emit('notifyAreaFilter')
      })
    }

    const init = async () => {
      const params = store.getters['area/getAPIParams']
      delete params.except_target_area
      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      loadChartData(params)
      loadScatterPlotData(params)
    }
    init()

    const handleAreaToggle = (value) => {
      if (value) {
        const polygons = getAreaSelectedPolygon()
        polygonOverlay.setProps({
          layers: [polygonFactory(polygons)]
        })
      } else {
        polygonOverlay.setProps({ layers: [] })
      }
    }
    const handleFilterResize = (value: boolean) => state.filterMinimum = value
    const handleSearch = (params: Record<string, any>) => {
      // user didn't specify any filter condition
      const trans = params.type && params.type.length === 0
      const freq = params.frequencies && params.frequencies.length === 0
      const attr = params.visitor_attribute && params.visitor_attribute.length === 0
      const week = params.week && params.week.length === 0
      delete params.except_target_area
      if (trans && attr && week && freq) {
        AlertDialog(NO_CONDITION_SPECIFIED)
        return
      }

      if (week) {
        AlertDialog(AREA_NO_WEEK)
        return
      }

      if (trans) {
        AlertDialog(AREA_NO_TRANSPORTATION)
        return
      }

      if (freq) {
        AlertDialog(AREA_NO_FREQUENCY)
        return
      }

      if (attr) {
        AlertDialog(AREA_NO_ATTRIBUTE)
        return
      }

      if (params.week && params.week.length > 0) {
        params.week = params.week[0]
      }
      loadChartData(params)
      loadScatterPlotData(params)
    }

    // watchers
    // watch(() => store.state.transportation.plots, (plots: Array<number[]>) => {
    //   deckOverlay.setProps({
    //     layers: [scatterPlotFactory(plots)]
    //   })
    // })

    globalEmitter.on(DOWNLOAD_AREA_TRANSPORTATION, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 500)
    })

    globalEmitter.on(HINT_AREA_TRANSPORTATION, () => {
      HintDialog(AreaTransportation)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_TRANSPORTATION)
      globalEmitter.off(HINT_AREA_TRANSPORTATION)
    })

    return {
      ...toRefs(state),
      sbMinimum,
      colors,
      availableDayFilter,
      isDetailLoaded,
      lessAmountTitle,
      lessAmountMessage,
      TransportationBarchartType,
      handleAreaToggle,
      handleMapLoad,
      handleSearch,
      handleFilterResize
    }
  }
})
