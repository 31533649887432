
import {defineComponent, inject, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {setAccessToken} from "@/utils/storage"
import {AlertDialog} from "@/service/dialog"
import {Emitter, EventType} from 'mitt'
import {CustomControl} from 'vue3-google-map'
import {AreaPolygonMasterActionType} from '@/store/modules/area/master/polygon/actions'
import {PrefectureMasterActionType} from '@/store/modules/area/master/prefecture/actions'
import {v4 as uuidv4} from 'uuid'
import UISearchField from '@/components/UI/UISearchField.vue'
import AreaSidebar from '@/components/UI/area/AreaSidebar.vue'
import AreaDistrictSelector from '@/components/UI/area/AreaDistrictSelector.vue'
import AreaPolygonSelector from '@/components/UI/area/AreaPolygonSelector.vue'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import AreaPolygonMasterApi from '@/data/master/area/polygon/store'
import AreaPolygonMasterRepository from '@/data/master/area/polygon/repository'
import AreaPrefectureMasterApi from '@/data/master/area/prefecture/store'
import AreaPrefectureMasterRepository from '@/data/master/area/prefecture/repository'
import AppLoader from '@/components/AppLoader.vue'

enum Mode {
  None = 0,
  District = 1,
  Area = 2,
}

interface State {
  mode: Mode
  isLoading: boolean
}

export default defineComponent({
  name: 'Area',
  components: {
    AppLoader,
    AreaPolygonSelector,
    AreaDistrictSelector,
    AreaSidebar,
    CustomControl,
    UISearchField,
  },
  emits: ['placeSearch', 'drawPolygon', 'allClear', 'escArea'],
  setup () {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const inputSearchId = `input_search_${uuidv4()}`

    // vuex
    const store = useStore()

    // polygon master
    const areaPolygonMasterApi = new AreaPolygonMasterApi()
    const areaPolygonMasterRepository = new AreaPolygonMasterRepository(areaPolygonMasterApi)

    // prefecture master
    const areaPrefectureMasterApi = new AreaPrefectureMasterApi()
    const areaPrefectureMasterRepository = new AreaPrefectureMasterRepository(areaPrefectureMasterApi)

    // auth
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    // initial load
    const getStreams = (): Promise<any>[] => {
      return [
        areaPrefectureMasterRepository.fetch({}),
        areaPolygonMasterRepository.fetch({})
      ]
    }
    Promise.all(getStreams()).then(([prefectures, polygons]) => {
      store.dispatch(`prefectureMaster/${PrefectureMasterActionType.setPrefectures}`, prefectures)
      store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setAreaPolygonMaster}`, polygons)
    })
    .catch(err => {
      if (err.status === 401) {
        return authRepository.refreshToken()
        .then(response => setAccessToken(response.access))
        .then(() => {
          return Promise.all(getStreams()).then(([prefectures, polygons]) => {
            store.dispatch(`prefectureMaster/${PrefectureMasterActionType.setPrefectures}`, prefectures)
            store.dispatch(`areaPolygonMaster/${AreaPolygonMasterActionType.setAreaPolygonMaster}`, polygons)
          })
        })
      } else {
        AlertDialog(err.message)
      }
    })
    .finally(() => state.isLoading = false)

    const state = reactive<State>({
      mode: Mode.None,
      isLoading: true
    })

    const onKeywordSearch = (keyword: string) => {
      if (keyword.length > 0) emitter?.emit('placeSearch', keyword)
    }

    const onDistrictSearch = () => {
      state.mode = Mode.District
    }

    const onAreaSearch = () => {
      state.mode = Mode.Area
      store.dispatch(`prefectureMaster/${PrefectureMasterActionType.clearSelected}`)
      emitter?.emit('drawPolygon', true)
    }

    const onClearDistrict = () => {
      state.mode = Mode.None
    }

    const onClearArea = () => {
      state.mode = Mode.None
      emitter?.emit('allClear', true)
    }

    const onEscArea = () => {
      emitter?.emit('escArea')
    }

    return {
      ...toRefs(state),
      inputSearchId,
      Mode,
      onKeywordSearch,
      onDistrictSearch,
      onAreaSearch,
      onClearDistrict,
      onClearArea,
      onEscArea
    }
  }
})
