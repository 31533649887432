import {IPrefectureType} from '@/data/master/area/prefecture/type'
import {IPrefectureMasterAPIStoreType} from '@/data/master/area/prefecture/store'
import {PrefectureMasterRequest} from '@/data/master/area/prefecture/store/request'

export interface IPrefectureMasterRepositoryType {
  api: IPrefectureMasterAPIStoreType
  fetch(params: Record<string, any>): Promise<IPrefectureType[]>
}

export default class PrefectureMasterRepository implements IPrefectureMasterRepositoryType {
  api: IPrefectureMasterAPIStoreType
  
  // DI
  constructor(api: IPrefectureMasterAPIStoreType) {
    this.api = api
  }
  
  async fetch(params: Record<string, any>): Promise<IPrefectureType[]> {
    const request = new PrefectureMasterRequest(params)
    return await this.api.fetch(request)
  }
}
