import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da979b94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-relative jr-bg-white jr-rounded-md jr-py-3 jr-px-2 jr-h-full" }
const _hoisted_2 = { class: "jr-text-xl jr-font-bold jr-text-white jr-absolute jr-top-2 jr-left-4" }
const _hoisted_3 = { class: "jr-flex jr-items-start jr-h-full jr-justify-start" }
const _hoisted_4 = { class: "jr-flex jr-items-end" }
const _hoisted_5 = { class: "jr-flex jr-justify-start jr-items-center jr-h-full jr-text-s jr-text-gray jr-text-center jr-pl-4.5" }
const _hoisted_6 = { class: "jr-block" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "jr-flex-1 jr-text-blue-light jr-text-base jr-font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "badge jr-absolute jr-top-0 jr-left-0",
      style: _normalizeStyle({ 'border-top-color': _ctx.color || '#C8C8C8', 'border-left-color': _ctx.color || '#C8C8C8' })
    }, null, 4),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.id), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.gender !== '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.gender), 1))
              : _createCommentVNode("", true),
            (_ctx.age !== '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.age), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("img", {
          class: "jr-max-w-16 jr-px-2.5",
          src: _ctx.avatar,
          alt: `avatar-${_ctx.id}`
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.slogan), 1)
    ])
  ]))
}