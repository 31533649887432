
import { defineComponent } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'UIInputField',
  components: { Field, ErrorMessage },
  props: {
    name: { type: String, required: false, default: '' },
    modelValue: { type: String, required: false, default: '' },
    readonly: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'text' },
    placeholder: { type: String, required: false, default: '' },
    rules: { type: String, required: false, default: '' },
    valid: { type: Boolean, required: false, default: false },
    showError: { type: Boolean, required: false, default: false }
  },
  setup(_, { emit }) {
    let id = uuidv4()
    const onInput = (e: InputEvent) => {
      emit('update:modelValue', (e.target as HTMLInputElement).value)
    }
    return {
      id,
      onInput
    }
  },
})
