import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon-pause.svg'
import _imports_1 from '@/assets/icon-play.svg'


const _withScopeId = n => (_pushScopeId("data-v-0ef7ef40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-flex jr-items-center jr-bg-white jr-px-2.5 jr-py-2" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "jr-m-auto",
  alt: "pause"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "jr-m-auto",
  alt: "play"
}
const _hoisted_4 = { class: "jr-w-11/12 jr-ml-5 jr-relative" }
const _hoisted_5 = ["id", "min", "max", "step"]
const _hoisted_6 = { class: "steps" }
const _hoisted_7 = { class: "jr-flex jr-relative jr-justify-between jr-text-s-flat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "jr-w-6 jr-h-6 jr-rounded-full jr-bg-primary",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePlay && _ctx.handlePlay(...args)))
    }, [
      (_ctx.play)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.play)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.rangeId,
        type: "range",
        class: _normalizeClass(["jr-w-full jr-appearance-none jr-bg-gray-soft jr-outline-none jr-slider-thumb", { 'jr-pointer-events-none': _ctx.play }]),
        min: _ctx.min,
        max: _ctx.max,
        step: (_ctx.max-_ctx.min) / _ctx.step,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event)),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 42, _hoisted_5), [
        [_vModelText, _ctx.model]
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepAry, (step) => {
          return (_openBlock(), _createElementBlock("span", {
            class: "step_num",
            key: 'step-'+step,
            style: _normalizeStyle({left: (step*100) + '%'})
          }, null, 4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("output", {
          class: _normalizeClass(["jr-absolute jr-text-s-flat jr--ml-1", {
                  'jr-opacity-1': _ctx.percentage > 5 && _ctx.percentage < 95,
                  'jr-opacity-0': _ctx.percentage <= 5 || _ctx.percentage >= 95
                }]),
          style: _normalizeStyle({'left': `${_ctx.percentage}%`})
        }, _toDisplayString(_ctx.displayModel), 7),
        _createElementVNode("span", null, _toDisplayString(_ctx.displayFrom), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.displayTo), 1)
      ])
    ])
  ]))
}