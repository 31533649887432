import {ITranslatorType} from '@/types'
import {IPrefectureType} from '@/data/master/area/prefecture/type'

export default class PrefectureMasterJsonTranslator implements ITranslatorType<Record<string, any>, IPrefectureType> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars
  translate(input: Record<string, any>, ...args): IPrefectureType {
    return {
      id: input.id,
      isCollapsed: true,
      name: input.name,
      cities: input.cities.map(c => {
        return {
          ...c,
          isSelected: false
        }
      })
    }
  }
}
