
import {DOWNLOAD_AREA_DASHBOARD, HINT_AREA_DASHBOARD, globalEmitter} from '@/service/GlobalEmmiter'
import {
  AREA_WARNING_TITLE,
  CONTENT_DOWNLOAD_AREA_DASHBOARD,
  DOUBLE_LOGIN_IN_PROCESSING_MESSAGE,
  DOUBLE_LOGIN_IN_PROCESSING_TITLE,
  PROCESS_CANCELED_MESSAGE,
  PROCESS_CANCELED_TITLE
} from '@/constant'
import {computed, defineComponent, onBeforeUnmount, reactive, toRefs} from 'vue'
import {setAccessToken, setSelectedAreaLabels} from '@/utils/storage'
import {AlertDialog, WarningDialog} from '@/service/dialog'
import {useStore} from 'vuex'
import {PersonaMasterActionType} from '@/store/modules/area/master/persona/actions'
import {useRoute, useRouter} from 'vue-router'
import {TabType} from '@/views/area/Ranking.vue'
import {ConvertToImage} from '@/service/file'
import {FacilityMasterActionType} from '@/store/modules/area/master/facility/actions'
import {AreaRankingActionType} from '@/store/modules/area/search/ranking/actions'
import {TransportationActionType} from '@/store/modules/area/search/transportation/actions'
import {AreaTimeSpentActionType} from '@/store/modules/area/search/timeSpent/actions'
import {AreaVisitorActionType} from '@/store/modules/area/search/visitor/actions'
import {AreaFacilityPinActionType} from '@/store/modules/area/search/facility/actions'
import {Config} from '@/config'
import {AreaActionType} from '@/store/modules/area/actions'
import {ActionType} from '@/store'
import {setAreaSelectedPolygon} from '@/utils/storage'
import AreaReportSidebar from '@/components/subviews/area/AreaReportSidebar.vue'
import FilterNavigation from '@/components/subviews/FilterNavigation.vue'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'
import PersonaMasterStubStore from '@/data/master/area/persona/store/stub'
import PersonaMasterAPIStore from '@/data/master/area/persona/store'
import PersonaMasterRepository from '@/data/master/area/persona/repository'
import TimingDoughnutChart from '@/components/UI/area/TimingDoughnutChart.vue'
import UIPersona from '@/components/UI/area/UIPersona.vue'
import VisitorBarChart from '@/components/UI/visitor/VisitorBarChart.vue'
import VisitorDoughnutChart from '@/components/UI/visitor/VisitorDoughnutChart.vue'
import TransportationDashboardBarchart from '@/components/UI/area/TransportationDashboardBarchart.vue'
import FacilityBarChart from '@/components/UI/area/FacilityBarChart.vue'
import AreaFacilityMasterAPIStore from '@/data/master/area/facility/store'
import AreaFacilityMasterRepository from '@/data/master/area/facility/repository'
import UILoader from '@/components/UI/UILoader.vue'
import AreaRepository from '@/data/area/repository'
import AreaAPIStore from '@/data/area/store'
import AreaStubStore from '@/data/area/store/stub'
import AppLoader from '@/components/AppLoader.vue'
import AreaTableAPIStore from '@/data/master/area/table/store'
import AreaTableRepository from '@/data/master/area/table/repository'
import {HintDialog} from '@/service/dialog'
import AreaDashboard from '@/components/hints/AreaDashboard.vue'

interface State {
  rankingTab: TabType
  isLoadingDetail: boolean
  isLoadingMaster: boolean
  isLoadingRanking: boolean
  isLoadingTransportation: boolean
  isLoadingFacility: boolean
  isLoadingTimeSpent: boolean
  isLoadingVisitor: boolean
  isLoaded: boolean
  captureClip: boolean
}

export default defineComponent({
  name: 'AreaReport',
  components: {
    AppLoader,
    UILoader,
    FacilityBarChart,
    TransportationDashboardBarchart,
    TimingDoughnutChart,
    AreaReportSidebar,
    FilterNavigation,
    VisitorBarChart,
    VisitorDoughnutChart,
    UIPersona
  },
  setup () {
    // routes
    const route = useRoute()
    const router = useRouter()
    const isDashboard = computed(() => route.path === '/area_report')
    const wrapperElementID = CONTENT_DOWNLOAD_AREA_DASHBOARD
    const lessAmountMessage = AREA_WARNING_TITLE
    const isLessRanking = computed(() => store.state.area.isLessRanking)
    const isLessTransportation = computed(() => store.state.area.isLessTransportation)
    const isLessFacility = computed(() => store.state.area.isLessFacility)
    const isLessTimeSpent = computed(() => store.state.area.isLessTimeSpent)
    const isLessVisitor = computed(() => store.state.area.isLessVisitor)
    const timeLabels = computed(() => store.getters['area/timeLabels'])
    const timeData = computed(() => store.getters['area/timeVolumes'])
    const timeAverage = computed(() => store.state.area.average)
    const isCapturing = computed(() => store.state.isCapturing)
    const isErrorTimeSpent = computed(() => {
      const volumes = store.getters['area/timeVolumes']
      const zero = volumes.some(d => d < store.state.percentageThreshold)
      const nan = volumes.some(d => isNaN(d))
      return zero || nan
    })

    // show area visitor
    const showAreaVisitor = computed(() => store.state.showAreaVisitor);

    // persona colors
    const personaColors = computed(() => Config.Instance.personaRankingColors)

    // ranking
    const residents = computed(() => store.state.area.resident)
    const workLocations = computed(() => store.state.area.workLocation)

    // transportations
    const inAreas = computed(() => store.state.area.inArea)
    const visitAreas = computed(() => store.state.area.visitArea)

    // facility
    const pins = computed(() => store.state.area.pins)

    // times spent
    const times = computed(() => store.state.area.times)

    // visitors
    const persona = computed(() => store.state.area.persona)
    const age = computed(() => store.state.area.age)
    const visitorLabels = computed(() => store.state.area.age.constraints)
    const visitorMen = computed(() => store.getters['area/ages'].men)
    const visitorWomen = computed(() => store.getters['area/ages'].women)

    const isErrorVisitorDoughnut = computed(() => {
      return store.getters['areaVisitor/personaVolumes'].some(d => d < store.state.percentageThreshold)
    })
    const isErrorVisitorChart = computed(() => {
      const vol = store.getters['areaVisitor/ages']
      const isMen = vol.men.some(m => m < store.state.percentageThreshold)
      const isWomen = vol.women.some(w => w < store.state.percentageThreshold)
      return isMen || isWomen
    })

    const areas = computed(() => store.state.area.areas)
    const personas = computed(() => {
      const rankedPersona = store.state.area.persona.map(p => p.id).filter(id => id)
      return rankedPersona.map(id => {
        return store.state.personaMaster.personas.find(persona => persona.id === id)
      }).filter(p => p)
    })
    const sbMinimum = computed(() => store.state.area.sbMinimum)

    // vuex
    const store = useStore()
    const state = reactive<State>({
      rankingTab: TabType.ResidencePlace,
      isLoadingRanking: false,
      isLoadingTransportation: false,
      isLoadingFacility: false,
      isLoadingTimeSpent: false,
      isLoadingVisitor: false,
      isLoadingDetail: false,
      isLoadingMaster: false,
      isLoaded: false,
      captureClip: false
    })

    // data layer instances
    const stub = new AreaStubStore()
    const api = new AreaAPIStore()
    const repository = new AreaRepository(stub, api)

    // persona master
    const personaMasterStub = new PersonaMasterStubStore()
    const personaMasterApi = new PersonaMasterAPIStore()
    const personaMasterRepository = new PersonaMasterRepository(personaMasterStub, personaMasterApi)

    // facility master
    const facilityMasterApi = new AreaFacilityMasterAPIStore()
    const facilityMasterRepository = new AreaFacilityMasterRepository(facilityMasterApi)

    // data layer instances
    const areaTableApiStore = new AreaTableAPIStore()
    const areaTableRepository = new AreaTableRepository(areaTableApiStore)

    // auth
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const loadDetail = () => {
      state.isLoadingDetail = true
      const drawnPolygon = store.state.polygon
      const prefs = store.getters['prefectureMaster/hasCitySelectedPref']
      if (prefs.length > 0 || drawnPolygon.length > 0) {
        let params: Record<string, any> = {}
        if (prefs.length > 0) {
          params.cities = prefs.map(pref => {
            return pref.cities.filter(city => city.isSelected).map(city => {
              return {
                pref_id: pref.id,
                city_id: city.id
              }
            })
          }).flat()
        }
        if (drawnPolygon.length > 0) {
          params.polygon = drawnPolygon
        }
        let loadCount = 0
        return areaTableRepository.dataEntryToTable1Detail(params)
        .then(() => {
          loadCount++
          return areaTableRepository.dataEntryToTable3Detail(params)
        })
        .then(() => {
          loadCount++
          return areaTableRepository.dataEntryToTable4(params)
        })
        .catch(err => {
          if (isDashboard.value) {
            if (err.status === 401) {
              return authRepository.refreshToken()
              .then(response => setAccessToken(response.access))
              .then(() => {
                const drawnPolygon = store.state.polygon
                const prefs = store.getters['prefectureMaster/hasCitySelectedPref']
                if (prefs.length > 0 || drawnPolygon.length > 0) {
                  let params: Record<string, any> = {}
                  if (prefs.length > 0) {
                    if (prefs.length > 0) {
                      params.cities = prefs.map(pref => {
                        return pref.cities.filter(city => city.isSelected).map(city => {
                          return {
                            pref_id: pref.id,
                            city_id: city.id
                          }
                        })
                      }).flat()
                    }
                  }
                  if (drawnPolygon.length > 0) {
                    params.polygon = drawnPolygon
                  }
                  if (loadCount === 1) {
                    return areaTableRepository.dataEntryToTable3Detail(params)
                    .then(() => areaTableRepository.dataEntryToTable4(params))
                  } else if (loadCount === 2) {
                    return areaTableRepository.dataEntryToTable4(params)
                  } else {
                    return areaTableRepository.dataEntryToTable1Detail(params)
                    .then(() => areaTableRepository.dataEntryToTable3Detail(params))
                    .then(() => areaTableRepository.dataEntryToTable4(params))
                  }
                }
              })
              .catch(err2 => {
                if (err2.status === 409) {
                  WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
                }
                if (err.status === 424) {
                  WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
                }
              })
            }
            if (err.status === 409) {
              WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            }
            if (err.status === 424) {
              WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            }
          }
        })
        .finally(() => {
          state.isLoadingDetail = false
        })
      } else {
        state.isLoadingDetail = false
        return Promise.resolve({})
      }
    }

    // load ranking
    const loadRanking = () => {
      state.isLoadingRanking = true
      return repository.fetchRanking({ dashboard: true }).then(ranking => {
        store.dispatch(`areaRanking/${AreaRankingActionType.setMin}`, ranking.min)
        store.dispatch(`areaRanking/${AreaRankingActionType.setMax}`, ranking.max)
        store.dispatch(`areaRanking/${AreaRankingActionType.setResident}`, ranking.resident)
        store.dispatch(`areaRanking/${AreaRankingActionType.setWorkLocation}`, ranking.work_location)

        store.dispatch(`area/${AreaActionType.setDBRanking}`, {
          resident: ranking.resident,
          workLocation: ranking.work_location
        })
      })
      .catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              return repository.fetchRanking({ dashboard: true }).then(ranking => {
                store.dispatch(`areaRanking/${AreaRankingActionType.setMin}`, ranking.min)
                store.dispatch(`areaRanking/${AreaRankingActionType.setMax}`, ranking.max)
                store.dispatch(`areaRanking/${AreaRankingActionType.setResident}`, ranking.resident)
                store.dispatch(`areaRanking/${AreaRankingActionType.setWorkLocation}`, ranking.work_location)

                store.dispatch(`area/${AreaActionType.setDBRanking}`, {
                  resident: ranking.resident,
                  workLocation: ranking.work_location
                })
              })
            })
          } else if (err.status === 404) {
            // WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            store.dispatch(`area/${AreaActionType.setLessRanking}`, true)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => state.isLoadingRanking = false)
    }

    // load transportations chart
    const loadTransportationChart = () => {
      state.isLoadingTransportation = true
      return repository.fetchTransportationChart({ dashboard: true }).then(transportation => {
        store.dispatch(`transportation/${TransportationActionType.setInArea}`, transportation.in_area)
        store.dispatch(`transportation/${TransportationActionType.setVisitArea}`, transportation.visit_area)

        store.dispatch(`area/${AreaActionType.setDBTransportation}`, {
          inArea: transportation.in_area,
          visitArea: transportation.visit_area
        })
      })
      .catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              return repository.fetchTransportationChart({ dashboard: true }).then(transportation => {
                store.dispatch(`transportation/${TransportationActionType.setInArea}`, transportation.in_area)
                store.dispatch(`transportation/${TransportationActionType.setVisitArea}`, transportation.visit_area)

                store.dispatch(`area/${AreaActionType.setDBTransportation}`, {
                  inArea: transportation.in_area,
                  visitArea: transportation.visit_area
                })
              })
            })
          } else if (err.status === 404) {
            // WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            store.dispatch(`area/${AreaActionType.setLessTransportation}`, true)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => state.isLoadingTransportation = false)
    }

    // load facility
    const loadFacility = () => {
      state.isLoadingFacility = true
      return repository.fetchFacilityPin({ dashboard: true }).then(pins => {
        store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setPins}`, pins)
        store.dispatch(`area/${AreaActionType.setDBFacility}`, pins)
      }).catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => response.access)
            .then(() => {
              return repository.fetchFacilityPin({ dashboard: true }).then(pins => {
                store.dispatch(`areaFacilityPin/${AreaFacilityPinActionType.setPins}`, pins)
                store.dispatch(`area/${AreaActionType.setDBFacility}`, pins)
              })
            })
          } else if (err.status === 404) {
            // WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            store.dispatch(`area/${AreaActionType.setLessFacility}`, true)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => state.isLoadingFacility = false)
    }

    // load time spent
    const loadTimeSpent = () => {
      state.isLoadingTimeSpent = true
      return repository.fetchTimeSpent({ dashboard: true }).then(times => {
        store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setTimes}`, times.data)
        store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setAverage}`, times.average)
        store.dispatch(`area/${AreaActionType.setDBTimeSpent}`, times.data)
        store.dispatch(`area/${AreaActionType.setAverage}`, times.average)
      })
      .catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              return repository.fetchTimeSpent({ dashboard: true }).then(times => {
                store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setTimes}`, times.data)
                store.dispatch(`areaTimeSpent/${AreaTimeSpentActionType.setAverage}`, times.average)
                store.dispatch(`area/${AreaActionType.setDBTimeSpent}`, times.data)
                store.dispatch(`area/${AreaActionType.setAverage}`, times.average)
              })
            })
          } else if (err.status === 404) {
            // WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            store.dispatch(`area/${AreaActionType.setLessTimeSpent}`, true)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => state.isLoadingTimeSpent = false)
    }

    // load visitor
    const loadVisitor = () => {
      state.isLoadingVisitor = true
      return repository.fetchVisitor({ dashboard: true }).then(visitor => {
        store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorPersona}`, visitor.persona)
        store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorAge}`, visitor.ages)

        store.dispatch(`area/${AreaActionType.setDBVisitor}`, {
          persona: visitor.persona,
          age: visitor.ages
        })
      })
      .catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => repository.fetchVisitor({ dashboard: true })).then(visitor => {
              store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorPersona}`, visitor.persona)
              store.dispatch(`areaVisitor/${AreaVisitorActionType.setVisitorAge}`, visitor.ages)

              store.dispatch(`area/${AreaActionType.setDBVisitor}`, {
                persona: visitor.persona,
                age: visitor.ages
              })
            })
          } else if (err.status === 404) {
            // WarningDialog(AREA_WARNING_TITLE, AREA_WARNING_MESSAGE)
            store.dispatch(`area/${AreaActionType.setLessVisitor}`, true)
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .finally(() => state.isLoadingVisitor = false)
    }

    // data entry AND fetch master data
    const getSequentialStreams = (): Promise<any>[] => {
      return [
        personaMasterRepository.fetch({}),
        facilityMasterRepository.fetch({})
      ]
    }

    const setSelectedCities = () => {
      const prefs = store.state.prefectureMaster.prefectures
      const labels = prefs.map(pref => {
        return pref.cities
        .filter(city => city.isSelected)
        .map(city => `${pref.name}${city.name}`)
      }).flat().join(',')
      setSelectedAreaLabels(labels)
    }

    const makeTableSequence = () => {
      const drawnPolygon = store.state.polygon
      const prefs = store.getters['prefectureMaster/hasCitySelectedPref']
      if ((prefs.length > 0 || drawnPolygon.length > 0) && !store.state.area.isDetailLoaded) {
        store.dispatch(ActionType.setMakingTable, true)
        let params: Record<string, any> = {}
        if (prefs.length > 0) {
          params.cities = prefs.map(pref => {
            return pref.cities.filter(city => city.isSelected).map(city => {
              return {
                pref_id: pref.id,
                city_id: city.id
              }
            })
          }).flat()
        }
        if (drawnPolygon.length > 0) {
          params.polygon = drawnPolygon
        }
        let loadCount = 0
        areaTableRepository.dataEntryToTable1(params)
        .then(() => {
          loadCount++
          return areaTableRepository.dataEntryToTable2(params)
        })
        .then(() => {
          loadCount++
          return areaTableRepository.dataEntryToTable3(params)
        })
        .then(() => setSelectedCities())
        .then(() => {
          loadMaster()
        })
        .catch(err => {
          if (err.status === 401) {
            authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => store.dispatch(ActionType.setMakingTable, true))
            .then(() => {
              if (loadCount === 1) {
                return areaTableRepository.dataEntryToTable2(params)
                .then(() => areaTableRepository.dataEntryToTable3(params))
              } else if (loadCount === 2) {
                return areaTableRepository.dataEntryToTable3(params)
              } else {
                return areaTableRepository.dataEntryToTable1(params)
                .then(() => areaTableRepository.dataEntryToTable2(params))
                .then(() => areaTableRepository.dataEntryToTable3(params))
              }
            })
            .then(() => setSelectedCities())
            .then(() => store.dispatch(ActionType.setMakingTable, false))
            .then(() => {
              loadMaster()
            })
            .catch((err2) => {
              if (err2.status === 409) {
                WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
              }
              setAreaSelectedPolygon([])
              setSelectedAreaLabels('')
              router.push('/area')
            })
            .finally(() => store.dispatch(ActionType.setMakingTable, false))
          } else if (err.status === 409) {
            setAreaSelectedPolygon([])
            setSelectedAreaLabels('')
            WarningDialog(DOUBLE_LOGIN_IN_PROCESSING_TITLE, DOUBLE_LOGIN_IN_PROCESSING_MESSAGE)
            router.push('/area')
          } else if (err.status === 424) {
            setAreaSelectedPolygon([])
            setSelectedAreaLabels('')
            WarningDialog(PROCESS_CANCELED_TITLE, PROCESS_CANCELED_MESSAGE)
            router.push('/area')
          } else {
            setAreaSelectedPolygon([])
            setSelectedAreaLabels('')
            AlertDialog(err.message)
            router.push('/area')
          }
        })
        .finally(() => store.dispatch(ActionType.setMakingTable, false))
      } else {
        loadMaster()
      }
    }

    const loadMaster = () => {
      state.isLoadingMaster = true
      Promise.all(getSequentialStreams()).then(([personas, facilities]) => {
        store.dispatch(`personaMaster/${PersonaMasterActionType.setPersonaMaster}`, personas)
        store.dispatch(`areaFacilityMaster/${FacilityMasterActionType.setFacilityMaster}`, facilities)
      })
      .catch(err => {
        if (isDashboard.value) {
          if (err.status === 401) {
            return authRepository.refreshToken()
            .then(response => setAccessToken(response.access))
            .then(() => {
              return Promise.all([
                personaMasterRepository.fetch({}),
                facilityMasterRepository.fetch({})
              ])
              .then(([personas, facilities]) => {
                store.dispatch(`personaMaster/${PersonaMasterActionType.setPersonaMaster}`, personas)
                store.dispatch(`areaFacilityMaster/${FacilityMasterActionType.setFacilityMaster}`, facilities)
              })
            })
          } else {
            AlertDialog(err.message)
          }
        }
      })
      .then(() => {
        state.isLoadingMaster = false
        const promises = [loadRanking(), loadTransportationChart(), loadTimeSpent()];

        if (showAreaVisitor.value === 1) {
          promises.push(...[loadVisitor(), loadFacility()]);
        } else {
          promises.push(loadFacility());
        }

        return Promise.all(promises).then(() => loadDetail());
      })
      .finally(() => state.isLoaded = true)
    }
    if (isDashboard.value) {
      makeTableSequence()
    } else {
      state.isLoaded = true
    }

    const handleTabChange = (tab: TabType) => {
      state.rankingTab = tab
    }

    const loadOnDashboard = () => {
      const isRanking = residents.value.length > 0 && workLocations.value.length > 0
      const isTransportation = inAreas.value.length > 0 && visitAreas.value.length > 0
      const isPin = Object.keys(pins.value).length > 0
      const isTimeSpent = times.value.length > 0
      const isVisitor = persona.value.length > 0 && age.value.constraints.length > 0 && age.value.men.length > 0 && age.value.women.length > 0
      if (!isRanking || !isTransportation || !isPin || !isTimeSpent || !isVisitor) {
        loadMaster()
      }
    }

    globalEmitter.on(DOWNLOAD_AREA_DASHBOARD, () => {
      state.captureClip = true
      store.dispatch(ActionType.setAppLoading, true)
      store.dispatch(ActionType.setIsCapturing, true)
      setTimeout(() => {
        ConvertToImage(wrapperElementID).then(function (link) {
          link.download = `${route.meta.filename}.jpg`
          link.target = '_blank'
          link.click()
        })
        .finally(() => {
          state.captureClip = false
          store.dispatch(ActionType.setAppLoading, false)
          store.dispatch(ActionType.setIsCapturing, false)
        })
      }, 250)
    })

    globalEmitter.on(HINT_AREA_DASHBOARD, () => {
      HintDialog(AreaDashboard)
    })

    onBeforeUnmount(() => {
      globalEmitter.off(DOWNLOAD_AREA_DASHBOARD)
      globalEmitter.off(HINT_AREA_DASHBOARD)
    })

    return {
      ...toRefs(state),
      isDashboard,
      areas,
      personas,
      sbMinimum,
      residents,
      workLocations,
      personaColors,
      inAreas,
      visitAreas,
      pins,
      times,
      persona,
      age,
      lessAmountMessage,
      isLessRanking,
      isLessTransportation,
      isLessFacility,
      isLessTimeSpent,
      isLessVisitor,
      timeLabels,
      timeData,
      timeAverage,
      visitorLabels,
      visitorMen,
      visitorWomen,
      isErrorVisitorDoughnut,
      isErrorVisitorChart,
      isErrorTimeSpent,
      isCapturing,
      TabType,
      loadOnDashboard,
      loadMaster,
      handleTabChange,
      showAreaVisitor
    }
  }
})
