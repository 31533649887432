import {AreaTimeSpentState} from '@/store/modules/area/search/timeSpent/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/timeSpent/getters'
import {actions} from '@/store/modules/area/search/timeSpent/actions'
import {mutations} from '@/store/modules/area/search/timeSpent/mutations'

export const state: AreaTimeSpentState = {
  times: [],
  average: 0
}

const namespaced = true
export const areaTimeSpent: Module<AreaTimeSpentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
