import { APIType } from '@/core/apiType';
import { HTTPMethod } from '@/core/httpClient';
import { HttpRequest } from '@/core/httpRequest'
import { getAccessToken } from '@/utils/storage';
import { IChartType } from '../type';

export class ChartRequest implements HttpRequest<IChartType> {
  public response!: IChartType
  public path = '/road/chart/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.Chart

  constructor (params: Record<string, any>) {
    this.params = params
  }
}