<template>
  <div class="jr-mx-15">
    <div class="jr-text-left">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">駅周辺移動元・先エリア分布</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>各エリア → エリア1：<br>エリア1来訪者の内、エリア1来訪前に各エリアを訪れた人の割合。</p>
        <p class="jr-pt-4">エリア1 → 各エリア：<br>エリア1来訪者の内、エリア1来訪後に各エリアを訪れた人の割合。</p>
        <div class="jr-pt-4">
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>エリア1来訪から3時間以上前/後の来訪はカウントしない。</span>
          </p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>各エリア内の居住者・勤務者はカウントしない。</span>
          </p>
        </div>
      </div>
    </div>

    <div class="jr-text-left jr-mt-10.5">
      <h1 class="jr-text-blue-bold jr-text-base jr-font-bold jr-border-b jr-border-primary">属性情報</h1>
      <div class="jr-pt-4 jr-font-base jr-text-sm">
        <p>設定したエリア全体の来訪者の性別・年代比を表示しています。</p>
        <div class="jr-pt-4">
          <p>いずれも集計は「日のべ人数」です。</p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>同一人物が2日間来訪した場合は2とカウント。</span>
          </p>
          <p>
            <span class="jr-pr-2.5">※</span>
            <span>エリア間の来訪有無は、同日中のみを対象とする。</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
