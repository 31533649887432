import { HttpClient } from '@/core/httpClient'
import { HttpRequest } from '@/core/httpRequest'
import { IQueryType } from '../type'

export interface IQueryAPIStoreType {
  fetch(request: HttpRequest<Array<Array<number>>>): Promise<Array<Array<number>>>
}

export default class QueryAPIStore implements IQueryAPIStoreType {
  async fetch(request: HttpRequest<Array<Array<number>>>): Promise<Array<Array<number>>> {
    const client = new HttpClient<Array<Array<number>>>()
    const response = await client.request<Array<Array<number>>>(request)
    return response.data
  }
}