import {AreaFacilityPinState} from '@/store/modules/area/search/facility/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/search/facility/getters'
import {actions} from '@/store/modules/area/search/facility/actions'
import {mutations} from '@/store/modules/area/search/facility/mutations'

export const state: AreaFacilityPinState = {
  isLoading: false,
  pins: {},
  pinGroups: []
}

const namespaced = true
export const areaFacilityPin: Module<AreaFacilityPinState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
