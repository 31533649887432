/**
 * Convert seconds to hours and minutes
 * @returns string (HH:MM)
 * @param value
 */
export function secondToHM (value: number): string {
    const HOUR = 3600 // 1 hour
    const MINUTE = 60 // 1 minute
    const hours = ('0' + Math.floor(value / HOUR)).toString().slice(-2)

    value = value % HOUR;
    const minutes = ('0' + Math.floor(value / MINUTE)).toString().slice(-2)
    return hours + ":" + minutes
}

/**
 * Format hour number to HH:MM
 * @returns string (HH:MM)
 * @param value
 */
export function formatHourToLeadingZero (value: number): string {
  return ('0' + value).toString().slice(-2)
}

import {TIME_REGEX} from '@/constant'

/**
 * Input: hh:mm:ss
 * Output: timestamp number
 */
export const timeStringToTimestamp = (value: string): number => {
  if (!TIME_REGEX.test(value)) return NaN
  const splitter = value.split(':')

  // smallest unit is second
  const h = Number(splitter[0]) * 3600 // 1h = 3600 secs
  const m = Number(splitter[1]) * 60   // 1min = 60 secs
  const s = Number(splitter[2])
  return h + m + s
}

/**
 * Trim second on time string
 * @param value time string hh:mm:ss
 * @returns trimed second string hh:mm
 */
export const trimSecond = (value: string): string => {
  if (!TIME_REGEX.test(value)) return value
  const splitter = value.split(':')
  return splitter[0] + ':' + splitter[1]
}

/**
 * convert date to time string
 * @param value date object
 * @returns hh:mm:ss
 */
export const dateToTimeString = (value: Date): string => {
  if (value === null || isNaN(value.getTime())) {
    console.warn('utils:date:dateToTimeString:', 'given date is invalid', value)
    return ''
  }
  const h = leadingZero(value.getHours())
  const m = leadingZero(value.getMinutes())
  const s = leadingZero(value.getSeconds())
  return h + ':' + m + ':' + s
}

/**
 * add leading zero to string or number
 * @param value target
 * @param digits amount of 0 to be appended
 * @returns formatted string
 */
export function leadingZero(value: string | number, digits = -2): string {
  return ('0' + value).slice(digits)
}
