import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "jr-flex jr-w-content jr-items-center jr-rounded-md jr-z-10 jr-bg-white" }
const _hoisted_2 = { class: "jr-flex jr-items-center jr-ml-3 jr-my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
        return (_openBlock(), _createElementBlock("li", {
          key: color,
          class: "jr-inline-block jr-w-1.5 jr-h-3.5",
          style: _normalizeStyle({ 'background-color': `rgba(${color.Red},${color.Green},${color.Blue},${color.Alpha / 255})` })
        }, null, 4))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("span", { class: "jr-text-xs jr-mx-3" }, _toDisplayString(_ctx.min) + "〜" + _toDisplayString(_ctx.max) + "位", 513), [
      [_vShow, _ctx.colors.length > 0]
    ])
  ]))
}