import {FacilityMasterState} from '@/store/modules/area/master/facility/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/master/facility/getters'
import {actions} from '@/store/modules/area/master/facility/actions'
import {mutations} from '@/store/modules/area/master/facility/mutations'

export const state: FacilityMasterState = {
  facilities: []
}

const namespaced = true
export const areaFacilityMaster: Module<FacilityMasterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
