
import {defineComponent, inject, reactive, toRefs} from 'vue'
import {v4 as uuidv4} from 'uuid'
import {EventType, Emitter} from 'mitt'
import {setAccessToken} from '@/utils/storage'
import {AlertDialog} from '@/service/dialog'
import {useStore} from 'vuex'
import {RailLineActionType} from '@/store/modules/railway/master/line/actions'
import AppLoader from '@/components/AppLoader.vue'
import RailwaySidebar from '@/components/subviews/railway/RailwaySidebar.vue'
import VisualizePanel from '@/components/subviews/railway/VisualizePanel.vue'
import StationLineSelector from '@/components/subviews/railway/StationLineSelector.vue'
import StationUserSelector from '@/components/subviews/railway/StationUserSelector.vue'
import RailwayMasterStubStore from '@/data/master/railway/store/stub'
import RailwayMasterAPIStore from '@/data/master/railway/store'
import RailwayMasterRepository from '@/data/master/railway/repository'
import AuthAPIStore from '@/data/auth/store'
import AuthRepository from '@/data/auth/repository'

enum Mode {
  None,
  Line,
  User
}

interface State {
  mode: Mode
  isLoading: boolean
  minimum: boolean
}

export default defineComponent({
  name: 'Railway',
  components: {
    AppLoader,
    RailwaySidebar,
    VisualizePanel,
    StationLineSelector,
    StationUserSelector
  },
  setup() {
    const emitter = inject<Emitter<Record<EventType, any>>>('emitter')
    const inputSearchId = `input_search_${uuidv4()}`
    const state = reactive<State>({
      mode: Mode.None,
      isLoading: false,
      minimum: false
    })
    const store = useStore()

    // data layer
    const stub = new RailwayMasterStubStore()
    const api = new RailwayMasterAPIStore()
    const repository = new RailwayMasterRepository(stub, api)

    // auth
    const authApi = new AuthAPIStore()
    const authRepository = new AuthRepository(authApi)

    const onKeywordSearch = (keyword: string) => {
      if (keyword.length > 0) {
        emitter?.emit('placeSearch', keyword)
      }
    }
    const onClear = () => {
      state.mode = Mode.None
    }

    // initial load
    const load = () => {
      state.isLoading = true
      repository.fetchRailLine({}).then(lines => {
        store.dispatch(`railLine/${RailLineActionType.setLines}`, lines)
      })
      .catch(err => {
        if (err.status === 401) {
          return authRepository.refreshToken()
          .then(response => setAccessToken(response.access))
          .then(() => {
            return repository.fetchRailLine({}).then(lines => {
              store.dispatch(`railLine/${RailLineActionType.setLines}`, lines)
            })
          })
        } else {
          AlertDialog(err.message)
        }
      }).finally(() => state.isLoading = false)
    }
    load()

    const handleNav = (mode: Mode) => state.mode = mode
    const onMinimum = (value: boolean) => {
      state.minimum = value
    }

    return {
      ...toRefs(state),
      inputSearchId,
      onClear,
      onKeywordSearch,
      onMinimum,
      handleNav,
      Mode
    }
  },
})
