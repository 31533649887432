import {GetterTree} from 'vuex'
import {RailwayState} from '@/store/modules/railway/type'
import {RootState} from '@/store/types'
import {leadingZero} from '@/utils/time'

export const getters: GetterTree<RailwayState, RootState> = {
  hasActivePolygons (state: RailwayState): boolean {
    return state.activePolygons.length > 0
  },
  timeRangeBadge (state: RailwayState): {title: string; content: string} {
    if (state.timeRange[0] !== state.timeRange[1]) {
      return {
        title: '時間帯',
        content: `${leadingZero(state.timeRange[0])}:00 - ${leadingZero(state.timeRange[1])}:00`
      }
    }
    return {
      title: '',
      content: ''
    }
  },
  weekBadge (state: RailwayState): {title: string; content: string} {
    if (state.week.length > 0) {
      const content = state.week
        .map(w => w.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.week.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      return {
        title: '平日・土日祝別',
        content: content
      }
    }
    return {
      title: '',
      content: ''
    }
  },
  transportationBadge (state: RailwayState): {title: string; content: string} {
    if (state.transportations.length > 0) {
      const content = state.transportations.map(t => t.name)
        .reduce((acc, val, index) => {
          let t = acc + val
          if (index < state.transportations.length - 1) {
            t = t + '/'
          }
          return t
        }, '')
      return {
        title: '駅までの交通手段別',
        content: content
      }
    }
    return {
      title: '',
      content: ''
    }
  },
  residentBadge (state: RailwayState): {title: string; content: string} {
    if (state.residents.length > 0) {
      const content = state.residents.map(r => r.name)
        .reduce((acc, val, index) => {
          let r = acc + val
          if (index < state.residents.length - 1) {
            r = r + '/'
          }
          return r
        }, '')
      return {
        title: '居住地・勤務地別',
        content: content
      }
    }
    return {
      title: '',
      content: ''
    }
  }
}

