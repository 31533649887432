import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "jr-block jr-px-2" }
const _hoisted_2 = { class: "jr-font-bold jr-text-primary jr-text-s jr-text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSlider = _resolveComponent("VueSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueSlider, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      min: 0,
      max: 24,
      height: 10,
      minRange: 3,
      maxRange: 24,
      enableCross: true,
      clickable: false,
      adsorb: true,
      stepStyle: _ctx.stepStyle,
      processStyle: _ctx.processStyle,
      tooltip: "none",
      onChange: _ctx.handleChange
    }, null, 8, ["modelValue", "stepStyle", "processStyle", "onChange"]),
    _createElementVNode("p", _hoisted_2, [
      (_ctx.value[0] !== _ctx.value[1])
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.formatTime(_ctx.value[0])) + " - " + _toDisplayString(_ctx.formatTime(_ctx.value[1])), 1))
        : _createCommentVNode("", true),
      (_ctx.value[0] === _ctx.value[1])
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "指定なし"))
        : _createCommentVNode("", true)
    ])
  ]))
}