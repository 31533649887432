import data from '@/assets/barchart.json'
import { IChartType } from '../type'

export interface IChartStubType {
	fetch(): Promise<IChartType>
}

export default class ChartStubStore implements IChartStubType {
	fetch (): Promise<IChartType> {
		return new Promise((resolve) => resolve(data as IChartType))
	}
}