export const SESSION_NAMESPACE = "t__jinryu_session"
export const AREA_SELECTED_POLYGON_NAMESPACE = "t__jinryu_area_polygons"
export const AREA_SELECTED_CITY_NAMESPACE = "t__jinryu_area_cities"
export const RAILWAY_POLYGON_NAMESPACE = "t__jinryu_railway_polygons"
export const RAILWAY_SELECTED_LINE_NAMESPACE = "t__jinryu_railway_selected_line"
export const RAILWAY_SELECTED_STATION_NAMESPACE = "t__jinryu_railway_selected_station"
export const SELECTED_ROAD_NAMESPACE = "t__jinryu_roads"
export const TIME_REGEX = /^(?:2[0-24]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/
export const CONTENT_DOWNLOAD_ELEMENT_ID = 'content-download'
export const CONTENT_DOWNLOAD_ROAD_GRAPH = 'content-download-road-graph'
export const CONTENT_DOWNLOAD_AREA_DASHBOARD = 'content-download-area-dashboard'
export const CONTENT_DOWNLOAD_AREA_VISITOR = 'content-download-area-visitor'
export const CONTENT_DOWNLOAD_RAILWAY_COMPARISON = 'content-download-railway-comparison'
export const CONTENT_DOWNLOAD_RAILWAY_STATION = 'content-download-railway-station'
export const LOCATION_TURN_OFF = '位置情報がオフになっています'
export const LOCATION_NOT_FOUND = '位置情報が取得できませんでした'
export const LOGOUT_QUESTION = "ログアウトしてよろしいですか？"
export const AREA_WARNING_TITLE = "該当レコードが少なすぎます"
export const AREA_WARNING_MESSAGE = "チェック項目を選び直してから、\n再度お試しください。"
export const NO_CONDITION_SPECIFIED = "いくつかの条件を指定してください"
export const MISMATCH_CONFIRM_PASSWORD = "パスワードとパスワードの確認が一致しません"
export const PASSWORD_RESET_MAIL_SEND = "パスワードリセット用のリンクを\nご登録のメールアドレスに送信しました"
export const PASSWORD_RESET_SUCCESS = "パスワードがリセットされました"

// area search filter
export const AREA_NO_WEEK = "曜日を指定してください"
export const AREA_NO_TRANSPORTATION = "エリア来訪時の交通手段を指定してください"
export const AREA_NO_FREQUENCY = "来訪頻度を指定してください"
export const AREA_NO_ATTRIBUTE = "エリア来訪者の属性を指定してください"

// railway search filter
export const RAILWAY_NO_TRANSPORTATION = "駅までの交通手段別を指定してください"
export const RAILWAY_NO_RESIDENT = "居住地・勤務地別を指定してください"
export const RAILWAY_NO_WEEK = "平日・土日祝を指定してください"

export const HOME_CITY_LABEL = "市民"
export const NOT_HOME_CITY_LABEL = "市外"

export const WEEKDAY_LABEL = "平日"
export const WEEKEND_LABEL = "土日祝日"

export const MALE_LABEL = "男性"
export const FEMALE_LABEL = "女性"

export const MAX_THRESHOLD_TITLE = "最大処理件数を超えました。"
export const MAX_THRESHOLD_MESSAGE = "条件を絞って再度実行して下さい。"

export const EMPTY_ROAD_ERROR_TITLE = "選択できる道路がありません。"
export const EMPTY_ROAD_ERROR_MESSAGE = "範囲を選択し直して下さい。"

// login error handling
export const DOUBLE_LOGIN_IN_PROCESSING_TITLE = "同一ユーザーで行っている処理が残っています。"
export const DOUBLE_LOGIN_IN_PROCESSING_MESSAGE = "処理が終わるまで待って、再度実行してください"
export const NOTIFY_LONG_PROCESSING_TITLE = "処理はキャンセルできません。"
export const NOTIFY_LONG_PROCESSING_MESSAGE = "データ量によっては時間を要する場合がございます。実行しますか？"
export const PROCESS_CANCELED_TITLE = "処理がキャンセルされました"
export const PROCESS_CANCELED_MESSAGE = "同時に複数の同じ処理を実行するとデータが\n不整合になる可能性があるため処理をキャンセルしました"
export const EMPTY_ROAD = "道路を選択してください"

export const POLYLGON_OPTIONS =  {
  fillColor: "#1967D2",
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: "#1967D2",
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 100,
}

export const NEW_POLYGON_OPTIONS = {
  fillColor: "#19253E",
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: "#000000",
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 100,
}

export const DRAWING_POLYGON_MESSAGE =
  '<div style="text-align:center;line-height:21px;padding:0 10px;">' +
    '<h1 style="font-weight:bold;font-size:14px;">地図をクリックして表示する範囲を描きます</h1>'
  '</div>';
