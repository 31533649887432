import {MutationTree} from 'vuex'
import {AreaVisitorState} from '@/store/modules/area/search/visitor/type'
import {IVisitorAgeType, IVisitorPersonaType} from '@/data/area/type'

export enum AreaVisitorMutationType {
  SET_VISITOR_PERSONA = 'SetVisitorPersona',
  SET_VISITOR_AGE = 'SetVisitorAge'
}

export const mutations: MutationTree<AreaVisitorState> = {
  [AreaVisitorMutationType.SET_VISITOR_PERSONA] (state: AreaVisitorState, payload: IVisitorPersonaType[]): void {
    state.persona = payload
  },
  [AreaVisitorMutationType.SET_VISITOR_AGE] (state: AreaVisitorState, payload: IVisitorAgeType): void {
    state.ages = payload
  }
}
