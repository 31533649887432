
import {defineComponent, PropType} from 'vue'

export interface TransportationColorType {
  name: string
  color: string
}

export default defineComponent({
  name: 'TransportationColors',
  props: {
    colors: {
      type: Object as PropType<Array<TransportationColorType>>,
      required: false,
    }
  }
})
