import {HttpRequest} from "@/core/httpRequest";
import {HTTPMethod} from "@/core/httpClient";
import {getAccessToken} from "@/utils/storage";
import {APIType} from "@/core/apiType";
import {IAnimationHoursType} from "@/data/animationhour/type";

export class AnimationHourRequest implements HttpRequest<IAnimationHoursType> {
    public response!: IAnimationHoursType
    public path = '/road/hour/'
    public method = HTTPMethod.POST
    public bearer = getAccessToken()
    public params: Record<string, any>
    public apiType = APIType.AnimationHour

    constructor (params: Record<string, any>) {
        this.params = params
    }
}