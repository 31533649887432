
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'UIBtnAddPolygon',
  emits: ['tap', 'clear'],
  props: {
    label: { type: String, required: false, default: '' },
    active: { type: Boolean, default: false },
    disable: { type: Boolean, default: false }
  },
  setup (_, {emit}) {
    const handleTap = () => emit('tap')
    const handleClear = () => emit('clear')
    return {
      handleTap,
      handleClear
    }
  }
})
