import {ScatterplotLayer} from '@deck.gl/layers'

export const scatterPlotFactory = function (data: Array<number[]>): ScatterplotLayer {
  return new ScatterplotLayer({
    data: data,
    filled: true,
    opacity: 0.3,
    radiusScale: 10,
    radiusMinPixels: 2,
    lineWidthUnits: 'pixels',
    getPosition: d => [d[1], d[0]],
    getFillColor: d => {
      const type = d[2]
      switch (type) {
        case 1: return [161, 188, 211, 255]
        case 2: return [250, 165, 171, 255]
        case 3: return [255, 196, 142, 255]
        default: return [161, 188, 211, 255]
      }
    }
  })
}
