
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import UIBtnAddPolygon from '@/components/UI/railway/UIBtnAddPolygon.vue'

interface State {
  minimum: boolean
}

export default defineComponent({
  name: 'StationPolygonSelector',
  components: {UIBtnAddPolygon},
  props: {
    disable: { type: Boolean, default: false }
  },
  emits: [
    'minimum',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'clearFirst',
    'clearSecond',
    'clearThird',
    'clearFourth',
    'clearFifth',
    'clearSixth',
    'search'
  ],
  setup (_, {emit}) {
    const store = useStore()
    const state = reactive<State>({
      minimum: false
    })
    const firstPolygon = computed(() => store.state.railway.firstPolygon)
    const secondPolygon = computed(() => store.state.railway.secondPolygon)
    const thirdPolygon = computed(() => store.state.railway.thirdPolygon)
    const fourthPolygon = computed(() => store.state.railway.fourthPolygon)
    const fifthPolygon = computed(() => store.state.railway.fifthPolygon)
    const sixthPolygon = computed(() => store.state.railway.sixthPolygon)
    const isValidForSearch = computed(() => {
      const constraints = [
        store.state.railway.firstPolygon,
        store.state.railway.secondPolygon,
        store.state.railway.thirdPolygon,
        store.state.railway.fourthPolygon,
        store.state.railway.fifthPolygon,
        store.state.railway.sixthPolygon
      ].filter(f => f)
      return constraints.length >= 2
    })
    const handleSearch = () => emit('search')
    const handleBtnPolygon = (event: 'first' | 'second' | 'third' | 'fourth' | 'fifth' | 'sixth') => emit(event)
    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
      emit('minimum', state.minimum)
    }

    return {
      ...toRefs(state),
      firstPolygon,
      secondPolygon,
      thirdPolygon,
      fourthPolygon,
      fifthPolygon,
      sixthPolygon,
      isValidForSearch,
      handleSearch,
      handleBtnPolygon,
      clickClose
    }
  }
})
