
import {computed, defineComponent} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {AreaActionType} from '@/store/modules/area/actions'

export default defineComponent({
  name: 'AreaReportSidebar',
  props: {
    loadingDetail: { type: Boolean, required: false, default: false },
    loaded: { type: Boolean, required: false, default: false }
  },
  emits: ['dashboard'],
  setup (_, {emit}) {
    const route = useRoute()
    const store = useStore()

    // pages
    const isDashboard = computed(() => route.path === '/area_report')
    const isSameDay = computed(() => route.path === '/area_report/same_day_action')
    const isRanking = computed(() => route.path === '/area_report/ranking')
    const isTransportation = computed(() => route.path === '/area_report/transportation')
    const isFacilities = computed(() => route.path === '/area_report/facilities')
    const isTiming = computed(() => route.path === '/area_report/timing')
    const isVisitor = computed(() => route.path === '/area_report/visitor')
    const sbMinimum = computed(() => store.state.area.sbMinimum)
    const isCapturing = computed(() => store.state.isCapturing)
    const onClickClose = () => store.dispatch(`area/${AreaActionType.toggleSidebar}`)
    const onClickLink = (name: string) => {
      if (name === 'ダッシュボード') {
        emit('dashboard')
      }
      store.dispatch(`area/${AreaActionType.setSelectPage}`, name)
    }

    // show area visitor
    const showAreaVisitor = computed(() => store.state.showAreaVisitor);

    return {
      onClickClose,
      onClickLink,
      isDashboard,
      isSameDay,
      isRanking,
      isTransportation,
      isFacilities,
      isTiming,
      isVisitor,
      isCapturing,
      sbMinimum,
      showAreaVisitor
    }
  }
})
