import {AreaPolygonMasterState} from '@/store/modules/area/master/polygon/type'
import {Module} from 'vuex'
import {RootState} from '@/store/types'
import {getters} from '@/store/modules/area/master/polygon/getters'
import {mutations} from '@/store/modules/area/master/polygon/mutations'
import {actions} from '@/store/modules/area/master/polygon/actions'

export const state: AreaPolygonMasterState = {
  polygons: [],
  rankingPolygons: [],
  selectedPolygons: []
}

const namespaced = true
export const areaPolygonMaster: Module<AreaPolygonMasterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

