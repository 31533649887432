import {POLYLGON_OPTIONS} from '@/constant'
import {DayAbbr, DayCode, WeekdayFlag} from './enum/barchart'
import {IConfigType, IAvailableDayType, IAvailableWeekType} from './types'
import {ITransportationType} from './types'

export class Config implements IConfigType {
  private static _instance: Config
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }
  readonly availableDayFilter = [
    DayCode.Sunday,
    DayCode.Monday,
    DayCode.Tuesday,
    DayCode.Wednesday,
    DayCode.Thursday,
    DayCode.Friday,
    DayCode.Saturday
  ]
  readonly availableWeekFilter = [
    WeekdayFlag.Weekday,
    WeekdayFlag.Weekend
  ]

  readonly heatmapTopProps = {
    intensity: 1,
    opacity: 0.65,
    threshold: 0.05,
    radiusPixels: 20,
  };

  readonly heatmapDetailProps = {
    // intensity: 5,
    // opacity: 0.65,
    // threshold: 0.05,
    // radiusPixels: 25,
    intensity: 50,
    opacity: 1,
    threshold: 0.05,
    radiusPixels: 15
  };
  
  readonly from = '2021/05/18'
  readonly to = '2021/11/18'

  readonly heatmapColors = {
    hex: ['#ffffb7', '#fadc7e', '#f4b758', '#f19347', '#e04928', '#af2128'],
    rgb: [[255, 255, 183, 128], [249, 220, 127, 255], [244, 182, 86, 255], [240, 147, 71, 255], [224, 74, 40, 255], [174, 33, 40, 255]]
  }

  polygonDrawingManager = () => {
    return new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: POLYLGON_OPTIONS,
    })
  }

  translateDay = (input: DayAbbr): string => {
    switch (input) {
      case DayAbbr.Sunday:    return '日'
      case DayAbbr.Monday:    return '月'
      case DayAbbr.Tuesday:   return '火'
      case DayAbbr.Wednesday: return '水'
      case DayAbbr.Thursday:  return '木'
      case DayAbbr.Friday:    return '金'
      case DayAbbr.Saturday:  return '土'
      default: return ''
    }
  }

  translateDayToCode = (input: DayAbbr): number => {
    switch (input) {
      case DayAbbr.Sunday:    return 1
      case DayAbbr.Monday:    return 2
      case DayAbbr.Tuesday:   return 3
      case DayAbbr.Wednesday: return 4
      case DayAbbr.Thursday:  return 5
      case DayAbbr.Friday:    return 6
      case DayAbbr.Saturday:  return 7
      default: return 0
    }
  }

  translateDayByCode = (input: DayCode): string => {
    switch (input) {
      case DayCode.Sunday:    return '日'
      case DayCode.Monday:    return '月'
      case DayCode.Tuesday:   return '火'
      case DayCode.Wednesday: return '水'
      case DayCode.Thursday:  return '木'
      case DayCode.Friday:    return '金'
      case DayCode.Saturday:  return '土'
      case DayCode.EveryDay:  return '祝'
      default: return ''
    }
  }

  translateWeekByCode = (input: WeekdayFlag): string => {
    switch (input) {
      case WeekdayFlag.Weekday: return '平日'
      case WeekdayFlag.Weekend: return '土日祝日'
      default: return ''
    }
  }

  translateUniversalDayByCode = (input: DayCode): string => {
    switch (input) {
      case DayCode.Sunday:    return 'sun'
      case DayCode.Monday:    return 'mon'
      case DayCode.Tuesday:   return 'tue'
      case DayCode.Wednesday: return 'wed'
      case DayCode.Thursday:  return 'thu'
      case DayCode.Friday:    return 'fri'
      case DayCode.Saturday:  return 'sat'
      default: return ''
    }
  }

  readonly availableDays: IAvailableDayType[] = [
    {
      code: 1,
      label: "日"
    },
    {
      code: 2,
      label: "月"
    },
    {
      code: 3,
      label: "火"
    },
    {
      code: 4,
      label: "水"
    },
    {
      code: 5,
      label: "木"
    },
    {
      code: 6,
      label: "金"
    },
    {
      code: 7,
      label: "土"
    },
    {
      code: 9,
      label: "祝日"
    }
  ]

  readonly availableWeeks: IAvailableWeekType[] = [
    {
      code: 1,
      label: "平日"
    },
    {
      code: 0,
      label: "土日祝日"
    },
  ]
  
  // FOR AREAS
  readonly transportationTypes: ITransportationType[] = [
    {
      id: 1,
      name: "車", // car
      color: "#A1BCD3" // rgb(161,188,211)
    },
    {
      id: 2,
      name: "鉄道", // rail road
      color: "#FAA5AB" // rgb(250,165,171)
    },
    {
      id: 3,
      name: "徒歩", // on foot
      color: "#FFC48E" // rgb(255,196,142)
    }
  ]
  readonly personaRankingColors: string[] = [
    'rgb(15, 65, 145)',
    'rgb(25, 103, 210)',
    'rgb(38, 160, 230)',
    'rgb(40, 185, 240)',
    'rgb(50, 200, 245)',
    'rgb(200, 200, 200)'
  ]
  readonly MAX_DASHBOARD_RANKING: number = 10
  readonly MAX_DASHBOARD_FACILITY: number = 10
  
  // FOR RAILWAY
  readonly polygonColor = {
    first: {
      rgb: 'rgb(170, 59, 19)',
      hex: '#AA3B13'
    },
    second: {
      rgb: 'rgb(15, 65, 145)',
      hex: '#0F4191'
    },
    third: {
      rgb: 'rgb(25, 103, 210)',
      hex: '#1967D2'
    },
    fourth: {
      rgb: 'rgb(38, 160, 230)',
      hex: '#26A0E6'
    },
    fifth: {
      rgb: 'rgb(50, 200, 245)',
      hex: '#32C8F5'
    },
    sixth: {
      rgb: 'rgb(164, 230, 250)',
      hex: '#A4E6FA'
    }
  }
  readonly stationBarchartColor = {
    '~1km': {
      hex: '#A4E6FA'
    },
    '1~2km': {
      hex: '#32C8F5'
    },
    '2~3km': {
      hex: '#28B9F0'
    },
    '3~5km': {
      hex: '#26A0E6'
    },
    '5km~': {
      hex: '#1967D2'
    }
  }

  /**
   * 0: 15min
   * 1: 30min
   * 2: 1hour
   */
  readonly makeTableUnit: number = 2;
  readonly makeTableUnitHourTime: number = 0;

  tableUnitVal(): number {
    const unit = Config.Instance.makeTableUnit
    if (unit === 0) {
      return 4
    } else if (unit === 1) {
      return 2
    } else {
      return 1
    }
  }

  tableUnitValHourTime(): number {
    const unit = Config.Instance.makeTableUnitHourTime
    if (unit === 0) {
      return 4
    } else if (unit === 1) {
      return 2
    } else {
      return 1
    }
  }

  getDayByCode(code: number): IAvailableDayType {
    const target = this.availableDays.find(s => s.code === code)
    if (target) return target
    return {
      label: '',
      code: -1
    }
  }

  getWeekByCode(code: number): IAvailableWeekType {
    const target = this.availableWeeks.find(s => s.code === code)
    if (target) return target
    return {
      label: '',
      code: -1
    }
  }
}
