
import {computed, defineComponent} from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'UIRadioButton',
  props: {
    label: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    modelValue: { type: String, required: false, default: '' },
    labelClasses: { type: String, required: false, default: '' }
  },
  emits: ['update:modelValue', 'change'],
  setup (props, { emit }) {
    const id = uuidv4()
    const isChecked = computed(() => props.modelValue === props.value)
    const handleChange = (e: Event) => {
      const target = (e.target as HTMLInputElement).value
      emit('update:modelValue', target)
      emit('change', target)
    }
    return {
      id,
      isChecked,
      handleChange
    }
  }
})
