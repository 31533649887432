import {MutationTree} from 'vuex'
import {PersonaMasterState} from '@/store/modules/area/master/persona/type'
import {IPersonaType} from '@/data/master/area/persona/type'

export enum PersonaMasterMutationType {
  SET_PERSONA_MASTER = 'SetPersonaMaster'
}

export const mutations: MutationTree<PersonaMasterState> = {
  [PersonaMasterMutationType.SET_PERSONA_MASTER] (state: PersonaMasterState, payload: IPersonaType[]): void {
    state.personas = payload
  }
}
