import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style.scss'
import './form/rules'
import { configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'

import mitt from 'mitt'
const emitter = mitt()

configure({
  validateOnInput: true,
  validateOnModelUpdate: true,
  generateMessage: localize({
    en: {
      names: {},
      messages: {
        required: '値を指定する必要があります',
        max: '0:{max}文字を超えて入力することはできません',
        digits: '指定できるのは0:{digits}文字のみ',
        email: '値は有効なメールではありません',
        numeric: '値は数値でなければなりません',
        regex: '値の形式が間違っています'
      },
    },
  })
})

const app = createApp(App)
app.use(store)
   .use(router)
   .provide('emitter', emitter)
   .mount('#app')
