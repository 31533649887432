import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2293606c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterNavigation = _resolveComponent("FilterNavigation")!
  const _component_StationPolygonSelector = _resolveComponent("StationPolygonSelector")!
  const _component_PolygonColors = _resolveComponent("PolygonColors")!
  const _component_UIGoogleMap = _resolveComponent("UIGoogleMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilterNavigation),
    _createVNode(_component_StationPolygonSelector, {
      onMinimum: _ctx.handleMinimum,
      onFirst: _ctx.handleFirst,
      onSecond: _ctx.handleSecond,
      onThird: _ctx.handleThird,
      onFourth: _ctx.handleFourth,
      onFifth: _ctx.handleFifth,
      onSixth: _ctx.handleSixth,
      onClearFirst: _ctx.handleClearFirst,
      onClearSecond: _ctx.handleClearSecond,
      onClearThird: _ctx.handleClearThird,
      onClearFourth: _ctx.handleClearFourth,
      onClearFifth: _ctx.handleClearFifth,
      onClearSixth: _ctx.handleClearSixth,
      onSearch: _ctx.handleSearchPolygon,
      disable: _ctx.manager === null
    }, null, 8, ["onMinimum", "onFirst", "onSecond", "onThird", "onFourth", "onFifth", "onSixth", "onClearFirst", "onClearSecond", "onClearThird", "onClearFourth", "onClearFifth", "onClearSixth", "onSearch", "disable"]),
    _createVNode(_component_UIGoogleMap, {
      onLoad: _ctx.handleMapLoad,
      class: "jr-absolute jr-w-full jr-h-full"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PolygonColors, {
          class: "transition-indicator jr-absolute jr-bottom-3.5",
          style: _normalizeStyle({ left: _ctx.sbMinimum ? '74px' : '250px' })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["onLoad"])
  ]))
}