
import {computed, defineComponent} from 'vue'
import {BarChart, useBarChart} from 'vue-chart-3'
import {Chart, registerables} from 'chart.js'
import {useStore} from 'vuex'
import {Config} from '@/config'
import {leadingZero} from '@/utils/time'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'
import {RailwayStationTab} from "@/enum/station";

Chart.register(...registerables, ChartDataLabels)
export default defineComponent({
  name: 'StationBarchart',
  components: {BarChart},
  setup () {
    const store = useStore()
    const isShow = computed(() => store.state.railway.railwayStation.labels.length > 0)
    const height = computed(() => {
      return (42 * store.state.railway.railwayStation.labels.length) + 102
    })
    const width = computed(() => {
      return window.innerWidth - 504
    })
    const options = computed(() => {
      const selectedTab = store.state.railway.stationTab
      const theData = selectedTab === RailwayStationTab.Resident ? store.state.railway.railwayStation.data.home : store.state.railway.railwayStation.data.office
      const data = _.values(theData)
      return {
        indexAxis: 'y',
        categoryPercentage: 0.8,
        barPercentage: 0.4,
        responsive: true,
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        // events: [], // remove this setting to show event related items such as tooltips
        plugins: {
          tooltip: {
            position: 'nearest',
            callbacks: {
              title: function() {
                return ''
              },
              label: function(chart) {
                return `${chart.dataset.label} ${chart.formattedValue}%`
              }
            }
          },
          datalabels: {
            color: function (v) {
              if (data.every(d => d[v.dataIndex] === 0)) {
                return 'black'
              }
              return 'white'
            },
            font: {
              size: 12
            },
            // display: (v) => {
            //   return data[v.datasetIndex][v.dataIndex] > 0
            //   // return data.every(d => d[v.dataIndex] === 0)
            // },
            align: function (v) {
              if (data.every(d => d[v.dataIndex] === 0)) {
                return 'end'
              }
              return 'center'
            },
            anchor: function (v) {
              if (data.every(d => d[v.dataIndex] === 0)) {
                return 'end'
              }
              return 'center'
            },
            formatter: function (v, x) {
              if (data.every(d => d[x.dataIndex] === 0)) {
                return 'データなし'
              }
              return v < 3 ? '' : `${v}%`
              // return 'データなし';
            }
          },
          legend: {
            align: 'end',
            labels: {
              color: '#000',
              padding: 25,
              boxWidth: 14,
              boxHeight: 14
            }
          }
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              padding: 25,
              crossAlign: 'far',
              align: 'center',
              color: 'black',
              font: { size: 14 }
            }
          },
          x: {
            position: 'top',
            stacked: true,
            grid: {
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              color: 'black',
              stepSize: 20,
              align: 'center',
              callback: function (value) {
                const n = Number(value)
                if (n % 100 === 0 && n !== 0) {
                  return `${value} (%)`
                }
                return n === 0 ? n : ''
              }
            }
          }
        }
      }
    })

    const chartData = computed(() => {
      const datasets: {label: string; data: number[]; backgroundColor?: string[]}[] = []
      const data = store.state.railway.railwayStation.data
      const selectedTab = store.state.railway.stationTab
      const theData = selectedTab === RailwayStationTab.Resident ? data.home : data.office
      for (let label in theData) {
        const color = Config.Instance.stationBarchartColor[label]
        const target = {
          label: label,
          data: theData[label]
        }
        if (color) {
          target['backgroundColor'] = [color.hex]
        }
        datasets.push(target)
      }
      return {
        labels: store.state.railway.railwayStation.labels.map((l, i) => `${leadingZero(i + 1)} ${l}`),
        datasets: datasets
      }
    })

    const { barChartProps } = useBarChart({
      chartData,
      options
    })
    return { barChartProps, height, width, isShow }
  }
})
