
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {RailLineActionType} from '@/store/modules/railway/master/line/actions'
import {useRouter} from 'vue-router'
import {setSelectedRailwayLine} from '@/utils/storage'
import UIRadioButton from '@/components/UI/UIRadioButton.vue'

interface State {
  minimum: boolean
}

export default defineComponent({
  name: 'StationUserSelector',
  components: {UIRadioButton},
  emits: ['clear', 'minimum', 'search'],
  setup(_, {emit}) {
    const state = reactive<State>({
      minimum: false
    })
    const router = useRouter()
    const store = useStore()
    const lines = computed(() => store.state.railLine.lines)
    const selectedLine = computed<string>(() => store.state.railLine.selectedLine)

    const clearAll = (event: Event) => {
      event.preventDefault()
      emit('clear')
    }

    const clickClose = (event: Event) => {
      event.preventDefault()
      state.minimum = !state.minimum
      emit('minimum', state.minimum)
    }

    const handleLineChange = (value: string) => {
      store.dispatch(`railLine/${RailLineActionType.setSelectedLine}`, value)
    }

    const handleSearch = () => {
      setSelectedRailwayLine(store.state.railLine.selectedLine)
      store.dispatch(`railLine/${RailLineActionType.setChosenLine}`, store.state.railLine.selectedLine)
      router.push('/railway_station')
    }

    return {
      ...toRefs(state),
      lines,
      selectedLine,
      clearAll,
      clickClose,
      handleSearch,
      handleLineChange
    }
  },
})
