import {IAreaType, IFacilityType} from '@/data/area/type'
import {ITransportationChart} from '@/store/modules/area/search/transportation/type'
import data from '@/assets/stubs/areas.json'
import facilities from '@/assets/stubs/facilities.json'
import AreaJsonTranslator from '@/data/area/translator'
import FacilityJsonTranslator from '@/data/area/translator/facility'
import Pins from '@/assets/stubs/area_facility_pin.json'

export interface IAreaStubType {
  fetch(): Promise<IAreaType[]>
  fetchTransportation(): Promise<Array<number[]>>
  fetchFacility(): Promise<IFacilityType[]>
  fetchTransportationChart(): Promise<{in_area: ITransportationChart[]; visit_area: ITransportationChart[]}>
  fetchHeatmap(): Promise<(number | string)[][]>
  fetchFacilityPin(): Promise<Record<string, any>>
}

export default class AreaStubStore implements IAreaStubType {
  protected translator: AreaJsonTranslator
  protected facilityTranslator: FacilityJsonTranslator
  constructor () {
    this.translator = new AreaJsonTranslator()
    this.facilityTranslator = new FacilityJsonTranslator()
  }
  
  fetch (): Promise<IAreaType[]> {
    return new Promise<IAreaType[]>((resolve) => {
      resolve(data.map(area => this.translator.translate(area)))
    })
  }
  
  fetchTransportation(): Promise<Array<number[]>> {
    return new Promise<Array<number[]>>((resolve) => {
      resolve([])
    })
  }
  
  fetchFacility(): Promise<IFacilityType[]> {
    return new Promise<IFacilityType[]>((resolve) => {
      resolve((facilities as IFacilityType[]).map(f => this.facilityTranslator.translate(f)))
    })
  }
  
  fetchTransportationChart(): Promise<{ in_area: ITransportationChart[]; visit_area: ITransportationChart[] }> {
    return Promise.resolve({
      in_area: [],
      visit_area: []
    });
  }
  
  fetchHeatmap(): Promise<(number | string)[][]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Promise.resolve([])
  }
  
  fetchFacilityPin(): Promise<Record<string, any>> {
    return Promise.resolve(Pins)
  }
}
