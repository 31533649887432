import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08d3317c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "jr-flex jr-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimingDoughnutChart = _resolveComponent("TimingDoughnutChart")!
  const _component_AreaSearchFilter = _resolveComponent("AreaSearchFilter")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "transition-left transition-right jr-flex jr-justify-center jr-pb-4 jr-bg-white jr-flex-1 jr-w-full jr-h-full jr-bottom-0 jr-right-0",
      style: _normalizeStyle({ marginLeft: _ctx.sbMinimum ? '64px' : '240px', marginRight: _ctx.filterMinimum ? '60px' : '292px', marginTop: _ctx.isCapturing ? '72px' : 0 })
    }, [
      _createVNode(_component_TimingDoughnutChart, {
        label: "エリア内の滞在時間",
        data: _ctx.data,
        average: _ctx.average,
        labels: _ctx.labels
      }, null, 8, ["data", "average", "labels"])
    ], 4),
    _createVNode(_component_AreaSearchFilter, {
      onSearch: _ctx.handleSearch,
      onResize: _ctx.handleFilterResize
    }, null, 8, ["onSearch", "onResize"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}