import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "jr-relative jr-flex jr-flex-col jr-justify-start" }
const _hoisted_2 = { class: "jr-text-center jr-text-primary jr-mb-5" }
const _hoisted_3 = { class: "jr-flex jr-items-center" }
const _hoisted_4 = { class: "jr-ml-4" }
const _hoisted_5 = { class: "jr-whitespace-nowrap jr-align-middle jr-ml-2 jr-text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DoughnutChart, _mergeProps({ style: _ctx.doughnutStyle }, _ctx.doughnutChartProps), null, 16, ["style"]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.datasets[0].data, (d, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            class: "jr-flex jr-items-center jr-mt-2"
          }, [
            _createElementVNode("div", {
              class: "jr-w-4 jr-h-4 jr-inline-block jr-align-middle",
              style: _normalizeStyle({ 'background-color': _ctx.chartData.datasets[0].backgroundColor[i] })
            }, null, 4),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.chartData.labels[i]) + " " + _toDisplayString(d) + "%", 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}