
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { stringToBoolean } from '@/utils/string'
export default defineComponent({
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: () => (uuidv4())
    },
    name: {
      type: String,
      required: false,
      default: () => (uuidv4())
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: () => ('tick'),
      validator: (value: string) => ['tick', 'minus', 'bin'].includes(value)
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    labelClasses: {
      type: String,
      required: false,
      default: ''
    },
    align: {
      type: String,
      required: false,
      default: 'center',
      validator: (value: string) => ['center', 'top', 'bottom'].includes(value)
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const handleChange = (e: Event) => {
      const val = (e.target as HTMLInputElement).value
      const res = !stringToBoolean(val)
      emit('update:modelValue', res)
      emit('change', res)
    }
    return { handleChange }
  }
})
