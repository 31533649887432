
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'
import {IRailwayComparisonCompareType} from '@/data/railway/type'
import UIComparisonChart from '@/components/UI/railway/UIComparisonChart.vue'

export default defineComponent({
  name: 'ComparisonBarchart',
  components: {UIComparisonChart},
  setup () {
    const store = useStore()
    const comparison = computed<IRailwayComparisonCompareType>(() => store.state.railway.comparison.comparison)
    return { comparison }
  }
})
