import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534db4aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide_container" }
const _hoisted_2 = { class: "wrpr" }
const _hoisted_3 = { class: "check_box_wrpr" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "item",
          key: option.value
        }, [
          _createElementVNode("label", null, [
            _createElementVNode("span", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                value: option.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event))
              }, null, 8, _hoisted_4), [
                [_vModelCheckbox, _ctx.selectedItems]
              ])
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}