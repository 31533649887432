import {HttpRequest} from '@/core/httpRequest'
import {IRailwayAnimationType, IRailwayComparisonType, IRailwayStationBarchartType} from '@/data/railway/type'
import {HTTPMethod} from '@/core/httpClient'
import {getAccessToken} from '@/utils/storage'
import {APIType} from '@/core/apiType'

export class RailwayStationBarchartRequest implements HttpRequest<IRailwayStationBarchartType> {
  public response!: IRailwayStationBarchartType
  public path = '/railway/stations/chart/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.RailwayStationBarchart
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class RailwayHeatmapRequest implements HttpRequest<number[][]> {
  public response!: number[][]
  public path = '/railway/heatmap/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.RailwayHeatmap
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class RailwayComparisonRequest implements HttpRequest<IRailwayComparisonType> {
  public response!: IRailwayComparisonType
  public path = '/railway/report/chart/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.RailwayComparison
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}

export class RailwayAnimationRequest implements HttpRequest<IRailwayAnimationType> {
  public response!: IRailwayAnimationType
  public path = '/railway/report/heatmap/'
  public method = HTTPMethod.POST
  public bearer = getAccessToken()
  public params: Record<string, any>
  public apiType = APIType.RailwayAnimation
  
  constructor (params: Record<string, any>) {
    this.params = params
  }
}
